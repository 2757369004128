import React from 'react'
import {arrayOf, bool, func, number, object, string} from 'prop-types'

import AminoSliders from 'components/AminoSliders'
import FormControl from 'components/FormControl'
import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import { TextInput } from 'components/text-input'
import AminoStack from './AminoStack'
import StackActions from './StackActions'
import cn from './VariableElement.css'

export default function VariableElement ({
  activeAminoMap,
  activeAminoMapLimit,
  activePreset,
  aminoStacks,
  disableAccept,
  disabled,
  name,
  modalAcceptText,
  modalSubtitle,
  modalTitle,
  onAminoMapUpdate,
  onFieldChange,
  onModalAccept,
  onModalCancel,
  onPresetChange,
  onStackAdd,
  onStackDelete,
  onStackReset,
  onStackSelect,
}) {
  return (
    <ModalLayout
      acceptButtonLabel={modalAcceptText}
      className={cn.base}
      disableAccept={disableAccept}
      onAccept={onModalAccept}
      onClose={onModalCancel}
    >
      <ModalIconHeader
        icon='square'
        subtitle={modalSubtitle}
        title={modalTitle}
      />

      <FormControl className={cn.control} icon='label' idFor='name' label='Name'>
        <TextInput
          disabled={disabled}
          id='name'
          name='name'
          onChange={onFieldChange}
          placeholder='Name is required'
          value={name}
        />
      </FormControl>

      <FormControl icon='view_column' idFor='desc' label='Aminos'>
        <div className={cn.aminos}>
          {aminoStacks.map(stack => (
            <AminoStack
              {...stack}
              disabled={disabled}
              key={stack.id}
              onDelete={onStackDelete}
              onSelect={onStackSelect}
            />
          ))}
        </div>
      </FormControl>

      <div className={cn.body}>
        <StackActions
          disabled={disabled}
          onPresetChange={onPresetChange}
          onStackAdd={onStackAdd}
          onStackReset={onStackReset}
          preset={activePreset}
        />
        <AminoSliders
          aminos={activeAminoMap}
          className={cn.sliders}
          disabled={disabled}
          limit={activeAminoMapLimit}
          onUpdateSlider={onAminoMapUpdate}
        />
      </div>
    </ModalLayout>
  )
}

VariableElement.propTypes = {
  activeAminoMap: object.isRequired,
  activeAminoMapLimit: number.isRequired,
  activePreset: string,
  aminoStacks: arrayOf(object).isRequired,
  disableAccept: bool,
  disabled: bool,
  modalAcceptText: string,
  modalSubtitle: string,
  modalTitle: string,
  name: string,
  onAminoMapUpdate: func.isRequired,
  onFieldChange: func.isRequired,
  onModalAccept: func.isRequired,
  onModalCancel: func.isRequired,
  onPresetChange: func.isRequired,
  onStackAdd: func.isRequired,
  onStackDelete: func.isRequired,
  onStackReset: func.isRequired,
  onStackSelect: func.isRequired,
}

VariableElement.defaultProps = {
  activePreset: '',
  disableAccept: false,
  disabled: false,
  modalAcceptText: '',
  modalSubtitle: '',
  modalTitle: '',
  name: '',
}
