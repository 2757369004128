import { bool } from 'prop-types'
import React from 'react'

import FaIconButton from 'components/FaIconButton'
import FaIconAndLabelButton from 'components/FaIconAndLabelButton'

export default function CollapsibleFaIconAndLabelButton ({ expanded, ...props }) {
  return expanded
    ? <FaIconAndLabelButton {...props} />
    : <FaIconButton {...props} />
}

CollapsibleFaIconAndLabelButton.propTypes = {
  expanded: bool,
}

CollapsibleFaIconAndLabelButton.defaultProps = {
  expanded: false,
}
