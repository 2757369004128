import React, {Component} from 'react'
import {bool, func, node, number, oneOfType, string} from 'prop-types'
import cx from 'classnames'

import cn from './BaseButton.css'

export default class BaseButton extends Component {
  static propTypes = {
    bodyClass: string,
    children: node.isRequired,
    className: string,
    disabled: bool,
    innerRef: func,
    name: string,
    onBlur: func,
    onClick: func,
    primary: bool,
    raised: bool,
    type: string,
    value: oneOfType([number, string]),
  }

  static defaultProps = {
    bodyClass: '',
    className: '',
    disabled: false,
    innerRef: () => console.debug('Inner ref callback'),
    name: '',
    onClick: () => console.debug('BaseButton clicked'),
    primary: false,
    raised: false,
    type: 'button',
    value: '',
  }

  handleClick = (e) => {
    this.props.onClick(e)
    // document.activeElement.blur()
  }

  render () {
    const {
      bodyClass,
      children,
      className,
      disabled,
      innerRef,
      name,
      onBlur,
      onClick,
      type,
      primary,
      raised,
      value,
    } = this.props

    return (
      <button
        className={cx(cn.base, className, {
          [cn.primary]: primary,
          [cn.raised]: raised,
        })}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onClick={this.handleClick}
        ref={innerRef}
        tabIndex={disabled ? -1 : 0}
        type={type}
        value={value}
      >
        <div className={cn.focus} />
        <div className={cx(cn.body, bodyClass)}>
          {children}
        </div>
      </button>
    )
  }
}
