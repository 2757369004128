import React from 'react'
import {arrayOf, func, node, object, string} from 'prop-types'

import Nav from './ElementModalNav'
import cn from './ElementModal.css'

export default function ElementModal ({
  activePanel,
  children,
  navOptions,
  onModalNavigate,
}) {
  return (
    <div className={cn.base}>
      <Nav activePanel={activePanel} onNavigate={onModalNavigate} options={navOptions} />
      <div className={cn.body}>
        {children}
      </div>
    </div>
  )
}

ElementModal.propTypes = {
  activePanel: string.isRequired,
  children: node,
  navOptions: arrayOf(object),
  onModalNavigate: func.isRequired,
}

ElementModal.defaultProps = {
  children: null,
  navOptions: [],
}
