import cx from 'classnames'
import {
  bool,
  func,
  node,
  number,
} from 'prop-types'
import React from 'react'

import { noop } from 'utils'

import cn from './Tab.css'

export default function Tab ({
  children,
  index,
  isActive,
  isDisabled,
  onSelect,
  ...props
}) {
  return (
    <button
      {...props}
      className={cx(cn.root, {
        [cn.root_active]: isActive,
        [cn.root_disabled]: isDisabled,
      })}
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault()
          return
        }

        onSelect(index)
      }}
      type="button"
    >
      {children}
    </button>
  )
}

Tab.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  isActive: bool,
  isDisabled: bool,
  onSelect: func,
}

Tab.defaultProps = {
  isActive: false,
  isDisabled: false,
  onSelect: noop,
}
