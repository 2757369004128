import { arrayOf, func, string } from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { setSequenceSelection } from 'actions/designer'
import { openModal } from 'actions/modal'
import { ElementContext } from 'common'
import { createAAConstruct } from 'core/construct'
import { CursorDataType } from 'components/SequenceEditor/types'
import { getElementContext } from 'selectors/designer'
import { getEditingTemplate, getSelection } from 'selectors/templates'
import { ElementContextType, TagType } from 'types'

export class SequenceEditorProvider extends Component {
  static propTypes = {
    children: func.isRequired,
    elementContext: ElementContextType.isRequired,
    onModalOpen: func.isRequired,
    onSequenceSelect: func.isRequired,
    selection: CursorDataType.isRequired,
    sequence: string.isRequired,
    tags: arrayOf(TagType).isRequired,
  }

  /**
   * Opens up the sequence editor with the element tag the cursor is currently
   * positioned at in the SequenceViewer.
   */
  handleSequenceEdit = (index) => {
    const { onModalOpen, sequence, tags } = this.props

    const editTag = tags.reduce((_editTag, tag) => (
      _editTag || (index >= tag.offset && index < tag.offset + tag.length ? tag : null)
    ), null)

    if (!editTag) {
      return
    }

    const { id, length, offset } = editTag
    const end = offset + length

    onModalOpen('tag', {
      initialCursorIndex: index,
      editorState: 'edit',
      end,
      id,
      sequence: sequence.slice(offset, offset + length),
      start: offset,
    })
  }

  render () {
    const {
      children,
      elementContext,
      onSequenceSelect,
      selection,
      sequence,
      tags,
    } = this.props

    return children({
      disabled: true,
      elementContext,
      onCursorUpdate: onSequenceSelect,
      onSequenceEditStart: this.handleSequenceEdit,
      selection,
      sequence,
      tags,
    })
  }
}

const mapState = (state) => {
  const elementContext = getElementContext(state)
  const construct = getEditingTemplate(state)

  const { sequence, tags } = elementContext === ElementContext.AA
    ? createAAConstruct(construct)
    : construct

  // If a tagId exists, than we use the tag's position as the selection.
  const { end, start, tagId } = getSelection(state)
  const maybeSelectTag = tags[tagId] || {}
  const { length: selectLength, offset: selectOffset } = maybeSelectTag


  return {
    elementContext,
    selection: {
      end: tagId ? selectLength + selectOffset : end,
      start: tagId ? selectOffset : start,
    },
    sequence,
    tags: Object.keys(tags).map(key => tags[key]),
  }
}

const mapDispatch = {
  onModalOpen: openModal,
  onSequenceSelect: setSequenceSelection,
}

export default connect(mapState, mapDispatch)(SequenceEditorProvider)
