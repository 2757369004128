import {connect} from 'react-redux'

import {openModal} from 'actions/modal'
import {reqUpdateProject} from 'actions/project'
import {getProjectId} from 'selectors/designer'
import {getProjectOptimizationSettings} from 'selectors/project'

export default connect((state) => {
  const projectId = getProjectId(state)

  return {
    projectId,
    ...getProjectOptimizationSettings(state, {id: projectId}),
  }
}, {
  onModalOpen: openModal,
  onProjectUpdate: reqUpdateProject,
})
