export const getStorageItem = (key) => {
  if (!window.localStorage) {
    return null
  }

  return JSON.parse(window.localStorage.getItem(key))
}

export const removeStorageItem = (key) => {
  if (!window.localStorage) {
    return null
  }

  return window.localStorage.removeItem(key)
}

export const setStorageItem = (key, item) => {
  if (!window.localStorage) {
    return null
  }

  return window.localStorage.setItem(key, JSON.stringify(item))
}
