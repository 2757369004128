import React, { Component } from 'react'
import { DndProvider } from 'react-dnd'
import Html5Backend from 'react-dnd-html5-backend'
import { object } from 'prop-types'

import DesignerCore from 'components/DesignerCore'
import DesignerSidebarMenu from 'components/DesignerSidebarMenu'
import DesignActions from 'components/DesignActions'
import ProjectLoader from 'components/ProjectLoader'

import ElementDragLayer from 'components/ElementDragLayer'
import cn from './DesignerView.css'

class DesignerView extends Component {
  static propTypes = {
    match: object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      expandLeft: false,
    }
  }

  toggleSidePanel = () => {
    this.setState({ expandLeft: !this.state.expandLeft })
  }

  render() {
    return (
      <ProjectLoader params={this.props.match.params}>
        {() => (
          <DndProvider backend={Html5Backend}>
            <div className={cn.base}>
              <DesignerSidebarMenu className={cn.leftPanel} />
              <div className={cn.body}>
                <DesignActions className={cn.actions} />
                <DesignerCore className={cn.designer} />
              </div>
              <ElementDragLayer />
            </div>
          </DndProvider>
        )}
      </ProjectLoader>
    )
  }
}

export { DesignerView }

export default DesignerView
