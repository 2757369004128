import React, {Component} from 'react'
import {arrayOf, func, string} from 'prop-types'

import AminoRadioSelect from './AminoRadioSelect'

export default class AminoRadioSelectContainer extends Component {
  static propTypes = {
    label: string.isRequired,
    name: string.isRequired,
    onCodonSelect: func.isRequired,
    options: arrayOf(string).isRequired,
    value: string.isRequired,
  }

  shouldComponentUpdate ({value}) {
    return value !== this.props.value
  }

  get options () {
    const defaultOption = ['']

    const {options, value} = this.props

    return defaultOption.concat(options).map(option => ({
      ...option,
      active: option === value,
      key: option,
      label: option || 'Random',
      value: option,
    }))
  }

  render () {
    return <AminoRadioSelect {...this.props} options={this.options} />
  }
}
