import React, { Component } from 'react'
import {
  bool,
  func,
  node,
  string,
} from 'prop-types'

import { withDrop } from 'hoc/withDragAndDrop'

/**
 * Clears the index on the dragged item. Using this to allow dragged element
 * to leave and enter the sequence.
 */
export class DragBoundary extends Component {
  static propTypes = {
    children: node.isRequired,
    className: string,
    clearDragItemIndex: func.isRequired,
    isOver: bool.isRequired,
    onDragLeave: func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  componentWillReceiveProps ({ clearDragItemIndex, isOver, onDragLeave }) {
    const { isOver: prevIsOver } = this.props

    if (!isOver && prevIsOver) {
      clearDragItemIndex()
      onDragLeave()
    }
  }

  render () {
    const { children, className } = this.props

    return (
      <div className={className}>
        {children}
      </div>
    )
  }
}

export default withDrop({})(DragBoundary)
