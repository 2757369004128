import { actions } from 'actions/designer'
import createReducer from 'utils/createReducer'

export default createReducer({ start: 0, end: 0 }, {
  [actions.SET_SEQUENCE_SELECTION]: (state, { start, end }) => ({
    ...state,
    end,
    start,
  }),

  [actions.SELECT_ELEMENT]: (state, { element }) => ({
    ...state,
    end: element.offset + element.length,
    start: element.offset,
  }),
})
