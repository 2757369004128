import {connect} from 'react-redux'

import {reqCreateCustomElement, reqUpdateCustomElement} from 'actions/customElement'

export default connect(null, {
  onElementCreate: reqCreateCustomElement,
  onElementUpdate: reqUpdateCustomElement,
}, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  disableName: false,
  disableDescription: false,
  disableSequence: ownProps.modalType === 'clone',
  disableType: ownProps.modalType === 'clone' || ownProps.modalType === 'edit',
  onModalAccept: ownProps.modalType === 'new' || ownProps.modalType === 'clone'
    ? dispatchProps.onElementCreate
    : dispatchProps.onElementUpdate
}))
