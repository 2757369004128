import classNames from 'classnames'
import { node, string } from 'prop-types'
import React from 'react'

import classes from './SmallTitle.css'

export default function SmallTitle ({ children, className }) {
  return (
    <h5 className={classNames(classes.base, className)}>
      {children}
    </h5>
  )
}

SmallTitle.propTypes = {
  children: node,
  className: string,
}

SmallTitle.defaultProps = {
  children: 'Small title children',
  className: '',
}
