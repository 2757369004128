const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

/**
 * Returns a formatted date string (e.g. Dec 7, 2016 2:59 PM).
 *
 * @return {String}
 */
export default function formatDate (ts) {
  ts = new Date(ts)

  if (isNaN(Date.parse(ts))) {
    throw new Error(`Couldnt create a valid Date from ${ts}`)
  }

  const components = [
    months[ts.getMonth()],
    ts.getDate() + ',',
    ts.getFullYear(),
    formatHours(ts.getHours() % 12) + ':' + formatMinutes(ts.getMinutes()),
    ts.getHours() > 11 ? 'PM' : 'AM'
  ]
  return components.join(' ')

  function formatHours (hours) {
    return hours === 0 ? '12' : hours
  }

  function formatMinutes (minutes) {
    return minutes.toString().length < 2 ? `0${minutes}` : minutes
  }
}
