/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {arrayOf, func, number, oneOfType, shape, string} from 'prop-types'

import FormControl from 'components/FormControl'
import { TextInput } from 'components/text-input'
import cn from './OptimizeDNASettingsModal.css'

const smallInputCss = css`
  display: inline-block;
  margin-left: var(--sp-tiny);
  width: 60px;
`

export default function OptimizeDNASettingsModal ({
  globalGcMax,
  globalGcMin,
  homopolymers,
  onFieldChange,
  onPercentFieldChange,
  onPolymerChange,
  restrictionSites,
  slidingGcMax,
  slidingGcMin,
  slidingGcWindow,
}) {
  return (
    <div className={cn.base}>
      <FormControl
        idFor='restrictionSites'
        label='Restriction sites to avoid'
        labelChildren={(
          <span className={cn.controlSubtitle}>
            Comma separated list of sites.<br />
            Ex. EcoRI, AscII, Mfel
          </span>
        )}
      >
        <TextInput
          id='restrictionSites'
          name='restrictionSites'
          onChange={onFieldChange}
          value={restrictionSites}
        />
      </FormControl>

      <FormControl bodyClass={cn.controlBodyFlex} label='Maximum homopolymer length'>
        {homopolymers.map(polymer => (
          <div className={cn.inputWrapper} key={polymer.key}>
            <span className={cn.inputLabel}>
              {polymer.label}
            </span>
            <TextInput
              css={smallInputCss}
              name={polymer.name}
              onChange={onPolymerChange}
              value={polymer.value}
            />
          </div>
        ))}
      </FormControl>

      <FormControl
        bodyClass={cn.controlBodyFlex}
        idFor='globalGc'
        label='Global GC content'
      >
        <div className={cn.inputWrapper}>
          <span className={cn.inputLabel}>
            Min %
          </span>
          <TextInput
            css={smallInputCss}
            id='globalGc'
            max={100}
            min={0}
            name='globalGcMin'
            onChange={onPercentFieldChange}
            type='number'
            value={globalGcMin}
          />
        </div>

        <div className={cn.inputWrapper}>
          <span className={cn.inputLabel}>
            Max %
          </span>
          <TextInput
            css={smallInputCss}
            max={100}
            min={0}
            name='globalGcMax'
            onChange={onPercentFieldChange}
            type='number'
            value={globalGcMax}
          />
        </div>
      </FormControl>

      <FormControl
        bodyClass={cn.controlBodyInline}
        idFor='slidingGc'
        label='Sliding Window GC content'
      >
        <div className={cn.inline}>
          <span>
            GC content between
          </span>

          <TextInput
            css={smallInputCss}
            id='slidingGc'
            max={100}
            min={0}
            name='slidingGcMin'
            onChange={onPercentFieldChange}
            type='number'
            value={slidingGcMin}
          />

          <span>
            and
          </span>

          <TextInput
            css={smallInputCss}
            max={100}
            min={0}
            name='slidingGcMax'
            onChange={onPercentFieldChange}
            type='number'
            value={slidingGcMax}
          />
        </div>

        <div className={cn.inline}>
          <span>
            percent over
          </span>

          <TextInput
            css={smallInputCss}
            name='slidingGcWindow'
            min={0}
            onChange={onFieldChange}
            type='number'
            value={slidingGcWindow}
          />

          <span>
            bp sliding window.
          </span>
        </div>
      </FormControl>
    </div>
  )
}

OptimizeDNASettingsModal.propTypes = {
  globalGcMax: oneOfType([number, string]).isRequired,
  globalGcMin: oneOfType([number, string]).isRequired,
  homopolymers: arrayOf(shape({
    key: string.isRequired,
    label: string.isRequired,
    name: string.isRequired,
    value: oneOfType([number, string]).isRequired,
  })),
  onFieldChange: func.isRequired,
  onPercentFieldChange: func.isRequired,
  onPolymerChange: func.isRequired,
  restrictionSites: string,
  slidingGcMax: oneOfType([number, string]).isRequired,
  slidingGcMin: oneOfType([number, string]).isRequired,
  slidingGcWindow: oneOfType([number, string]).isRequired,
}

OptimizeDNASettingsModal.defaultProps = {
  globalGcMax: '65',
  globalGcMin: '40',
  homopolymers: [
    {key: 'a', label: 'A', name: 'homopolymer', value: '9'},
    {key: 'c', label: 'C', name: 'homopolymer', value: '9'},
    {key: 'g', label: 'G', name: 'homopolymer', value: '9'},
    {key: 't', label: 'T', name: 'homopolymer', value: '9'},
  ],
  restrictionSites: '',
  slidingGcMax: '80',
  slidingGcMin: '25',
  slidingGcWindow: '50',
}
