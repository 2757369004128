import React, {Component} from 'react'
import {arrayOf, bool, func, object, string} from 'prop-types'

import PollingModal from 'components/PollingModal'
import {getDNAOptimizeJob, pollJobResult} from 'services/ApiService'

export default class OptimizeDNAModalContainer extends Component {
  static propTypes = {
    aminoSequence: string.isRequired,
    expressionSystem: string.isRequired,
    isModalOpen: bool,
    onCancel: func,
    onClose: func.isRequired,
    onModalOpen: func.isRequired,
    onSequenceSave: func.isRequired,
    optimizationConfig: object,
    optimizationSettings: arrayOf(string),
  }

  static defaultProps = {
    isModalOpen: false,
    onCancel: () => console.debug('Cancel optimize action'),
    optimizationConfig: {},
    optimizationSettings: [],
  }

  state = {
    fulfilledData: {},
    modalState: 'idle',
    results: [],
  }

  componentDidMount () {
    this.handleOptimization()
  }

  async getOptimizedDNA ({
    aminoSequence: aminos,
    expressionSystem,
    optimizationConfig,
  }) {
    this.setState({modalState: 'searching'})

    try {
      const {data: jobData} = await getDNAOptimizeJob({
        aminos,
        expressionSystem,
        config: optimizationConfig,
      })

      const {
        data: dnaSequence,
        executionTime,
        didFinish,
      } = await pollJobResult(jobData.jobId)

      if (!this.props.isModalOpen) {
        return
      }

      if (!didFinish) {
        const error = new Error('Incomplete')
        error.message = 'An issue occurred during our optimization process. Please try again.'

        throw error
      }

      const {optimizationSettings} = this.props

      this.setState({
        fulfilledData: dnaSequence,
        modalState: 'success',
        results: [
          {
            icon: 'settings',
            key: 'settings',
            label: 'Optimization Constraints',
            value: (
              <ul>
                {optimizationSettings.map((setting, i) => (
                  <li key={i}>{setting}</li>
                ))}
              </ul>
            )
          },
          {
            icon: 'timelapse',
            key: 'time',
            label: 'Execution Time',
            value: <span>{executionTime.toFixed(4)}s</span>,
          },
          {
            icon: 'format_size',
            key: 'sequence',
            label: 'Optimized DNA',
            value: <span>{dnaSequence}</span>,
          },
        ],
      })
    }
    catch (e) {
      if (!this.props.isModalOpen) {
        return
      }

      this.setState({
        modalState: 'error',
        results: [
          {
            icon: 'error',
            key: 'error',
            label: 'Error',
            value: e.message
          }
        ],
      })
    }
  }

  handleOptimization = () => {
    this.getOptimizedDNA(this.props)
  }

  handleAccept = () => {
    this.props.onSequenceSave({
      dnaSequence: this.state.fulfilledData,
      aminoSequence: this.props.aminoSequence,
    })
    this.props.onClose()
  }

  handleCancel = () => {
    this.props.onCancel()
    this.props.onClose()
  }

  handleRetry = () => {
    const {aminoSequence, expressionSystem, onModalOpen} = this.props

    onModalOpen('optimizeDNASettings', {aminoSequence, expressionSystem})
  }

  get modalAcceptHandler () {
    return this.state.modalState === 'success'
      ? this.handleAccept
      : this.state.modalState === 'error'
        ? this.handleRetry
        : this.handleCancel
  }

  render () {
    const {modalState, results} = this.state

    return (
      <PollingModal
        errorSubtitle='An issue occurred during our optimization process. Please try again.'
        errorTitle='DNA Optimization Error'
        idleTitle='Optimizing DNA'
        onAccept={this.modalAcceptHandler}
        onCancel={this.handleCancel}
        pollState={modalState}
        results={results}
        searchingSubtitle='Please be patient while we optimize for your design.'
        searchingTitle='Optimizing DNA'
        successSubtitle='Click done to save this to your design.'
        successTitle='DNA Optimization Complete'
      />
    )
  }
}
