/**
 * Share user is a user who any entity is shared with (e.g. project, element, etc.).
 */

import { shape, string } from 'prop-types'

const ShareUserType = shape({
  access: string.isRequired,
  username: string.isRequired,
})

export default ShareUserType
