import { push as pushRoute } from 'connected-react-router'
import { arrayOf, bool, func, number, string } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { openModal } from 'actions/modal'
import { reqDeleteProject, reqUpdateProject } from 'actions/project'
import { updateExpressionSystem } from 'actions/templates'
import { addToast } from 'actions/toast'
import BaseButton from 'components/BaseButton'
import InlineEditText from 'components/InlineEditText'
import { InputOptionType } from 'types'
import { getProjectId } from 'selectors/designer'
import { getProjectById, getProjectCodonMaps } from 'selectors/project'
import { getTemplateExpression } from 'selectors/templates'
import { hasOwnerAccess, hasWriteAccess } from 'utils/checkAccess'

import ExpressionInput from './ProjectSettingsExpressionInput'
import Section from './ProjectSettingsSection'
import cn from './ProjectSettings.css'

class ProjectSettings extends Component {
  static propTypes = {
    activeExpression: string.isRequired,
    disableExpressionChange: bool,
    disableNameChange: bool,
    disableProjectDelete: bool,
    expressions: arrayOf(InputOptionType).isRequired,
    onModalOpen: func.isRequired,
    onNavigateTo: func.isRequired,
    onProjectDelete: func.isRequired,
    onProjectExpressionChange: func.isRequired,
    onProjectUpdate: func.isRequired,
    onToastAdd: func.isRequired,
    projectId: number.isRequired,
    projectName: string.isRequired,
  }

  static defaultProps = {
    disableExpressionChange: false,
    disableNameChange: false,
    disableProjectDelete: false,
  }

  /**
   * Deletes the active project. The delete function from props is wrapped in a
   * promise to allow us to redirect the user back to the home view after the
   * project successfully deletes.
   */
  deleteProject = () => {
    const { projectId, onProjectDelete, onNavigateTo, onToastAdd } = this.props

    Promise.resolve(onProjectDelete(projectId))
      .then(() => {
        onNavigateTo('/')

        return onToastAdd({
          type: 'success',
          message: 'Project deleted',
        })
      })
      .catch(err =>
        onToastAdd({
          type: 'error',
          message: err,
        }),
      )
  }

  handleProjectDelete = () => {
    this.openModal('delete', {
      modalSubtitle: 'Deleting this project is a permanent action.',
      modalTitle: 'Delete Project',
      onAccept: this.deleteProject,
    })
  }

  handleExpressionCreate = () => {
    this.openModal('codon')
  }

  handleExpressionChange = ({ target }) => {
    const { value: expressionSystem } = target
    const { onProjectExpressionChange } = this.props

    onProjectExpressionChange(expressionSystem)
  }

  handleProjectNameUpdate = update => {
    const { onProjectUpdate, projectId } = this.props

    onProjectUpdate(projectId, update)
  }

  openModal(...args) {
    const { onModalOpen } = this.props

    onModalOpen(...args)
  }

  render() {
    const {
      activeExpression,
      disableExpressionChange,
      disableNameChange,
      disableProjectDelete,
      expressions,
      projectName,
    } = this.props

    return (
      <div className={cn.base}>
        <Section subtitle="Click name to edit." title="Project Name">
          <InlineEditText
            isDisabled={disableNameChange}
            onUpdate={this.handleProjectNameUpdate}
            propName="name"
            value={projectName}
          />
        </Section>

        <Section
          subtitle="Select an expression to optimize your generated sequences or
          create a custom one to use."
          title="Codon Expression System"
        >
          <ExpressionInput
            activeExpression={activeExpression}
            disabled={disableExpressionChange}
            expressions={expressions}
            onExpressionCreate={this.handleExpressionCreate}
            onExpressionChange={this.handleExpressionChange}
          />
        </Section>

        <Section
          subtitle="Use with caution. This is a permanent action. Deleted projects
          cannot be restored!"
          title="Delete Project"
        >
          <BaseButton
            className={cn.delete_btn}
            disabled={disableProjectDelete}
            onClick={this.handleProjectDelete}
          >
            Delete Project
          </BaseButton>
        </Section>
      </div>
    )
  }
}

const mapState = state => {
  const projectId = getProjectId(state)
  const projectProps = { id: projectId }

  const { access, name: projectName } = getProjectById(state, projectProps)

  const isAuthUserOwner = hasOwnerAccess(access)
  const canAuthUserEdit = hasWriteAccess(access)

  return {
    activeExpression: getTemplateExpression(state),
    disableExpressionChange: !canAuthUserEdit,
    disableNameChange: !isAuthUserOwner,
    disableProjectDelete: !isAuthUserOwner,
    expressions: getProjectCodonMaps(state, projectProps),
    projectId,
    projectName,
  }
}

const mapDispatch = {
  onModalOpen: openModal,
  onNavigateTo: pushRoute,
  onProjectDelete: reqDeleteProject,
  onProjectExpressionChange: updateExpressionSystem,
  onProjectUpdate: reqUpdateProject,
  onToastAdd: addToast,
}

export { ProjectSettings }

export default connect(
  mapState,
  mapDispatch,
)(ProjectSettings)
