/**
 *  Used in Select field where the value is the displayed text and the key is the value of the item.
 *  CRITICAL: the keys need to match the ones in ExpressionSystem Enum in bio.expression.py
 */
const EXPRESSION_SYSTEMS = {
  none: 'None',
  e_coli: 'E. coli',
  h_sapiens: 'H. sapiens',
  s_cerevisiae: 'S. Cerevisiae',
}

const DEFAULT_EXPRESSION_SYSTEM = 'e_coli'

const ELEMENT_TYPES = {
  CUSTOM_DNA: 'custom.dna',
  CUSTOM_AMINO: 'custom.amino',
  VARIABLE: 'variable',
  BARCODE_50: 'barcode.50',
  BARCODE_100: 'barcode.100',
  ENZYME: 'enzyme',
  IGEM: 'igem',
  NCBI: 'ncbi',
}

const MOUSE_BUTTONS = {
  left: 0,
  right: 2,
}

const TAG_TYPES = {
  motif: 'features.motif',
  user: 'custom.user',
}

const PROJECT_TYPES = {
  library: 'library',
  singleSequence: 'single_sequence',
}

const DEFAULT_PROJECT_TYPE = PROJECT_TYPES.singleSequence

const DRAG_TYPES = {
  element: '@@drag/ELEMENT',
}

const ACCESS_TYPES = {
  owner: 'rwao',
  admin: 'rwa',
  write: 'rw',
  read: 'r',
}

const DEFAULT_USER_ACCESS_OPTIONS = [
  {key: 'readonly', label: 'View Only', value: 'r'},
  {key: 'readwrite', label: 'View/Edit', value: 'rw'},
]

const DEFAULT_OPTIMIZATION_SETTINGS = {
  globalGc: {
    max: '65',
    min: '40',
  },
  homopolymers: [
    {label: 'A', value: '9'},
    {label: 'C', value: '6'},
    {label: 'G', value: '9'},
    {label: 'T', value: '9'},
  ],
  restrictionSites: 'BsaI, AarI',
  slidingGc: {
    max: '80',
    min: '25',
    window: '50',
  },
}

export {
  ACCESS_TYPES,
  DEFAULT_EXPRESSION_SYSTEM,
  DEFAULT_OPTIMIZATION_SETTINGS,
  DEFAULT_PROJECT_TYPE,
  DEFAULT_USER_ACCESS_OPTIONS,
  DRAG_TYPES,
  ELEMENT_TYPES,
  EXPRESSION_SYSTEMS,
  MOUSE_BUTTONS,
  PROJECT_TYPES,
  TAG_TYPES,
}
