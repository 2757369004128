const regex = {
  // Regex accepts whitespace and newline characters in case user is copy/pasting
  // from another source
  amino: /^[FLSYCWPHQRIMTNKVADEGX*]*$/i,
  dna: /^[ATGCPDN]*$/i,
}

export default function validateSequence({ type = 'dna', sequence = '' }) {
  return regex[type.toLowerCase()] && regex[type.toLowerCase()].test(sequence)
}
