/**
 * Returns an object of action types for Redux.
 *
 * @param {Array}   actions
 * @param {String}  prefix
 * @return {Object} action constants
 */
export default function createActionTypes (actions = [], prefix = '') {
  return actions.reduce((actionTypes, action) => {
    return {
      ...actionTypes,
      [action]: `@${prefix}/${action}`,
    }
  }, {})
}
