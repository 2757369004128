import React from 'react'

import BaseButton from './BaseButton'

/**
 * A component that renders a BaseButton component with an raised style.
 * @prop       {Object} props          BaseButton props
 */
export default function RaisedButton (props) {
  return <BaseButton {...props} raised />
}
