import React from 'react'

import cn from './Spinner.css'

export default function Spinner () {
  return (
    <div className={cn.base}>
      <div className={cn.spinner}>
        Loading...
      </div>
    </div>
  )
}
