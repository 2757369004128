import {ElementContext} from 'common'
import {JOB_POLL_INTERVAL} from 'config'
import {request} from 'store/apiMiddleware'
import createActionTypes from 'utils/createActionTypes'
import {getStorageItem} from 'common'

/**
 * @constants
 */
export const actions = createActionTypes([
  'ADD_TEMPLATE',
  'DELETE_TAG',
  'DELETE_TEMPLATE',
  'DELETE_TEMPLATE_ELEMENT',
  'DUPLICATE_TEMPLATE',
  'SELECT_ELEMENT',
  'SELECT_TEMPLATE',
  'SEQ_JOB_POLLING_FINISH',
  'SEQ_JOB_POLLING_START',
  'SET_ACTIVE_PROJECT',
  'SET_ELEMENT_CONTEXT',
  'SET_EXPRESSION_SYSTEM',
  'SET_SEQUENCE_SELECTION',
  'SET_TEMPLATE_VARIATIONS',
  'UPDATE_TEMPLATE',
  'UPDATE_TEMPLATE_ELEMENT',
  'UPSERT_TAG',
], 'designer')

/**
 * Adds a new template to the project.
 *
 * @return {Action}
 */
export function addTemplate () {
  return {
    type: actions.ADD_TEMPLATE,
  }
}

/**
 * Deletes a tag from the template.
 *
 * @param {Number}  templateIndex
 * @return {Action}
 */
export function deleteTag (id) {
  return {
    type: actions.DELETE_TAG,
    id,
  }
}

/**
 * Deletes a template from the project.
 *
 * @param {Number}  templateIndex
 * @return {Action}
 */
export function deleteTemplate (templateIndex) {
  return {
    type: actions.DELETE_TEMPLATE,
    index: templateIndex,
  }
}

/**
 * Deletes an element from the template.
 *
 * @param {Number}  id
 * @return {Action}
 */
export function deleteTemplateElement (id) {
  return {
    type: actions.DELETE_TEMPLATE_ELEMENT,
    id,
  }
}

/**
 * Duplicates a template from the project.
 *
 * @param {Number}  templateIndex
 * @return {Action}
 */
export function duplicateTemplate (templateIndex) {
  return {
    type: actions.DUPLICATE_TEMPLATE,
    index: templateIndex,
  }
}

/**
 * Initiates a poll for a job that when completed, dispatches the job result.
 *
 * @param {Number}  jobId
 */
export function pollForSequenceJobCompletion (jobId) {
  const requestOpts = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getStorageItem('auth0token')}`,
      'Content-Type': 'application/json',
    },
  }

  return (dispatch) => {
    dispatch({type: actions.SEQ_JOB_POLLING_START})
    console.debug(`Polling jobId=${jobId} for changes every ${JOB_POLL_INTERVAL}ms`)

    const jobPollTimer = setInterval(async () => {
      try {
        const jobResult = await request(`/api/jobs/${jobId}`, requestOpts)

        if (jobResult) {
          clearInterval(jobPollTimer)

          console.debug('Job completed with data=', jobResult)

          dispatch({
            type: actions.SEQ_JOB_POLLING_FINISH,
            jobResult,
          })
        }
        else {
          console.debug('Job still working with info.')
          return false
        }
      }
      catch (error) {
        console.debug(error)

        clearInterval(jobPollTimer)
      }
    }, JOB_POLL_INTERVAL)
  }
}

/**
 * Sets an element as the active element.
 *
 * @param {Object}    element
 * @return {Action}
 */
export function selectElement (element) {
  return {
    type: actions.SELECT_ELEMENT,
    element,
  }
}

/**
 * Sets a template as the active template.
 *
 * @param {Number}  templateIndex
 * @return {Action}
 */
export function selectTemplate (templateIndex) {
  return {
    type: actions.SELECT_TEMPLATE,
    index: templateIndex,
  }
}

/**
 * Sets the active project to designer.
 *
 * @param {Object}    projectData
 * @return {Action}
 */
export function setActiveProject (project) {
  return {
    type: actions.SET_ACTIVE_PROJECT,
    project,
  }
}

/**
 * Sets the expression system for the project.
 *
 * @param {String}  expressionSystem
 * @return {Action}
 */
export function setExpressionSystem (expressionSystem) {
  return {
    type: actions.SET_EXPRESSION_SYSTEM,
    expressionSystem,
  }
}

/**
 * Sets the number of variations for a template.
 *
 * @param {Number}    variations
 * @return {Action}
 */
export function setVariationsForTemplate (variations) {
  return {
    type: actions.SET_TEMPLATE_VARIATIONS,
    variations,
  }
}

/**
 * Updates the active template.
 *
 * @param {[String]} sequence Array of element ids
 * @param {Object}   element  Element to add
 * @param {Number}   index    Index at which to add element
 * @return {Action}
 */
export function updateTemplate (sequence, element, index) {
  return {
    type: actions.UPDATE_TEMPLATE,
    element,
    index,
    sequence,
  }
}

/**
 * Updates a template element.
 * @param {String} id     Template element id
 * @param {Object} update Element update
 */
export function updateTemplateElement (id, update) {
  return {
    type: actions.UPDATE_TEMPLATE_ELEMENT,
    id,
    update
  }
}

/**
 * Updates or creates a new tag if now tag exists with the id provided.
 *
 * @return {Action}
 */
export function upsertTag (id, tag) {
  return {
    type: actions.UPSERT_TAG,
    id,
    tag,
  }
}

export function setSequenceSelection (start = 0, end = 0) {
  return {
    type: actions.SET_SEQUENCE_SELECTION,
    start: Math.min(start, end),
    end: Math.max(start, end),
  }
}

export const setElementContext = (context = ElementContext.DNA) => ({
  type: actions.SET_ELEMENT_CONTEXT,
  context,
})
