import React, {Component} from 'react'
import {func, object, oneOf, shape, string} from 'prop-types'

import {isVariable} from 'core/element/ElementType'
import ElementModal from './ElementModal'
import CustomElement from './CustomElement'
import ShareElement from './ShareElement'
import VariableElement from './VariableElement'

const NAV_OPTIONS = {
  new: [
    {
      icon: 'plus',
      key: 'create',
      label: 'Create',
      value: 'create',
    }
  ],
  own: [
    {
      icon: 'pencil',
      key: 'edit',
      label: 'Edit',
      value: 'edit',
    },
    {
      icon: 'clone',
      key: 'clone',
      label: 'Clone',
      value: 'clone',
    },
    {
      icon: 'user-plus',
      key: 'share',
      label: 'Share',
      value: 'share',
    },
  ],
  vendor: [
    {
      icon: 'clone',
      key: 'clone',
      label: 'Clone',
      value: 'clone',
    }
  ],
}

export default class ElementModalContainer extends Component {
  static propTypes = {
    element: shape({
      type: string.isRequired,
    }).isRequired,
    initialPanel: oneOf(['create', 'clone', 'share', 'edit']).isRequired,
    modalProps: object,
    modalType: oneOf(['clone', 'new', 'edit']).isRequired,
    onClose: func.isRequired,
    onToastAdd: func.isRequired,
  }

  static defaultProps = {
    initialPanel: 'create',
    modalProps: {},
  }

  constructor (props) {
    super(props)

    this.state = {
      activePanel: props.initialPanel
    }
  }

  get navOptions () {
    const { modalType } = this.props

    if (modalType === 'new') {
      return NAV_OPTIONS.new
    }

    if (modalType === 'clone') {
      return NAV_OPTIONS.vendor
    }

    return NAV_OPTIONS.own
  }

  getModalType ({modalType}) {
    const {activePanel} = this.state

    return activePanel === 'clone' || activePanel === 'share'
      ? activePanel
      : modalType
  }

  getPanelComponent ({element}) {
    const {activePanel} = this.state
    const isEditPanel = activePanel === 'create' || activePanel === 'clone' ||
      activePanel === 'edit'

    return isVariable(element) && isEditPanel
      ? VariableElement
      : isEditPanel
        ? CustomElement
        : ShareElement
  }

  handleModalNavigate = ({target}) => {
    this.setState({activePanel: target.value})
  }

  render () {
    const {element, modalProps, onClose, onToastAdd} = this.props
    const {activePanel} = this.state
    const Panel = this.getPanelComponent(this.props)

    return (
      <ElementModal
        activePanel={activePanel}
        onModalNavigate={this.handleModalNavigate}
        navOptions={this.navOptions}
      >
        <Panel
          {...modalProps}
          {...element}
          key={activePanel}
          modalType={this.getModalType(this.props)}
          onModalCancel={onClose}
          onToastAdd={onToastAdd}
        />
      </ElementModal>
    )
  }
}
