import React, {Component} from 'react'
import {bool, func, string} from 'prop-types'

import FaIconAndLabelButton from 'components/FaIconAndLabelButton'
import cn from './ContextMenuSelection.css'

export default class ContextMenuSelection extends Component {
  static propTypes = {
    disableSequenceCopy: bool,
    disableSequenceCut: bool,
    disableSequenceDelete: bool,
    disableTagAdd: bool,
    onClose: func.isRequired,
    onMotifSearch: func.isRequired,
    onMount: func.isRequired,
    onSequenceCopy: func.isRequired,
    onSequenceCut: func.isRequired,
    onSequenceDelete: func.isRequired,
    onTagModalOpen: func.isRequired,
    sequence: string.isRequired,
  }

  static defaultProps = {
    disableMotifSearch: false,
    disableSequenceCopy: false,
    disableSequenceCut: true,
    disableSequenceDelete: true,
    disableTagAdd: false,
  }

  componentDidMount () {
    this.props.onMount()
  }

  handleNothing = () => {}

  handleAddTag = () => {
    this.props.onTagModalOpen({
      tag: {},
      editorState: 'create',
    })
    this.props.onClose()
  }

  handleCopy = () => {
    this.props.onSequenceCopy(this.props.sequence)
    this.props.onClose()
  }

  handleCut = () => {
    this.props.onSequenceCut()
    this.props.onClose()
  }

  handleDelete = () => {
    this.props.onSequenceDelete()
    this.props.onClose()
  }

  handleMotifSearch = () => {
    this.props.onMotifSearch()
    this.props.onClose()
  }

  handleNothing = () => {}

  render () {
    const {
      disableSequenceCopy,
      disableSequenceCut,
      disableSequenceDelete,
      disableTagAdd,
    } = this.props

    return (
      <div className={cn.base}>
        {!disableTagAdd && <FaIconAndLabelButton
          icon='tag'
          label='Add annotation'
          onClick={this.handleAddTag}
        />}
        {!disableSequenceCopy && <FaIconAndLabelButton
          icon='copy'
          label='Copy bases'
          onClick={this.handleCopy}
        />}
        {!disableSequenceCut && <FaIconAndLabelButton
          icon='cut'
          label='Cut bases'
          onClick={this.handleCut}
        />}
        {!disableSequenceDelete && <FaIconAndLabelButton
          icon='eraser'
          label='Delete bases'
          onClick={this.handleDelete}
        />}
      </div>
    )
  }
}
