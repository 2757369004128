import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import flow from 'lodash/flow'
import omit from 'lodash/omit'

import {reqCustomElements, reqPublicCustomElements} from 'actions/customElement'
import {reqEnzymeSearch, reqIGemSearch, reqNCBISearch} from 'actions/externalElement'
import {reqVariableElements} from 'actions/variableElement'
import {openModal} from 'actions/modal'
import {getCustomElements, getPublicCustomElements} from 'selectors/customElement'
import {getVariableElements} from 'selectors/variableElement'
import {getEnzymeElements, getIGemElements, getNCBIElements} from 'selectors/externalElement'
import {getProjectId} from 'selectors/designer'
import {getProjectType} from 'selectors/project'
import {makeBarcodeElement} from 'core/element/Element'
import {ELEMENT_TYPES, PROJECT_TYPES} from 'core/Constants'
import {dnaToAminos} from 'core/sequence'
import { getGCValue, getMolecularWeight } from 'core/plugins'
import {roundTo} from 'core/utils'
import { fetchCustomElements } from 'providers/store/element/actions'
import { isString } from 'utils'

import {
  getCustomElementTableItems,
  getEnzymeElementTableItems,
  getIGemElementTableItems,
  getNCBIElementTableItems,
} from './helpers'

// Barcode elements are hardcoded for now.
const barcodeElements = [
  makeBarcodeElement({id: 0, length: 50, type: ELEMENT_TYPES.BARCODE_50}),
  makeBarcodeElement({id: 1, length: 100, type: ELEMENT_TYPES.BARCODE_100}),
]

const times100 = x => x * 100
const roundToHundredth = roundTo(2)
const roundToHundredThousandth = roundTo(4)
const noNaN = x => Number.isNaN(x) ? '' : x
const lowercase = str => String.prototype.toLowerCase.call(str)
const getGC = flow(getGCValue, times100, roundToHundredth, noNaN)
const getDNAMol = flow(lowercase, dnaToAminos, getMolecularWeight, roundToHundredThousandth, noNaN)
const getAAMol = flow(lowercase, getMolecularWeight, roundToHundredThousandth, noNaN)

const shapeElementDisplayData = elementSelector => createSelector(
  elementSelector,
  elements => elements.map(element => ({
    ...element,
    // Only custom elements have paths. This prevents methods expecting strings
    // from breaking throughout the ElementExplorer component.
    folderPath: isString(element.folderPath) ? element.folderPath : '',
    gc: getGC(element.sequence),
    molWeight: element.amino ? getAAMol(element.sequence) : getDNAMol(element.sequence),
    sequenceLength: element.length,
  }))
)

const getDirectories = createSelector(
  ({stateProps, dispatchProps, ownProps}) => ({stateProps, dispatchProps, ownProps}),
  ({stateProps, dispatchProps, ownProps}) => {
    const libraryOnlyDirectories = ['variable', 'barcode']
    const directories = {
      custom: {
        elements: stateProps.custom,
        onFetch: dispatchProps.fetchCustom,
      },
      variable: {
        elements: stateProps.variable,
        onFetch: dispatchProps.fetchVariable,
      },
      barcode: {
        elements: stateProps.barcode,
        onFetch: () => {},
      },
      public: {
        elements: stateProps.public,
        onFetch: dispatchProps.fetchPublic,
      },
      enzyme: {
        elements: stateProps.enzyme,
        onFetch: dispatchProps.fetchEnzyme,
      },
      igem: {
        elements: stateProps.igem,
        onFetch: dispatchProps.fetchIgem,
      },
      ncbi: {
        elements: stateProps.ncbi,
        onFetch: dispatchProps.fetchNcbi,
      },
    }

    return stateProps.projectType === PROJECT_TYPES.library
      ? directories
      : omit(directories, libraryOnlyDirectories)
  }
)

export default connect(state => ({
  custom: getCustomElementTableItems(state),
  variable: shapeElementDisplayData(getVariableElements)(state),
  barcode: barcodeElements,
  public: shapeElementDisplayData(getPublicCustomElements)(state),
  enzyme: getEnzymeElementTableItems(state),
  igem: getIGemElementTableItems(state),
  ncbi: getNCBIElementTableItems(state),
  projectType: getProjectType(state, {id: getProjectId(state)}),
}), {
  fetchCustom: fetchCustomElements,
  fetchVariable: reqVariableElements,
  fetchPublic: reqPublicCustomElements,
  fetchEnzyme: reqEnzymeSearch,
  fetchIgem: reqIGemSearch,
  fetchNcbi: reqNCBISearch,
  onModalOpen: openModal,
}, (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onModalOpen: dispatchProps.onModalOpen,
  directories: getDirectories({stateProps, dispatchProps, ownProps}),
}))
