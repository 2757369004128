import {createApiAction} from 'store/apiMiddleware'
import createActionTypes from 'utils/createActionTypes'

/**
 * @constants
 */
export const actions = createActionTypes([
  'LOCK_ERROR',
  'LOCK_SUCCESS',
  'LOGIN_USER',
  'LOGOUT_USER',
  'REQ_AWS_FILE_URL',
  'REQ_AWS_FILE_URL_SUCCESS',
  'REQ_USER_INFO',
  'REQ_USER_INFO_ERROR',
  'REQ_USER_INFO_SUCCESS',
  'SHOW_LOGIN',
], 'authUser')

export const loginUser = user => ({
  type: actions.LOGIN_USER,
  user,
})

/**
 * Creates action with error from auth0 authentication process.
 *
 * @param {String}      error
 * @return {Action}
 */
export function onLockError (error) {
  return {
    type: actions.LOCK_ERROR,
    error,
  }
}

/**
 * Creates action with user profile from auth0.
 *
 * @param {Object}       profile
 * @param {String}       authToken
 * @return {Action}
 */
export function onLockSuccess (profile, authToken) {
  return {
    type: actions.LOCK_SUCCESS,
    authToken,
    profile,
  }
}

/**
 * Creates action to logout user.
 *
 * @return {Action}
 */
export function logoutUser () {
  return {
    type: actions.LOGOUT_USER,
  }
}

/**
 * Returns an action with AWS file key.
 *
 * @param {String} fileKey
 * @return {Action} resolves to file url
 */
export function reqAWSFileUrl (fileKey) {
  return {
    type: actions.REQ_AWS_FILE_URL,
    fileKey,
  }
}

/**
 * Returns an action that signals the download url was successfully requested.
 *
 * @param {String} fileKey
 * @return {Action} resolves to file url
 */
export function reqAWSFileUrlSuccess () {
  return {
    type: actions.REQ_AWS_FILE_URL_SUCCESS,
  }
}

/**
 * If a userInfo object is provided (e.g. from local storage) return a normal
 * action. Otherwise, dispatch a request for the user logging in.
 *
 * @return {Promise}      resolve to user's info.
 */
export function reqTPUserInfo (userInfo) {
  if (userInfo) {
    return {
      type: actions.REQ_USER_INFO_SUCCESS,
      response: userInfo,
    }
  }

  return createApiAction({
    actionTypes: [
      actions.REQ_USER_INFO,
      actions.REQ_USER_INFO_SUCCESS,
      actions.REQ_USER_INFO_ERROR,
    ],
    endpoint: '/me',
  })
}

/**
 * Creates action to show auth lock.
 *
 * @return {Action}
 */
export function showLogin () {
  return {
    type: actions.SHOW_LOGIN,
  }
}
