import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
} from 'prop-types'

import FaIconAndLabelButton from 'components/FaIconAndLabelButton'
import { getBlastJob } from 'services/ApiService'
import cn from './ContextMenuTag.css'

export default class ContextMenuTag extends Component {
  static propTypes = {
    disableFeatureSearch: bool,
    disableOptimize: bool,
    disableTagDelete: bool,
    disableTagDeleteAll: bool,
    disableTagEdit: bool,
    disableTagEditAll: bool,
    expressionSystem: string.isRequired,
    onClose: func.isRequired,
    onModalOpen: func.isRequired,
    onSequenceCopy: func.isRequired,
    onSequenceUpdate: func.isRequired,
    onTagDelete: func.isRequired,
    onTagModalOpen: func.isRequired,
    onMount: func.isRequired,
    relatedTagIds: arrayOf(number).isRequired,
    tag: shape({
      aminos: string,
    }).isRequired,
    tagId: string.isRequired,
    tagSequence: string.isRequired,
  }

  static defaultProps = {
    disableFeatureSearch: true,
    disableOptimize: true,
    disableTagDelete: false,
    disableTagDeleteAll: false,
    disableTagEdit: false,
    disableTagEditAll: false,
  }

  componentDidMount () {
    const { onMount } = this.props

    onMount()
  }

  handleDelete = () => {
    const {
      onClose,
      onTagDelete,
      tagId,
      relatedTagIds,
    } = this.props

    onTagDelete([tagId, ...relatedTagIds])
    onClose()
  }

  handleEdit = () => {
    this.openTagModal()
  }

  handleEditAll = () => {
    const { relatedTagIds } = this.props

    this.openTagModal({
      relatedTagIds,
      editorState: 'editMany',
    })
  }

  handleCopyTagSequence = () => {
    const { onSequenceCopy, tagSequence } = this.props

    onSequenceCopy(tagSequence)
  }

  handleMotifSearch = () => {
    const { onModalOpen, tag } = this.props
    const { aminos, id } = tag

    getBlastJob(aminos)
      .then(({ data }) => onModalOpen('featureSearch', { jobId: data.jobId, tagId: id }))
      .catch(e => console.error(e))
  }

  handleOptimize = () => {
    const {
      expressionSystem,
      onModalOpen,
      onSequenceUpdate,
      tag,
    } = this.props

    onModalOpen('optimizeDNA', {
      aminoSequence: tag.aminos,
      expressionSystem,
      onSequenceSave: ({ dnaSequence: sequence }) => {
        onSequenceUpdate({
          elementId: tag.id,
          sequence,
          offset: tag.offset,
        })
      }
    })
  }

  openTagModal (modalProps) {
    const { onTagModalOpen, tagId: id } = this.props

    onTagModalOpen({
      id,
      editorState: 'edit',
      ...modalProps,
    })
  }

  render () {
    const {
      disableTagDelete,
      disableTagDeleteAll,
      disableTagEdit,
      disableTagEditAll,
      disableOptimize,
      disableFeatureSearch,
      tag,
    } = this.props

    const { hasMappedDNA } = tag

    return (
      <div className={cn.base}>
        {!disableTagEdit && (
          <FaIconAndLabelButton
            icon="pencil"
            label="Edit annotation"
            onClick={this.handleEdit}
          />
        )}
        {!disableTagEditAll && (
          <FaIconAndLabelButton
            icon="pencil"
            label="Edit all copies of this annotation"
            onClick={this.handleEditAll}
          />
        )}
        {!disableTagDelete && (
          <FaIconAndLabelButton
            icon="trash"
            label="Delete annotation"
            onClick={this.handleDelete}
          />
        )}
        {!disableTagDeleteAll && (
          <FaIconAndLabelButton
            icon="trash"
            label="Delete all copies of this annotation"
            onClick={this.handleDelete}
          />
        )}

        <FaIconAndLabelButton
          icon="copy"
          label="Copy bases"
          onClick={this.handleCopyTagSequence}
        />

        {!disableFeatureSearch && (
          <FaIconAndLabelButton
            icon="search"
            label="Auto-detect Conserved Domains"
            onClick={this.handleMotifSearch}
          />
        )}
        {!disableOptimize && (
          <FaIconAndLabelButton
            icon="gears"
            label={hasMappedDNA ? 'Re-optimize DNA' : 'Express DNA'}
            onClick={this.handleOptimize}
          />
        )}
      </div>
    )
  }
}
