import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types'

import { UserAccessType } from 'types'

import User from './User'

class UserContainer extends Component {
  static propTypes = {
    access: UserAccessType.isRequired,
    accessOptions: arrayOf(object).isRequired,
    disabled: bool,
    isAuthUser: bool,
    onEdit: func.isRequired,
    onDelete: func.isRequired,
    username: string.isRequired,
  }

  static defaultProps = {
    disabled: false,
    isAuthUser: false,
  }

  state = {
    updating: false,
  }

  handleAccessDelete = () => {
    const { onDelete, username } = this.props

    this.setState({ updating: true })

    Promise.resolve(onDelete(username))
      .then(() => this.setState({ updating: false }))
      .catch(() => this.setState({ updating: false }))
  }

  handleAccessSelect = ({ target }) => {
    const { value: access } = target
    const { onEdit, username } = this.props

    this.setState({ updating: true })

    Promise.resolve(onEdit({ access, username }))
      .then(() => this.setState({ updating: false }))
      .catch(() => this.setState({ updating: false }))
  }

  render () {
    const {
      access,
      accessOptions,
      disabled,
      isAuthUser,
      username,
    } = this.props

    const { updating } = this.state

    return (
      <User
        access={access}
        accessOptions={accessOptions}
        disabled={disabled}
        onEdit={this.handleAccessSelect}
        onDelete={this.handleAccessDelete}
        overrideDeleteDisable={isAuthUser}
        updating={updating}
        username={username}
      />
    )
  }
}

export default UserContainer
