import {connect} from 'react-redux'

import {setActiveProject} from 'actions/designer'
import {displayError} from 'actions/error'
import {reqProjectDetails} from 'actions/project'
import {setTemplates} from 'actions/templates'
import {getProjectById, getPublicProjectById} from 'selectors/project'

const mapState = (state, props) => {
  const {params} = props
  const {projectId} = params

  const selectorProps = {id: projectId}

  const project = getProjectById(state, selectorProps) ||
    getPublicProjectById(state, selectorProps) ||
    {}

  return {
    project,
    projectId,
  }
}

const mapDispatch = {
  onError: displayError,
  onFetchProject: reqProjectDetails,
  onProjectLoad: setActiveProject,
  onTemplatesLoad: setTemplates,
}

export default connect(mapState, mapDispatch)
