import {connect} from 'react-redux'

import {closeSnackbar, openSnackbar} from 'actions/snackbar'
import {openModal} from 'actions/modal'
import {deleteTagsFromTemplate, updateSequence} from 'actions/templates'
import {
  getAllRelatedTags,
  getIsTagElement,
  getIsTagAmino,
  getTagSequenceSliceAsAminos,
  getTagById,
  getTemplateExpression,
} from 'selectors/templates'

export default connect(
  (state, ownProps) => {
    const isElementTag = getIsTagElement(state, ownProps)
    const relatedTags = getAllRelatedTags(state, ownProps)
    const hasRelatedTags = relatedTags.length > 0

    return {
      disableFeatureSearch: !getIsTagAmino(state, ownProps),
      disableTagDelete: isElementTag,
      disableTagDeleteAll: isElementTag || !hasRelatedTags,
      disableTagEditAll: isElementTag || !hasRelatedTags,
      disableOptimize: !getIsTagAmino(state, ownProps),
      expressionSystem: getTemplateExpression(state),
      relatedTagIds: relatedTags.map(tag => tag.id),
      tag: getTagById(state, ownProps),
      tagSequence: getTagSequenceSliceAsAminos(state, ownProps),
    }
  }, {
    onModalOpen: openModal,
    onSequenceUpdate: updateSequence,
    onSnackbarClose: closeSnackbar,
    onSnackbarOpen: openSnackbar,
    onTagDelete: deleteTagsFromTemplate,
  },
)
