/**
 * Returns a reducer function for Redux.
 *
 * @return {Func}     reducer
 */
export default function createReducer (initialState = {}, actionMap = {}) {
  return (state = initialState, action = {}) => {
    const mapper = actionMap[action.type]

    return mapper ? mapper(state, action) : state
  }
}
