import React from 'react'
import {arrayOf, func, shape, string} from 'prop-types'

import {ACCESS_TYPES} from 'core/Constants'
import User from './User'
import cn from './AccessList.css'

const ELEMENT_ACCESS_TYPES = [
  {key: 'r', label: 'View', value: ACCESS_TYPES.read},
  {key: 'w', label: 'Edit', value: ACCESS_TYPES.write},
]

export default function AccessList ({
  onAccessChange,
  onAccessDelete,
  users,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.header}>
        Users with access to this element
      </div>
      <div className={cn.body}>
        {users.map(user => (
          <User
            {...user}
            accessTypes={ELEMENT_ACCESS_TYPES}
            key={user.username}
            onAccessChange={onAccessChange}
            onAccessDelete={onAccessDelete}
          />
        ))}
      </div>
    </div>
  )
}

AccessList.propTypes = {
  onAccessChange: func.isRequired,
  onAccessDelete: func.isRequired,
  users: arrayOf(shape({
    access: string.isRequired,
    username: string.isRequired,
  }))
}
