import {camelizeKeys, decamelizeKeys} from 'humps'
import 'isomorphic-fetch'

function isServerError ({status}) {
  return status === 500
}

/**
 * Make request to api.
 *
 * @param {String} endpoint
 * @param {Object} opts
 *    @param {Object} data
 *    @param {Bool}   isAuthenticated
 *    @param {String} method
 *    @param {Object} query
 * @return {Promise}
 */
export default async function request (endpoint, opts = {}) {
  try {
    const {body, data, headers, method, query} = opts

    const config = {
      headers: headers || getDefaultHeaders(),
      body: body || structureRequestData(data),
      method: method || 'GET',
    }

    const url = query ? buildQueryUrl(endpoint, query) : endpoint

    const response = await fetch(url, config)

    if (isValidResponse(response)) {
      const jsonRes = await response.json()
      const camelizedJson = camelizeKeys(jsonRes)
      console.debug(`Response for endpoint="${url}"`, response, camelizedJson)

      return camelizedJson
    }
    // Should not try to parse any JSON since there won't be a message involved
    // for any 500 errors.
    else if (isServerError(response)) {
      throw Error('Error communicating with server')
    }
    else {
      const error = new Error('API Error')
      error.response = response

      throw error
    }
  }
  catch (err) {
    console.error(err) // eslint-disable-line no-console

    if (err.response && err.response.json) {
      const errorJson = await err.response.json()
      console.error(errorJson)

      err.message = errorJson.error || err.message
    }

    // window.alert(JSON.stringify(await err.response.json()))
    throw err
  }
}

/**
 * Builds query url.
 *
 * @param {String} endpoint
 * @param {Object} query
 * @return {String}
 */
function buildQueryUrl (endpoint, query) {
  return Object.keys(query).reduce((str, key, index) => {
    if (index === 0) {
      return `${str}${key}=${query[key]}`
    }

    return `${str}&${key}=${query[key]}`
  }, `${endpoint}?`)
}

/**
 * Returns default headers.
 *
 * @return {Object}   headers
 */
function getDefaultHeaders () {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

/**
 * Returns if response is valid.
 *
 * @param {Object}  response
 * @return {Bool}
 */
function isValidResponse (response) {
  const contentType = response.headers.get('content-type')

  return response.status === 200 && contentType.indexOf('application/json') > -1
}

/**
 * Structure data to send.
 *
 * @param {Object} data
 * @return {Object}
 */
function structureRequestData (data) {
  if (!data) {
    return undefined
  }
  else if (data instanceof FormData) {
    return data
  }

  return JSON.stringify(decamelizeKeys(data))
}
