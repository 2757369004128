import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import cx from 'classnames'

import BoundaryRect from 'components/BoundaryRect'
import noop from 'utils/noop'
import { ElementContextType, TagType } from 'types'

import ContextMenu from './ContextMenu'
import ContextMenuProvider from './ContextMenuProvider'
import DetailsBar from './SequenceEditorDetailsBar'
import RowVirtualizer from './SequenceEditorRowVirtualizer'
import cn from './SequenceEditor.css'
import { CursorDataType } from './types'

export default class SequenceEditor extends Component {
  static propTypes = {
    className: string,
    cursor: CursorDataType.isRequired,
    disabled: bool,
    elementContext: ElementContextType.isRequired,
    onCursorChange: func,
    onEditorRefSet: func,
    onSequenceCopy: func,
    onSequenceCut: func,
    onSequenceEdit: func,
    onSequencePaste: func,
    scrollToIndex: number,
    sequence: string,
    tags: arrayOf(TagType),
    transformCounter: func,
  }

  static defaultProps = {
    className: '',
    disabled: false,
    onCursorChange: noop,
    onEditorRefSet: noop,
    onSequenceCopy: () => 'Copy sequence',
    onSequenceCut: () => 'Cut sequence',
    onSequenceEdit: () => 'Edit sequence',
    onSequencePaste: () => 'Paste sequence',
    scrollToIndex: 0,
    sequence: '',
    tags: [],
    transformCounter: null,
  }

  get details () {
    const { cursor, sequence } = this.props
    const { start, end } = cursor
    const min = Math.min(start, end) + 1
    const max = Math.max(start, end) + 1
    const isSelection = min !== max

    return {
      length: isSelection ? max - min : sequence.length,
      pos: isSelection ? null : min,
      start: isSelection ? min : null,
      end: isSelection ? max : null,
    }
  }

  render () {
    const {
      className,
      cursor,
      disabled,
      elementContext,
      onCursorChange,
      onEditorRefSet,
      onSequenceCopy,
      onSequenceCut,
      onSequenceEdit,
      onSequencePaste,
      scrollToIndex,
      sequence,
      tags,
      transformCounter,
    } = this.props

    return (
      <ContextMenuProvider>
        {({
          menuPosition,
          menuProps,
          menuType,
          onContextMenuClose,
          onContextMenuOpen,
        }) => (
          <div className={cx(cn.base, className)}>
            <div className={cn.body}>
              <RowVirtualizer
                cursor={cursor}
                disabled={disabled}
                elementContext={elementContext}
                onCursorChange={onCursorChange}
                onEditorRefSet={onEditorRefSet}
                onScroll={onContextMenuClose}
                onContextMenu={onContextMenuOpen}
                onSequenceCopy={onSequenceCopy}
                onSequenceCut={onSequenceCut}
                onSequenceEdit={onSequenceEdit}
                onSequencePaste={onSequencePaste}
                scrollToIndex={scrollToIndex}
                sequence={sequence}
                sequenceLength={sequence.length}
                tags={tags}
                transformCounter={transformCounter}
              />
              <BoundaryRect className={cn.context}>
                {({ boundaryRect }) => (
                  <ContextMenu
                    boundaryRect={boundaryRect}
                    menuPosition={menuPosition}
                    menuProps={menuProps}
                    menuType={menuType}
                    onMenuClose={onContextMenuClose}
                    onSequenceCopy={onSequenceCopy}
                    onSequenceCut={onSequenceCut}
                    onSequencePaste={onSequencePaste}
                    selection={cursor}
                    sequence={sequence}
                    tags={tags}
                  />
                )}
              </BoundaryRect>
            </div>
            <DetailsBar details={this.details} />
          </div>
        )}
      </ContextMenuProvider>
    )
  }
}
