import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { addToast } from 'actions/toast'
import { displayError } from 'actions/error'
import { reqCreateProjectShare, reqDeleteProjectShare } from 'actions/project'
import { openModal } from 'actions/modal'
import SmallTitle from 'components/typography/SmallTitle'
import ShareUserCreator from 'components/ShareUserCreator'
import ShareUserList from 'components/ShareUserList'
import { DEFAULT_USER_ACCESS_OPTIONS } from 'core/Constants'
import { InputOptionType } from 'types'
import { getMyUsername } from 'selectors/authUser'
import { getProjectId } from 'selectors/designer'
import { getProjectById } from 'selectors/project'
import { hasOwnerAccess } from 'utils/checkAccess'

import cn from './ProjectShare.css'

const DeleteModalText = {
  SELF: {
    modalSubtitle: 'Remove your access from this project.',
    modalTitle: 'Leave Project',
  },
  SHARE: {
    modalSubtitle: 'Remove user from having access to this project.',
    modalTitle: 'Delete Project Share',
  },
}

class ProjectShare extends Component {
  static propTypes = {
    accessOptions: arrayOf(InputOptionType).isRequired,
    authUsername: string.isRequired,
    disabled: bool,
    id: number.isRequired,
    onCreate: func.isRequired,
    onDelete: func.isRequired,
    onError: func.isRequired,
    onModalOpen: func.isRequired,
    onRoutePush: func.isRequired,
    onToastAdd: func.isRequired,
    projectName: string.isRequired,
    shares: arrayOf(object).isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  /**
   * Creates/updates a share to the current project.
   * @param  {Object} share          Share data
   * @param  {String} share.access   Access of share user
   * @param  {String} share.username Username of share user
   */
  upsertShare = (share) => {
    const { id, onCreate, onToastAdd } = this.props

    onCreate(id, share)
      .then(() => {
        onToastAdd({
          type: 'success',
          message: `${share.username} added to project`,
        })
      })
      .catch((err) => {
        onToastAdd({
          body: err,
          type: 'error',
          message: 'Error adding user to project',
        })
      })
  }

  /**
   * Delets a share from the current project.
   * @param  {String} username Username of share user
   */
  deleteShare = (username) => {
    const {
      authUsername,
      id,
      onDelete,
      onModalOpen,
      onRoutePush,
      onToastAdd,
      projectName,
    } = this.props

    const selfDelete = username === authUsername
    const { modalSubtitle, modalTitle } = selfDelete
      ? DeleteModalText.SELF
      : DeleteModalText.SHARE

    onModalOpen('delete', {
      modalSubtitle,
      modalTitle,
      onAccept: () => {
        if (selfDelete) {
          onRoutePush('/')
        }

        onDelete(id, username, selfDelete)
          .then(() => {
            onToastAdd({
              type: 'success',
              message: selfDelete
                ? `You've been removed from project ${projectName}`
                : `${username} removed from project ${projectName}`,
            })
          })
          .catch((err) => {
            onToastAdd({
              type: 'error',
              message: `${err.message}`,
            })
          })
      },
    })
  }

  render () {
    const {
      accessOptions,
      authUsername,
      disabled,
      onError,
      shares,
    } = this.props

    return (
      <div className={cn.base}>
        <div className={cn.section}>
          <div className={cn.header}>
            <SmallTitle>
              Add users to share your project with
            </SmallTitle>
            <p className={cn.desc}>
              Access types
              <br />
              <b>View/Edit</b>
              : Can edit the project
              <br />
              <b>View Only</b>
              : Cannot edit the project
              <br />
            </p>
          </div>
          <div className={cn.body}>
            <ShareUserCreator
              accessOptions={accessOptions}
              disabled={disabled}
              onShareUserCreate={this.upsertShare}
              onError={onError}
              shares={shares}
            />
          </div>
        </div>
        <div className={cn.section}>
          <ShareUserList
            accessOptions={accessOptions}
            authUsername={authUsername}
            disabled={disabled}
            onDelete={this.deleteShare}
            onEdit={this.upsertShare}
            users={shares}
          />
        </div>
      </div>
    )
  }
}

const mapState = (state) => {
  const id = getProjectId(state)
  const { access, name: projectName, shares } = getProjectById(state, { id })

  return {
    accessOptions: DEFAULT_USER_ACCESS_OPTIONS,
    authUsername: getMyUsername(state),
    // Project sharing can only be changed by the owner.
    disabled: !hasOwnerAccess(access),
    id,
    projectName,
    shares,
  }
}

const mapDispatch = {
  onCreate: reqCreateProjectShare,
  onDelete: reqDeleteProjectShare,
  onError: displayError,
  onModalOpen: openModal,
  onRoutePush: push,
  onToastAdd: addToast,
}

export { ProjectShare }

export default connect(mapState, mapDispatch)(ProjectShare)
