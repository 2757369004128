import isNil from 'lodash/isNil'
import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer'
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache'
import List from 'react-virtualized/dist/commonjs/List'

import noop from 'utils/noop'
import { ElementContextType, TagType } from 'types'

import Row from './SequenceRow'
import { getRowCount, getRowLength } from './selectors'
import cn from './SequenceEditorRowVirtualizer.css'
import { CursorDataType } from './types'

function rowRenderer ({
  charWidth,
  cursor,
  disabled,
  elementContext,
  onContextMenu,
  onCursorChange,
  rowCache,
  rowCount,
  rowLength,
  sequence,
  tags,
  transformCounter,
  updateRowCache,
}) {
  // eslint-disable-next-line
  return ({ index, key, parent, style }) => (
    <CellMeasurer
      cache={rowCache}
      columnIndex={0}
      key={key}
      parent={parent}
      rowIndex={index}
    >
      <Row
        charWidth={charWidth}
        columnIndex={0}
        cursor={cursor}
        disabled={disabled}
        elementContext={elementContext}
        onContextMenu={onContextMenu}
        onCursorChange={onCursorChange}
        onRowSizeUpdate={updateRowCache}
        rowCount={rowCount}
        rowLength={rowLength}
        rowIndex={index}
        sequence={sequence}
        style={style}
        tags={tags}
        transformCounter={transformCounter}
      />
    </CellMeasurer>
  )
}

export default class SequenceEditorRowVirtualizer extends Component {
  static propTypes = {
    cursor: CursorDataType.isRequired,
    disabled: bool,
    elementContext: ElementContextType.isRequired,
    onAnimationComplete: func,
    onContextMenu: func,
    onCursorChange: func,
    onEditorOff: func,
    onEditorOn: func,
    onEditorRefSet: func,
    onSequenceCopy: func.isRequired,
    onSequenceCut: func.isRequired,
    onSequenceEdit: func.isRequired,
    onSequencePaste: func.isRequired,
    onScroll: func.isRequired,
    scrollToIndex: number,
    sequence: string.isRequired,
    sequenceLength: number.isRequired,
    tags: arrayOf(TagType).isRequired,
    transformCounter: func,
  }

  static defaultProps = {
    disabled: false,
    onAnimationComplete: noop,
    onContextMenu: noop,
    onCursorChange: noop,
    onEditorOff: noop,
    onEditorOn: noop,
    onEditorRefSet: noop,
    scrollToIndex: 0,
    transformCounter: null,
  }

  constructor (props) {
    super(props)

    this.state = {
      scrollTop: null,
    }

    this.rowCache = new CellMeasurerCache({
      defaultHeight: 138,
      fixedWidth: true,
      minHeight: 134,
    })

    this.rowLength = 0
    this.rowCount = 0
    this.currentRowStartIndex = 0
    this.currentRowEndIndex = 0

    this.animateDuration = 500
    this.animateEasing = t => (
      /* eslint-disable-next-line */
      t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
    )
  }

  componentDidMount () {
    if (!this.node) {
      return
    }

    this.node.focus()
    this.forceUpdate()

    if (this.props.scrollToIndex) {
      this.scrollToIndex(this.props.scrollToIndex)
    }
  }

  componentWillReceiveProps ({ scrollToIndex, tags }) {
    const { scrollToIndex: prevScrollToIndex, tags: prevTags } = this.props

    if (tags.length !== prevTags.length || scrollToIndex !== prevScrollToIndex) {
      this.adjustRowHeights()
    }
  }

  componentDidUpdate ({ scrollToIndex: prevScrollToIndex }) {
    const { scrollToIndex } = this.props

    if (scrollToIndex !== prevScrollToIndex) {
      this.scrollToIndex(scrollToIndex)
    }
  }

  setRef = (r) => {
    const { onEditorRefSet } = this.props

    this.node = r

    onEditorRefSet(r)
  }

  setListRef = (r) => {
    this.list = r
  }

  get charWidth () {
    if (!this.node) {
      return 0
    }

    const tempChar = document.createElement('span')
    tempChar.innerText = 'A'
    this.node.appendChild(tempChar)

    const { width } = tempChar.getBoundingClientRect()

    this.node.removeChild(tempChar)

    return width
  }

  get leftPos () {
    return this.node ? this.node.getBoundingClientRect().left : 0
  }

  getRowIndexFromSequenceIndex (sequenceIndex) {
    const { rowLength } = this

    let index = null
    let counter = 0
    let offset = 0
    let end = 0

    while (isNil(index)) {
      offset = rowLength * counter
      end = offset + rowLength

      if (sequenceIndex >= offset && sequenceIndex <= end) {
        index = counter
      }
      else {
        counter += 1
      }
    }

    return index
  }

  scrollToIndex = index => {
    if (!this.rowCount || !this.list) {
      return
    }

    this.initAnimation(index)
  }

  handleOnRowsRendered = ({ startIndex, stopIndex }) => {
    this.currentRowStartIndex = startIndex
    this.currentRowEndIndex = stopIndex
  }


  handleRowCacheUpdate = (rowIndex) => {
    this.rowCache.clear(rowIndex)
  }

  handleScroll = ({ scrollTop }) => {
    const { onScroll } = this.props

    if (!this.animationStartTime) {
      this.scrollTopInitial = scrollTop
    }

    onScroll()
  }

  /**
   * This clears all the heights stored in cache and recomputes the row heights
   * to ensure things are properly rendered after tags are added and removed.
   */
  adjustRowHeights () {
    if (!this.list || !this.rowCache) {
      return
    }

    this.rowCache.clearAll()
    this.list.recomputeRowHeights()
  }

  /**
   * Scrolls the list to the index passed as an argument. The row index gets
   * calculated from the cursor index. React-virtualized scrollTo only brings
   * the desired index into the viewport, meaning it only works if the index is
   * out of the viewport and doesn't care where in the viewport it appears. This
   * works fine when scrolling upwards/backward as the List component will
   * scroll until the beginning index is the first row rendered. However when
   * scrolling downward/forward, the row will appear at the bottom of the List
   * if the row is out of the viewport. To make sure the desired index is always
   * at the top, we need to add the number of additional visible rows to the
   * calculated row index.
   */
  scrollToRow (cursorIndex) {
    const { currentRowEndIndex, currentRowStartIndex } = this
    const visibleRowCount = currentRowEndIndex - currentRowStartIndex

    const rowIndex = this.getRowIndexFromSequenceIndex(cursorIndex)

    return rowIndex > currentRowEndIndex
      ? rowIndex + visibleRowCount - 1
      : rowIndex
  }

  animate () {
    requestAnimationFrame(() => {
      const { animateDuration, animateEasing } = this
      const { onAnimationComplete } = this.props
      const now = performance.now()
      const ellapsed = now - this.animationStartTime
      const scrollDelta = this.scrollTopFinal - this.scrollTopInitial
      const easedTime = animateEasing(Math.min(1, ellapsed / animateDuration))
      const scrollTop = this.scrollTopInitial + scrollDelta * easedTime

      this.setState({ scrollTop })

      if (ellapsed < animateDuration) {
        this.animate()
      }
      else {
        this.animationStartTime = undefined
        this.scrollTopInitial = this.scrollTopFinal
        onAnimationComplete()
      }
    })
  }

  initAnimation (scrollToCursorIndex) {
    if (this.animationStartTime) {
      throw Error('Animation in progress') // You handle this however you want.
    }

    this.animationStartTime = performance.now()

    // Ask List for the offset in case it's complex
    // eg CellMeasurer might be involved and we don't want to duplicate effort
    this.scrollTopFinal = this.list.getOffsetForRow({
      index: this.scrollToRow(scrollToCursorIndex),
    })

    this.animate()
  }

  render () {
    const { charWidth } = this
    const { scrollTop } = this.state
    const {
      cursor,
      disabled,
      elementContext,
      onContextMenu,
      onCursorChange,
      onSequenceCopy,
      onSequenceCut,
      onSequenceEdit,
      onSequencePaste,
      onEditorOff,
      onEditorOn,
      sequence,
      sequenceLength,
      tags,
      transformCounter,
    } = this.props

    return (
      <div
        className={cn.base}
        onBlur={onEditorOff}
        onCopy={onSequenceCopy}
        onCut={onSequenceCut}
        onFocus={onEditorOn}
        onKeyUp={onSequenceEdit}
        onPaste={onSequencePaste}
        ref={this.setRef}
        role="button"
        tabIndex={0}
      >
        <AutoSizer>
          {({ height, width }) => {
            const rowProps = {
              charWidth,
              containerWidth: width,
              sequenceLength,
            }

            const rowLength = getRowLength(rowProps)
            this.rowLength = rowLength

            const rowCount = getRowCount(rowProps)
            this.rowCount = rowCount

            return (
              <List
                height={height}
                onRowsRendered={this.handleOnRowsRendered}
                onScroll={this.handleScroll}
                overscanRowCount={1}
                ref={this.setListRef}
                rowCount={rowCount}
                rowHeight={this.rowCache.rowHeight}
                rowRenderer={rowRenderer({
                  charWidth,
                  cursor,
                  disabled,
                  elementContext,
                  onContextMenu,
                  onCursorChange,
                  rowCache: this.rowCache,
                  rowCount,
                  rowLength,
                  sequence,
                  tags,
                  transformCounter,
                  updateRowCache: this.handleRowCacheUpdate,
                })}
                scrollTop={scrollTop}
                width={width}
              />
            )
          }}
        </AutoSizer>
      </div>
    )
  }
}
