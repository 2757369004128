import React, {Component} from 'react'
import {func, number, object, string} from 'prop-types'
import omit from 'lodash/omit'

import aminoAcids from 'utils/aminoAcids'
import CustomCodonModal from './CustomCodonModal'

export default class CustomCodonModalContainer extends Component {
  static propTypes = {
    mappings: object,
    name: string,
    onClose: func.isRequired,
    onCodonMapCreate: func.isRequired,
    projectId: number.isRequired,
  }

  static defaultProps = {
    mappings: {},
    name: '',
  }

  constructor (props) {
    super(props)

    this.state = {
      mappings: props.mappings,
      name: props.name,
    }
  }

  handleFieldChange = ({target}) => {
    this.setState({[target.name]: target.value})
  }

  handleCodonSelect = ({target}) => {
    const {mappings} = this.state
    const {name, value} = target

    return value
      ? this.setState({mappings: {...mappings, [name]: value}})
      : this.setState({mappings: omit(mappings, name)})
  }

  handleModalAccept = () => {
    const {onClose, onCodonMapCreate, projectId} = this.props

    onCodonMapCreate(projectId, this.state).then(onClose)
  }

  get aminoMap () {
    return Object.keys(aminoAcids).map(amino => ({
      label: aminoAcids[amino].abbreviation,
      name: aminoAcids[amino].abbreviation,
      options: aminoAcids[amino].codons,
      value: this.state.mappings[aminoAcids[amino].abbreviation] || '',
    }))
  }

  get canSubmit () {
    // Must have a name and at least one mapping.
    return !!this.state.name && Object.keys(this.state.mappings).length > 0
  }

  render () {
    const {onClose} = this.props
    const {name} = this.state

    return (
      <CustomCodonModal
        aminoMap={this.aminoMap}
        disableAccept={!this.canSubmit}
        name={name}
        onCodonSelect={this.handleCodonSelect}
        onFieldChange={this.handleFieldChange}
        onModalAccept={this.handleModalAccept}
        onModalCancel={onClose}
      />
    )
  }
}
