import cx from 'classnames'
import React, { Component } from 'react'
import { bool } from 'prop-types'

import { withDrop } from 'hoc/withDragAndDrop'

import ElementSize from './ElementSize'
import { ConstructElementStyleType } from './types'
import cn from './DropTarget.css'

/**
 * A component that renders a drop target for sequence elements. We have to
 * extend React.Component in order for React DnD to properly connect due to refs.
 */
// eslint-disable-next-line react/prefer-stateless-function
class DropTarget extends Component {
  static propTypes = {
    isOver: bool,
    style: ConstructElementStyleType.isRequired,
  }

  static defaultProps = {
    isOver: false,
  }

  render () {
    const { isOver, style } = this.props

    return (
      <div className={cn.border}>
        <div
          className={cx(cn.base, { [cn.base__over]: isOver })}
          style={{ ...style, ...ElementSize }}
        >
          <span className={cn.hint}>
            Drag elements here
          </span>
        </div>
      </div>
    )
  }
}

const dropSpec = {
  drop: (props, monitor) => {
    const { index, onDrop } = props

    const { element } = monitor.getItem()

    onDrop(element, index)
  },
}

export { DropTarget }

export default withDrop(dropSpec)(DropTarget)
