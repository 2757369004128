import React from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import cx from 'classnames'

import FontAwesomeButton from 'components/FontAwesomeButton'
import cn from './ElementModalNav.css'

export default function ElementModalNav ({activePanel, onNavigate, options}) {
  return (
    <div className={cn.base}>
      {options.map(option => (
        <FontAwesomeButton
          bodyClass={cn.btnBody}
          className={cx(cn.btn, {[cn.btnActive]: option.value === activePanel})}
          focusClass={cn.btnFocus}
          icon={option.icon}
          key={option.key}
          label={option.label}
          labelClass={cn.btnLabel}
          onClick={onNavigate}
          value={option.value}
        />
      ))}
    </div>
  )
}

ElementModalNav.propTypes = {
  activePanel: string.isRequired,
  onNavigate: func.isRequired,
  options: arrayOf(shape({
    active: bool,
    icon: string.isRequired,
    key: string.isRequired,
    label: string,
    value: string.isRequired,
  })).isRequired,
}
