import { actions as designerActions } from 'actions/designer'
import { actions } from 'actions/templates'
import { ElementContext } from 'common'
import {
  addConstructAARegionMotifFeature,
  addConstructElement,
  convertConstructSelectionToAA,
  convertConstructSelectionToDNA,
  deleteConstructElement,
  getTagEnd,
  moveConstructElement,
  updateConstruct,
  updateConstructAARegionSequence,
} from 'core/construct'
import Tag, { deleteNonElementTag } from 'core/template/Tag'
import Template from 'core/template/Template'
import { alwaysObject, insertInString, spliceString, overlap } from 'core/utils'
import IdGenerator from 'core/utils/IdGenerator'
import { CONSTRUCT } from 'providers/store/action-types'
import createReducer from 'utils/createReducer'
import { omit } from 'utils'

const ID = new IdGenerator()

export default createReducer(
  {
    context: ElementContext.DNA,
    editing: Template.create({ id: 0 }),
    expressionSystem: 'none',
    loadingTags: {},
    order: [0],
    selection: {
      end: 0,
      start: 0,
      tagId: '',
    },
  },
  {
    [actions.setTemplates]: (state, { templates }) => {
      const createTemplate = Template.makeCreator(ID)

      const templateState = templates.reduce(
        (finalState, templateData) => {
          const template = createTemplate({
            template: templateData,
            idMap: finalState,
          })

          return {
            ...finalState,
            order: finalState.order.concat(template.id),
            [template.id]: template,
          }
        },
        { order: [] },
      )

      return {
        ...state,
        ...templateState,
        editing: templateState[templateState.order[0]],
        expressionSystem:
          templateState[templateState.order[0]].expressionSystem,
      }
    },

    [actions.startTemplateEdit]: (state, { templateId }) => ({
      ...state,
      editing: Template.create(state[templateId]),
      [state.editing.id]: Template.create(state.editing),
    }),

    [actions.addTemplate]: state => {
      const id = ID.newId(state)

      return {
        ...state,
        order: state.order.concat(id),
        [id]: Template.create({ id }),
      }
    },

    [actions.duplicateTemplate]: (state, { templateId }) => {
      const { editing } = state
      const id = ID.newId(state)

      return {
        ...state,
        order: state.order.concat(id),
        [id]:
          templateId === editing.id
            ? Template.create({ ...editing, id })
            : Template.create({ ...state[templateId], id }),
      }
    },

    [actions.deleteTemplate]: (state, { templateId }) => {
      const { editing, order } = state

      if (order.length === 1) {
        return state
      }

      const orderIndex = order.indexOf(templateId)
      const newOrder = order.filter(id => id !== templateId)

      const newEditing =
        orderIndex >= newOrder.length
          ? state[newOrder[orderIndex - 1]]
          : state[newOrder[orderIndex]]

      return {
        ...omit(state, templateId),
        order: newOrder,
        editing: editing.id !== templateId ? editing : newEditing,
      }
    },

    [actions.addElementToTemplate]: (state, { element, id, index }) => {
      const { editing } = state

      return {
        ...state,
        editing: addConstructElement(editing, element, index, id),
      }
    },

    [actions.updateTemplate]: (state, { payload }) => ({
      ...state,
      editing: payload,
    }),

    [actions.deleteElementFromTemplate]: (state, { index }) => {
      const { editing } = state

      return {
        ...state,
        editing: deleteConstructElement(editing, index),
      }
    },

    [actions.updateTemplateElementOrder]: (state, { moveId, newIndex }) => {
      const { editing } = state

      return {
        ...state,
        editing: moveConstructElement(editing, newIndex, moveId),
      }
    },

    [actions.updateTemplateVariations]: (state, { variations }) => {
      const { editing } = state

      return {
        ...state,
        editing: updateConstruct(editing, { variations }),
      }
    },

    [actions.updateExpressionSystem]: (state, { expressionSystem }) => {
      const { editing } = state

      return {
        ...state,
        editing: updateConstruct(editing, { expressionSystem }),
        expressionSystem,
      }
    },

    [actions.updateTags]: (state, { sequence, tags }) => {
      const { editing } = state

      return {
        ...state,
        editing: updateConstruct(editing, { sequence, tags }),
      }
    },

    [actions.addTagToTemplate]: (state, { tag }) => {
      const { editing } = state
      const { elementOrder, tags } = editing
      const spannedElements = elementOrder.filter(id =>
        overlap(
          tag.offset,
          getTagEnd(tag),
          tags[id].offset,
          getTagEnd(tags[id]),
        ),
      )

      return {
        ...state,
        editing: Template.update({
          template: editing,
          tags: Tag.addToMap({
            tag: Tag.create({
              ...tag,
              id: ID.newId(tags),
              offset: tag.offset - tags[spannedElements[0]].offset,
              length: getTagEnd(tag) - tag.offset,
              spannedElements,
            }),
            tagMap: tags,
          }),
        }),
      }
    },

    [actions.deleteTagsFromTemplate]: (state, { tagIds = [] }) => {
      const { editing } = state
      const { elementOrder, tags } = deleteNonElementTag({
        elementOrder: editing.elementOrder,
        tags: editing.tags,
        tagIds: tagIds.filter(id => !editing.elementOrder.includes(id)),
      })

      return {
        ...state,
        editing: {
          ...editing,
          elementOrder,
          tags,
        },
      }
    },

    [actions.addPendingAminoElementToTemplate]: (
      state,
      { element, index, elementOrder, tags },
    ) => {
      const { editing } = state

      return {
        ...state,
        editing: Template.update({
          template: editing,
          sequence: insertInString({
            insert: element.sequence.toLowerCase(),
            index: alwaysObject(tags[elementOrder[index]]).offset,
            string: editing.sequence,
          }),
          elementOrder,
          tags,
        }),
      }
    },

    [actions.fulfillPendingAminoElement]: (state, { id, sequence }) => {
      const { editing } = state
      const tag = editing.tags[id]

      return {
        ...state,
        editing: Template.update({
          template: editing,
          sequence: spliceString({
            startIndex: tag.offset,
            endIndex: getTagEnd(tag),
            insert: sequence,
            string: editing.sequence,
          }),
        }),
      }
    },

    [actions.addFeatureTags]: (state, { features, tagId }) => {
      const { editing } = state

      return {
        ...state,
        editing: addConstructAARegionMotifFeature(editing, tagId, features),
      }
    },

    [actions.updateSequence]: (state, { elementId, sequence }) => {
      const { editing } = state

      return {
        ...state,
        editing: updateConstructAARegionSequence(editing, elementId, sequence),
      }
    },

    [designerActions.SET_ELEMENT_CONTEXT]: (state, { context }) => {
      const { editing, selection } = state

      const isAA = context === ElementContext.AA
      const { end, start } = isAA
        ? convertConstructSelectionToAA(editing, selection)
        : convertConstructSelectionToDNA(editing, selection)

      return {
        ...state,
        context,
        selection: {
          ...selection,
          end,
          start,
        },
      }
    },

    [designerActions.SELECT_ELEMENT]: (state, { element }) => {
      const { id, offset } = element

      return {
        ...state,
        selection: {
          end: getTagEnd(element),
          start: offset,
          tagId: `${id}`,
        },
      }
    },

    [designerActions.SET_SEQUENCE_SELECTION]: (state, { start, end }) => ({
      ...state,
      selection: {
        end,
        start,
        tagId: '',
      },
    }),

    [CONSTRUCT.ADD_ELEMENT]: (state, { payload }) => ({
      ...state,
      editing: payload,
    }),

    [CONSTRUCT.ADD_ELEMENT_WITHOUT_SEQUENCE]: (state, { payload }) => ({
      ...state,
      editing: payload.construct,
      loadingTags: {
        ...state.loadingTags,
        [payload.tagId]: true,
      },
    }),

    [CONSTRUCT.ADD_ELEMENT_SEQUENCE]: (state, { payload }) => ({
      ...state,
      editing: payload.construct,
      loadingTags: omit(state.loadingTags, payload.tagId),
    }),
  },
)
