import React from 'react'
import {bool, func, object} from 'prop-types'
import cx from 'classnames'

import cn from './ModalRoot.css'

export default function ModalRoot ({isOpen, modalProps, Modal, onClose}) {
  return (
    <div className={cx(cn.base, {[cn.baseOpen]: isOpen})}>
      <div className={cx(cn.bg, {[cn.bgOpen]: isOpen})} onClick={onClose} />
      <div className={cx(cn.modal, {[cn.modalOpen]: isOpen})}>
        <Modal {...modalProps} isModalOpen={isOpen} onClose={onClose} />
      </div>
    </div>
  )
}

ModalRoot.propTypes = {
  isOpen: bool,
  modalProps: object,
  Modal: func,
  onClose: func.isRequired,
}

ModalRoot.defaultProps = {
  isOpen: false,
  modalProps: {},
  Modal: () => null,
}
