import {Component} from 'react'
import {bool, func, number, object, shape, string} from 'prop-types'
import {DragLayer} from 'react-dnd'

export class CustomDragLayer extends Component {
  static propTypes = {
    children: func.isRequired,
    dragging: bool.isRequired,
    item: object,
    itemType: string,
    offset: shape({
      x: number.isRequired,
      y: number.isRequired
    }),
    renderDragType: string.isRequired,
  }

  render () {
    const {
      children,
      item,
      itemType,
      dragging,
      renderDragType,
      offset,
    } = this.props

    return renderDragType === itemType && dragging
      ? children({item, offset})
      : null
  }
}

export default DragLayer(monitor => ({
  offset: monitor.getClientOffset(),
  dragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
}))(CustomDragLayer)
