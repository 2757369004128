/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import cx from 'classnames'
import { push as pushRoute } from 'connected-react-router'
import {
  arrayOf,
  bool,
  func,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { reqCreateProject } from 'actions/project'
import BaseButton from 'components/BaseButton'
import ComposedSelectInput from 'components/SelectInput'
import { TextInput } from 'components/text-input'
import {
  DEFAULT_EXPRESSION_SYSTEM,
  DEFAULT_PROJECT_TYPE,
  EXPRESSION_SYSTEMS,
  PROJECT_TYPES,
} from 'core/Constants'
import { getMyUsername } from 'selectors/authUser'
import { getGroupsWithWriteAccess } from 'selectors/group'
import { noop } from 'utils'

import cn from './NewProjectView.css'

const DEFAULT_GROUP = 'none'

// const projectTypes = Object.keys(PROJECT_TYPES).map((key, i) => ({
//   key: PROJECT_TYPES[key],
//   primaryText: i ? 'Single Sequence' : 'Library',
//   value: PROJECT_TYPES[key],
// }))

const projectTypes = [{
  key: PROJECT_TYPES.singleSequence,
  label: 'Single Sequence',
  value: PROJECT_TYPES.singleSequence,
}]

/**
 * @component
 */
export class NewProjectView extends Component {
  static propTypes = {
    /**
     * List of codon map options.
     */
    codonMapOptions: arrayOf(shape({
      key: string,
      label: string,
      value: string,
    })),
    /**
     * Group name.
     */
    groupName: string,
    /**
     * List of groups.
     */
    groups: arrayOf(shape({})),
    /**
     * If `true`, disable group select.
     */
    isGroupProject: bool,
    /**
     * Creates new project.
     */
    onCreate: func,
    /**
     * Dispatches route change.
     */
    onPushRoute: func,
    /**
     * Username of logged in user.
     */
    username: string,
  }

  static defaultProps = {
    codonMapOptions: [],
    groupName: '',
    groups: [],
    isGroupProject: false,
    onCreate: noop,
    onPushRoute: noop,
    username: '',
  }

  /**
   * @lifecycle
   */
  constructor (props) {
    super(props)

    const { groupName } = props

    this.state = {
      description: '',
      expressionSystem: DEFAULT_EXPRESSION_SYSTEM,
      error: '',
      group: groupName || DEFAULT_GROUP,
      name: '',
      type: DEFAULT_PROJECT_TYPE,
    }
  }

  /**
   * @handlers
   */
  onProjectFieldChange = ({ target }) => {
    const { name, value } = target

    this.setState({ [name]: value })
  }

  onReturnToHome = () => {
    const { groupName, onPushRoute, isGroupProject } = this.props

    if (isGroupProject) {
      onPushRoute(`/groups/${groupName}`)
      return
    }

    onPushRoute('/')
  }

  onSubmit = () => {
    const { onCreate, onPushRoute } = this.props
    const {
      description,
      expressionSystem,
      group,
      name,
      type,
    } = this.state

    const project = {
      description,
      expressionSystem,
      name,
      type,
    }

    // Group property is added by <String> name, don't include 'none' to prevent
    // group being falsely added.
    if (group !== DEFAULT_GROUP) {
      project.group = group
    }

    Promise.resolve(onCreate(project))
      .then((newProject = {}) => {
        const { data } = newProject

        // Redirect to project page if project is successfully created.
        if (data && data.id) {
          const { groupName, id } = data
          const { username } = this.props

          const basename = groupName ? `groups/${groupName}` : username

          const route = `/${basename}/projects/${id}`

          onPushRoute(route)
        }
      })
      .catch((e) => {
        this.setState({ error: `Project could not be created: ${e}` })
      })
  }

  /**
   * @renders
   */
  render () {
    const { codonMapOptions, groups, isGroupProject } = this.props

    const {
      description,
      expressionSystem,
      error,
      group,
      name,
      type,
    } = this.state

    // Create props
    const canSubmit = name.length > 0
    const submitLabel = canSubmit ? 'Create Project' : 'No Project Name'

    const groupSectionTitle = `Group settings ${isGroupProject ? '' : '(optional)'}`
    const groupSectionText = isGroupProject
      ? 'This is a group project.'
      : 'Select a group to create a group project.'

    return (
      <div className={cn.root}>
        <div className="container-fluid">
          {error && (
            <div
              className="row"
              style={{
                paddingBottom: 'var(--sp-lg)',
                paddingLeft: 'var(--sp-sm)',
                paddingRight: 'var(--sp-sm)',
              }}
            >
              <span className={cn.text_error}>
                {error}
              </span>
            </div>
          )}
          <div className="row">
            <div className="col-md-3">
              <h4 className={cn.section__title}>
                Create new project
              </h4>

              <div className={cn.section__top_gutter}>
                <h5 className={cn.section__title}>
                  General info
                </h5>

                <div className={cn.section__top_gutter_sm}>
                  <span className={cn.section__text}>
                    Project name is required.
                  </span>

                  <div className={cn.section__top_gutter_sm}>
                    <span className={cn.section__text}>
                      Although optional, a description can help others better
                      understand the scope of your project.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <label className={cn.label} htmlFor="projectName">
                  Project name

                  <TextInput
                    className={cn.input}
                    id="projectName"
                    name="name"
                    onChange={this.onProjectFieldChange}
                    placeholder="Project-01"
                    value={name}
                  />
                </label>

                <div className={cn.section__top_gutter_lg}>
                  <label className={cn.label} htmlFor="projectDescription">
                    Project description (optional)

                    <TextInput
                      className={cn.input}
                      onChange={this.onProjectFieldChange}
                      name="description"
                      placeholder="My project is about..."
                      value={description}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className="col-md-3">
              <h5 className={cn.section__title}>
                Project type
              </h5>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text}>
                  Select the type of project this is.
                </span>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <label className={cn.label} htmlFor="projectType">
                  Project type

                  <ComposedSelectInput
                    className={cn.select_input}
                    id="projectType"
                    name="type"
                    onChange={this.onProjectFieldChange}
                    options={projectTypes}
                    toggleClass={cn.select_input_toggle}
                    value={type}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className="col-md-3">
              <h5 className={cn.section__title}>
                Codon expression system
              </h5>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text}>
                  Select an expression to optimize your generated sequences.
                </span>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <label className={cn.label} htmlFor="optimize">
                  Optimize Codons For

                  <ComposedSelectInput
                    className={cn.select_input}
                    id="optimize"
                    name="expressionSystem"
                    onChange={this.onProjectFieldChange}
                    options={codonMapOptions}
                    toggleClass={cn.select_input_toggle}
                    value={expressionSystem}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className="col-md-3">
              <h5 className={cn.section__title}>
                {groupSectionTitle}
              </h5>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text}>
                  {groupSectionText}
                </span>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <label className={cn.label} htmlFor="group">
                  Select a group

                  <ComposedSelectInput
                    className={cn.select_input}
                    id="group"
                    name="group"
                    onChange={this.onProjectFieldChange}
                    options={groups}
                    toggleClass={cn.select_input_toggle}
                    value={group}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className={cx(cn.actions, 'col-md-8 col-md-offset-4')}>
              <BaseButton
                disabled={!canSubmit}
                onClick={this.onSubmit}
                primary
                raised
              >
                {submitLabel}
              </BaseButton>

              <BaseButton onClick={this.onReturnToHome}>
                Cancel
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const defaultGroupOption = [{
  key: DEFAULT_GROUP,
  label: 'None',
  value: DEFAULT_GROUP,
}]

/**
 * @redux
 */
function mapState (state, props) {
  const { match } = props
  const { params } = match
  const { groupName } = params

  const groups = getGroupsWithWriteAccess(state)

  return {
    codonMapOptions: Object.keys(EXPRESSION_SYSTEMS).map(key => ({
      key,
      label: EXPRESSION_SYSTEMS[key],
      value: key,
    })),
    groupName,
    groups: defaultGroupOption.concat(
      groups.map(group => ({
        key: group.id,
        label: group.name,
        value: group.name,
      }))
    ),
    isGroupProject: !!groupName,
    username: getMyUsername(state),
  }
}

const mapDispatch = {
  onCreate: reqCreateProject,
  onPushRoute: pushRoute,
}

/**
 * @export
 */
export default connect(mapState, mapDispatch)(NewProjectView)
