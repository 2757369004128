import {createApiAction} from 'store/apiMiddleware'
import applyTransformsToArray from 'utils/applyTransformsToArray'
import createActionTypes from 'utils/createActionTypes'
import addAuthUsername from './utils/addAuthUsername'

/**
 * Api endpoint
 */
const api = '/elements/custom'

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_CREATE_CUSTOM_ELEMENT',
  'REQ_CREATE_CUSTOM_ELEMENT_ERROR',
  'REQ_CREATE_CUSTOM_ELEMENT_SUCCESS',
  'REQ_CREATE_CUSTOM_ELEMENT_SHARE',
  'REQ_CREATE_CUSTOM_ELEMENT_SHARE_ERROR',
  'REQ_CREATE_CUSTOM_ELEMENT_SHARE_SUCCESS',
  'REQ_CUSTOM_ELEMENTS',
  'REQ_CUSTOM_ELEMENTS_ERROR',
  'REQ_CUSTOM_ELEMENTS_SUCCESS',
  'REQ_DELETE_CUSTOM_ELEMENT',
  'REQ_DELETE_CUSTOM_ELEMENT_ERROR',
  'REQ_DELETE_CUSTOM_ELEMENT_SUCCESS',
  'REQ_DELETE_CUSTOM_ELEMENT_SHARE',
  'REQ_DELETE_CUSTOM_ELEMENT_SHARE_ERROR',
  'REQ_DELETE_CUSTOM_ELEMENT_SHARE_SUCCESS',
  'REQ_PUBLIC_CUSTOM_ELEMENTS',
  'REQ_PUBLIC_CUSTOM_ELEMENTS_ERROR',
  'REQ_PUBLIC_CUSTOM_ELEMENTS_SUCCESS',
  'REQ_UPDATE_CUSTOM_ELEMENT',
  'REQ_UPDATE_CUSTOM_ELEMENT_ERROR',
  'REQ_UPDATE_CUSTOM_ELEMENT_SUCCESS',
], 'customElement')

/**
 * Dispatches request to create new custom element.
 *
 * @param {Object}    elementData
 *    @param {String}   (optional) description
 *    @param {String}   (required) name
 *    @param {String}   (required) sequence
 *    @param {String}   (required) type
 * @return {Promise}  resolves to new element
 */
export function reqCreateCustomElement ({elementData}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_CUSTOM_ELEMENT,
      actions.REQ_CREATE_CUSTOM_ELEMENT_SUCCESS,
      actions.REQ_CREATE_CUSTOM_ELEMENT_ERROR,
    ],
    endpoint: api,
    requestOpts: {
      data: {
        description: elementData.description,
        name: elementData.name,
        sequence: elementData.sequence,
        type: elementData.type,
      },
      method: 'POST',
    },
    schemaType: 'customElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create new custom element access share.
 *
 * @param {Number}    elementId
 * @param {Object}    shareData
 *    @param {String}   (required) access
 *    @param {String}   (required) username
 * @return {Promise}  resolves to updated element
 */
export function reqCreateCustomElementShare (elementId, shareData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_CUSTOM_ELEMENT_SHARE,
      actions.REQ_CREATE_CUSTOM_ELEMENT_SHARE_SUCCESS,
      actions.REQ_CREATE_CUSTOM_ELEMENT_SHARE_ERROR,
    ],
    endpoint: `${api}/${elementId}/share`,
    requestOpts: {
      data: shareData,
      method: 'POST',
    },
    schemaType: 'customElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for user's custom elements.
 *
 * @return {Promise}  resolves to user's elements
 */
export function reqCustomElements () {
  return createApiAction({
    actionTypes: [
      actions.REQ_CUSTOM_ELEMENTS,
      actions.REQ_CUSTOM_ELEMENTS_SUCCESS,
      actions.REQ_CUSTOM_ELEMENTS_ERROR,
    ],
    endpoint: api,
    schemaType: 'customElementArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request to delete a custom element.
 *
 * @param {Number}    elementId
 * @return {Promise}  resolves to id of deleted element
 */
export function reqDeleteCustomElement (elementId) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_CUSTOM_ELEMENT,
      actions.REQ_DELETE_CUSTOM_ELEMENT_SUCCESS,
      actions.REQ_DELETE_CUSTOM_ELEMENT_ERROR,
    ],
    endpoint: `${api}/${elementId}`,
    isDestructive: true,
    requestOpts: {
      method: 'DELETE',
    },
    schemaType: 'customElement',
  })
}

/**
 * Dispatches request to delete a custom element access share.
 *
 * @param {Number}    elementId
 * @param {String}    shareUsername
 * @return {Promise}  resolves to updated element
 */
export function reqDeleteCustomElementShare (elementId, shareUsername) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_CUSTOM_ELEMENT_SHARE,
      actions.REQ_DELETE_CUSTOM_ELEMENT_SHARE_SUCCESS,
      actions.REQ_DELETE_CUSTOM_ELEMENT_SHARE_ERROR,
    ],
    endpoint: `${api}/${elementId}/share`,
    requestOpts: {
      data: {username: shareUsername},
      method: 'DELETE',
    },
    schemaType: 'customElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for public custom elements.
 *
 * @param {String}    searchTerm
 * @return {Promise}  resolves to user's elements
 */
export function reqPublicCustomElements (searchTerm) {
  return createApiAction({
    actionTypes: [
      actions.REQ_PUBLIC_CUSTOM_ELEMENTS,
      actions.REQ_PUBLIC_CUSTOM_ELEMENTS_SUCCESS,
      actions.REQ_PUBLIC_CUSTOM_ELEMENTS_ERROR,
    ],
    endpoint: `${api}/public`,
    requestOpts: {
      query: {search: searchTerm},
    },
    schemaType: 'publicElementArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request to delete a custom element access share.
 *
 * @param {Number}    elementId
 * @param {Object}    elementData
 *    @param {String}   (optional) description
 *    @param {String}   (optional) name
 *    @param {String}   (optional) sequence
 *    @param {String}   (optional) type
 * @return {Promise}  resolves to updated element
 */
export function reqUpdateCustomElement ({elementId, elementData}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_UPDATE_CUSTOM_ELEMENT,
      actions.REQ_UPDATE_CUSTOM_ELEMENT_SUCCESS,
      actions.REQ_UPDATE_CUSTOM_ELEMENT_ERROR,
    ],
    endpoint: `${api}/${elementId}`,
    requestOpts: {
      data: {
        description: elementData.description,
        folderPath: elementData.folderPath,
        name: elementData.name,
        sequence: elementData.sequence,
      },
      method: 'PUT',
    },
    schemaType: 'customElement',
    transformResponse: addAuthUsername,
  })
}
