import React, {Component} from 'react'
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types'

import {InputOptionType, ShareUserType} from 'types'

import ShareUserCreator from './ShareUserCreator'

class ShareUserCreatorContainer extends Component {
  static propTypes = {
    accessOptions: arrayOf(InputOptionType).isRequired,
    className: string,
    disabled: bool,
    onShareUserCreate: func.isRequired,
    shares: arrayOf(ShareUserType).isRequired,
  }

  static defaultProps = {
    className: '',
    disabled: false,
  }

  constructor (props) {
    super(props)

    // Default access to first option.
    this.state = {
      activeAccessIndex: 0,
      error: '',
      username: '',
    }
  }

  isShared (username) {
    const {shares} = this.props

    return shares.filter(({username: name}) => name === username).length > 0
  }

  /**
   * Changes the user's access. Refer to RadioButtonSelect for more details on
   * what values are passed back from its event handler.
   * @param  {Number} index Index of the selected access option
   */
  handleAccessChange = (index) => {
    this.setState({activeAccessIndex: index})
  }

  handleShareUserCreate = () => {
    const {accessOptions, disabled, onShareUserCreate} = this.props
    const {activeAccessIndex, username} = this.state

    if (disabled) {
      return
    }

    if (this.isShared(username)) {
      this.setState({error: `*${username} is already shared with`})
      return
    }

    const {value: access} = accessOptions[activeAccessIndex]

    Promise.resolve(onShareUserCreate({access, username}))
      .then(() => this.setState({
        activeAccessIndex: 0,
        username: '',
      }))
      .catch(({message: error}) => this.setState({error}))
  }

  handleUsernameChange = ({target}) => {
    const {value: username} = target

    this.setState({
      error: '',
      username,
    })
  }

  render () {
    const {accessOptions, className, disabled} = this.props
    const {activeAccessIndex, username} = this.state

    // A username must be present.
    const disableSubmit = disabled || username.length < 1

    return (
      <ShareUserCreator
        accessOptions={accessOptions}
        activeAccessIndex={activeAccessIndex}
        className={className}
        disabled={disabled}
        disableSubmit={disableSubmit}
        onAccessChange={this.handleAccessChange}
        onShareUserCreate={this.handleShareUserCreate}
        onUsernameChange={this.handleUsernameChange}
        username={username}
      />
    )
  }
}

export default ShareUserCreatorContainer
