import React from 'react'
import { bool, func, shape, string } from 'prop-types'

import FontAwesomeButton from 'components/FontAwesomeButton'
import { TextInput } from 'components/text-input'
import cn from './index.css'

export default function ControlSearch({
  disableSearch,
  hint,
  onInputChange,
  onSearch,
  searchProps,
}) {
  const handleSearch = () => onSearch(searchProps.search)

  return (
    <div className={cn.base}>
      <TextInput
        className={cn.input}
        name="search"
        onChange={onInputChange}
        onReturn={handleSearch}
        placeholder={hint}
        value={searchProps.search}
      />
      <FontAwesomeButton
        className={cn.btn}
        disabled={disableSearch}
        icon="search"
        label="Search"
        onClick={handleSearch}
        primary={!disableSearch}
      />
    </div>
  )
}

ControlSearch.propTypes = {
  disableSearch: bool,
  hint: string,
  onInputChange: func.isRequired,
  onSearch: func.isRequired,
  searchProps: shape({
    search: string.isRequired,
  }).isRequired,
}

ControlSearch.defaultProps = {
  disableSearch: false,
  hint: 'Search directory',
}
