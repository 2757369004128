// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
export default function paginate ({ types, mapActionToKey }) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected types to be an array of three elements.')
  }
  if (!types.every(t => typeof t === 'string')) {
    throw new Error('Expected types to be strings.')
  }
  if (typeof mapActionToKey !== 'function') {
    throw new Error('Expected mapActionToKey to be a function.')
  }

  const [ requestType, successType, failureType ] = types

  const initialState = {
    isFetching: false,
    pages: [],
  }

  const updatePagination = (state = initialState, action) => {
    const {paginate, response, type} = action

    switch (type) {
      case requestType:
        return {
          ...state,
          isFetching: true
        }
      case successType:
        const {result} = response
        const {page, perPage, totalPages} = paginate
        const total = result.length

        return {
          ...state,
          activePage: page,
          isFetching: false,
          // nextPageUrl: response.nextPageUrl,
          totalItems: total || state.totalItems,
          totalPages,
          perPage: perPage || state.perPage,
          pages: (
            result
              ? {...state.pages, [page]: result}
              : state.pages
          ),
        }
      case failureType:
        return {
          ...state,
          isFetching: false
        }
      default:
        return state
    }
  }

  return (state = {currentKey: ''}, action) => {
    // Update pagination by key
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
        const key = mapActionToKey(action)

        if (typeof key !== 'string') {
          throw new Error('Expected pagination key to be a string.')
        }
        return { ...state,
          currentKey: key,
          [key]: updatePagination(state[key], action)
        }
      default:
        return state
    }
  }
}
