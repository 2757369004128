import cx from 'classnames'
import { push as pushRoute } from 'connected-react-router'
import {
  arrayOf,
  element,
  func,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import BaseButton from 'components/BaseButton'
import FAIcon from 'components/FontAwesomeIcon'
import { TextInput } from 'components/text-input'
import { noop } from 'utils'

import cn from './classes.css'

/**
   * @helpers
   */
const filterProjects = (filter, projects) => {
  if (filter.length < 1) {
    return projects
  }

  const fixedFilter = filter.toLowerCase()

  return projects.filter(project => (
    project.name.toLowerCase().indexOf(fixedFilter) > -1
    || project.description.toLowerCase().indexOf(fixedFilter) > -1
  ))
}

/**
 * @component
 */
export class ProjectsView extends Component {
  static propTypes = {
    /**
     * Group in view.
     */
    group: shape({
      name: string,
      projects: arrayOf(shape({
        description: string,
        tableName: element,
      })),
    }),
    /**
     * Dispaches route change.
     */
    onPushRoute: func,
  }

  static defaultProps = {
    group: {
      name: '',
      projects: [],
    },
    onPushRoute: noop,
  }

  /**
   * @lifecycle
   */
  state = {
    filter: '',
  }

  /**
   * @handlers
   */
  onChangeFilter = (e) => {
    this.setState({ filter: e.target.value })
  }

  onCreateProject = () => {
    const { group, onPushRoute } = this.props
    const { name } = group

    onPushRoute(`/groups/${name}/projects/new`)
  }

  /**
   * @renders
   */
  render () {
    const { group } = this.props
    const { projects } = group
    const { filter } = this.state

    const { access, accountType, name: groupName } = group

    const hasWriteAccess = access === 'o' || (access && access.indexOf('w') > -1)

    const limit = accountType === 'free_group' ? 3 : 100

    // Projects is a number before the details are fetched from server.
    const projectArray = Array.isArray(projects) ? projects : []

    const projectLimit = `${limit - projectArray.length} / ${limit} projects remaining`

    const filteredProjects = filterProjects(filter, projectArray)

    return (
      <div className={cx(cn.view__root, 'container-fluid')}>
        <div className={cn.view__actions}>
          <BaseButton
            disabled={!hasWriteAccess}
            onClick={this.onCreateProject}
            primary
            raised
          >
            <FAIcon className={cn.btnIcon} icon="plus" />
            <span className={cn.btnLabel}>
              Create Group Project
            </span>
          </BaseButton>

          <div className={cn.filter}>
            <TextInput
              className={cn.filterInput}
              onChange={this.onChangeFilter}
              placeholder="Filter projects"
              value={filter}
            />

            <FAIcon className={cn.filterIcon} icon="search" />
          </div>
        </div>

        <div className={cn.project_limit}>
          {projectLimit}
        </div>

        <div className={cn.view__table}>
          <div className={cn.table}>
            <div className={cn.table_head}>
              <div className={cn.table_head_col}>
                Name
              </div>
              <div className={cn.table_head_col}>
                Description
              </div>
            </div>

            <div className={cn.table_body}>
              {filteredProjects.map(project => (
                <div className={cn.table_body_row} key={project.id}>
                  <div className={cn.table_body_row_col}>
                    <Link to={`/groups/${groupName}/projects/${project.id}`}>
                      {project.name}
                    </Link>
                  </div>
                  <div className={cn.table_body_row_col}>
                    {project.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @redux
 */
const mapDispatch = {
  onPushRoute: pushRoute,
}

/**
 * @export
 */
export default connect(null, mapDispatch)(ProjectsView)
