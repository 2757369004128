import React from 'react'
import {Motion, spring} from 'react-motion'
import {number, string} from 'prop-types'
import cx from 'classnames'

import cn from './TabsUnderline.css'

const motionConfig = {stiffness: 120, damping: 20}

export default function TabsUnderline ({className, xPos, width}) {
  return (
    <Motion style={{
      x: spring(xPos, motionConfig),
      w: spring(width, motionConfig),
    }}>
      {({x, w}) => (
        <div
          className={cx(cn.base, className)}
          style={{
            transform: `translate(${x}px)`,
            width: w,
          }}
        />
      )}
    </Motion>
  )
}

TabsUnderline.propTypes = {
  className: string,
  xPos: number,
  width: number,
}

TabsUnderline.defaultProps = {
  className: '',
  xPos: 0,
  width: 0,
}
