/**
 * Returns a new array with element added at the specified index.
 *
 * @param {Array}       arr
 * @param {Number}      index
 * @param {Any}         item
 * @param {Number}      itemsRemove
 * @return {Array}      array with added element
 */
export function insertAtIndex (arr, index, item, itemsRemove = 0) {
  const newArr = arr.slice()

  newArr.splice(index, itemsRemove, item)

  return newArr
}

/**
 * Returns a new array with element removed from the specified index.
 *
 * @param {Array}       arr
 * @param {Number}      index
 * @return {Array}      array with removed element
 */
export function removeAtIndex (arr, index) {
  const newArr = arr.slice()

  newArr.splice(index, 1)

  return newArr
}

/**
 * Returns a new array with elements swapped at the specified indices.
 *
 * @param {Array}       arr
 * @param {Number}      fromIndex
 * @param {Number}      toIndex
 * @return {Array}      array with swapped elements
 */
export function swapAtIndex (arr, fromIndex, toIndex) {
  const newArr = arr.slice()
  const val = newArr[fromIndex]

  newArr.splice(fromIndex, 1)
  newArr.splice(toIndex, 0, val)

  return newArr
}

export default {
  insertAtIndex,
  removeAtIndex,
  swapAtIndex,
}
