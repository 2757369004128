import {HOME_RESULTS_PER_PAGE} from 'config'
import {createApiAction} from 'store/apiMiddleware'
import applyTransformsToArray from 'utils/applyTransformsToArray'
import createActionTypes from 'utils/createActionTypes'
import addAuthUsername from './utils/addAuthUsername'

/**
 * Api endpoint
 */
const api = '/projects'

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_CREATE_CODON_MAP',
  'REQ_CREATE_CODON_MAP_ERROR',
  'REQ_CREATE_CODON_MAP_SUCCESS',
  'REQ_CREATE_PROJECT',
  'REQ_CREATE_PROJECT_ERROR',
  'REQ_CREATE_PROJECT_SUCCESS',
  'REQ_CREATE_PROJECT_COMMIT',
  'REQ_CREATE_PROJECT_COMMIT_ERROR',
  'REQ_CREATE_PROJECT_COMMIT_SUCCESS',
  'REQ_CREATE_PROJECT_SHARE',
  'REQ_CREATE_PROJECT_SHARE_ERROR',
  'REQ_CREATE_PROJECT_SHARE_SUCCESS',
  'REQ_DELETE_PROJECT',
  'REQ_DELETE_PROJECT_ERROR',
  'REQ_DELETE_PROJECT_SUCCESS',
  'REQ_DELETE_PROJECT_SHARE',
  'REQ_DELETE_PROJECT_SHARE_ERROR',
  'REQ_DELETE_PROJECT_SHARE_SUCCESS',
  'REQ_FORK_PROJECT',
  'REQ_FORK_PROJECT_ERROR',
  'REQ_FORK_PROJECT_SUCCESS',
  'REQ_JOB_STATUS',
  'REQ_JOB_STATUS_ERROR',
  'REQ_JOB_STATUS_SUCCESS',
  'REQ_PROJECT_DETAILS',
  'REQ_PROJECT_DETAILS_ERROR',
  'REQ_PROJECT_DETAILS_SUCCESS',
  'REQ_PROJECTS',
  'REQ_PROJECTS_ERROR',
  'REQ_PROJECTS_SUCCESS',
  'REQ_PUBLIC_PROJECTS',
  'REQ_PUBLIC_PROJECTS_ERROR',
  'REQ_PUBLIC_PROJECTS_SUCCESS',
  'REQ_UPDATE_PROJECT',
  'REQ_UPDATE_PROJECT_ERROR',
  'REQ_UPDATE_PROJECT_SUCCESS',
], 'project')

/**
 * Dispatches request to create codon map.
 *
 * @param {Number}    projectId
 * @param {Object}    codonData
 *    @param {Object}   (required) mappings
 *    @param {String}   (required) name
 * @return {Promise}  resolves to updated project
 */
export function reqCreateCodonMap (projectId, codonData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_CODON_MAP,
      actions.REQ_CREATE_CODON_MAP_SUCCESS,
      actions.REQ_CREATE_CODON_MAP_ERROR,
    ],
    endpoint: `${api}/${projectId}/codonmaps`,
    requestOpts: {
      data: codonData,
      method: 'POST',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create new project.
 *
 * @param {Object}    projectData
 *    @param {String}   (optional) description
 *    @param {Object}   (optional) expressionSystem
 *    @param {String}   (optional) group
 *    @param {String}   (required) name
 * @return {Promise}  resolves to new project
 */
export function reqCreateProject (projectData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_PROJECT,
      actions.REQ_CREATE_PROJECT_SUCCESS,
      actions.REQ_CREATE_PROJECT_ERROR,
    ],
    endpoint: api,
    requestOpts: {
      data: projectData,
      method: 'POST',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create a project commit.
 *
 * @param {Number}    projectId
 * @param {Object}    commitData
 *    @param {String}    (required) commitMessage
 *    @param {Array}     (required) sequenceTemplates
 * @return {Promise}  resolves to updated project
 */
export function reqCreateProjectCommit (projectId, commitData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_PROJECT_COMMIT,
      actions.REQ_CREATE_PROJECT_COMMIT_SUCCESS,
      actions.REQ_CREATE_PROJECT_COMMIT_ERROR,
    ],
    endpoint: `${api}/${projectId}/commit`,
    requestOpts: {
      data: commitData,
      method: 'POST',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create a project access share.
 *
 * @param {Number}    projectId
 * @param {Object}    shareData
 *    @param {String}    (required) access
 *    @param {String}    (required) username
 * @return {Promise}  resolves to updated project
 */
export function reqCreateProjectShare (projectId, shareData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_PROJECT_SHARE,
      actions.REQ_CREATE_PROJECT_SHARE_SUCCESS,
      actions.REQ_CREATE_PROJECT_SHARE_ERROR,
    ],
    endpoint: `${api}/${projectId}/share`,
    requestOpts: {
      data: shareData,
      method: 'POST',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to delete a project.
 *
 * @param {Number}    projectId
 * @return {Promise}  resolves to id of deleted project
 */
export function reqDeleteProject (projectId) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_PROJECT,
      actions.REQ_DELETE_PROJECT_SUCCESS,
      actions.REQ_DELETE_PROJECT_ERROR,
    ],
    endpoint: `${api}/${projectId}`,
    isDestructive: true,
    requestOpts: {
      method: 'DELETE',
    },
    schemaType: 'project',
  })
}

/**
 * Dispatches request to delete a project access share.
 *
 * @param {Number}    projectId
 * @param {String}    shareUsername
 * @return {Promise}  resolves to id of deleted project
 */
export function reqDeleteProjectShare (projectId, shareUsername, removeProject = false) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_PROJECT_SHARE,
      actions.REQ_DELETE_PROJECT_SHARE_SUCCESS,
      actions.REQ_DELETE_PROJECT_SHARE_ERROR,
    ],
    endpoint: `${api}/${projectId}/share`,
    isDestructive: removeProject,
    requestOpts: {
      data: {username: shareUsername},
      method: 'DELETE',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to fork a project.
 *
 * @param {Number}    projectId
 * @return {Promise}  resolves to forked project
 */
export function reqForkProject (projectId) {
  return createApiAction({
    actionTypes: [
      actions.REQ_FORK_PROJECT,
      actions.REQ_FORK_PROJECT_SUCCESS,
      actions.REQ_FORK_PROJECT_ERROR,
    ],
    endpoint: `${api}/${projectId}/fork`,
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for a job's status.
 *
 * @param {Number}    jobId
 * @return {Promise}  resolves to job status
 */
export function reqJobStatus (jobId) {
  return createApiAction({
    actionTypes: [
      actions.REQ_JOB_STATUS,
      actions.REQ_JOB_STATUS_SUCCESS,
      actions.REQ_JOB_STATUS_ERROR,
    ],
    endpoint: `/api/jobs/${jobId}`,
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for a project's details.
 *
 * @param {Number}    projectId
 * @param {bool}      isPublic
 * @return {Promise}  resolves to detailed project
 */
export function reqProjectDetails (projectId, isPublic) {
  return createApiAction({
    actionTypes: [
      actions.REQ_PROJECT_DETAILS,
      actions.REQ_PROJECT_DETAILS_SUCCESS,
      actions.REQ_PROJECT_DETAILS_ERROR,
    ],
    endpoint: `${api}/${projectId}`,
    requestOpts: {
      noAuth: isPublic,
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for a user's projects.
 *
 * @param {String}    username
 * @param {Number}    page
 * @param {Number}    perPage
 * @return {Promise}  resolves to list of user's projects
 */
export function reqProjects (username = '', page = 0, perPage = HOME_RESULTS_PER_PAGE) {
  return createApiAction({
    actionTypes: [
      actions.REQ_PROJECTS,
      actions.REQ_PROJECTS_SUCCESS,
      actions.REQ_PROJECTS_ERROR,
    ],
    endpoint: api,
    paginate: {
      key: username,
      page,
      perPage,
    },
    requestOpts: {
      query: {
        page,
        per_page: perPage,
        user: username,
      },
    },
    schemaType: 'projectArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request for public projects.
 *
 * @param {String}    searchTerm
 * @param {Number}    page
 * @param {Number}    perPage
 * @return {Promise}  resolves to list of public projects
 */
export function reqPublicProjects (searchTerm = '', page = 0, perPage = HOME_RESULTS_PER_PAGE) {
  const query = {
    page,
    per_page: perPage,
  }

  // Only add search query if a term is present
  if (searchTerm.length > 0) {
    query.search = searchTerm
  }

  return createApiAction({
    actionTypes: [
      actions.REQ_PUBLIC_PROJECTS,
      actions.REQ_PUBLIC_PROJECTS_SUCCESS,
      actions.REQ_PUBLIC_PROJECTS_ERROR,
    ],
    endpoint: `${api}/public`,
    paginate: {
      key: searchTerm,
      page,
      perPage,
    },
    requestOpts: {
      noAuth: true,
      query,
    },
    schemaType: 'publicProjectArray',
  })
}

/**
 * Dispatches request to update a project's info like name, description, etc.
 *
 * @param {Number}    projectId
 * @param {Object}    projectData
 *    @param {String}   (optional) description
 *    @param {String}   (optional) name
 * @return {Promise}  resolves to forked project
 */
export function reqUpdateProject (projectId, projectData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_UPDATE_PROJECT,
      actions.REQ_UPDATE_PROJECT_SUCCESS,
      actions.REQ_UPDATE_PROJECT_ERROR,
    ],
    endpoint: `${api}/${projectId}`,
    requestOpts: {
      data: projectData,
      method: 'PUT',
    },
    schemaType: 'project',
    transformResponse: addAuthUsername,
  })
}
