import React from 'react'
import {bool, func, shape, string} from 'prop-types'

import BaseButton from 'components/buttons/BaseButton'
import { FontAwesomeButton } from 'components/FontAwesomeButton'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { TextInput } from 'components/text-input'
import cn from './index.css'

export default function ControlFilterCreate ({
  disableCreate,
  filterProps,
  hint,
  onInputChange,
  onCreate,
  onUpload,
}) {
  return (
    <div className={cn.base}>
      <TextInput
        className={cn.input}
        name='name'
        onChange={onInputChange}
        placeholder={hint}
        value={filterProps.name}
      />
      <BaseButton
        className={cn.btn}
        disabled={disableCreate}
        onClick={onCreate}
        primary={!disableCreate}
      >
        <div className={cn.btnBody}>
          <FontAwesomeIcon icon='plus' />
          <span className={cn.btnLabel}>
            Create
          </span>
        </div>
      </BaseButton>
      <FontAwesomeButton
        className={cn.btn}
        icon="upload"
        label="Fasta"
        onClick={onUpload}
        primary
      />
    </div>
  )
}

ControlFilterCreate.propTypes = {
  disableCreate: bool,
  filterProps: shape({
    name: string.isRequired,
  }).isRequired,
  hint: string,
  onInputChange: func.isRequired,
  onCreate: func.isRequired,
  onUpload: func.isRequired,
}

ControlFilterCreate.defaultProps = {
  disableCreate: false,
  hint: 'Filter by name',
}
