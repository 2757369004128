/**
 * This file contains selectors that pertain to the state provided by React
 * Router Redux.
 * @see https://github.com/reactjs/react-router-redux {[type]}
 */
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import { createSelector } from 'reselect'

import { getProjectById } from './project'

const getRoot = get('router')
const getDetails = get('location')

// Provide an empty object in case params doesn't exist in props to prevent
// errors.
const getParams = getOr({}, 'params')

// Creates a function for reselect selectors that extracts the desired property
// from the params prop.
const getFromParamsProp = propName => (_, props) =>
  flow(
    getParams,
    get(propName),
  )(props)

// Returns the pathname for the current route.
export const getRoutePath = flow(
  getRoot,
  getDetails,
  get('pathname'),
)

// Returns the base link for authorized users to return to the home view. This
// allows users working with group projects to get brought back to the group
// home view rather than their personal one.
export const getBaseLink = createSelector(
  getRoutePath,
  path => {
    const paths = path.split('/')
    const groupParamIndex = paths.indexOf('groups')

    return groupParamIndex > 0 ? `/groups/${paths[groupParamIndex + 1]}` : '/'
  },
)

// Returns the base title that relates to the base link. This also includes the
// project title should the user be editing/viewing a project.
export const getBaseTitle = createSelector(
  getRoutePath,
  state => state, // Used to get project name
  (pathname, state) => {
    const paths = pathname.split('/')
    const groupParamIndex = paths.indexOf('groups')
    const projectParamIndex = paths.indexOf('projects')

    let prefix = 'Tupac.Bio'
    let projectId = null

    // Group aram should be split string after 'groups' keyword.
    if (groupParamIndex > 0) {
      prefix = paths[groupParamIndex + 1]
    }
    // Project owner param should be split string before 'projects' keyword.
    // Project id param should be split string after 'projects' keyword.
    if (projectParamIndex > 0) {
      prefix = paths[projectParamIndex - 1]
      projectId = paths[projectParamIndex + 1]
    }

    const project = projectId && getProjectById(state, { id: projectId })
    const suffix = project ? project.name : ''

    return suffix ? `${prefix} / ${suffix}` : prefix
  },
)
