import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'

import FormControl from 'components/FormControl'
import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import { TextInput } from 'components/text-input'
import AminoRadioSelect from './AminoRadioSelect'
import cn from './CustomCodonModal.css'

export default function CustomCodonModal({
  aminoMap,
  disableAccept,
  modalAcceptText,
  modalTitle,
  name,
  onCodonSelect,
  onFieldChange,
  onModalAccept,
  onModalCancel,
}) {
  return (
    <ModalLayout
      acceptButtonLabel={modalAcceptText}
      className={cn.base}
      disableAccept={disableAccept}
      onAccept={onModalAccept}
      onClose={onModalCancel}
    >
      <ModalIconHeader
        icon="square"
        subtitle="Custom codon expression maps can be used to fine-tailor the optimizations for your generated sequences."
        title={modalTitle}
      />

      <div className={cn.body}>
        <FormControl
          className={cn.control}
          icon="label"
          idFor="name"
          label="Name"
        >
          <TextInput
            id="name"
            name="name"
            onChange={onFieldChange}
            placeholder="Name is required"
            value={name}
          />
        </FormControl>

        <div className={cn.aminoSelect}>
          {aminoMap.map(amino => (
            <AminoRadioSelect
              key={amino.label}
              label={amino.label}
              name={amino.name}
              onCodonSelect={onCodonSelect}
              options={amino.options}
              value={amino.value}
            />
          ))}
        </div>
      </div>
    </ModalLayout>
  )
}

CustomCodonModal.propTypes = {
  aminoMap: arrayOf(
    shape({
      label: string.isRequired,
      name: string.isRequired,
      options: arrayOf(string),
      value: string.isRequired,
    }),
  ),
  disableAccept: bool,
  modalAcceptText: string,
  modalTitle: string,
  name: string,
  onCodonSelect: func.isRequired,
  onFieldChange: func.isRequired,
  onModalAccept: func.isRequired,
  onModalCancel: func.isRequired,
}

CustomCodonModal.defaultProps = {
  aminoMap: [],
  disableAccept: false,
  modalAcceptText: 'Save',
  modalTitle: 'Create Custom Codon Map',
  name: '',
}
