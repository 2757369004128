import { connect } from 'react-redux'

import { getHasUnsavedChanges, getProjectId } from 'selectors/designer'
import { reqForkProject } from 'actions/project'
import { addToast } from 'actions/toast'

export default connect(
  state => ({
    hasUnsavedChanges: getHasUnsavedChanges(state),
    projectId: getProjectId(state),
  }),
  {
    onProjectFork: reqForkProject,
    onToastAdd: addToast,
  },
)
