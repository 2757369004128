import React, {Component} from 'react'
import {func} from 'prop-types'

import SelectInput from 'components/SelectInput'
import cn from './DesignSelectInputContainer.css'

export default class DesignSelectInputContainer extends Component {
  static propTypes = {
    onDesignChange: func.isRequired,
  }

  handleDesignChange = ({target}) => {
    this.props.onDesignChange(target.value)
  }

  render () {
    return (
      <SelectInput
        {...this.props}
        className={cn.base}
        label='Design: '
        labelClass={cn.title}
        name='design'
        onChange={this.handleDesignChange}
        toggleWrapperClass={cn.input}
      />
    )
  }
}
