import { oneOf, string } from 'prop-types'
import React from 'react'

import logoSVG from 'styles/img/tupac-logo-orange.png'

/**
 * A component that renders the app's logo.
 * @prop       {String} [className='']    Custom class
 * @prop       {Number} [size=32]         Size of logo in pixels
 */
export default function Brand ({ className, size }) {
  return (
    <img
      alt="Tupac Bio"
      className={className}
      src={logoSVG}
      style={{ height: size, width: size }}
    />
  )
}

Brand.propTypes = {
  className: string,
  size: oneOf([8, 16, 32]),
}

Brand.defaultProps = {
  className: '',
  size: 32,
}
