import codonMap from './codon_map'

// Splits input into an array of strings, with each containing 3 bases.
const chunkIntoCodons = input => input.match(/.{1,3}/g) || []

/**
 * Converts a string of DNA bases into a string of amino acids.
 * @param {String} seq DNA sequence
 */
export default function dnaToAminos (seq) {
  return chunkIntoCodons(seq.toLowerCase()).reduce((sequence, chunk, i) => (
    sequence + (codonMap[chunk] || '')
  ), '')
}
