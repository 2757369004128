import React, {Component} from 'react'
import {func, string} from 'prop-types'
import cx from 'classnames'

import cn from './BoundaryRect.css'

export default class BoundaryRect extends Component {
  static propTypes = {
    children: func.isRequired,
    className: string,
  }

  static defaultProps = {
    className: '',
  }

  setRef = (r) => {
    this.node = r
  }

  get rect () {
    return this.node ? this.node.getBoundingClientRect() : {}
  }

  render () {
    const {children, className} = this.props

    return (
      <div className={cx(cn.base, className)} ref={this.setRef}>
        {children({boundaryRect: this.rect})}
      </div>
    )
  }
}
