import { bool } from 'prop-types'
import React from 'react'

export default function DNAIcon ({ mapped }) {
  const fillColor = mapped
    ? '#F4831F' // orange
    : 'var(--clr-bg-xdk)' // faint-grey

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">
      <g fill="none" fillRule="evenodd">
        <g fill={fillColor}>
          <path fillRule="nonzero" d="M5.95 25.4265C-.35 20.993-1.8735 12.25 2.5667 5.95 7.0069-.35 15.743-1.8735 22.05 2.5598c6.3069 4.4333 7.8235 13.1765 3.3833 19.4765-4.4402 6.3-13.1764 7.8235-19.4833 3.3902zM21.7686 2.951C15.6814-1.3382 7.2402.1373 2.9578 6.2176-1.3314 12.305.1441 20.7461 6.2245 25.0284c6.0873 4.2892 14.5284 2.8138 18.8108-3.2666 4.2823-6.0804 2.8274-14.5285-3.2667-18.8108z" />
          <path d="M16.3154 3.9048l.3713-.5275 6.4983 4.5743-.3713.5275zM11.2151 10.5955l.3713-.5275 6.908 4.8627-.3713.5275zM9.1894 13.5421l.3713-.5275 6.8238 4.8035-.3713.5274zM4.5365 20.8487l.3713-.5275 6.2122 4.373-.3714.5274z" />
          <path d="M2.7588 21.8922c1.0912-1.3451 3.0265-3.349 7.7343-2.9579 3.301.2745 5.902-.7206 7.151-2.7314 1.1324-1.8323.9402-4.248-.5078-6.6431-1.7157-2.8206-1.448-5.1745-.9265-6.6637.398-1.1255 1.0088-1.9353 1.3382-2.3128l.4736.3294c-.8785 1.0912-2.855 4.248-.3775 8.3314 1.5784 2.5941 1.7637 5.2432.5079 7.2677-1.3726 2.2098-4.1726 3.3078-7.7069 3.0058-4.399-.3705-6.4441 1.7775-7.4049 2.951l-.2814-.5764z" />
          <path d="M9.7588 27.2176c.7892-1.297 2.1137-3.946.2814-7.9676-1.4755-3.246-1.4686-6.2245.0274-8.1804 1.4275-1.8598 3.9804-2.5941 7.2128-2.0657 4.701.7755 6.9931-2.0176 7.7137-3.1637l.4804.3363c-.247.4255-.796 1.2284-1.7157 1.9559-1.2078.9607-3.3284 2.0039-6.5813 1.4617-2.9922-.4941-5.353.1579-6.6432 1.8392-1.3588 1.7706-1.345 4.5363.0412 7.5765 1.9559 4.296.7343 6.7804-.1647 8.2559l-.652-.048z" />
        </g>
      </g>
    </svg>
  )
}

DNAIcon.propTypes = {
  mapped: bool,
}

DNAIcon.defaultProps = {
  mapped: false,
}
