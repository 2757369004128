import {getMyUsername} from 'selectors/authUser'

/**
 * Adds logged in username to response entity.
 *
 * @param {Object}  entity
 * @param {Object}  state
 * @return {Object} shaped entity
 */
export default function addAuthUsername (entity, state) {
  return {
    ...entity,
    authUsername: getMyUsername(state),
  }
}
