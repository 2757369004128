import auth0 from 'auth0-js'
import Auth0Lock from 'auth0-lock'

import config from './config'

export class Auth {
  constructor() {
    const {
      callbackURL,
      clientID,
      credentialOpts,
      domain,
      params,
      responseType,
    } = config

    this.credentialOpts = credentialOpts

    this.auth = new auth0.WebAuth({
      callbackURL,
      clientID,
      domain,
      responseType,
    })

    this.lock = new Auth0Lock(clientID, domain, {
      autoclose: true,
      auth: {
        redirectUrl: callbackURL,
        responseType,
        params,
      },
    })
  }

  /**
   * Shows Auth0 Lock. An option object can be passed to it to configure.
   * @see https://auth0.com/docs/libraries/lock/v11/api#show-
   */
  handleLockShow = opts => {
    this.lock.show(opts)
  }

  /**
   * Logs out the current user from Auth0.
   * @see https://auth0.com/docs/libraries/lock/v11/api#logout-
   */
  handleLogout = () => {
    this.lock.logout()
  }

  /**
   * Authorization for AWS is handled through Auth0 credential delegation. This
   * method resolves to those credentials.
   */
  getUserCredentials = token =>
    new Promise((resolve, reject) => {
      const credentialOpts = {
        ...this.credentialOpts,
        id_token: token,
      }

      this.auth.client.delegation(credentialOpts, (error, res) => {
        if (error) {
          return reject(error)
        }

        const { Credentials } = res

        return resolve(Credentials)
      })
    })

  /**
   * Returns a user's auth0 profile.
   * @see https://auth0.com/docs/libraries/lock/v11/api#getuserinfo-
   */
  getUserInfo = accessToken =>
    new Promise((resolve, reject) => {
      this.lock.getUserInfo(accessToken, (error, userInfo) => {
        if (error) {
          return reject(error)
        }

        return resolve(userInfo)
      })
    })

  /**
   * Sets up lock events to be handled by callbacks.
   * @see https://auth0.com/docs/libraries/lock/v11/api#on-
   */
  setupLockEvents(onError, onSuccess) {
    this.lock.on('authorization_error', onError)

    this.lock.on('unrecoverable_error', onError)

    this.lock.on('authenticated', async ({ accessToken, idToken }) => {
      try {
        const profile = await this.getUserInfo(accessToken)
        const credentials = await this.getUserCredentials(idToken)

        onSuccess({
          credentials,
          profile,
          token: idToken,
        })
      } catch (e) {
        onError(e)
      }
    })
  }
}

export default new Auth()
