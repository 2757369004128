import {combineReducers} from 'redux'
import paginate from './utils/paginate'

import {actions} from 'actions/project'

// import {actions} from 'actions/customElement'

// Updates the pagination data for different actions.
const pagination = combineReducers({
  projects: paginate({
    mapActionToKey: action => action.paginate.key,
    types: [
      actions.REQ_PROJECTS,
      actions.REQ_PROJECTS_SUCCESS,
      actions.REQ_PROJECTS_ERROR,
    ]
  }),

  // customElements: paginate({
  //   mapActionToKey: action => action.paginationKey,
  //   types: [
  //     REQ_CUSTOM_ELEMENTS,
  //     REQ_CUSTOM_ELEMENTS_SUCCESS,
  //     REQ_CUSTOM_ELEMENTS_ERROR,
  //   ]
  // }),

  publicProjects: paginate({
    mapActionToKey: action => action.paginate.key,
    types: [
      actions.REQ_PUBLIC_PROJECTS,
      actions.REQ_PUBLIC_PROJECTS_SUCCESS,
      actions.REQ_PUBLIC_PROJECTS_ERROR,
    ]
  }),

  // myElements,
  // myVariableElements
})

export default pagination
