/**
 * User access types should be one of the following string values. Each
 * increasing level of access type appends to the string.
 *
 * r    => read-only (view only)
 * rw   => read-write (can edit)
 * rwa  => read-write-admin (admin)
 * rwao => read-write-admin-owner (owner)
 */

import { oneOf } from 'prop-types'

const UserAccessType = oneOf(['r', 'rw', 'rwa', 'o'])

export default UserAccessType
