import React, {Component} from 'react'
import {arrayOf, func, number, oneOfType, shape, string} from 'prop-types'
import isNil from 'lodash/isNil'

import Tabs from './Tabs'

export default class TabsContainer extends Component {
  static propTypes = {
    initialValue: oneOfType([number, string]),
    onTabChange: func,
    tabs: arrayOf(shape({
      label: string.isRequired,
    }))
  }

  static defaultProps = {
    initialValue: '',
    onTabChange: () => console.debug('Tab changed'),
  }

  constructor (props) {
    super(props)

    const {initialValue, tabs} = props

    this.state = {
      activeTabValue: initialValue || tabs[0].value || 0,
      nodeX: null,
      underlineX: null,
      underlineWidth: null,
    }
  }

  shapeTabs (tabs) {
    return tabs.map((tab, i) => ({
      ...tab,
      key: tab.label,
      value: i,
    }))
  }

  getTabDimensions (tabNode) {
    const {left: tabLeft, width} = tabNode.getBoundingClientRect()
    const {left} = tabNode.parentNode.getBoundingClientRect()

    return {
      nodeX: left,
      underlineX: tabLeft - left,
      underlineWidth: width,
    }
  }

  handleGetInitialTabDimensions = (tab) => {
    if (!isNil(this.state.nodeX) || !tab || !tab.getBoundingClientRect) {
      return
    }

    this.setState(this.getTabDimensions(tab))
  }

  handleTabChange = ({target}) => {
    this.props.onTabChange(target.value)
    this.setState({
      activeTabValue: Number(target.value), // Need to change it back to number
      ...this.getTabDimensions(target),
    })
  }

  render () {
    const {tabs, ...props} = this.props
    const {activeTabValue, underlineX, underlineWidth} = this.state

    return (
      <Tabs
        {...props}
        activeTabValue={activeTabValue}
        onGetInitialTabDimensions={this.handleGetInitialTabDimensions}
        onTabChange={this.handleTabChange}
        tabs={this.shapeTabs(tabs)}
        underlineX={underlineX}
        underlineWidth={underlineWidth}
      />
    )
  }
}
