import { func } from 'prop-types'
import React from 'react'

import Button from 'components/BaseButton'
import BioDesignerLogo from 'styles/img/biodesigner_logo.svg'
import TupacLogo from 'styles/img/tupacbio_logo_gradient.svg'

import cn from './LoginView.css'

export default function LoginView ({ onLoginShow, onSignUpShow }) {
  return (
    <div className={cn.root}>
      <div className={cn.corner}>
        <img
          alt="Tupac Bio"
          className={cn.corner__logo}
          src={TupacLogo}
        />
      </div>

      <div className={cn.content}>
        <div className={cn.content__header}>
          <div className={cn.content__header__top}>
            <div className={cn.content__header__logo_wrapper}>
              <img
                alt="BioDesigner"
                className={cn.content__header__logo}
                src={BioDesignerLogo}
              />
            </div>
            <span className={cn.content__header__beta}>
              Beta
            </span>
          </div>
          <span className={cn.content__header__tagline}>
            Synthetic DNA and AA Construct Design
          </span>
        </div>

        <div className={cn.content__actions}>
          <Button
            className={cn.btn__outline}
            onClick={onSignUpShow}
          >
            Signup
          </Button>
          <Button
            className={cn.btn}
            onClick={onLoginShow}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  )
}

LoginView.propTypes = {
  onLoginShow: func.isRequired,
  onSignUpShow: func.isRequired,
}
