import React, {Component} from 'react'
import {Motion, spring} from 'react-motion'
import {number, string} from 'prop-types'
import cx from 'classnames'

import cn from './RectStackRect.css'

const motionConfig = {stiffness: 120, damping: 20}

export default class RectStackRect extends Component {
  static propTypes = {
    className: string,
    fill: string,
    height: number.isRequired,
    width: number.isRequired,
    y: number.isRequired,
  }

  static defaultProps = {
    className: '',
    fill: '',
  }

  shouldComponentUpdate ({height, y}) {
    return y !== this.props.y || height !== this.props.height
  }

  render () {
    const {className, fill, width} = this.props
    const style = {
      height: spring(this.props.height, motionConfig),
      y: spring(this.props.y, motionConfig),
    }

    return (
      <Motion style={style}>
        {({height, y}) => (
          <rect
            className={cx(cn.base, className)}
            fill={fill}
            height={height}
            width={width}
            y={y}
          />
        )}
      </Motion>
    )
  }
}
