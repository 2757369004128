import {
  arrayOf,
  bool,
  number,
  shape,
  string,
} from 'prop-types'

import InputValueType from './inputValueType'

const TagType = shape({
  amino: bool,
  aminos: string,
  color: string,
  description: string,
  hasMappedDNA: bool,
  id: InputValueType.isRequired,
  length: number.isRequired,
  name: string,
  offset: number.isRequired,
  spannedElements: arrayOf(InputValueType).isRequired,
  type: string.isRequired,
})

export default TagType
