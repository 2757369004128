import React, {Component} from 'react'
import {func} from 'prop-types'

import {ACCESS_TYPES} from 'core/Constants'
import AccessAdd from './AccessAdd'

const ELEMENT_ACCESS_TYPES = [
  {key: 'r', label: 'View Only', value: ACCESS_TYPES.read},
  {key: 'w', label: 'View/Edit', value: ACCESS_TYPES.write},
]

export default class AccessAddContainer extends Component {
  static propTypes = {
    onAccessAdd: func.isRequired,
  }

  state = {
    access: ACCESS_TYPES.read,
    error: '',
    submitting: false,
    username: '',
  }

  get accessTypes () {
    return ELEMENT_ACCESS_TYPES.map(type => ({
      ...type,
      active: type.value === this.state.access,
    }))
  }

  get valid () {
    return !this.state.submitting && this.state.username
  }

  handleAccessAdd = () => {
    const {access, username} = this.state

    this.setState({submitting: true})
    Promise.resolve(this.props.onAccessAdd({access, username}))
      .then(() => this.setState({
        access: ACCESS_TYPES.read,
        submitting: false,
        username: '',
      }))
      .catch(error => this.setState({
        submitting: false,
        error,
      }))
  }

  handleFieldChange = ({target}) => {
    this.setState({[target.name]: target.value})
  }

  render () {
    const {submitting, ...state} = this.state

    return (
      <AccessAdd
        {...state}
        accessTypes={this.accessTypes}
        disabled={!this.valid}
        onAccessAdd={this.handleAccessAdd}
        onFieldChange={this.handleFieldChange}
      />
    )
  }
}
