const DEFAULT_CONFIG = {
  contextMenuProps: {
    // ContextMenuTag options
    disableTagDelete: true,
    disableTagDeleteAll: true,
    disableTagEdit: true,
    disableTagEditAll: true,

    // ContextMenuSelection options
    disableMotifSearch: true,
    disableTagAdd: true,
  },
  modalTitle: 'Create/edit annotation',
  modalAcceptText: 'Save',
}

const EDITOR_STATES = {
  // Creating new tags
  create: {
    contextMenuProps: {
      ...DEFAULT_CONFIG.contextMenuProps,
      disableSequenceCopy: false,
      disableSequenceCut: true,
      disableSequenceDelete: true,
      disableSequencePaste: true,
    },
    disableSequenceEditor: false,
    disableTypeSelect: true,
    modalTitle: 'Create annotation',
    modalAcceptText: 'Create',
  },

  // Editing existing tag
  edit: {
    contextMenuProps: {
      ...DEFAULT_CONFIG.contextMenuProps,
      disableSequenceCopy: false,
      disableSequenceCut: false,
      disableSequenceDelete: false,
      disableSequencePaste: false,
    },
    disableSequenceEditor: false,
    disableTypeSelect: true,
    modalTitle: 'Edit annotation',
    modalAcceptText: 'Save',
  },

  // Editing existing tags (multiple)
  editMany: {
    contextMenuProps: {
      ...DEFAULT_CONFIG.contextMenuProps,
      disableSequenceCopy: false,
      disableSequenceCut: false,
      disableSequenceDelete: false,
      disableSequencePaste: false,
    },
    disableNameChange: true,
    disableSequenceEditor: false,
    disableTypeSelect: true,
    modalTitle: 'Edit all copies of this annotation',
    modalAcceptText: 'Save All',
  },
}

export default function configEditorByState (state) {
  return EDITOR_STATES[state] || DEFAULT_CONFIG
}
