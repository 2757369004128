import React from 'react'
import {bool, func, number} from 'prop-types'

import FaIconButton from 'components/FaIconButton'
import cn from './TemplateListHeader.css'

export default function TemplateListHeader ({disabled, onTemplateAdd, total}) {
  return (
    <div className={cn.base}>
      <span className={cn.title}>
        {`Total: ${total}`}
      </span>
      <FaIconButton
        disabled={disabled}
        icon='plus'
        onClick={onTemplateAdd}
        title='Add Design'
      />
    </div>
  )
}

TemplateListHeader.propTypes = {
  disabled: bool,
  onTemplateAdd: func.isRequired,
  total: number.isRequired,
}

TemplateListHeader.defaultProps = {
  disabled: false,
}
