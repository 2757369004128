/* eslint react/no-find-dom-node: 0 */

import cx from 'classnames'
import {
  bool,
  func,
  number,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'

import { noop } from 'utils'

import cn from './InlineEditText.css'

/**
 * @export
 * @component
 */
export default class InlineEditText extends Component {
  static propTypes = {
    /**
     * If `true`, disable edit.
     */
    isDisabled: bool,
    /**
     * If `true`, render textarea instead of input.
     */
    isTextArea: bool,
    /**
     * Handler called when input is blurred.
     */
    onUpdate: func,
    /**
     * Key to map the value to in the object that gets passed to `onUpdate`.
     */
    propName: string,
    /**
     * Number of rows to render for text area.
     */
    rows: number,
    /**
     * Value of input.
     */
    value: string,
  }

  static defaultProps = {
    isDisabled: false,
    isTextArea: false,
    onUpdate: noop,
    propName: 'value',
    rows: 6,
    value: '',
  }

  /**
   * @lifecycle
   */
  constructor (props) {
    super(props)

    const { value } = props

    this.state = {
      initialValue: value,
      isEditing: false,
      isLoading: false,
      value,
    }
  }

  componentWillReceiveProps (newProps) {
    const { value } = newProps
    const { initialValue } = this.state

    if (value !== initialValue) {
      this.setState({
        initialValue: value,
        value,
      })
    }
  }

  componentDidUpdate (oldProps, oldState) {
    const { isEditing: prevIsEditing } = oldState
    const { isEditing } = this.state

    // If componenet goes from not editing to editing, focus input
    if (!prevIsEditing && isEditing && this.input) {
      findDOMNode(this.input).focus()
    }
  }

  /**
   * @refs
   */
  cacheInput = (ref) => {
    this.input = ref
  }

  /**
   * @handlers
   */
  onChangeValue = ({ target }) => {
    const { isLoading } = this.state

    if (isLoading) {
      return
    }

    this.setState({ value: target.value })
  }

  onFinishEdit = () => {
    const { onUpdate, propName } = this.props
    const { initialValue, value } = this.state

    // Ignore if value hasn't changed
    if (initialValue === value) {
      this.setState({ isEditing: false })

      return
    }

    this.setState({ isLoading: true }, () => {
      Promise.resolve(onUpdate({ [propName]: value })).then(() => {
        this.setState({
          isEditing: false,
          isLoading: false,
        })
      })
    })
  }

  onStartEdit = () => {
    const { isDisabled } = this.props

    if (isDisabled) {
      return
    }

    this.setState({ isEditing: true })
  }

  /**
   * @renders
   */
  renderInput () {
    const { isTextArea, rows } = this.props
    const { value } = this.state

    if (isTextArea) {
      return (
        <textarea
          className={cn.input}
          onBlur={this.onFinishEdit}
          onChange={this.onChangeValue}
          ref={this.cacheInput}
          rows={rows}
          value={value}
        />
      )
    }

    return (
      <input
        className={cn.input}
        onBlur={this.onFinishEdit}
        onChange={this.onChangeValue}
        ref={this.cacheInput}
        value={value}
      />
    )
  }

  renderText () {
    const { value } = this.state

    // Use default text if no value exists.
    const text = value || 'Click here to edit'

    return (
      <button className={cn.text} onClick={this.onStartEdit} type="button">
        {text}
      </button>
    )
  }

  render () {
    const { isEditing } = this.state

    return (
      <div className={cx(cn.root, { [cn.root_editing]: isEditing })}>
        {isEditing && this.renderInput()}

        {!isEditing && this.renderText()}
      </div>
    )
  }
}
