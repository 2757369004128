import React from 'react'
import {node, string} from 'prop-types'
import cx from 'classnames'

import cn from './MaterialIcon.css'

export default function MaterialIcon ({className, icon, iconClass, label}) {
  return (
    <div className={cx(cn.base, className)}>
      <i className={cx(cn.icon, 'material-icons', iconClass)}>
        {icon}
      </i>
      {label}
    </div>
  )
}

MaterialIcon.propTypes = {
  className: string,
  icon: string.isRequired,
  iconClass: string,
  label: node,
}

MaterialIcon.defaultProps = {
  className: '',
  iconClass: '',
  label: null,
}
