/**
 * Symbol to identify API actions with.
 */
export const API = Symbol('API')

/**
 * Returns an API action.
 *
 * @param {Object}    opts
 *    @param {Array}  (required) actionTypes => 3 in order [request, success, error]
 *    @param {String} (required) endpoint
 *    @param {Bool}   (optional) isDestructive
 *    @param {String} (optional) paginationKey
 *    @param {Object} (required) requestOpts => options to pass to request
 *    @param {String} (required) schemaType
 *    @param {Func}   (optional) transformResponse
 * @return {Func}     api action
 */
export default function createApiAction (opts) {
  const {actionTypes} = opts

  if (!Array.isArray(actionTypes) || actionTypes.length !== 3) {
    throw new Error('Expected an array of three action types')
  }

  return function apiActionThunk (dispatch, getState) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: '@ignore/API_MIDDLEWARE_CALL',
        [API]: {
          ...opts,
          onError: reject,
          onResolve: resolve,
        },
      })
    })
  }
}
