import React from 'react'
import {bool, func, node, oneOf, string} from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/buttons/BaseButton'
import cn from './ModalLayout.css'

export default function ModalLayout ({
  acceptButtonClass,
  acceptButtonLabel,
  bodyClass,
  cancelButtonLabel,
  children,
  className,
  disableAccept,
  onAccept,
  onClose,
  size,
}) {
  return (
    <div className={cx(cn.base, className, {
      [cn.small]: size === 'small',
      [cn.large]: size === 'large',
    })}>
      <div className={cx(cn.body, bodyClass)}>
        {children}
      </div>
      <div className={cn.actions}>
        <BaseButton className={cn.cancel} onClick={onClose}>
          {cancelButtonLabel}
        </BaseButton>
        <BaseButton
          className={cx(cn.accept, acceptButtonClass)}
          disabled={disableAccept}
          onClick={onAccept}
          primary
        >
          {acceptButtonLabel}
        </BaseButton>
      </div>
    </div>
  )
}

ModalLayout.propTypes = {
  acceptButtonClass: string,
  acceptButtonLabel: node,
  bodyClass: string,
  cancelButtonLabel: node,
  children: node,
  className: string,
  disableAccept: bool,
  onAccept: func.isRequired,
  onClose: func.isRequired,
  size: oneOf(['', 'small', 'large']),
}

ModalLayout.defaultProps = {
  acceptButtonClass: '',
  acceptButtonLabel: 'Accept',
  bodyClass: '',
  cancelButtonLabel: 'Cancel',
  children: null,
  className: '',
  disableAccept: false,
  size: '',
}
