import cx from 'classnames'
import { bool, node, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import cn from './LinkButton.css'

/**
 * A component that renders a link with the same styling as our buttons.
 * @prop       {String} [bodyClass=''] Custom class for body element
 * @prop       {Node}   children       HTML node
 * @prop       {String} [className=''] Custom class for root element
 * @prop       {Bool}   [disabled=false]  Disables button
 * @prop       {String} to             Link path
 */
export default function LinkButton ({
  bodyClass,
  children,
  className,
  disabled,
  to,
}) {
  return (
    <Link
      className={cx(cn.base, className)}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      to={to}
    >
      <div className={cx(cn.body, bodyClass)}>
        {children}
      </div>
    </Link>
  )
}

LinkButton.propTypes = {
  bodyClass: string,
  children: node.isRequired,
  className: string,
  disabled: bool,
  to: string.isRequired,
}

LinkButton.defaultProps = {
  bodyClass: '',
  className: '',
  disabled: false,
}
