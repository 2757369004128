import omit from 'lodash/omit'

/**
 * Properties to strip.
 */
const baseStrip = ['authUsername']

/**
 * Shapes group data structure.
 *
 * @param {Object}  entity
 * @return {Object} shaped entity
 */
export function shapeGroup (entity) {
  const {
    authUsername,
    id,
    name,
    ownerName,
    shares,
  } = entity

  const access = getShareAccess(shares, ownerName, authUsername)

  return {
    ...omit(entity, baseStrip),
    access,
    link: `/groups/${name}`,
    uuid: `${id}-${name}`,
  }
}

/**
 * Shapes project data structure.
 *
 * @param {Object}  entity
 * @return {Object} shaped entity
 */
export function shapeProject (entity) {
  const {
    authUsername,
    group,
    id,
    latestCommit = {},
    name,
    ownerName,
    shares,
  } = entity

  const isPublic = !ownerName

  const access = group
    ? getShareAccess(group.shares, group.ownerName, authUsername)
    : getShareAccess(shares, ownerName, authUsername)

  const {
    sequenceKeypath,
    sequenceTemplates,
    sequenceTemplatesCount,
  } = latestCommit

  return {
    ...omit(entity, baseStrip),
    access,
    link: `${ownerName}/projects/${id}`,
    linkName: isPublic ? name : `${ownerName} / ${name}`,
    sequenceKeypath,
    templates: sequenceTemplates,
    templateCount: sequenceTemplatesCount,
    uuid: `${id}-${name}`,
  }
}

/**
 * Returns the user's share access.
 *
 * @param {Array}   shares
 * @param {String}  username
 * @return {String} access
 */
function getShareAccess (shares, ownerName, username) {
  if (ownerName === username) {
    return 'rwao'
  }

  return shares.reduce((access, share) => {
    if (access.length > 0) {
      return access
    }
    else if (share.username === username) {
      return share.access
    }

    return access
  }, '')
}
