import cx from 'classnames'
import { push as pushRoute } from 'connected-react-router'
import {
  arrayOf,
  bool,
  element,
  func,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { reqGroupDetails } from 'actions/group'
import TabHeader from 'components/TabHeader'
import { getGroupByName } from 'selectors/group'
import { noop } from 'utils'

import ConnectedProjectsView from './ProjectsView'
import ConnectedSettingsView from './SettingsView'
import ConnectedShareView from './ShareView'
import cn from './GroupView.css'

// Group view tabs
const viewTabs = [
  {
    TabComponent: ConnectedProjectsView,
    label: 'Projects',
  },
  {
    TabComponent: ConnectedShareView,
    label: 'Members',
  },
  {
    TabComponent: ConnectedSettingsView,
    label: 'Settings',
  },
]

/**
 * @component
 */
export class GroupView extends Component {
  static propTypes = {
    /**
     * Group in view.
     */
    group: shape({}),
    /**
     * Fetches group details.
     */
    onFetchGroupDetails: func,
    /**
     * @react-router-redux
     * Dispatches a route change.
     */
    onPushRoute: func,
    /**
     * @react-router
     * Route params.
     */
    match: shape({
      params: shape({
        groupName: string,
      }),
    }).isRequired,
    /**
     * List of tabs that this view can render.
     */
    tabs: arrayOf(shape({
      TabComponent: oneOfType([element, func]),
      isDisabled: bool,
      label: string,
      tabProps: shape({}),
    })),
  }

  static defaultProps = {
    group: {},
    onFetchGroupDetails: noop,
    onPushRoute: noop,
    tabs: viewTabs,
  }

  /**
   * @lifecycle
   */
  state = {
    activeTabIndex: 0,
  }

  componentWillMount () {
    const {
      onFetchGroupDetails,
      onPushRoute,
      match,
    } = this.props

    const { groupName } = match.params

    Promise.resolve(onFetchGroupDetails(groupName))
      .catch(() => {
        // TODO: add a 401 page
        onPushRoute('/')
      })
  }

  /**
   * @handlers
   */
  onChangeTab = (index) => {
    this.setState({ activeTabIndex: index })
  }

  /**
   * @renders
   */
  render () {
    const { group, tabs } = this.props

    const { activeTabIndex } = this.state

    const { TabComponent, tabProps } = tabs[activeTabIndex]

    return (
      <div className={cn.root}>
        <div className={cn.header}>
          <div className={cx(cn.content, 'container-fluid')}>
            <div className={cn.tabs}>
              <TabHeader
                activeIndex={activeTabIndex}
                onChangeTab={this.onChangeTab}
                tabs={tabs}
              />
            </div>
          </div>
        </div>

        <div className={cx(cn.body__content, 'container-fluid')}>
          <TabComponent
            {...tabProps}
            group={group}
          />
        </div>
      </div>
    )
  }
}

/**
 * @redux
 */
function mapState (state, ownProps) {
  const { match } = ownProps
  const { groupName } = match.params

  return {
    group: getGroupByName(state, { name: groupName }),
  }
}

const mapDispatch = {
  onFetchGroupDetails: reqGroupDetails,
  onPushRoute: pushRoute,
}

/**
 * @export
 */
export default connect(mapState, mapDispatch)(GroupView)
