import {connect} from 'react-redux'

import {openModal} from 'actions/modal'
import {getProjectId} from 'selectors/designer'
import {getProjectOptimizationSettingsResults} from 'selectors/project'

export default connect(state => ({
  optimizationSettings: getProjectOptimizationSettingsResults(state, {id: getProjectId(state)}),
}), {
  onModalOpen: openModal,
})
