import cx from 'classnames'
import React from 'react'
import {
  arrayOf,
  func,
  node,
  object,
  shape,
  string,
} from 'prop-types'

import { ElementTable } from 'components/ElementTable'

import Directory from './ElementExplorerDirectory'
import cn from './ElementExplorer.css'

export default function ElementExplorer ({
  activeDirectory,
  controls,
  directories,
  onDirectoryChange,
  preFetch,
  tableElements,
  tableProps,
}) {
  return (
    <div className={cn.base}>
      <Directory
        activeOption={activeDirectory}
        onDirectoryChange={onDirectoryChange}
        options={directories}
      />
      <div className={cn.body}>
        <div className={cn.controls}>
          {controls}
        </div>
        <ElementTable
          {...tableProps}
          className={cx({ [cn.table__empty]: tableElements.length < 1 })}
          elements={tableElements}
        />
        {preFetch}
      </div>
    </div>
  )
}

ElementExplorer.propTypes = {
  activeDirectory: string.isRequired,
  controls: node,
  directories: arrayOf(object).isRequired,
  onDirectoryChange: func.isRequired,
  preFetch: node,
  tableElements: arrayOf(object).isRequired,
  tableProps: shape({}),
}

ElementExplorer.defaultProps = {
  controls: null,
  preFetch: null,
  tableProps: {},
}
