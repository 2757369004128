import omit from 'lodash/omit'

import { randomColor } from 'common/color'
import {alwaysObject, requiredParam, overlap, objectToArray, spliceArray} from 'core/utils'
import IdGenerator from 'core/utils/IdGenerator'

const idMaker = new IdGenerator()

// Returns the end index of a tag.
export function getTagEnd ({offset, length}) {
  return offset + length
}

// Prevent boundary overlaps between tags.
export function baseOne (x) {
  return x + 1
}

export function normalizeTagOffset ({offset, ...tag}) {
  return {
    ...tag,
    offset: offset > 0 ? offset + 1 : offset,
  }
}

export function areTagsRelated (tag1, tag2) {
  // Related tags are denoted by ending in a hyphen and series of numbers.
  // Ex. sometag-1
  const nameRegex = /-*[0-9](?!.*-[0-9])/

  return tag1.id !== tag2.id &&
    tag1.name.replace(nameRegex, '') === tag2.name.replace(nameRegex, '')
}

export function isTagElement ({id, spannedElements}) {
  return spannedElements[0] === id
}

export function isTagVariable ({metadata = {}}) {
  return !!metadata.variable
}

export function isTagAmino ({amino}) {
  return !!amino
}

export function isTagBeforeIndex (tag, index, inclusive = false) {
  return inclusive ? getTagEnd(tag) <= index : getTagEnd(tag) < index
}

export function isTagAfterIndex (tag, index, inclusive = false) {
  return inclusive ? tag.offset >= index : tag.offset > index
}

export function isTagOverlappingIndex (tag, startIndex, endIndex, inclusive = false) {
  return overlap(tag.offset, getTagEnd(tag), startIndex, endIndex, inclusive)
}

export function isTagAfterIndices (tag, indices, inclusive = false) {
  return indices.reduce((isAfter, {endIndex}) => (
    isAfter || isTagAfterIndex(tag, endIndex, inclusive)
  ), false)
}

export function isTagOverlappingIndices (tag, indices, inclusive = false) {
  return indices.reduce((isOverlapping, {startIndex, endIndex}) => (
    isOverlapping || isTagOverlappingIndex(tag, startIndex, endIndex, inclusive)
  ), false)
}

export function isTagSpanningMultipleElements ({spannedElements}) {
  return spannedElements.length > 1
}

export function isTagSpanningElementId ({spannedElements}, id) {
  return spannedElements.includes(id)
}

export function adjustTagLength ({length, ...tag}, adjustLength = 0) {
  return {...tag, length: length + adjustLength}
}

export function adjustTagOffset ({offset, ...tag}, adjustLength = 0) {
  return {...tag, offset: offset + adjustLength}
}

export function adjustElementOffsets ({tags, elementOrder}) {
  return elementOrder.reduce((tagMap, tagId, i) => ({
    ...tagMap,
    [tagId]: {
      ...tags[tagId],
      offset: i === 0 ? 0 : getTagEnd(tagMap[elementOrder[i - 1]]),
    }
  }), tags)
}

export function findSpannedElements (tag, tags, elementOrder) {
  const {offset} = tag

  return elementOrder.filter(id => (
    overlap(offset, getTagEnd(tag), tags[id].offset, getTagEnd(tags[id]))
  ))
}

export function findRelatedUpdateIndices ({tagId, relatedTagIds, tags, startIndex, endIndex}) {
  const offsetDelta = Math.max(startIndex - tags[tagId].offset, 1)
  const length = endIndex - startIndex
  const origin = {startIndex, endIndex}

  return relatedTagIds.map(id => {
    const offset = tags[id].offset + offsetDelta

    return {id, startIndex: offset, endIndex: offset + length}
  })
}

export function findLastRelatedTagIndex (tag, relatedTagIndices, inclusive = false) {
  return relatedTagIndices.reduce((index, {id, startIndex, endIndex}, i) => (
    id !== tag.id && isTagOverlappingIndex(tag, startIndex, endIndex, inclusive)
      ? baseOne(i)
      : index
  ), 1)
}

export function findTagOffsetToAnchorElement ({tag, tags}) {
  return tag.offset - tags[tag.spannedElements[0]].offset
}

export function findNumberOfPreceedingTags ({tags, tagIds, startIndex}) {
  return tagIds.reduce((count, id) => (
    isTagBeforeIndex(tags[id], startIndex) ? count + 1 : count
  ), 0)
}

// Index 1 instead of 0 should be starting point.
export function makeLengthAdjuster (relatedIndices, length, inclusive = false) {
  return tag => length * findLastRelatedTagIndex(tag, relatedIndices, inclusive)
}

export function createTag (tagFields = {}) {
  const {
    amino = false,
    aminos = '',
    color = randomColor(),
    description = '',
    hasMappedDNA = false,
    id = requiredParam('id'),
    length,
    name,
    metadata = {},
    offset,
    spannedElements = [],
    type,
  } = tagFields

  return {
    amino,
    aminos,
    color,
    description,
    hasMappedDNA,
    length,
    id,
    name,
    metadata,
    offset,
    spannedElements,
    type,
  }
}

export function addElementTag ({element, index, elementOrder, tags}) {
  const prevIndex = index - 1
  const id = idMaker.newId(tags)
  const newTag = createTag({
    ...element,
    id,
    offset: getTagEnd(alwaysObject(tags[elementOrder[prevIndex]])) || 0,
    spannedElements: [id],
  })
  const insertIndex = getTagEnd(alwaysObject(tags[elementOrder[prevIndex]])) || 0

  return {
    id,
    elementOrder: spliceArray(elementOrder, index, 0, newTag.id),
    tags: objectToArray(tags).reduce((tagMap, tag) => (
      isTagBeforeIndex(tag, insertIndex, true)
        ? {...tagMap, [tag.id]: tag}
        : isTagAfterIndex(tag, insertIndex, true)
          ? {...tagMap, [tag.id]: adjustTagOffset(tag, element.sequence.length)}
          : tagMap
    ), {[newTag.id]: newTag})
  }
}

export function deleteElementTag ({index, elementOrder, tags}) {
  const deleteId = elementOrder[index]
  const {offset: deleteIndex, length} = tags[deleteId]

  return {
    deletedTag: tags[deleteId],
    elementOrder: elementOrder.filter(id => id !== deleteId),
    tags: objectToArray(tags).reduce((tagMap, tag) => (
      isTagSpanningElementId(tag, deleteId)
        ? omit(tagMap, tag.id)
        : isTagAfterIndex(normalizeTagOffset(tag), deleteIndex)
          ? {...tagMap, [tag.id]: adjustTagOffset(tag, length * -1)}
          : tagMap
    ), omit(tags, deleteId))
  }
}

export function reorderElementTags ({elementOrder, moveId, oldOrder, tags}) {
  const oldIndex = oldOrder.indexOf(moveId)
  const shiftId = elementOrder[oldIndex]
  const isTagSpanningReorderedIds = tag => (
    isTagSpanningElementId(tag, moveId) || isTagSpanningElementId(tag, shiftId)
  )

  return {
    elementOrder,
    tags: objectToArray(tags).reduce((tagMap, tag) => (
      isTagElement(tag)
        ? tagMap
        : isTagSpanningReorderedIds(tag) && tag.spannedElements.length > 1
          ? omit(tagMap, tag.id)
          : {
            ...tagMap,
            [tag.id]: {
              ...tag,
              offset: tagMap[tag.spannedElements[0]].offset +
                findTagOffsetToAnchorElement({tag, tags}),
            }
          }
    ), adjustElementOffsets({tags, elementOrder}))
  }
}

export function addNonElementTag ({tag, tags, elementOrder}) {
  const newTag = createTag({
    ...tag,
    id: idMaker.newId(tags),
    spannedElements: findSpannedElements(tag, tags, elementOrder),
  })

  return {
    tag: newTag,
    id: newTag.id,
    elementOrder,
    tags: {
      ...tags,
      [newTag.id]: newTag,
    }
  }
}

export function deleteNonElementTag ({elementOrder, tags, tagIds}) {
  return {
    elementOrder,
    tags: omit(tags, tagIds),
  }
}

export function updateTagLength ({
  length = 0,
  startIndex,
  maybeEndIndex,
  tagId,
  relatedTagIds = [],
  elementOrder,
  tags,
  tagUpdates,
}) {
  const endIndex = maybeEndIndex || startIndex // endIndex is for multi-selection
  const relatedIndices = relatedTagIds.length > 0
    ? findRelatedUpdateIndices({
      tagId,
      relatedTagIds,
      tags,
      startIndex: baseOne(startIndex),
      endIndex: baseOne(endIndex),
    })
    : []
  const adjustLength = makeLengthAdjuster(relatedIndices, length, true)

  return {
    elementOrder,
    tags: objectToArray(tags).reduce((tagMap, tag) => {
      if (tagId === tag.id) {
        console.log('Updating edit tag')
        return {
          ...tagMap,
          [tag.id]: {
            ...adjustTagLength(tag, adjustLength(tag)),
            ...tagUpdates,
          },
        }
      }

      if (relatedTagIds.includes(tag.id)) {
        console.log('Updating related tag')
        return {
          ...tagMap,
          [tag.id]: adjustTagOffset(
            adjustTagLength(tag, length),
            length * findNumberOfPreceedingTags({
              tags,
              tagIds: relatedTagIds.concat(tagId),
              startIndex: tag.offset
            }),
          ),
        }
      }

      if (isTagOverlappingIndices(normalizeTagOffset(tag), relatedIndices)) {
        console.log('Updating overlapping tag')
        return {
          ...tagMap,
          [tag.id]: adjustTagLength(tag, adjustLength(tag)),
        }
      }

      if (isTagAfterIndices(normalizeTagOffset(tag), relatedIndices, true)) {
        console.log('Updating tag after editing tag')
        return {
          ...tagMap,
          [tag.id]: adjustTagOffset(tag, adjustLength(tag)),
        }
      }

      console.log('Updating tag before editing tag')
      return { ...tagMap, [tag.id]: tag }
    }, {}),
  }
}

export function sortTagArrayByIndex (tags) {
  return tags.sort((tag1, tag2) => (
    tag1.offset < tag2.offset
      ? -1
      : tag1.offset > tag2.offset
        ? 1
        : 0
  ))
}

/**
 * Updates the flags regarding the status of an associated DNA mapping. Should
 * only apply to Amino Acid element tags.
 */
export const applyDNAMapUpdate = ({ hasMappedDNA, ...tag }) => ({
  ...tag,
  hasMappedDNA: true,
})

/**
 * Update types determine what update function to apply to a tag.
 */
export const UpdateTypes = {
  AA_MAP_DNA: '@Tag/UpdateTypes/AA_MAP_DNA',
}

/**
 * Finds a tag by its id from a tag store and applies an update based on the
 * update type. If no type can be found, then the rest of the update is merged
 * into the tag, overwriting previous properties.
 */
export function updateTag (tagStore, tagId, update) {
  const tag = tagStore[tagId]

  if (!tag) {
    throw new Error(`No tag exists with id ${tagId}`)
  }

  const { type } = update

  switch (type) {
    case UpdateTypes.AA_MAP_DNA: {
      return applyDNAMapUpdate(tag)
    }
    default: {
      return {
        ...tag,
        ...update,
      }
    }
  }
}

export default {
  addElementTag,
  addNonElementTag,
  adjustElementOffsets,
  adjustTagLength,
  adjustTagOffset,
  areTagsRelated,
  baseOne,
  createTag,
  deleteElementTag,
  deleteNonElementTag,
  findLastRelatedTagIndex,
  findRelatedUpdateIndices,
  findSpannedElements,
  findTagOffsetToAnchorElement,
  getTagEnd,
  isTagAfterIndex,
  isTagAfterIndices,
  isTagAmino,
  isTagBeforeIndex,
  isTagElement,
  isTagOverlappingIndex,
  isTagOverlappingIndices,
  isTagSpanningElementId,
  isTagSpanningMultipleElements,
  isTagVariable,
  makeLengthAdjuster,
  normalizeTagOffset,
  reorderElementTags,
  sortTagArrayByIndex,
  updateTag,
  updateTagLength,
}
