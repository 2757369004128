import React, {Component} from 'react'
import {func, number, shape, string} from 'prop-types'

import PollingModal from 'components/PollingModal'
import {pollJobResult} from 'services/ApiService'

export default class FeatureSearchModalContainer extends Component {
  static propTypes = {
    jobId: string.isRequired,
    onClose: func.isRequired,
    onFeaturesAdd: func.isRequired,
    tagId: number.isRequired,
  }

  state = {
    fulfilledData: {},
    modalState: 'idle',
    results: [],
  }

  componentDidMount () {
    this.handleFeaturesPoll()
  }

  async getFeatures ({jobId}) {
    this.setState({modalState: 'searching'})

    try {
      const {data: features, executionTime, wasSuccess} = await pollJobResult(jobId)
      this.setState({
        fulfilledData: features,
        modalState: wasSuccess ? 'success' : 'error',
        results: [
          {
            icon: 'timelapse',
            key: 'time',
            label: 'Execution Time',
            value: `${executionTime.toFixed(4)}s`,
          },
          {
            icon: 'style',
            key: 'features',
            label: 'Features Found',
            value: Object.keys(features).length,
          },
        ],
      })
    }
    catch (e) {
      this.setState({
        modalState: 'error',
        results: [
          {
            icon: 'error',
            key: 'error',
            label: 'Error',
            value: e.message
          }
        ],
      })
    }
  }

  handleFeaturesPoll = () => {
    this.getFeatures(this.props)
  }

  handleAccept = () => {
    const { fulfilledData } = this.state
    const { onClose, onFeaturesAdd, tagId } = this.props

    onFeaturesAdd(tagId, fulfilledData)
    onClose()
  }

  handleCancel = () => {
    this.props.onClose()
  }

  get modalAcceptHandler () {
    return this.state.modalState === 'success'
      ? this.handleAccept
      : this.state.modalState === 'error'
        ? this.handleFeaturesPoll
        : this.handleCancel
  }

  render () {
    const {modalState, results} = this.state

    return (
      <PollingModal
        errorSubtitle='An issue occurred during our search. Please try again.'
        errorTitle='Feature Search Error'
        idleTitle='Finding features'
        onAccept={this.modalAcceptHandler}
        onCancel={this.handleCancel}
        pollState={modalState}
        results={results}
        searchingSubtitle='Please be patient while we vigilantly search for features in your selection.'
        searchingTitle='Finding features'
        successSubtitle='Click done to save these features to your design.'
        successTitle='Feature Search Complete'
      />
    )
  }
}
