import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {startTemplateEdit} from 'actions/templates'
import {getEditingTemplateId, getTemplateOrder} from 'selectors/templates'

const getDesignOptions = createSelector(
  getTemplateOrder,
  templateIds => templateIds.map((id, i) => ({
    key: `${id}`,
    label: `${i + 1}`,
    value: id,
  })),
)

export default connect(state => ({
  options: getDesignOptions(state),
  value: getEditingTemplateId(state),
}), {onDesignChange: startTemplateEdit})
