import React from 'react'
import {arrayOf} from 'prop-types'
import {connect} from 'react-redux'

import {ProjectCommitType} from 'types'
import {getProjectId} from 'selectors/designer'
import {getProjectById} from 'selectors/project'
import formatDate from 'utils/formatDate'
import cn from './ProjectCommitHistory.css'

function ProjectCommitHistory ({commits}) {
  return (
    <div className={cn.base}>
      <div className={cn.body}>
        {commits.map((commit, i) => (
          <div className={cn.commit} key={i}>
            <div className={cn.commit__message}>
              <span className={cn.commit__message__text}>
                {commit.message}
              </span>
            </div>
            <span className={cn.commit__date}>
              {commit.date}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

ProjectCommitHistory.propTypes = {
  commits: arrayOf(ProjectCommitType).isRequired,
}

const mapState = (state) => {
  const id = getProjectId(state)
  const project = getProjectById(state, {id})

  return {
    commits: project.commits.map((commit, i) => ({
      date: formatDate(commit.createdAt),
      key: `${i}`,
      message: commit.commitMessage,
    })),
  }
}

export {ProjectCommitHistory}

export default connect(mapState)(ProjectCommitHistory)
