import codonMap from './codon_map'

const codons = Object.keys(codonMap)

const randomCodonIndex = () => Math.floor(Math.random() * (codons.length - 1)) + 0

export default function randomSequence (length = 0) {
  let sequence = ''

  for (let i = 0; i < length; i++) {
    sequence += codons[randomCodonIndex()]
  }

  return sequence
}
