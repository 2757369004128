import tinycolor from 'tinycolor2'

export const randomColor = () => tinycolor.random().toHexString()

export const darkenColor = (color, percent = 30) => tinycolor(color).darken(percent).toHexString()

export const lightenColor = (color, percent = 30) => tinycolor(color).lighten(percent).toHexString()

const Color = {
  AMINO_BG: '#FFE4E4',
  AMINO_FG: '#FF0100',
  DNA_BG: '#E4EEFE',
  DNA_FG: '#296CFF',
}

export default Color
