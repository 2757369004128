import React from 'react'
import {arrayOf, node, shape, string} from 'prop-types'

import FormControl from 'components/FormControl'
import cn from './PollingModalSummary.css'

export default function PollingModalSummary ({results}) {
  return (
    <div className={cn.base}>
      {results.map(result => (
        <FormControl
          bodyClass={cn.resultBody}
          className={cn.result}
          icon={result.icon}
          key={result.key}
          label={result.label}
        >
          {result.value}
        </FormControl>
      ))}
    </div>
  )
}

PollingModalSummary.propTypes = {
  results: arrayOf(shape({
    icon: string,
    key: string.isRequired,
    label: string.isRequired,
    value: node.isRequired,
  })).isRequired,
}
