import { LOCATION_CHANGE } from 'connected-react-router'

import { actions } from 'actions/designer'
import { actions as templateActions } from 'actions/templates'
import { ElementContext } from 'common'
import { CONSTRUCT } from 'providers/store/action-types'
import createReducer from 'utils/createReducer'

const initialState = {
  activeElement: {},
  context: ElementContext.DNA,
  hasUnsavedChanges: false,
  id: '',
  isGeneratingSequences: false,
  selection: {
    end: 0,
    start: 0,
    tagId: '',
  },
  sequenceKeypath: null,
}

export default createReducer(initialState, {
  [LOCATION_CHANGE]: () => initialState,

  [actions.SET_ELEMENT_CONTEXT]: (state, { context }) => ({
    ...state,
    context,
  }),

  [actions.SEQ_JOB_POLLING_FINISH]: (state, { jobResult = {} }) => {
    const { latestCommit = {} } = jobResult
    const { sequenceKeypath } = latestCommit

    return {
      ...state,
      isGeneratingSequences: false,
      sequenceKeypath,
    }
  },

  [actions.SEQ_JOB_POLLING_START]: state => ({
    ...state,
    isGeneratingSequences: true,
  }),

  [actions.SELECT_ELEMENT]: (state, action) => {
    const { element } = action
    const { id, length, offset } = element

    return {
      ...state,
      activeElement: element,
      selection: {
        end: offset + length,
        start: offset,
        tagId: `${id}`,
      },
    }
  },

  [actions.SET_SEQUENCE_SELECTION]: (state, { start, end }) => ({
    ...state,
    selection: {
      end,
      start,
      tagId: '',
    },
  }),

  [actions.SET_ACTIVE_PROJECT]: (state, action) => {
    const { project } = action
    const { id, latestCommit } = project
    const { sequenceKeypath } = latestCommit

    return {
      ...state,
      id,
      sequenceKeypath,
    }
  },

  [templateActions.addElementToTemplate]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.addTemplate]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.deleteElementFromTemplate]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.deleteTemplate]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.duplicateTemplate]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.updateExpressionSystem]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.updateTemplateElementOrder]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.updateTemplateVariations]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [templateActions.fulfillPendingAminoElement]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [CONSTRUCT.ADD_ELEMENT]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),

  [CONSTRUCT.ADD_ELEMENT_SEQUENCE]: state => ({
    ...state,
    hasUnsavedChanges: true,
  }),
})
