import { darkenColor, lightenColor } from 'common/color'

// Element tags only span themselves.
const isElementTag = ({ id, spannedElements }) => spannedElements.includes(id)

export const styleTag = tag => {
  const darkShade = darkenColor(tag.color)

  return {
    ...tag,
    label: `${tag.name}`,
    start: tag.offset,
    end: tag.offset + tag.length,
    labelStyle: {
      fill: darkShade,
      transform: 'translate(4, 13)',
    },
    rectStyle: {
      fill: lightenColor(tag.color),
      height: 12,
      stroke: darkShade,
      strokeWidth: 1,
      width: 0,
    },
  }
}

export const shapeTags = (tagMap) => Object.keys(tagMap).map(key => {
  const tag = tagMap[key]
  const start = isElementTag(tag)
    ? tag.offset
    : tagMap[tag.spannedElements[0]].offset + tag.offset

  return {
    ...styleTag(tag),
    end: start + tag.length,
    start,
  }
})

export const setTagWidth = ({ tag, width }) => ({
  ...tag,
  rectStyle: {
    ...tag.rectStyle,
    width,
  }
})

export const setTagPosition = ({ tag, x, y }) => ({
  ...tag,
  style: {
    transform: `translate(${x}, ${y})`,
  },
})

const aminoColorMap = {
  f: '#9191c1', l: '#71ba71', i: '#99e599', m: '#e8e886', v: '#99e599',
  s: '#f7cc8c', p: '#dc9682', t: '#f7cc8c', a: '#c8c8c8', y: '#adaddb',
  h: '#bebef7', q: '#bebef7', n: '#9aeaea', k: '#baceff', d: '#e28585',
  e: '#e28585', c: '#e8e886', w: '#e09fe0', r: '#baceff', g: '#ebebeb',
}

export function getAminoColor (amino = '') {
  return aminoColorMap[amino.toLowerCase()]
}

export default {
  getAminoColor,
  setTagPosition,
  setTagWidth,
  shapeTags,
  styleTag,
}
