import React, {Component} from 'react'
import {bool, func, number, string} from 'prop-types'
import cx from 'classnames'
import clamp from 'lodash/clamp'

import cn from './VerticalSlider.css'

export default class VerticalSlider extends Component {
  static propTypes = {
    className: string,
    disabled: bool,
    fillColor: string,
    limit: number,
    max: number,
    min: number,
    onDragStart: func,
    onDragMove: func,
    onDragEnd: func,
    value: number,
  }

  static defaultProps = {
    className: '',
    disabled: false,
    fillColor: 'orange',
    limit: 0,
    max: 0,
    min: 0,
    onDragStart: () => {},
    onDragMove: () => {},
    onDragEnd: () => {},
    value: 0,
  }

  constructor (props) {
    super(props)

    const {limit, max, min, value} = props
    // Handles cases where the min and max values might be negative numbers.
    const range = Math.abs(min) + Math.abs(max)

    this.state = {
      dragging: false,
      // Percentage of the max the slider is allowed to go to.
      limitPercentage: this.calculatePercentage((max - limit), range),
      // Percentage of the max the value is currently at.
      percentage: this.calculatePercentage(value, range),
      range,
      value,
    }
  }

  // Recalculate percentages if the limit or value has changed.
  componentWillReceiveProps ({limit, max, value}) {
    // Do nothing if limit or value hasn't changed.
    if (limit === this.props.limit && value === this.props.value) {
      return
    }

    this.setState({
      limitPercentage: this.calculatePercentage((max - limit), max),
      percentage: this.calculatePercentage(value, this.state.range),
    })
  }

  calculatePercentage (value, max) {
    return Math.round(value / max * 100)
  }

  cacheNode = (ref) => {
    this.node = ref
  }

  startDrag = (e) => {
    e.preventDefault()

    if (this.props.disabled) {
      return
    }

    this.setState({dragging: true})
    this.props.onDragStart(e, this.state.value)
  }

  drag = (e) => {
    // Prevent event handlers like text selection from firing while dragging.
    e.preventDefault()

    // NOTE: Y values increase the farther south we go on a screen. This may not
    // be intuitive to how a slider operates since an increase in the value
    // corresponds with dragging the slider upwards. Make sure the calculations
    // below account for that inverse relationship betweem direction and value.
    //
    // minY: maximum value
    // maxY: minimum value
    const {bottom, height, top} = this.node.getBoundingClientRect()
    const yPos = e.clientY
    // The actual minimum 'y' value is the limit, which we store as a percentage
    // of the total height. To get the min, we have to convert this percentage
    // to pixels and add it from the top point of the component.
    const minY = top + (this.state.limitPercentage / 100 * height)
    const maxY = bottom
    // The distance from the bottom point of the component to the handle.
    const delta = maxY - clamp(yPos, minY, maxY)
    // We can now get the correct percentage by comparing delta to the total
    // height.
    const percentage = this.calculatePercentage(delta, height)
    // After we get the percentage, we can use it to calculate the actual value
    const value = Math.floor(percentage / 100 * this.state.range) + this.props.min

    this.setState({percentage, value})
    this.props.onDragMove(e, value)
  }

  endDrag = (e) => {
    this.setState({dragging: false})
    this.props.onDragEnd(e, this.state.value)
  }

  render () {
    const {className, disabled, fillColor} = this.props
    const {dragging, limitPercentage, percentage, value} = this.state
    const limitStyle = {transform: `translate3d(0, ${limitPercentage}%, 0)`}
    const handleStyle = {backgroundColor: fillColor}
    const fillStyle = {
      backgroundColor: fillColor,
      // At 0%, the fill should sit below the component and moves upward as the
      // value grows. To account for this, we have to adjust the translation
      // value to subtract from a base 100.
      transform: `translate3d(0, ${100 - percentage}%, 0)`,
    }
    const controlStyle = {
      transform: fillStyle.transform,
      // Get rid of transition while dragging.
      transition: dragging ? 'none' : '',
    }

    return (
      <div
        className={cx(cn.base, className, {[cn.dragging]: dragging})}
        ref={this.cacheNode}
      >
        <div className={cn.track}>
          <div className={cn.limit} style={limitStyle} />
          <div className={cn.fill} style={fillStyle} />
        </div>
        <div className={cn.controlsAlign} style={controlStyle}>
          <div className={cn.controls}>
            <div
              className={cx(cn.handle, {[cn.disabled]: disabled})}
              onMouseDown={this.startDrag}
              style={handleStyle}
            />
            <div className={cx(cn.tooltip, {[cn.tooltipShow]: dragging})}>
              <span>{value}</span>
            </div>
          </div>
        </div>
        {!disabled && dragging && (
          <div
            className={cn.mouseTracker}
            onMouseMove={this.drag}
            onMouseUp={this.endDrag}
          />
        )}
      </div>
    )
  }
}
