import React from 'react'

import { ElementContext } from 'common'
import CustomDragLayer from 'components/CustomDragLayer'
import { Element } from 'components/ConstructViewer'
import { DRAG_TYPES } from 'core/Constants'
import cn from './ElementDragLayer.css'

/**
 * Half the element dimensions to render the element centered to the cursor.
 * @see ConstructElement.css > .base
 */
const WIDTH = 110
const HEIGHT = 35

const getElementStyle = offset => (
  !offset
    ? { display: 'none' }
    : {
      display: 'inline-block',
      transform: `translate(${offset.x - WIDTH}px, ${offset.y - HEIGHT}px)`,
    }
)

export default function ElementDragLayer () {
  return (
    <CustomDragLayer renderDragType={DRAG_TYPES.element}>
      {({ item, offset }) => {
        const { element } = item
        const { bpLength, name, type } = element
        const amino = type.includes('amino')
        const context = amino ? ElementContext.AA : ElementContext.DNA
        const length = amino ? Math.floor(bpLength / 3) : bpLength
        const lengthUnit = amino ? 'AA' : 'BP'

        return (
          <div className={cn.base}>
            <div style={getElementStyle(offset)}>
              <Element
                elementContext={context}
                lengthText={`${length} ${lengthUnit}`}
                name={name}
              />
            </div>
          </div>
        )
      }}
    </CustomDragLayer>
  )
}
