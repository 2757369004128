import React from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/BaseButton'
import FormControl from 'components/FormControl'
import RoundOutlineButton from 'components/RoundOutlineButton'
import { TextInput } from 'components/text-input'
import cn from './AccessAdd.css'

export default function AccessAdd ({
  accessTypes,
  disabled,
  error,
  onAccessAdd,
  onFieldChange,
  username,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.header}>
        Add user to element
      </div>
      <div className={cn.body}>
        <FormControl
          icon='label'
          idFor='name'
          label='Name'
        >
          <TextInput
            id='name'
            name='username'
            onChange={onFieldChange}
            placeholder='Enter username'
            value={username}
          />
        </FormControl>

        <FormControl
          bodyClass={cn.controlInline}
          className={cn.controlLast}
          icon='label'
          idFor='type'
          label='Type'
        >
          {accessTypes.map(type => (
            <RoundOutlineButton
              className={cx(cn.btn, {[cn.btnActive]: type.active})}
              key={type.key}
              name='access'
              onClick={onFieldChange}
              value={type.value}
            >
              {type.label}
            </RoundOutlineButton>
          ))}
        </FormControl>

        <div className={cn.submit}>
          <h5 className={cx(cn.error, {[cn.errorShow]: error})}>
            {error}
          </h5>

          <BaseButton
            className={cn.submitBtn}
            disabled={disabled}
            onClick={onAccessAdd}
            primary
          >
            Add User
          </BaseButton>
        </div>
      </div>
    </div>
  )
}

AccessAdd.propTypes = {
  accessTypes: arrayOf(shape({
    active: bool,
    key: string.isRequired,
    label: string.isRequired,
    value: string.isRequired,
  })).isRequired,
  disabled: bool,
  error: string,
  onAccessAdd: func.isRequired,
  onFieldChange: func.isRequired,
  username: string.isRequired,
}

AccessAdd.defaultProps = {
  disabled: false,
  error: '',
}
