import React from 'react'
import {func, node, shape, string} from 'prop-types'

import BaseButton from 'components/buttons/BaseButton'
import cn from './Snackbar.css'

export default function Snackbar ({
  actionText,
  message,
  onAction,
  onClose,
  style,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.body} style={style}>
        <div className={cn.bodyText}>
          <span className={cn.text}>
            {message}
          </span>
        </div>
        <BaseButton className={cn.bodyAction} onClick={onAction || onClose}>
          {actionText}
        </BaseButton>
      </div>
    </div>
  )
}

Snackbar.propTypes = {
  actionText: string,
  message: node.isRequired,
  onAction: func,
  onClose: func.isRequired,
  style: shape({
    transform: string
  }).isRequired,
}

Snackbar.defaultProps = {
  actionText: 'Hide',
  onAction: null,
}
