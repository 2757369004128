import React, {Component} from 'react'
import {func} from 'prop-types'

import ConfirmDeleteModal from './ConfirmDeleteModal'

export default class ConfirmDeleteModalContainer extends Component {
  static propTypes = {
    onAccept: func.isRequired,
    onCancel: func,
    onClose: func.isRequired,
  }

  static defaultProps = {
    onCancel: () => console.debug('Cancel'),
  }

  handleAccept = () => {
    this.props.onClose()
    this.props.onAccept()
  }

  handleCancel = () => {
    this.props.onClose()
    this.props.onCancel()
  }

  render () {
    return (
      <ConfirmDeleteModal
        {...this.props}
        onAccept={this.handleAccept}
        onCancel={this.handleCancel}
      />
    )
  }
}
