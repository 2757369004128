import cx from 'classnames'
import React from 'react'
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types'

import FontAwesomeButton from 'components/FontAwesomeButton'
import SelectInputContainer from 'components/SelectInput'
import { UserAccessType } from 'types'

import cn from './User.css'

function User ({
  access,
  accessOptions,
  disabled,
  onEdit,
  onDelete,
  overrideDeleteDisable,
  updating,
  username,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.username}>
        <span>
          {username}
        </span>
      </div>

      <SelectInputContainer
        className={cn.access}
        disabled={disabled}
        onChange={onEdit}
        options={accessOptions}
        value={access}
      />

      <div className={cn.delete}>
        <FontAwesomeButton
          bodyClass={cn.delete__btn__body}
          className={cx(cn.delete__btn, { [cn['delete__btn--progress']]: updating })}
          disabled={!overrideDeleteDisable && (disabled || updating)}
          icon="trash"
          iconSize="lg"
          onClick={onDelete}
          title={`Remove user's access`}
        />
      </div>
    </div>
  )
}

User.propTypes = {
  access: UserAccessType.isRequired,
  accessOptions: arrayOf(object).isRequired,
  disabled: bool,
  onEdit: func.isRequired,
  onDelete: func.isRequired,
  overrideDeleteDisable: bool,
  updating: bool,
  username: string.isRequired,
}

User.defaultProps = {
  disabled: false,
  overrideDeleteDisable: false,
  updating: false,
}

export default User
