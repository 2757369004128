import React from 'react'
import {bool, func, string} from 'prop-types'

import RaisedButton from 'components/buttons/RaisedButton'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { TextInput } from 'components/text-input'
import cn from './ProjectForkModalSaveSection.css'

export default function ProjectForkModalSaveSection ({
  acceptButtonLabel,
  commitMessage,
  disableAccept,
  onChangeCommitMessage,
  onProjectCommit,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.header}>
        <FontAwesomeIcon icon='exclamation-circle' size='2x' />
        <p className={cn.title}>
          Commit your unsaved changes below before continuing to your forked project.
        </p>
      </div>
      <div className={cn.body}>
        <label className={cn.inputLabel} htmlFor='commit-message'>
          Add a brief message describing the changes
        </label>
        <div className={cn.control}>
          <TextInput
            className={cn.input}
            id='commit-message'
            name='message'
            onChange={onChangeCommitMessage}
            placeholder='Summarize your changes'
            value={commitMessage}
          />
          <RaisedButton
            className={cn.commitBtn}
            disabled={disableAccept}
            onClick={onProjectCommit}
            primary
          >
            {acceptButtonLabel}
          </RaisedButton>
        </div>
      </div>
    </div>
  )
}

ProjectForkModalSaveSection.propTypes = {
  acceptButtonLabel: string,
  commitMessage: string,
  disableAccept: bool,
  onChangeCommitMessage: func.isRequired,
  onProjectCommit: func.isRequired,
}

ProjectForkModalSaveSection.defaultProps = {
  acceptButtonLabel: 'Save',
  commitMessage: '',
  disableAccept: false,
}
