import React, {Component} from 'react'
import {bool, func, string} from 'prop-types'

import Select from 'components/inputs/Select'
import {applyPreset, presets} from 'utils/aminoMap'
import cn from './StackActionsPresets.css'

export default class StackActionsPresets extends Component {
  static propTypes = {
    disabled: bool,
    onChange: func,
    preset: string,
  }

  static defaultProps = {
    disabled: false,
    onChange: () => {},
    preset: '',
  }

  select = (e, k, value) => {
    this.props.onChange(value, applyPreset(value))
  }

  render () {
    const {disabled, preset} = this.props

    return (
      <div className={cn.base}>
        <span className={cn.title}>
          Preset:
        </span>
        <Select
          className={cn.select}
          disabled={disabled}
          menuClass={cn.menu}
          onChange={this.select}
          options={presets}
          tipClass={cn.menuTip}
          toggleClass={cn.selectToggle}
          value={preset}
        />
      </div>
    )
  }
}
