import {
  arrayOf,
  bool,
  number,
  shape,
  string,
} from 'prop-types'

/**
 * Tag data object shape.
 */
export const TagDataType = shape({
  id: string,
  label: string,
  length: number,
  offset: number,
})

/**
 * Tag style object shape.
 */
export const TagStyleType = shape({
  fill: string.isRequired,
  height: number.isRequired,
  labelXOffset: number.isRequired,
  labelYOffset: number.isRequired,
  stroke: string.isRequired,
  strokeWidth: number.isRequired,
  x: number.isRequired,
  width: number.isRequired,
})

/**
 * Tag object shape.
 */
export const TagType = shape({
  tag: TagDataType.isRequired,
  style: TagStyleType.isRequired,
})

/**
 * Hashmark counter object shape.
 */
export const CounterType = shape({
  labelXOffset: number.isRequired,
  labelYOffset: number.isRequired,
  markHeight: number.isRequired,
  marks: arrayOf(shape({
    key: string.isRequired,
    label: string.isRequired,
    x: number.isRequired,
  })),
  width: number.isRequired,
})

/**
 * Row style object shape.
 */
export const RowStyleType = shape({
  height: number,
  left: number,
  position: string,
  top: number,
  width: string,
})

/**
 * Cursor data object shape.
 */
export const CursorDataType = shape({
  end: number.isRequired,
  start: number.isRequired,
})

/**
 * Cursor style object shape.
 */
export const CursorStyleType = shape({
  x: number.isRequired,
  width: number.isRequired,
})

/**
 * Cursor object shape.
 */
export const CursorType = shape({
  cursor: CursorDataType.isRequired,
  exists: bool,
  isLine: bool,
  showLeftBound: bool,
  showRightBound: bool,
  style: CursorStyleType.isRequired,
})
