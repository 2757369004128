import {createApiAction} from 'store/apiMiddleware'
import applyTransformsToArray from 'utils/applyTransformsToArray'
import createActionTypes from 'utils/createActionTypes'
import addAuthUsername from './utils/addAuthUsername'

/**
 * Api endpoint
 */
const api = '/groups'

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_CREATE_GROUP',
  'REQ_CREATE_GROUP_ERROR',
  'REQ_CREATE_GROUP_SUCCESS',
  'REQ_CREATE_GROUP_MEMBER',
  'REQ_CREATE_GROUP_MEMBER_ERROR',
  'REQ_CREATE_GROUP_MEMBER_SUCCESS',
  'REQ_DELETE_GROUP_MEMBER',
  'REQ_DELETE_GROUP_MEMBER_ERROR',
  'REQ_DELETE_GROUP_MEMBER_SUCCESS',
  'REQ_GROUP_DETAILS',
  'REQ_GROUP_DETAILS_ERROR',
  'REQ_GROUP_DETAILS_SUCCESS',
  'REQ_GROUPS',
  'REQ_GROUPS_ERROR',
  'REQ_GROUPS_SUCCESS',
  'REQ_UPDATE_GROUP',
  'REQ_UPDATE_GROUP_ERROR',
  'REQ_UPDATE_GROUP_SUCCESS',
], 'group')

/**
 * Dispatches request to create new group.
 *
 * @param {Object}    groupData
 *    @param {String}   (optional) description
 *    @param {String}   (required) name
 *    @param {[Object]} (required) shares
 * @return {Promise}  resolves to new group
 */
export function reqCreateGroup (groupData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_GROUP,
      actions.REQ_CREATE_GROUP_SUCCESS,
      actions.REQ_CREATE_GROUP_ERROR,
    ],
    endpoint: api,
    requestOpts: {
      data: groupData,
      method: 'POST',
    },
    schemaType: 'group',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create new group member.
 *
 * @param {String}    groupName
 * @param {Object}    memberData
 *    @param {String}   (required) access
 *    @param {String}   (required) username
 * @return {Promise}  resolves to updated group
 */
export function reqCreateGroupMember (groupName, memberData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_GROUP_MEMBER,
      actions.REQ_CREATE_GROUP_MEMBER_SUCCESS,
      actions.REQ_CREATE_GROUP_MEMBER_ERROR,
    ],
    endpoint: `${api}/${groupName}/share`,
    requestOpts: {
      data: memberData,
      method: 'POST',
    },
    schemaType: 'group',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to delete a group member.
 *
 * @param {String}    groupName
 * @param {Object}    memberData
 *    @param {String}   (required) access
 *    @param {String}   (required) username
 * @return {Promise}  resolves to updated group
 */
export function reqDeleteGroupMember (groupName, memberUsername) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_GROUP_MEMBER,
      actions.REQ_DELETE_GROUP_MEMBER_SUCCESS,
      actions.REQ_DELETE_GROUP_MEMBER_ERROR,
    ],
    endpoint: `${api}/${groupName}/share`,
    requestOpts: {
      data: {username: memberUsername},
      method: 'DELETE',
    },
    schemaType: 'group',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for a group's details.
 *
 * @param {String}    groupName
 * @return {Promise}  resolves to group
 */
export function reqGroupDetails (groupName) {
  return createApiAction({
    actionTypes: [
      actions.REQ_GROUP_DETAILS,
      actions.REQ_GROUP_DETAILS_SUCCESS,
      actions.REQ_GROUP_DETAILS_ERROR,
    ],
    endpoint: `${api}/${groupName}`,
    schemaType: 'group',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for user's group.
 *
 * @return {Promise}  resolves to user's groups
 */
export function reqGroups () {
  return createApiAction({
    actionTypes: [
      actions.REQ_GROUPS,
      actions.REQ_GROUPS_SUCCESS,
      actions.REQ_GROUPS_ERROR,
    ],
    endpoint: api,
    schemaType: 'groupArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request to update a group.
 *
 * @param {String}    groupName
 * @param {Object}    groupData
 *    @param {String}   (optional) description
 *    @param {String}   (optional) name
 * @return {Promise}  resolves to updated group
 */
export function reqUpdateGroup (groupName, groupData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_UPDATE_GROUP,
      actions.REQ_UPDATE_GROUP_SUCCESS,
      actions.REQ_UPDATE_GROUP_ERROR,
    ],
    endpoint: `${api}/${groupName}`,
    requestOpts: {
      data: groupData,
      method: 'PUT',
    },
    schemaType: 'group',
    transformResponse: addAuthUsername,
  })
}
