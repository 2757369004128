import React, { Component } from 'react'
import { func } from 'prop-types'

import noop from 'utils/noop'

import { TagDataType, TagStyleType } from '../types'
import Tag from './Tag'

export default class TagContainer extends Component {
  static propTypes = {
    onClick: func,
    tag: TagDataType.isRequired,
    style: TagStyleType.isRequired,
  }

  static defaultProps = {
    onClick: noop,
  }

  handleClick = (e) => {
    const { onClick, tag } = this.props

    onClick(e, tag)
  }

  render () {
    const { tag, style } = this.props

    return (
      <Tag
        onClick={this.handleClick}
        onContextMenu={this.handleClick}
        tag={tag}
        style={style}
      />
    )
  }
}
