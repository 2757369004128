import {connect} from 'react-redux'
import {createSelector} from 'reselect'

import {ELEMENT_TYPES} from 'core/Constants'
import {reqCreateCustomElementShare, reqDeleteCustomElementShare} from 'actions/customElement'
import {reqCreateVariableElementShare, reqDeleteVariableElementShare} from 'actions/variableElement'
import {getEntities as getCustomElements} from 'selectors/customElement'
import {getEntities as getVariableElements} from 'selectors/variableElement'

const getElementId = createSelector(
  (_, {id}) => id,
  id => id,
)

const getElementType = createSelector(
  (_, {type}) => type,
  type => type,
)

const getSharedUsers = createSelector(
  getElementId,
  getElementType,
  getCustomElements,
  getVariableElements,
  (id, type, customElements, variableElements) => (
    type.includes(ELEMENT_TYPES.variable) && variableElements[id]
      ? variableElements[id].shares
      : customElements[id]
        ? customElements[id].shares
        : []
  )
)

export default connect((state, ownProps) => ({
  elementId: getElementId(state, ownProps),
  elementType: getElementType(state, ownProps),
  users: getSharedUsers(state, ownProps)
}), {
  onCustomAccessAdd: reqCreateCustomElementShare,
  onCustomAccessDelete: reqDeleteCustomElementShare,
  onVariableAccessAdd: reqCreateVariableElementShare,
  onVariableAccessDelete: reqDeleteVariableElementShare,
}, (stateProps, dispatchProps, ownProps) => {
  // Determine which action to call based on element type.
  const variable = stateProps.elementType.includes(ELEMENT_TYPES.variable)

  return {
    ...ownProps,
    ...stateProps,
    onAccessAdd: variable
      ? dispatchProps.onVariableAccessAdd
      : dispatchProps.onCustomAccessAdd,
    onAccessDelete: variable
      ? dispatchProps.onVariableAccessDelete
      : dispatchProps.onCustomAccessDelete,
  }
})
