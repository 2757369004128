import React from 'react'
import {Motion, spring} from 'react-motion'
import {bool, func} from 'prop-types'

import Snackbar from './Snackbar'

const motionConfig = {stiffness: 120, damping: 20}

export default function SnackbarAnimated ({onMotionEnd, open, ...props}) {
  // Transform by height percentage. The extra 5 is just a little buffer space.
  const yPos = open ? -15 : 105

  return (
    <Motion onRest={onMotionEnd} style={{y: spring(yPos, motionConfig)}}>
      {({y}) => <Snackbar {...props} style={{transform: `translate(0, ${y}%)`}} />}
    </Motion>
  )
}

SnackbarAnimated.propTypes = {
  onMotionEnd: func.isRequired,
  open: bool,
}

SnackbarAnimated.defaultProps = {
  open: false,
}
