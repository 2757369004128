import React, {Component} from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'

import User from './User'

export default class UserContainer extends Component {
  static propTypes = {
    access: string.isRequired,
    accessTypes: arrayOf(shape({
      active: bool,
      key: string.isRequired,
      label: string.isRequired,
      value: string.isRequired,
    })).isRequired,
    onAccessChange: func.isRequired,
    onAccessDelete: func.isRequired,
    username: string.isRequired,
  }

  state = {
    processing: false,
  }

  get accessTypes () {
    return this.props.accessTypes.map(type => ({
      ...type,
      active: type.value === this.props.access,
    }))
  }

  handleAccessChange = ({target}) => {
    this.setState({processing: true})
    Promise.resolve(this.props.onAccessChange({
      access: target.value,
      username: this.props.username,
    }))
      .then(() => this.setState({processing: false}))
      .catch(() => this.setState({processing: false}))
  }

  handleAccessDelete = ({target}) => {
    this.setState({processing: true})
    Promise.resolve(this.props.onAccessDelete({username: this.props.username}))
      .catch(() => this.setState({processing: false}))
  }

  render () {
    const {username} = this.props

    return (
      <User
        accessTypes={this.accessTypes}
        onAccessChange={this.handleAccessChange}
        onAccessDelete={this.handleAccessDelete}
        username={username}
      />
    )
  }
}
