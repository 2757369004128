import { createSelector } from 'reselect'

import { getEditingTemplateSequence } from 'selectors/templates'

const fromProps = {
  getEnd: (_, props) => props.end,
  getIsAA: (_, props) => props.isAA,
  getStart: (_, props) => props.start,
  getTagId: (_, props) => parseInt(props.id, 10),
  getTags: (_, props) => props.tags,
  getTagsFromMap: (_, props) => Object.keys(props.tags).map(key => props.tags[key]),
}

export const getSequenceSlice = createSelector(
  getEditingTemplateSequence,
  fromProps.getEnd,
  fromProps.getStart,
  (sequence, end, start) => sequence.slice(start, end),
)

const isDisplayTag = ({ id, spannedElements }, editTagId) => (
  id === editTagId || spannedElements.includes(editTagId)
)

export const getTagsToDisplay = createSelector(
  fromProps.getTagsFromMap,
  fromProps.getTagId,
  fromProps.getIsAA,
  fromProps.getTags,
  (tags, editTagId, isAA, tagStore) => tags
    .filter(tag => isDisplayTag(tag, editTagId))
    .map(tag => ({
      ...tag,
      aminos: isAA ? '' : tag.aminos,
      length: isAA ? tag.aminos.length : tag.length,
      offset: tag.id === editTagId
        ? 0
        : tag.offset - tagStore[editTagId].offset,
    }))
)
