import {actions} from 'actions/modal'

const initialState = {
  props: {},
  type: '',
}

export default function modalReducer (state = initialState, action) {
  const {type} = action

  if (type === actions.closeModal) {
    return {
      props: {},
      type: '',
    }
  }
  else if (type === actions.openModal) {
    const {modalProps, modalType} = action

    return {
      props: modalProps,
      type: modalType,
    }
  }

  return state
}
