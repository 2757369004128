import React from 'react'

export const DiagonalStripePatternId = '@@DiagonalStripePattern'

export const DiagonalStripePatternColor = {
  BG: '#dfdbe5',
  STROKE: '#cdc2dd',
}

export default function DiagonalStripPattern () {
  return (
    <pattern
      height="6"
      id={DiagonalStripePatternId}
      patternUnits="userSpaceOnUse"
      width="6"
    >
      <rect fill={DiagonalStripePatternColor.BG} height="6" width="6" />
      <g fill={DiagonalStripePatternColor.STROKE} fillRule="evenodd">
        <path d="M5 0h1L0 6V5zM6 5v1H5z" />
      </g>
    </pattern>
  )
}
