import React, {Component} from 'react'
import {arrayOf, func, number, oneOfType, shape, string} from 'prop-types'
import clamp from 'lodash/clamp'
import trim from 'lodash/trim'

import BasicModal from 'components/BasicModal'
import OptimizeDNASettingsModal from './OptimizeDNASettingsModal'

export default class OptimizeDNASettingsModalContainer extends Component {
  static propTypes = {
    globalGc: shape({
      max: oneOfType([number, string]).isRequired,
      min: oneOfType([number, string]).isRequired,
    }),
    homopolymers: arrayOf(shape({
      label: string.isRequired,
      value: oneOfType([number, string]).isRequired,
    })),
    onClose: func.isRequired,
    onModalOpen: func.isRequired,
    onProjectUpdate: func.isRequired,
    projectId: number.isRequired,
    restrictionSites: string,
    slidingGc: {
      max: oneOfType([number, string]).isRequired,
      min: oneOfType([number, string]).isRequired,
      window: oneOfType([number, string]).isRequired,
    }
  }

  static defaultProps = {
    globalGc: {
      max: '65',
      min: '40',
    },
    homopolymers: [
      {label: 'A', value: '9'},
      {label: 'C', value: '6'},
      {label: 'G', value: '9'},
      {label: 'T', value: '9'},
    ],
    restrictionSites: 'BsaI, AarI',
    slidingGc: {
      max: '80',
      min: '25',
      window: '50',
    },
  }

  constructor (props) {
    super(props)

    const {globalGc, homopolymers, restrictionSites, slidingGc} = props

    this.state = {
      globalGcMax: globalGc.max,
      globalGcMin: globalGc.min,
      homopolymers: homopolymers.reduce((dict, polymer) => ({
        ...dict,
        [polymer.label]: polymer.value,
      }), {}),
      restrictionSites,
      slidingGcMax: slidingGc.max,
      slidingGcMin: slidingGc.min,
      slidingGcWindow: slidingGc.window,
    }
  }

  makeOptimizationConfig ({
    globalGcMax,
    globalGcMin,
    homopolymers,
    restrictionSites,
    slidingGcMax,
    slidingGcMin,
    slidingGcWindow,
  }) {
    return {
      globalGcContent: {
        min: parseInt(globalGcMin) / 100,
        max: parseInt(globalGcMax) / 100,
      },
      globalGcContentSliding: {
        min: parseInt(slidingGcMin) / 100,
        max: parseInt(slidingGcMax) / 100,
        window: parseInt(slidingGcWindow),
      },
      avoidRepeatedHomopolymers: Object.keys(homopolymers).reduce((final, key) => ({
        ...final,
        [key]: parseInt(homopolymers[key]),
      }), {}),
      avoidRestrictedEnzymes: restrictionSites.split(',').map(trim).filter(enzyme => enzyme !== ''),
    }
  }

  handleFieldChange = ({target}) => {
    this.setState({[target.name]: target.value})
  }

  handlePercentFieldChange = ({target}) => {
    this.setState({[target.name]: target.value
      ? String(clamp(parseInt(target.value), 0, 100))
      : target.value
    })
  }

  handlePolymerChange = ({target}) => {
    this.setState({homopolymers: {
      ...this.state.homopolymers,
      [target.name]: target.value,
    }})
  }

  handleModalAccept = () => {
    const {onModalOpen, onProjectUpdate, projectId, ...props} = this.props
    const optimizationConfig = this.makeOptimizationConfig(this.state)

    onModalOpen('optimizeDNA', {...props, optimizationConfig})
    onProjectUpdate(projectId, {optimizationConfig})
  }

  get homopolymers () {
    return Object.keys(this.state.homopolymers).map(key => ({
      key,
      label: key,
      name: key,
      value: this.state.homopolymers[key],
    }))
  }

  render () {
    return (
      <BasicModal
        modalIcon='cog'
        modalSubtitle='Customize the settings used to optimize the DNA for these amino acids'
        modalTitle='Optimization Settings'
        onAccept={this.handleModalAccept}
        onCancel={this.props.onClose}
      >
        <OptimizeDNASettingsModal
          {...this.state}
          homopolymers={this.homopolymers}
          onFieldChange={this.handleFieldChange}
          onPercentFieldChange={this.handlePercentFieldChange}
          onPolymerChange={this.handlePolymerChange}
        />
      </BasicModal>
    )
  }
}
