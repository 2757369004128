export const actions = {
  closeSnackbar: '@snackbar/CLOSE_SNACKBAR',
  openSnackbar: '@snackbar/OPEN_SNACKBAR',
}

export function closeSnackbar () {
  return {type: actions.closeSnackbar}
}

export function openSnackbar ({actionText = '', text = '', onAction}) {
  return {
    type: actions.openSnackbar,
    actionText,
    text,
    onAction,
  }
}
