import React, { Component } from 'react'
import { bool, func, number, oneOf, string } from 'prop-types'

import { ELEMENT_TYPES } from 'core/Constants'
import { isAmino } from 'core/element/ElementType'
import validateSequence from 'core/sequence/validateSequence'
import { getCustomElementWithSequence } from 'services/element-service'
import CustomElement from './CustomElement'

const VALIDATION_TYPES = {
  [ELEMENT_TYPES.CUSTOM_AMINO]: 'amino',
  [ELEMENT_TYPES.CUSTOM_DNA]: 'dna',
}

const isShorterThan = (len = 0) => (str = '') => str.length <= len

const isValidName = isShorterThan(80)
const isValidDescription = isShorterThan(255)

export default class CustomElementContainer extends Component {
  static propTypes = {
    bpLength: number.isRequired,
    description: string,
    disableName: bool,
    disableDescription: bool,
    disableSequence: bool,
    disableType: bool,
    id: number,
    modalType: oneOf(['new', 'clone', 'edit']).isRequired,
    name: string,
    onModalAccept: func.isRequired,
    onModalCancel: func.isRequired,
    onToastAdd: func.isRequired,
    sequence: string,
    type: string,
  }

  static defaultProps = {
    description: '',
    disableName: false,
    disableDescription: false,
    disableSequence: false,
    disableType: false,
    id: null,
    name: '',
    sequence: '',
    type: ELEMENT_TYPES.CUSTOM_DNA,
  }

  constructor(props) {
    super(props)

    const { description, name, sequence, type } = props

    this.state = {
      description,
      errors: {
        description: this.isValidDescription(description),
        name: this.isValidName(name),
      },
      name,
      sequence,
      sequenceError: false,
      type: this.normalizeType(type),
    }
  }

  componentDidMount() {
    const { bpLength, id, onToastAdd, sequence } = this.props

    if (bpLength && !sequence) {
      getCustomElementWithSequence(id)
        .then(res => {
          this.setState({ sequence: res.sequence })
        })
        .catch(() => {
          onToastAdd({
            type: 'error',
            message: 'Issue retrieving sequence data',
          })
        })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.errorTimer)
  }

  isValidName(name = '') {
    return isValidName(name) ? '' : 'Must be 80 characters or less.'
  }

  isValidDescription(desc = '') {
    return isValidDescription(desc) ? '' : 'Must be 255 characters or less.'
  }

  normalizeType(type) {
    return type.includes('amino')
      ? ELEMENT_TYPES.CUSTOM_AMINO
      : ELEMENT_TYPES.CUSTOM_DNA
  }

  get modalAcceptText() {
    return this.props.modalType === 'edit' ? 'Save' : 'Create'
  }

  get modalSubtitle() {
    return this.props.modalType === 'edit'
      ? 'Make changes to the details of this element.'
      : this.props.modalType === 'clone'
      ? 'Make a copy of this element to edit.'
      : 'Create a new element with customized details.'
  }

  get modalTitle() {
    return this.props.modalType === 'edit'
      ? 'Edit Element'
      : this.props.modalType === 'clone'
      ? 'Clone Element'
      : 'Create Element'
  }

  get sequenceErrorText() {
    return `Invalid ${isAmino(this.state) ? 'amino acid' : 'DNA'} character`
  }

  get types() {
    const isAminoType = isAmino(this.state)

    return [
      {
        active: !isAminoType,
        key: 'dna',
        label: 'DNA',
        value: ELEMENT_TYPES.CUSTOM_DNA,
      },
      {
        active: isAminoType,
        key: 'aa',
        label: 'AA',
        value: ELEMENT_TYPES.CUSTOM_AMINO,
      },
    ]
  }

  get valid() {
    const { errors, name, sequence } = this.state

    return name && sequence && !errors.description && !errors.name
  }

  handleFieldChange = ({ target }) => {
    const { errors } = this.state
    const { name, value } = target
    let nextErrors = errors

    if (name === 'description') {
      nextErrors.description = this.isValidDescription(value)
    } else if (name === 'name') {
      nextErrors.name = this.isValidName(value)
    }

    this.setState({
      [name]: value,
      errors: nextErrors,
    })
  }

  handleSequenceFieldChange = ({ target }) => {
    const isValid = validateSequence({
      type: VALIDATION_TYPES[this.state.type],
      sequence: target.value,
    })

    if (!isValid) {
      clearTimeout(this.errorTimer)
      this.errorTimer = setTimeout(() => {
        if (this.state.sequenceError) {
          this.setState({ sequenceError: false })
        }
      }, 1500)
    }

    this.setState({
      sequence: isValid ? target.value.toLowerCase() : this.state.sequence,
      sequenceError: !isValid,
    })
  }

  handleTypeFieldChange = ({ target }) => {
    this.setState({
      sequence: '',
      type: target.value,
    })
  }

  handleModalAccept = () => {
    const {
      id: elementId,
      modalType,
      onModalAccept,
      onModalCancel,
      onToastAdd,
    } = this.props

    if (!this.valid) {
      return
    }

    Promise.resolve(onModalAccept({ elementId, elementData: this.state }))
      .then(() => {
        onToastAdd({
          type: 'success',
          message:
            modalType === 'new'
              ? 'Element created'
              : modalType === 'edit'
              ? 'Element edited'
              : 'Element cloned',
        })
        onModalCancel()
      })
      .catch(err =>
        onToastAdd({
          type: 'error',
          message: err,
        }),
      )
  }

  render() {
    const {
      disableName,
      disableDescription,
      disableSequence,
      disableType,
      onModalCancel,
    } = this.props

    return (
      <CustomElement
        {...this.state}
        disableAccept={!this.valid}
        disableDescription={disableDescription}
        disableName={disableName}
        disableSequence={disableSequence}
        disableType={disableType}
        modalAcceptText={this.modalAcceptText}
        modalSubtitle={this.modalSubtitle}
        modalTitle={this.modalTitle}
        onFieldChange={this.handleFieldChange}
        onModalAccept={this.handleModalAccept}
        onModalCancel={onModalCancel}
        onSequenceFieldChange={this.handleSequenceFieldChange}
        onTypeFieldChange={this.handleTypeFieldChange}
        sequenceErrorText={this.sequenceErrorText}
        types={this.types}
      />
    )
  }
}
