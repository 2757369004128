function fallbackCopyTextToClipboard (text) {
  const textarea = document.createElement('textarea')
  textarea.value = text
  document.body.appendChild(textarea)
  textarea.focus()
  textarea.select()

  const success = document.execCommand('copy')

  if (!success) {
    console.error(`Could not copy ${text} to clipboard`)
  }

  document.body.removeChild(textarea)

  return success
}

export async function copyTextToClipboard (text) {
  try {
    await navigator.clipboard.writeText(text)
  }
  catch (e) {
    console.log(`Couldn't copy text to clipboard, using fallback`, e)
    fallbackCopyTextToClipboard(text)
  }
}

export default {
  copyText: copyTextToClipboard,
}
