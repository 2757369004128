/**
 * Returns a function to apply a data shape to api responses with arrays.
 *
 * @param {[Func]}  transformers
 * @return {Func}   handles response
 */
export default function applyTransformsToArray (...transformers) {
  const transforms = validateTransformers(transformers)

  return function (entities = [], state = {}) {
    return entities.map((datum) => {
      return transforms.reduce((final, transform) => {
        return transform(final, state)
      }, datum)
    })
  }
}

/**
 * Validates argument type.
 *
 * @param {[Func]}  transformers
 * @return {Bool}
 */
function validateTransformers (transformers) {
  transformers.forEach((transformer) => {
    if (typeof transformer !== 'function') {
      throw new Error('Transformer must be a function')
    }
  })

  return transformers
}
