import { shape, string } from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router'

import DesignerView from 'components/DesignerView'
import GroupView from 'components/GroupView'
import NewGroupView from 'components/NewGroupView'
import NewProjectView from 'components/NewProjectView'

export default function GroupRoute ({ match }) {
  const { path } = match

  return (
    <Switch>
      <Route exact path={`${path}/new`} component={NewGroupView} />
      <Route exact path={`${path}/:groupName`} component={GroupView} />
      <Route path={`${path}/:groupName/projects/new`} component={NewProjectView} />
      <Route path={`${path}/:groupName/projects/:projectId`} component={DesignerView} />
    </Switch>
  )
}

GroupRoute.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
}
