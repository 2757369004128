import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'

import Color, { darkenColor, lightenColor } from 'common/color'
import { getTagEnd, isTagElement } from 'core/construct'

import { DiagonalStripePatternColor, DiagonalStripePatternId } from './SequenceRow/DiagonalStripePattern'

/**
 * Returns the number of text characters that can fit into a row based on its
 * width.
 */
export const getRowLength = createSelector(
  state => state.containerWidth,
  state => state.charWidth,
  (containerWidth, charWidth) => Math.floor(containerWidth / charWidth),
)

/**
 * Returns the number of rows a sequence can be split into.
 */
export const getRowCount = createSelector(
  state => state.sequenceLength,
  getRowLength,
  (seqLength, rowLength) => Math.ceil(seqLength / rowLength),
)

/**
 * Returns if 2 ranges overlap each other.
 */
const overlap = ([a, c], [b, d]) => a <= d && c >= b

/**
 * Returns a function to filter an array of tags down to those that exist within
 * the bounds supplied.
 */
const filterRowTags = (rowOffset, rowEnd) => ({ length, offset }) => (
  overlap([rowOffset, rowEnd], [offset, offset + length])
)

/**
 * Returns the svg style to shape tags into rects.
 */
const styleTag = (tag, rowOffset, rowEnd, charWidth) => {
  const height = 16

  const { length, offset } = tag
  const tagEnd = offset + length

  const x1 = Math.max(offset, rowOffset)
  const x2 = Math.min(tagEnd, rowEnd)

  const getWidth = x => x * charWidth

  return {
    fill: tag.fill || lightenColor(tag.color),
    height,
    labelXOffset: getWidth(1),
    labelYOffset: 12,
    stroke: tag.stroke || darkenColor(tag.color),
    strokeWidth: 1,
    textColor: tag.textColor || darkenColor(tag.color),
    x: getWidth(x1 - rowOffset),
    width: getWidth(x2 - x1),
  }
}

/**
 * Prop getters.
 */
const fromProps = {
  getCharWidth: (_, props) => props.charWidth,
  getContext: (_, props) => props.context,
  getCursor: (_, props) => props.cursor,
  getIsAminoContext: (_, props) => props.isAminoContext,
  getRowIndex: (_, props) => props.rowIndex,
  getRowLength: (_, props) => props.rowLength,
  getSequence: (_, props) => props.sequence,
  getTags: (_, props) => props.tags,
  getCounterTransform: (_, props) => props.transformCounter,
}

/**
 * Returns the offset and end indices of a row.
 */
export const getRowBounds = createSelector(
  fromProps.getRowLength,
  fromProps.getRowIndex,
  (rowLength, rowIndex) => {
    const rowOffset = rowLength * rowIndex
    const rowEnd = rowOffset + rowLength

    return [rowOffset, rowEnd]
  }
)

/**
 * Appends ellipsis if label can fit it.
 */
const addEllipsis = (str, maxChars) => {
  const ellipsis = '...'

  return str.length + ellipsis.length > maxChars ? str : `${str}${ellipsis}`
}

/**
 * Returns the tag array as a store object.
 */
const getTagStore = createSelector(
  fromProps.getTags,
  tags => tags.reduce((store, tag) => ({
    ...store,
    [tag.id]: tag,
  }), {}),
)

/**
 * Returns the tags array with all non-element tags adjusted for rendering
 * purposes. Since all non-element tags have offsets relative to their parent
 * elements' offsets, they must be adjusted to be absolute offsets within the
 * whole sequence.
 */
const getAdjustedTags = createSelector(
  fromProps.getTags,
  getTagStore,
  (tags, store) => tags.map((tag) => {
    const {
      amino,
      id,
      offset,
      spannedElements,
    } = tag
    const parentId = spannedElements[0]

    /**
     * There are instances when the store won't contain the parent element such
     * such as a non-element tag in the editor.
     */
    const { offset: parentOffset = 0 } = store[parentId] || {}

    return id === parentId
      ? {
        ...tag,
        color: amino ? Color.AMINO_FG : Color.DNA_FG,
      }
      : {
        ...tag,
        offset: parentOffset + offset,
      }
  })
)

/**
 * Returns the group index the tag belongs to. This is to prevent rendering
 * overlapping tags on top of each other. This checks all previous tags and,
 * ignoring element tags, continually increases the group number when the
 * current tag overlaps a previous one.
 */
const getTagGroup = (tag, tags, idx) => {
  const { offset } = tag

  let groupIdx = 0

  for (let i = 0; i < idx; i += 1) {
    const prevTag = tags[i]

    if (!isTagElement(prevTag)
      && overlap([offset, getTagEnd(tag)], [prevTag.offset, getTagEnd(prevTag)])
    ) {
      groupIdx += 1
    }
  }

  return groupIdx
}

/**
 * Returns the tags that exist within the bounds of a row.
 */
export const getRowTags = createCachedSelector(
  getAdjustedTags,
  getRowBounds,
  fromProps.getCharWidth,
  (tags, rowBounds, charWidth) => {
    const [rowOffset, rowEnd] = rowBounds

    return tags
      .filter(filterRowTags(rowOffset, rowEnd))
      .map((tag, i, arr) => {
        const style = styleTag(tag, rowOffset, rowEnd, charWidth)

        // Truncate label if name is longer than the tag length.
        const { width } = style
        const { name } = tag
        const maxTagCharCount = Math.floor(width / charWidth) - 1

        return {
          group: isTagElement(tag) ? 0 : getTagGroup(tag, arr, i),
          style,
          tag: {
            ...tag,
            id: `${tag.id}`,
            label: name.length > maxTagCharCount
              ? addEllipsis(`${name.slice(0, Math.max(0, maxTagCharCount - 3))}`, maxTagCharCount)
              : name,
          },
        }
      })
  }
)(
  (_, { rowIndex, rowLength }) => `${rowIndex}-${rowLength}`,
)

/**
 * Returns if a tag is an element. All elements contain their own id as the first
 * item in their spannedElements array. We parse the id for an int since there
 * may be cases where the id has been converted to a string.
 */
const isElement = ({ id, spannedElements }) => spannedElements[0] === parseInt(id, 10)

/**
 * Returns the element tags that exist within the bounds of a row.
 */
export const getRowElements = createSelector(
  getRowTags,
  // Tag id must be parsed for number due to them existing as strings for rendering purposes.
  tags => tags.filter(({ tag }) => isElement(tag)),
)

/**
 * Returns the element tags that exist within the bounds of a row.
 */
export const getRowNonElements = createSelector(
  getRowTags,
  // Tag id must be parsed for number due to them existing as strings for rendering purposes.
  tags => tags
    .filter(({ tag }) => !isElement(tag))
    .reduce((groups, tagProps) => {
      const { group, ...tag } = tagProps

      if (Array.isArray(groups[group])) {
        groups[group].push(tag)

        return groups
      }

      groups.push([tag])

      return groups
    }, [])
)

/**
 * Returns if a tag is an amino acid element
 */
const filterAminoAcidTags = ({ tag }) => tag.amino && tag.aminos.length > 0

/**
 * Returns the amino tags that exist within the bounds of a row.
 */
export const getRowAminos = createSelector(
  getRowElements,
  getRowBounds,
  fromProps.getCharWidth,
  (tags, rowBounds, charWidth) => {
    const [rowOffset, rowEnd] = rowBounds

    // 3 DNA basepairs per amino.
    const aminoLength = 3

    return tags
      // Filters out all tags without aminos.
      .filter(filterAminoAcidTags)
      // Reduces the amino elements down to an array of amino tags to render.
      .reduce((aminoTags, { tag }) => {
        const { aminos, name, offset } = tag

        const aaTags = aminos
          .toUpperCase()
          // Split the amino string into individual chars.
          .split('')
          // Map each char to basic data dimensions.
          .map((aaChar, i) => ({
            color: '#969696',
            id: `${name}-${offset}-${aaChar}-${i}`,
            label: aaChar,
            length: aminoLength,
            offset: offset + (aminoLength * i),
          }))
          // Filter out any aminos that don't exist within the row's bounds.
          .filter(filterRowTags(rowOffset, rowEnd))
          // Map render styles to the remaining aminos.
          .map((aa) => {
            const style = styleTag(aa, rowOffset, rowEnd, charWidth)

            const { width } = style

            return {
              tag: {
                ...aa,
                // Only render label if the rect width is more than a single char.
                label: width === charWidth ? '' : aa.label,
              },
              style,
            }
          })

        return aminoTags.concat(aaTags)
      }, [])
  }
)

/**
 * Returns the sequence text that exists within the bounds of a row.
 */
export const getRowSequence = createSelector(
  fromProps.getSequence,
  getRowBounds,
  fromProps.getRowLength,
  (sequence, rowBounds) => {
    const [rowOffset, rowEnd] = rowBounds

    return sequence.slice(rowOffset, rowEnd)
  }
)

/**
 * Returns the basepair hash mark counter that exists within the bounds of a row.
 * Currently, this is broken for AA context since we need to have a way to
 * keep a running total of either basepairs or amino acids that coincide with
 * the elements rendered. Because DNA elements are truncated in that context,
 * space between hashmarks become non-linear.
 */
export const getRowCounter = createSelector(
  getRowSequence,
  getRowBounds,
  fromProps.getRowLength,
  fromProps.getCharWidth,
  fromProps.getCounterTransform,
  (
    sequence,
    rowBounds,
    rowLength,
    charWidth,
    transformCounter,
  ) => {
    // Account for rows where sequence is shorter than the row itself.
    const charsInRow = Math.min(sequence.length, rowLength)

    const markHeight = 10

    // In AA context, using 9 as a clean multiple of 3.
    const markEvery = 10

    // Subtract 1 to allow room to render the count underneath without it getting cut off.
    const markCount = Math.floor(charsInRow / markEvery)

    // Prevents last marks from needlessly rendering off screen.
    const shouldRemoveLastMark = charsInRow % markEvery < 5
    const count = shouldRemoveLastMark && markCount > 0 ? markCount - 1 : markCount

    const [rowOffset] = rowBounds

    return {
      labelXOffset: -10,
      labelYOffset: markHeight + 20,
      markHeight,
      marks: [...Array(count)].map((_, i) => {
        // Start the index at 1 to make sure the first mark is rendered.
        const index = i + 1
        const mark = markEvery * index
        const label = rowOffset + mark + 1

        return {
          key: `${i}`,
          /**
           * Ignore labels for AA context.
           * Add additional 1 since editor should be base1 instead of base0.
           */
          label: `${transformCounter ? transformCounter(label) : label}`,
          x: charWidth * mark,
        }
      }),
      width: charWidth * charsInRow,
    }
  },
)

/**
 * Returns the cursor selection that exists within the bounds of a row.
 */
export const getRowCursorSelection = createSelector(
  getRowSequence,
  getRowBounds,
  fromProps.getRowLength,
  fromProps.getCharWidth,
  fromProps.getCursor,
  (sequence, rowBounds, rowLength, charWidth, cursor) => {
    // Account for rows where sequence is shorter than the row itself.
    const charsInRow = Math.min(sequence.length, rowLength)

    const [rowOffset] = rowBounds
    const rowEnd = rowOffset + charsInRow
    const { end, start } = cursor

    const x1 = Math.max(start, rowOffset)
    const x2 = Math.min(end, rowEnd)

    const exists = overlap(rowBounds, [start, end])

    return {
      cursor,
      exists,
      isLine: x1 === x2,
      // Only show the left boundary if it exists in the row.
      showLeftBound: exists && overlap(rowBounds, [start, start]),
      // Only show the right boundary if it exists in the row.
      showRightBound: exists && overlap(rowBounds, [end, end]),
      style: {
        x: charWidth * (x1 - rowOffset),
        width: charWidth * (x2 - x1),
      },
    }
  },
)

/**
 * Returns if an element is an amino acid and has invalid or no DNA mapped to it.
 */
const filterAminoAcidTagsWithoutMappedDNA = element => (
  filterAminoAcidTags(element) && !element.tag.hasMappedDNA
)

/**
 * Returns the unmapped amino acid regions that exists within the bounds of a row.
 */
export const getRowUnmappedRegions = createSelector(
  getRowElements,
  fromProps.getIsAminoContext,
  (elements, isAminoContext) => elements
    .filter(element => (isAminoContext
      ? !element.tag.amino
      : filterAminoAcidTagsWithoutMappedDNA(element)
    ))
    .map(({ tag, style, ...element }) => ({
      ...element,
      style: {
        ...style,
        // Pattern fill.
        fill: `url(#${DiagonalStripePatternId})`,
        stroke: DiagonalStripePatternColor.STROKE,
      },
      tag: {
        ...tag,
        label: '',
      },
    })),
)

/**
 * Returns the tags that exist within the bounds of a row for amino acid context.
 * All non-amino acid elements are truncated to preserve the space for amino
 * acid elements. Therefore during iteration, we have to adjust the offset and
 * length of every element.
 */
export const getTagsForAminoAcidContext = createSelector(
  fromProps.getTags,
  (tags) => {
    // Truncated length of DNA elements.
    const dnaLength = 3

    // Offset will accumulate after each time we truncate a DNA
    let runningOffset = 0

    return tags
      .sort((tagA, tagB) => tagA.offset - tagB.offset)
      .reduce((rowTags, tag) => {
        // Ignore all non-element tags.
        if (!isElement(tag)) {
          return rowTags.concat(tag)
        }

        const currentOffset = runningOffset
        const isAmino = tag.aminos.length > 0

        // Truncate all non-amino acid elements and adjust runningOffset for truncated length.
        if (!isAmino) {
          const deltaLength = tag.length - dnaLength

          // Only add to offset if length is greater than truncated length.
          runningOffset += (deltaLength < 1 ? 0 : deltaLength)
        }

        return rowTags.concat({
          ...tag,
          length: isAmino ? tag.length : Math.min(dnaLength, tag.length),
          offset: tag.offset - currentOffset,
        })
      }, [])
  }
)

/**
 * Returns the sequence length based on the lengths of element tags.
 */
export const getSequenceLengthFromTags = createSelector(
  fromProps.getTags,
  tags => tags
    .filter(isElement)
    .reduce((sum, tag) => sum + tag.length, 0)
)
