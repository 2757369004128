import {actions} from 'actions/snackbar'

const initialState = {
  open: false,
  text: '',
  onAction: null,
}

export default function snackbarReducer (state = initialState, action) {
  const {type} = action

  if (type === actions.closeSnackbar) {
    return {
      open: false,
      text: '',
      onAction: null,
    }
  }
  else if (type === actions.openSnackbar) {
    return {
      open: true,
      text: action.text,
      onAction: action.onAction,
    }
  }

  return state
}
