import React, {Component} from 'react'
import {arrayOf, func, number, object, shape, string} from 'prop-types'
import cx from 'classnames'

import Selection from './ContextMenuSelection'
import Tag from './ContextMenuTag'
import provisionSelectionRedux from './provisionContextMenuSelectionRedux'
import provisionTagRedux from './provisionContextMenuTagRedux'
import cn from './ContextMenu.css'

const MENU_MAP = {
  selection: provisionSelectionRedux(Selection),
  tag: provisionTagRedux(Tag),
}

export default class ContextMenu extends Component {
  static propTypes = {
    boundaryRect: shape({
      bottom: number,
      left: number,
      right: number,
      top: number,
    }),
    menuPosition: shape({
      x: number.isRequired,
      y: number.isRequired,
    }),
    menuProps: object,
    menuType: string,
    onMenuClose: func.isRequired,
    onSequenceCopy: func.isRequired,
    onSequenceCut: func.isRequired,
    onSequencePaste: func.isRequired,
    onTagModalOpen: func.isRequired,
    selection: shape({
      end: number.isRequired,
      start: number.isRequired,
    }).isRequired,
    sequence: string.isRequired,
    tags: arrayOf(object).isRequired,
  }

  static defaultProps = {
    boundaryRect: {bottom: 0, left: 0, right: 0, top: 0},
    menuPosition: {x: 0, y: 0},
    menuProps: {},
    menuType: '',
  }

  componentDidUpdate ({menuType}) {
    if (this.node && !menuType && this.props.menuType && MENU_MAP[this.props.menuType]) {
      this.node.focus()
    }
  }

  setRef = (r) => {
    this.node = r
  }

  get style () {
    if (!this.node) {
      return null
    }

    const gutter = 20 // Spacing

    const {boundaryRect, menuPosition} = this.props
    const {bottom, left, right, top} = boundaryRect
    const {x, y} = menuPosition
    const {height, width} = this.node.getBoundingClientRect()
    const menuRight = x + width
    const menuBottom = y + height
    const xPos = menuRight > right ? right - left - width - gutter : x - left
    const yPos = menuBottom > bottom ? bottom - top - height : y - top

    return {transform: `translate(${xPos}px, ${yPos}px)`}
  }

  handleReposition = () => {
    this.forceUpdate()
  }

  handleClose = ({relatedTarget}) => {
    return this.node && this.node.contains(relatedTarget)
      ? setTimeout(this.props.onMenuClose, 300)
      : this.props.onMenuClose()
  }

  handleTagModalOpen = (modalProps) => {
    const {onTagModalOpen, selection, sequence} = this.props
    const {start, end} = selection

    onTagModalOpen('tag', {
      sequence: sequence.slice(Math.min(start, end), Math.max(start, end)),
      start,
      end,
      ...modalProps,
    })
  }

  render () {
    const {
      menuProps,
      menuType,
      onMenuClose,
      onSequenceCopy,
      onSequenceCut,
      onSequencePaste,
      selection,
      sequence,
      tags,
    } = this.props
    const Menu = MENU_MAP[menuType]

    return (
      <div
        className={cx(cn.base, {[cn.open]: Menu})}
        onBlur={this.handleClose}
        ref={this.setRef}
        style={this.style}
        tabIndex={Menu ? 0 : -1}
      >
        <div className={cx(cn.body, {[cn.bodyOpen]: Menu})}>
          {Menu && (
            <Menu
              {...menuProps}
              onClose={onMenuClose}
              onMount={this.handleReposition}
              onSequenceCopy={onSequenceCopy}
              onSequenceCut={onSequenceCut}
              onSequencePaste={onSequencePaste}
              onTagModalOpen={this.handleTagModalOpen}
              selection={selection}
              sequence={sequence}
              tags={tags}
            />
          )}
        </div>
      </div>
    )
  }
}
