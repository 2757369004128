import React from 'react'
import {bool, node, oneOf, string} from 'prop-types'
import cx from 'classnames'

import FaIcon from 'components/FontAwesomeIcon'
import BaseButton from 'components/buttons/BaseButton'
import cn from './FaIconButton.css'

export default function FaIconButton ({
  children,
  className,
  icon,
  iconClass,
  iconSize,
  iconSpin,
  label,
  size,
  title,
  ...props
}) {
  return (
    <BaseButton className={cx(cn.base, cn[size], className)} {...props}>
      <FaIcon
        className={iconClass}
        icon={icon}
        size={iconSize}
        spin={iconSpin}
        title={title || label}
      />
      {children}
    </BaseButton>
  )
}

FaIconButton.propTypes = {
  children: node,
  className: string,
  icon: string.isRequired,
  iconClass: string,
  iconSize: string,
  iconSpin: bool,
  label: string,
  size: oneOf(['xsmall', 'small', 'default']),
  title: string,
}

FaIconButton.defaultProps = {
  children: null,
  className: '',
  iconClass: '',
  iconSize: '',
  iconSpin: false,
  label: '',
  size: 'default',
  title: '',
}
