import clamp from 'lodash/clamp'
import { Component } from 'react'
import { arrayOf, bool, func } from 'prop-types'

import { InputValueType } from 'types'
import noop from 'utils/noop'

import ElementSize from './ElementSize'

export default class DragProvider extends Component {
  static propTypes = {
    children: func.isRequired,
    elementIds: arrayOf(InputValueType).isRequired,
    disabled: bool,
    onClick: func,
    onDragEnd: func.isRequired,
    onDragIndexChange: func.isRequired,
  }

  static defaultProps = {
    disabled: false,
    onClick: noop,
  }

  constructor (props) {
    super(props)

    this.state = {
      draggingElement: {},
      startIndex: 0,
      lastPressedIndex: 0,
      topDeltaY: 0,
      mouseY: 0,
      // Flag to indicate user is dragging.
      pressed: false,
      // Flag to indicate an update has occurred to the sequence.
      updated: false,
    }

    // Timer and flag to check if the click handler should be called.
    this.dragTimer = null
    this.dragging = false
  }

  // Mouse tracking handlers should only be binded to the window when the user
  // is dragging.
  componentDidUpdate (_, { pressed: prevPressed }) {
    const { pressed } = this.state

    if (prevPressed && !pressed) {
      window.removeEventListener('mousemove', this.handleDragMove)
      window.removeEventListener('mouseup', this.handleDragEnd)

      clearTimeout(this.dragTimer)
      this.dragging = false
    }
    else if (!prevPressed && pressed) {
      window.addEventListener('mousemove', this.handleDragMove)
      window.addEventListener('mouseup', this.handleDragEnd)

      this.dragTimer = setTimeout(() => {
        this.dragging = true
      }, 500)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.dragTimer)
  }

  handleDragEnd = () => {
    const { onClick, onDragEnd } = this.props
    const { draggingElement, startIndex, lastPressedIndex } = this.state

    if (!this.dragging) {
      onClick(draggingElement)
    }

    this.setState({
      draggingElement: {},
      startIndex: 0,
      lastPressedIndex: 0,
      topDeltaY: 0,
      pressed: false,
      updated: false,
    })

    if (startIndex !== lastPressedIndex) {
      onDragEnd({ finalIndex: lastPressedIndex })
    }
  }

  handleDragStart = (index, pressY, deltaY, element) => {
    const { disabled } = this.props

    // We only need to add the disabled check here since the other handlers only
    // bind after this handler is fully called. By exiting early, we prevent
    // them from ever being binded.
    if (disabled) {
      return
    }

    this.setState({
      draggingElement: element,
      startIndex: index,
      lastPressedIndex: index,
      topDeltaY: deltaY - pressY,
      mouseY: pressY,
      pressed: true,
    })
  }

  handleDragMove = (e) => {
    // Prevent mouse from other actions while dragging like selecting text.
    e.preventDefault()

    const { elementIds, onDragIndexChange } = this.props
    const { lastPressedIndex, topDeltaY, updated } = this.state
    const mouseY = e.pageY - topDeltaY

    const hoverIndex = clamp(
      Math.round(mouseY / ElementSize.height),
      0,
      elementIds.length - 1,
    )

    const hasUpdate = hoverIndex !== lastPressedIndex
    const index = hasUpdate ? hoverIndex : lastPressedIndex

    this.setState({
      lastPressedIndex: index,
      mouseY,
      updated: hasUpdate || updated,
    })

    if (hasUpdate) {
      onDragIndexChange(lastPressedIndex, hoverIndex)
    }
  }

  render () {
    const { children } = this.props
    const { lastPressedIndex, mouseY, pressed } = this.state

    return children({
      lastPressedIndex,
      mouseY,
      onDragEnd: this.handleDragEnd,
      onDragMove: this.handleDragMove,
      onDragStart: this.handleDragStart,
      pressed,
    })
  }
}
