import React, {Component} from 'react'
import {arrayOf, func, object, oneOf, string} from 'prop-types'

import BasicModal from 'components/BasicModal'
import Spinner from './PollingModalSpinner'
import Summary from './PollingModalSummary'

export default class PollingModalContainer extends Component {
  static propTypes = {
    errorProps: object,
    errorSubtitle: string,
    errorTitle: string,
    idleSubtitle: string,
    idleTitle: string,
    onAccept: func.isRequired,
    onCancel: func.isRequired,
    pollState: oneOf(['idle', 'searching', 'error', 'success']).isRequired,
    results: arrayOf(object),
    searchingSubtitle: string,
    searchingTitle: string,
    SuccessComponent: func.isRequired,
    successProps: object,
    successSubtitle: string,
    successTitle: string,
  }

  static defaultProps = {
    errorProps: {},
    errorSubtitle: 'An error occurred.',
    errorTitle: 'Error',
    idleSubtitle: '',
    idleTitle: '',
    results: [],
    searchingSubtitle: 'Searching...',
    searchingTitle: 'Search',
    successProps: {},
    successSubtitle: 'Hoozah!',
    successTitle: 'Success',
  }

  getModalIcon ({pollState}) {
    return pollState === 'success'
      ? 'check-circle-o'
      : pollState === 'error'
        ? 'exclamation'
        : 'search'
  }

  getModalAcceptText ({pollState}) {
    return pollState === 'error' ? 'Try again' : 'Done'
  }

  getModalDisableAccept ({pollState}) {
    return pollState === 'searching' || pollState === 'idle'
  }

  getModalSubtitle ({errorSubtitle, idleSubtitle, searchingSubtitle, successSubtitle, pollState}) {
    return pollState === 'success'
      ? successSubtitle
      : pollState === 'error'
        ? errorSubtitle
        : pollState === 'searching'
          ? searchingSubtitle
          : idleSubtitle
  }

  getModalTitle ({errorTitle, idleTitle, searchingTitle, successTitle, pollState}) {
    return pollState === 'success'
      ? successTitle
      : pollState === 'error'
        ? errorTitle
        : pollState === 'searching'
          ? searchingTitle
          : idleTitle
  }

  getModalProps ({
    errorSubtitle,
    errorTitle,
    idleSubtitle,
    idleTitle,
    searchingSubtitle,
    searchingTitle,
    successSubtitle,
    successTitle,
    ...props
  }) {
    return {
      acceptButtonLabel: this.getModalAcceptText(props),
      disableAccept: this.getModalDisableAccept(props),
      modalIcon: this.getModalIcon(props),
      modalSubtitle: this.getModalSubtitle()
    }
  }

  renderBody ({pollState, results}) {
    return pollState === 'success' || pollState === 'error'
      ? <Summary results={results} />
      : <Spinner />
  }

  render () {
    const {onAccept, onCancel, ...props} = this.props

    return (
      <BasicModal
        acceptButtonLabel={this.getModalAcceptText(props)}
        disableAccept={this.getModalDisableAccept(props)}
        modalIcon={this.getModalIcon(props)}
        modalSubtitle={this.getModalSubtitle(props)}
        modalTitle={this.getModalTitle(props)}
        onAccept={onAccept}
        onCancel={onCancel}
      >
        {this.renderBody(this.props)}
      </BasicModal>
    )
  }
}
