import React, {Component} from 'react'
import {bool, func, number, string} from 'prop-types'
import cx from 'classnames'

import VerticalSlider from 'components/VerticalSlider'
import cn from './AminoSlidersSlider.css'

export default class AminoSlidersSlider extends Component {
  static propTypes = {
    color: string.isRequired,
    disabled: bool,
    id: string.isRequired,
    label: string.isRequired,
    limit: number.isRequired,
    onUpdate: func.isRequired,
    value: number.isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  state = {
    showValue: false,
  }

  /**
   * Not using a simple toggle function because we need a function to
   * specifically hide the value when the slider starts to drag.
   */
  hideValue = () => {
    this.setState({showValue: false})
  }

  showValue = () => {
    this.setState({showValue: true})
  }

  update = (e, value) => {
    this.props.onUpdate(this.props.id, value)
  }

  render () {
    const {color, disabled, label, limit, value} = this.props
    const {showValue} = this.state

    return (
      <div
        className={cn.base}
        onMouseEnter={this.showValue}
        onMouseLeave={this.hideValue}
      >
        <VerticalSlider
          className={cn.slider}
          disabled={disabled}
          fillColor={color}
          limit={limit}
          max={100}
          min={0}
          onDragEnd={this.update}
          onDragStart={this.hideValue}
          value={value}
        />
        <span className={cx(cn.label, {[cn.labelBold]: showValue})}>
          {showValue ? `${value}%` : label}
        </span>
      </div>
    )
  }
}
