import React from 'react'
import {arrayOf, func, node, number, oneOfType, shape, string} from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/buttons/BaseButton'
import Underline from './TabsUnderline'
import cn from './Tabs.css'

export default function Tabs ({
  activeTabClass,
  activeTabValue,
  className,
  onGetInitialTabDimensions,
  onTabChange,
  tabClass,
  tabs,
  underlineClass,
  underlineX,
  underlineWidth,
}) {
  return (
    <div className={cx(cn.base, className)}>
      {tabs.map((tab) => {
        const active = tab.value === activeTabValue

        return (
          <BaseButton
            className={cx(cn.tab, tabClass, activeTabClass, {
              [cn.active]: active,
            })}
            focusClass={cx({ [cn.active__no_focus]: active })}
            innerRef={r => active && onGetInitialTabDimensions(r)}
            key={tab.key}
            onClick={onTabChange}
            value={tab.value}
          >
            {tab.label}
          </BaseButton>
        )
      })}
      <Underline
        className={underlineClass}
        xPos={underlineX}
        width={underlineWidth}
      />
    </div>
  )
}

Tabs.propTypes = {
  activeTabClass: string,
  activeTabValue: oneOfType([number, string]).isRequired,
  className: string,
  onGetInitialTabDimensions: func,
  onTabChange: func.isRequired,
  tabClass: string,
  tabs: arrayOf(shape({
    key: string.isRequired,
    label: node.isRequired,
    value: oneOfType([number, string]).isRequired,
  })).isRequired,
  underlineClass: string,
  underlineX: number,
  underlineWidth: number,
}

Tabs.defaultProps = {
  activeTabClass: '',
  className: '',
  onGetInitialTabDimensions: () => console.debug('Get initial tab dimensions'),
  tabClass: '',
  underlineClass: '',
  underlineX: 0,
  underlineWidth: 0,
}
