import { func } from 'prop-types'
import React from 'react'

import noop from 'utils/noop'

import { TagDataType, TagStyleType } from '../types'
import cn from './Tag.css'

export default function Tag ({
  onBlur,
  onClick,
  onContextMenu,
  tag,
  style,
}) {
  const { label } = tag
  const {
    labelXOffset,
    labelYOffset,
    x,
    ...tagStyle
  } = style
  const { stroke, textColor } = tagStyle

  return (
    <g
      onBlur={onBlur}
      onClick={onClick}
      onContextMenu={onContextMenu}
      tabIndex={0}
      transform={`translate(${x})`}
    >
      <rect {...tagStyle} />

      {label && (
        <text
          className={cn.label}
          fill={textColor}
          x={labelXOffset}
          y={labelYOffset}
        >
          {label}
        </text>
      )}
    </g>
  )
}

Tag.propTypes = {
  onBlur: func,
  onClick: func,
  onContextMenu: func,
  tag: TagDataType.isRequired,
  style: TagStyleType.isRequired,
}

Tag.defaultProps = {
  onBlur: noop,
  onClick: noop,
  onContextMenu: noop,
}
