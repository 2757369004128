import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _getOr from 'lodash/fp/getOr'

/**
 * @root
 */
const getRoot = _get('authUser')

/**
 * @base
 */
const getAuth0 = _flow(getRoot, _get('auth0'))
export const getAccountType = _flow(getRoot, _get('accountType'))

/**
 * Returns the aws file key fetching status.
 *
 * @return {String}
 */
export const getIfFetchingAWSFile = _flow(getRoot, _get('isFetchingAWSFile'))

/**
 * Returns the aws file key.
 *
 * @return {String}
 */
export const getAWSFileKey = _flow(getRoot, _getOr('', 'awsFileKey'))

/**
 * Returns the auth user's auth0 token.
 *
 * @return {String}
 */
export const getMyAuthToken = _flow(getRoot, _getOr('', 'authToken'))

/**
 * Returns the auth user's username.
 *
 * @return {String}
 */
export const getMyUsername = _flow(getAuth0, _getOr('', 'username'))

/**
 * Returns the auth user's account permissions.
 *
 * @return {Object}
 */
export const getMyPermissions = _flow(getAccountType, _getOr({}, 'permissions'))
