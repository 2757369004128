export const actions = {
  addToast: '@toast/ADD_TOAST',
  deleteToast: '@toast/DELETE_TOAST',
}

export const addToast = ({type, message, ...props}) => ({
  type: actions.addToast,
  toast: {
    ...props,
    type,
    message,
  },
})

export const deleteToast = id => ({
  type: actions.deleteToast,
  id,
})

export default {
  addToast,
  deleteToast,
}
