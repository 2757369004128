export default class RowGroupCounter {
  constructor (initialCount = 0, defaultGroupHeight = 30) {
    this.count = initialCount
    this.defaultGroupHeight = defaultGroupHeight
    this.height = 0
  }

  getNewHeight (groupHeight = this.defaultGroupHeight) {
    this.count += 1
    this.height += groupHeight

    return this.height
  }
}
