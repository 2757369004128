import React, {Component} from 'react'
import {Motion, spring} from 'react-motion'
import {bool, node, string} from 'prop-types'

const motionConfig = {stiffness: 120, damping: 30}

export default class CollapsibleParent extends Component {
  static propTypes = {
    children: node,
    className: string,
    disableHeight: bool,
    disableWidth: bool,
  }

  static defaultProps = {
    children: null,
    className: '',
    disableHeight: false,
    disableWidth: true,
  }

  state = {
    auto: true,
    height: 0,
    width: 0,
  }

  componentDidMount () {
    const {height, width} = this.getDimensions()
    this.setState({height, width})
  }

  componentWillReceiveProps ({children}) {
    if (children !== this.props.children) {
      this.setState({auto: true})
    }
  }

  componentDidUpdate ({children}) {
    if (children !== this.props.children) {
      this.setState({auto: false})
    }
  }

  getRef = (r) => {
    this.node = r
  }

  getDimensions () {
    return this.node && this.node.getBoundingClientRect
      ? this.node.getBoundingClientRect()
      : {height: 0, width: 0}
  }

  render () {
    const {auto} = this.state
    const {children, className, disableHeight, disableWidth} = this.props
    const {height: h, width: w} = this.getDimensions()

    return (
      <Motion style={{
        height: disableHeight ? h : spring(h, motionConfig),
        width: disableWidth ? w : spring(w, motionConfig),
      }}>
        {({height, width}) => (
          <div
            className={className}
            ref={this.getRef}
            style={{
              height: auto ? 'auto' : height,
              width: auto ? 'auto' : width,
            }}
          >
            {children}
          </div>
        )}
      </Motion>
    )
  }
}
