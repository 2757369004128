import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'

export const getRoot = get('designer')
export const getActiveElement = flow(getRoot, get('activeElement'))
export const getElementContext = flow(getRoot, get('context'))
export const getHasUnsavedChanges = flow(getRoot, get('hasUnsavedChanges'))
export const getIsGeneratingSequences = flow(getRoot, get('isGeneratingSequences'))
export const getProjectId = flow(getRoot, get('id'))
export const getSequenceKeypath = flow(getRoot, get('sequenceKeypath'))

export default {
  getActiveElement,
  getElementContext,
  getHasUnsavedChanges,
  getIsGeneratingSequences,
  getProjectId,
  getRoot,
  getSequenceKeypath,
}
