/**
 * This is where we configure the dev environment and any tools we wish to use
 * in the browser.
 */
import { hot } from 'react-hot-loader/root'

import App from './App'

/**
 * Renders our app inside of a Hot Loader wrapper along with any dev tools we
 * want to include.
 *
 * @see https://github.com/gaearon/react-hot-loader
 */
export default hot(App)
