import cx from 'classnames'
import {
  bool,
  func,
  node,
  string,
} from 'prop-types'
import React from 'react'

import { InputValueType } from 'types'

import cn from './Switch.css'

export default function Switch ({
  className,
  labelLeft,
  labelRight,
  onToggleClick,
  toggleOn,
  trackClass,
  value,
}) {
  return (
    <div className={cx(cn.root, className)}>
      <input className={cn.input} readOnly value={value} />

      <div className={cx(cn.label, { [cn.label_active]: !toggleOn })}>
        {labelLeft}
      </div>

      <div className={cx(cn.track_wrapper, trackClass)}>
        <div className={cn.track} />

        <button
          className={cx(cn.toggle, { [cn.toggle_on]: toggleOn })}
          onClick={onToggleClick}
          type="button"
        />
      </div>

      <div className={cx(cn.label, { [cn.label_active]: toggleOn })}>
        {labelRight}
      </div>
    </div>
  )
}

Switch.propTypes = {
  className: string,
  labelLeft: node.isRequired,
  labelRight: node.isRequired,
  onToggleClick: func.isRequired,
  toggleOn: bool,
  trackClass: string,
  value: InputValueType.isRequired,
}

Switch.defaultProps = {
  className: '',
  toggleOn: false,
  trackClass: '',
}
