import classNames from 'classnames'
import { bool } from 'prop-types'
import React from 'react'

import classes from './ActiveMarker.css'

export default function ActiveMarker ({ active }) {
  return <div className={classNames(classes.base, { [classes.active]: active })} />
}

ActiveMarker.propTypes = {
  active: bool,
}

ActiveMarker.defaultProps = {
  active: false,
}
