import React, { Component } from 'react'
import { func, shape, string } from 'prop-types'

import Spinner from 'components/Spinner'
import { ELEMENT_TYPES } from 'core/Constants'
import ElementExplorer from './ElementExplorer'
import { Filter, FilterCreate, Search, SearchType } from './Control'
import Loader from './ElementExplorerLoader'
import Suggestion from './ElementExplorerSuggestion'

const directoryMap = {
  custom: {
    modalType: 'element',
    modalProps: {
      element: { type: ELEMENT_TYPES.CUSTOM_DNA },
      modalType: 'new',
    },
    label: 'Custom',
    value: 'custom',
    PreFetch: Loader,
    Control: FilterCreate,
    controlProps: {
      actionIcon: 'filter',
      actionText: 'Filter',
      hint: 'Filter by name',
    },
    controlKey: 'filterProps',
    tableProps: {
      showBreadcrumbs: true,
    }
  },
  variable: {
    modalType: 'element',
    modalProps: {
      element: { type: ELEMENT_TYPES.VARIABLE },
      modalType: 'new',
    },
    label: 'Variable',
    value: 'variable',
    PreFetch: Loader,
    Control: FilterCreate,
    controlProps: {
      actionIcon: 'filter',
      actionText: 'Filter',
      hint: 'Filter by name',
    },
    controlKey: 'filterProps',
  },
  barcode: {
    label: 'Barcode',
    value: 'barcode',
    Control: Filter,
    controlProps: {
      actionIcon: 'filter',
      actionText: 'Filter',
      hint: 'Filter by name',
    },
    controlKey: 'filterProps',
    tableProps: {
      disableContextMenu: true,
    },
  },
  public: {
    label: 'Public',
    value: 'public',
    Control: Search,
    controlKey: 'searchProps',
  },
  enzyme: {
    label: 'Enzyme',
    value: 'enzyme',
    PreFetch: Suggestion,
    preFetchProps: {
      suggestion: 'ecor',
    },
    Control: Search,
    controlKey: 'searchProps',
  },
  igem: {
    label: 'IGem',
    value: 'igem',
    PreFetch: Suggestion,
    preFetchProps: {
      suggestion: 't7 promoter',
    },
    Control: Search,
    controlKey: 'searchProps',
  },
  ncbi: {
    label: 'NCBI',
    value: 'ncbi',
    PreFetch: Suggestion,
    preFetchProps: {
      suggestion: 'pten cds',
    },
    Control: SearchType,
    controlProps: {
      typeOptions: [
        { label: 'Nucleotide', key: 'dna', value: 'nucleotide' },
        { label: 'Amino Acid', key: 'aa', value: 'protein' },
      ],
    },
    defaultSearchProps: {
      type: 'nucleotide',
    },
    controlKey: 'searchProps',
  },
}

export default class ElementExplorerContainer extends Component {
  static propTypes = {
    directories: shape({}),
    initialDirectory: string,
    onModalOpen: func.isRequired,
  }

  static defaultProps = {
    directories: {},
    initialDirectory: 'custom', // Custom elements.
  }

  constructor (props) {
    super(props)

    this.state = {
      activeDirectory: props.initialDirectory,
      controlKey: directoryMap[props.initialDirectory].controlKey,
      fetched: false,
      fetching: false,
      filterProps: {
        name: '',
      },
      searchProps: {
        search: '',
      },
      sortingKey: 'length',
      inverseSort: false,
    }
  }

  get directories () {
    const { activeDirectory, fetching } = this.state
    const { directories } = this.props

    const getIconProps = directory => (
      activeDirectory === directory && fetching
        ? { icon: 'spinner', spin: true }
        : { icon: '' }
    )

    return Object.keys(directories).map(key => ({
      ...directoryMap[key],
      key: directoryMap[key].label,
      iconProps: getIconProps(directoryMap[key].value),
    }))
  }

  get elements () {
    const { activeDirectory } = this.state
    const { directories } = this.props
    const { elements = [] } = directories[activeDirectory]

    return elements
  }

  handleDirectoryChange = ({ target }) => {
    const { defaultSearchProps, controlKey } = directoryMap[target.value]

    this.setState({
      activeDirectory: target.value,
      controlKey,
      fetched: false,
      filterProps: {
        name: '',
      },
      searchTerm: '',
      searchProps: {
        search: '',
        ...defaultSearchProps,
      },
      sortingKey: 'length',
      inverseSort: false,
    })
  }

  handleInputChange = ({ target }) => {
    this.setState({
      [this.state.controlKey]: {
        ...this.state[this.state.controlKey],
        [target.name]: target.value,
      }
    })
  }

  handleSearch = (search) => {
    if (this.state.fetching) {
      return
    }

    const { activeDirectory, searchProps } = this.state
    const { onFetch } = this.props.directories[activeDirectory]

    this.setState({ fetching: true })

    Promise.resolve(onFetch({ ...searchProps, search }))
      .then(() => this.setState({ fetching: false, fetched: true }))
      .catch(() => this.setState({ fetching: false, fetched: true }))
  }

  handleCreate = (props) => {
    const { modalProps, modalType } = directoryMap[this.state.activeDirectory]
    this.props.onModalOpen(modalType, {
      ...modalProps,
      ...props
    })
  }

  handleUpload = () => {
    const { onModalOpen } = this.props

    onModalOpen('fasta')
  }

  render () {
    const {
      activeDirectory,
      fetched,
      fetching,
      filterProps,
      searchProps,
    } = this.state

    const {
      PreFetch,
      preFetchProps,
      Control,
      controlProps,
      tableProps,
    } = directoryMap[activeDirectory]

    const { elements } = this

    const preFetchElement = fetching
      ? <Spinner />
      : !fetched && elements.length === 0 && PreFetch
        ? <PreFetch {...preFetchProps} onFetch={this.handleSearch} />
        : null

    return (
      <ElementExplorer
        activeDirectory={activeDirectory}
        controls={(
          <Control
            disableSearch={fetching}
            filterProps={filterProps}
            onCreate={this.handleCreate}
            onInputChange={this.handleInputChange}
            onSearch={this.handleSearch}
            onUpload={this.handleUpload}
            searchProps={searchProps}
            {...controlProps}
          />
        )}
        directories={this.directories}
        onDirectoryChange={this.handleDirectoryChange}
        preFetch={preFetchElement}
        tableElements={elements}
        tableProps={tableProps}
      />
    )
  }
}
