import flow from 'lodash/flow'
import get from 'lodash/fp/get'

import {psuedoSequence} from 'core/sequence/sequence_factory'
import {identity, requiredParam} from 'core/utils'
import {ELEMENT_TYPES} from 'core/Constants'
import {addAminoType, addVariableType, makeElementType, isAmino} from './ElementType'

const makeType = (...typeDecorators) => type => (
  makeElementType(flow(...typeDecorators)(type))
)

export const createElement = ({
  color = '',
  description = '',
  id = requiredParam('id'),
  length = 0,
  metadata = {},
  name = '',
  sequence = '',
  type = '',
  ...element
}) => Object.freeze({
  ...element,
  amino: isAmino({ type }),
  color,
  description,
  id,
  length,
  metadata,
  name,
  sequence,
  type,
})

export const createVariableElement = element => createElement({
  ...element,
  metadata: {
    ...element.metadata,
    variable: true,
  },
})

export const makeElementCreator = ({
  idGenerator = () => 0,
  idMap,
  addLength = identity,
  addSequence = identity,
  makeElement = requiredParam('makeElement'),
}) => flow(
  addSequence,
  addLength,
  (element) => element.id ? element : {...element, id: idGenerator(idMap)},
  makeElement,
)

const generatePsuedoSequence = psuedoSequence()

const addPsuedoSequence = getElementLength => element => ({
  ...element,
  sequence: generatePsuedoSequence(getElementLength(element)),
})

const addElementSequenceLength = (element = {}) => {
  const { sequence = '', type = '' } = element
  return {
    ...element,
    length: type.includes('amino') ? sequence.length * 3 : sequence.length,
  }
}

const shapeBarcodeElement = ({
  length = requiredParam('length'),
  ...element
}) => ({
  ...element,
  color: '#e5c494', // var(--element-color-barcode)
  length,
  name: `${length}bp`,
  type: makeType(addVariableType)(element.type),
})

export const makeBarcodeElement = flow(
  shapeBarcodeElement,
  addPsuedoSequence(get('length')),
  createVariableElement,
)

const shapeCustomElement = element => ({
  ...element,
  color: '#a6d854', // var(--element-color-custom)
  type: makeType()(element.type),
})

export const makeCustomElement = flow(
  shapeCustomElement,
  addElementSequenceLength,
  createElement,
)

const shapeVariableElement = element => ({
  ...element,
  color: '#e78ac3', // var(--element-color-variable)
  type: makeType(addAminoType)(ELEMENT_TYPES.VARIABLE),
})

// const aminosToBasePairs = aminos => aminos.length * 3

export const makeVariableElement = flow(
  shapeVariableElement,
  element => ({...element, length: element.aminoProbabilities.length}),
  // 3 bases per amino acid.
  addPsuedoSequence(element => element.aminoProbabilities.length),
  createVariableElement,
)

const shapeEnzymeElement = ({id, name, sequence, ...metadata}) => ({
  color: '#8da0cb', // var(--element-color-enzyme)
  description: metadata.enzymeType,
  id,
  metadata,
  name,
  sequence,
  type: makeType()(ELEMENT_TYPES.ENZYME),
})

export const makeEnzymeElement = flow(
  shapeEnzymeElement,
  addElementSequenceLength,
  createElement,
)

const shapeIgemElement = ({id, name, sequence, ...metadata}) => ({
  color: '#66c2a5', // var(--element-color-igem)
  description: metadata.shortDesc,
  id,
  metadata,
  name,
  sequence,
  type: makeType()(ELEMENT_TYPES.IGEM),
})

export const makeIgemElement = flow(
  shapeIgemElement,
  addElementSequenceLength,
  createElement,
)

const isProtein = ({type}) => type.indexOf('protein') > -1

const shapeNcbiElement = ({description, length, sequence, ...metadata}) => ({
  color: '#fc8d62', // var(--element-color-ncbi)
  description,
  id: `${metadata.accession}-${Math.random() * 100}`,
  length: isProtein(metadata) ? Number(length) * 3 : Number(length),
  metadata,
  name: description,
  sequence,
  type: makeType(isProtein(metadata) ? addAminoType : identity)(ELEMENT_TYPES.NCBI),
})

export const makeNcbiElement = flow(
  shapeNcbiElement,
  createElement,
)

export const isShareEditable = ({authUsername, shares}) => (
  shares.reduce((canEdit, {username, access}) => (
    canEdit || (username === authUsername && access.indexOf('w') > -1)
  ), false)
)

export default {
  create: createElement,
  createVariable: createVariableElement,
  isShareEditable,
  makeBarcodeElement,
  makeCustomElement,
  makeCreator: makeElementCreator,
  makeEnzymeElement,
  makeIgemElement,
  makeNcbiElement,
  makeVariableElement,
}
