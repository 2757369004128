/**
 * All input options require both a label and value at the minimum since almost
 * all of our input components utilize those props to function correctly.
 */

import { node, shape, string } from 'prop-types'

import InputValueType from './inputValueType'

const InputOptionType = shape({
  key: string.isRequired,
  label: node.isRequired,
  value: InputValueType.isRequired,
})

export default InputOptionType
