import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _values from 'lodash/values'
import { createSelector } from 'reselect'

import { hasWriteAccess } from 'utils/checkAccess'
import {
  EXPRESSION_SYSTEMS,
  DEFAULT_OPTIMIZATION_SETTINGS,
} from 'core/Constants'

/**
 * @root
 */
const getRoot = _get('entities')

/**
 * @base
 */
export const getEntities = _flow(getRoot, _get('projects'))
const getPublicEntities = _flow(getRoot, _get('publicProjects'))

/**
 * @helpers
 */
const getIdFromProps = (_, props) => props.id

/**
 * Returns an array of all user's projects.
 *
 * @return {Array}
 */
export const getProjects = createSelector(
  getEntities,
  entities => {
    return _values(entities)
  },
)

/**
 * Returns a project that matches the id provided in props.
 *
 * @return {Object}
 */
export const getProjectById = createSelector(
  getEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id] || {}
  },
)

/**
 * Returns an array of all public projects.
 *
 * @return {Array}
 */
export const getPublicProjects = createSelector(
  getPublicEntities,
  entities => {
    return _values(entities)
  },
)

/**
 * Returns a public project that matches the id provided in props.
 *
 * @return {Object}
 */
export const getPublicProjectById = createSelector(
  getPublicEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)

/**
 * Returns an array of all user's projects that don't belong to a group.
 *
 * @return {Array}
 */
export const getNonGroupProjects = createSelector(
  getProjects,
  projects => {
    return projects.filter(project => !project.group)
  },
)

// Default codon maps shaped for component use.
const defaultCodonMaps = Object.keys(EXPRESSION_SYSTEMS).map(key => ({
  key,
  label: EXPRESSION_SYSTEMS[key],
  value: key,
}))

// Returns any custom codon maps attached to a project along with default maps.
export const getProjectCodonMaps = createSelector(
  getProjectById,
  project => {
    if (!project.codonMaps) {
      return defaultCodonMaps
    }

    return defaultCodonMaps.concat(
      project.codonMaps.map(map => ({
        key: map.name,
        label: map.name,
        value: map.name,
      })),
    )
  },
)

// Returns array of users the project is shared with.
export const getProjectShares = createSelector(
  getProjectById,
  project => {
    return project.shares
  },
)

// Returns if the auth user has write access to a project.
export const getIfUserCanEditProject = createSelector(
  getProjectById,
  ({ access }) => hasWriteAccess(access),
)

export const getProjectType = createSelector(
  getProjectById,
  ({ type }) => type,
)

export const x100 = x => x * 100

export const getProjectOptimizationSettings = createSelector(
  getProjectById,
  ({ optimizationConfig }) => {
    const {
      globalGcContent: global = {},
      globalGcContentSliding: sliding = {},
      avoidRepeatedHomopolymers = {},
      avoidRestrictedEnzymes = [],
    } = optimizationConfig || DEFAULT_OPTIMIZATION_SETTINGS

    const { min: globalMin, max: globalMax } = global
    const { min: slidingMin, max: slidingMax, window: slidingWindow } = sliding

    return {
      globalGc: {
        min: x100(globalMin),
        max: x100(globalMax),
      },
      slidingGc: {
        min: x100(slidingMin),
        max: x100(slidingMax),
        window: slidingWindow,
      },
      homopolymers: Object.keys(avoidRepeatedHomopolymers).map(key => ({
        label: key.toUpperCase(),
        value: avoidRepeatedHomopolymers[key],
      })),
      restrictionSites: avoidRestrictedEnzymes.join(', '),
    }
  },
)

export const getProjectOptimizationSettingsResults = createSelector(
  getProjectById,
  ({ optimizationConfig }) => {
    const {
      globalGcContent: global = {},
      globalGcContentSliding: sliding = {},
      avoidRepeatedHomopolymers = {},
      avoidRestrictedEnzymes = [],
    } = optimizationConfig || DEFAULT_OPTIMIZATION_SETTINGS

    const { min: globalMin, max: globalMax } = global
    const { min: slidingMin, max: slidingMax, window: slidingWindow } = sliding

    const restrictionSites = avoidRestrictedEnzymes.map(
      site => `No restriction site ${site} in the sequence`,
    )

    const homopolymers = Object.keys(avoidRepeatedHomopolymers).map(
      key =>
        `No ${
          avoidRepeatedHomopolymers[key]
        }-homopolymers of ${key.toUpperCase()}`,
    )

    const globalGc = `Global GC contnet between ${x100(globalMin)} and ${x100(
      globalMax,
    )} percent`

    const slidingGc = `GC contnet between ${x100(slidingMin)} and ${x100(
      slidingMax,
    )} percent over ${slidingWindow} bp sliding window`

    return restrictionSites.concat(homopolymers, globalGc, slidingGc)
  },
)
