/* global AWS, __SHOW_LOGIN_UI__, __AUTH0_CLIENT_ID__, __AUTH0_DOMAIN__ */
export const SITE_ROOT = '/'
export const HOME_RESULTS_PER_PAGE = 15
export const PANEL_RESULTS_PER_PAGE = 10

export const JOB_POLL_INTERVAL = 3000 // ms

// setup AWS (loaded from index.html)
if (typeof AWS !== 'undefined') {
  AWS.config.region = 'us-west-2'
}

// feature flags
export const SHOW_LOGIN_UI =
  typeof __SHOW_LOGIN_UI__ !== 'undefined' ? __SHOW_LOGIN_UI__ : false

/**
 * Configure auth middleware
 */
export const authConfig = {
  clientId: __AUTH0_CLIENT_ID__,
  domain: __AUTH0_DOMAIN__,

  auth: {
    authOptions: {
      api: 'aws',
      role: 'arn:aws:iam::511726753330:role/tupacbio_user_s3_access',
      principal: 'arn:aws:iam::511726753330:saml-provider/auth0',
      grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    },
    responseType: 'token id_token',
    callbackURL: `${location.protocol}//${location.host}`,
  },
}

// Unauthorized routes
export const unauthorizedRoutes = {
  about: { label: 'About Us', path: '/about' },
  licensing: { label: 'Licensing', path: '/licensing' },
  tutorials: { label: 'Tutorials', path: '/tutorials' },
  news: { label: 'News', path: '/media' },
  publicProjects: { label: 'Public Projects', path: '/public' },
}

// Unauthorized mobile routes
export const unauthorizedMobileRoutes = {
  home: { label: 'Home', path: '/' },
  ...unauthorizedRoutes,
}
