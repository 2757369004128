import { node, shape } from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import { AppLayout } from 'components/app-layout'
import AuthProvider from 'components/AuthProvider'
import LoginView from 'components/LoginView'
import Spinner from 'components/Spinner'
import { Toaster } from 'components/toaster'

import cn from './Root.css'

export function RootView({ children, location, match }) {
  return (
    <div className={cn.root}>
      <AuthProvider location={location}>
        {({
          checkingAuth,
          isAuthorized,
          onLoginShow,
          onLogout,
          onSignUpShow,
        }) => {
          if (checkingAuth) {
            return <Spinner />
          }

          if (isAuthorized) {
            return (
              <AppLayout onLogout={onLogout} params={match.params}>
                {children}
              </AppLayout>
            )
          }

          return (
            <LoginView onLoginShow={onLoginShow} onSignUpShow={onSignUpShow} />
          )
        }}
      </AuthProvider>
      <Toaster />
    </div>
  )
}

RootView.propTypes = {
  children: node,
  location: shape({}).isRequired,
  match: shape({
    params: shape({}),
  }).isRequired,
}

RootView.defaultProps = {
  children: null,
}

export default withRouter(RootView)
