/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import cx from 'classnames'
import { push as pushRoute } from 'connected-react-router'
import {
  arrayOf,
  func,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { reqCreateGroup } from 'actions/group'
import { reqUserInfo } from 'actions/user'
import BaseButton from 'components/BaseButton'
import ShareUserCreator from 'components/ShareUserCreator'
import ShareUserList from 'components/ShareUserList'
import { TextInput } from 'components/text-input'
import { noop } from 'utils'

import cn from './NewGroupView.css'

/**
 * Returns a new array with element added at the specified index.
 *
 * @param {Array}       arr
 * @param {Number}      index
 * @param {Any}         item
 * @param {Number}      itemsRemove
 * @return {Array}      array with added element
 */
function insertAtIndex (arr, index, item, itemsRemove) {
  const newArr = arr.slice()

  newArr.splice(index, itemsRemove, item)

  return newArr
}

/**
 * @component
 */
export class NewGroupView extends Component {
  static propTypes = {
    /**
     * List of access options.
     */
    accessOptions: arrayOf(shape({
      key: string,
      label: string,
      value: string,
    })),
    /**
     * Creates new project.
     */
    onCreate: func,
    /**
     * Fetches user info.
     */
    onFetchUserInfo: func,
    /**
     * Dispatches route change.
     */
    onPushRoute: func,
  }

  static defaultProps = {
    accessOptions: [
      { key: 'View Only', label: 'View Only', value: 'r' },
      { key: 'Member', label: 'Member', value: 'rw' },
      { key: 'Admin', label: 'Admin', value: 'rwa' },
    ],
    onCreate: noop,
    onFetchUserInfo: noop,
    onPushRoute: noop,
  }

  /**
   * @lifecycle
   */
  state = {
    description: '',
    name: '',
    shares: [],
  }

  /**
   * @handlers
   */
  onFieldChange = ({ target }) => {
    const { name, value } = target

    this.setState({ [name]: value })
  }

  onCreateShare = (shareData) => {
    const { onFetchUserInfo } = this.props
    const { shares } = this.state
    const { username } = shareData

    // Check if user is already in list
    const userIndex = shares.reduce((index, share, shareIndex) => {
      if (index > -1) {
        return index
      }

      return share.username === username ? shareIndex : index
    }, -1)

    // If already exists, no need to fetch user info to verify them as a
    // valid user.
    if (userIndex > -1) {
      this.setState({ shares: insertAtIndex(shares, userIndex, shareData, 1) })

      return Promise.reject()
    }

    // Return the promise
    return Promise.resolve(onFetchUserInfo(username)).then(() => {
      this.setState({ shares: shares.concat(shareData) })
    })
  }

  onDeleteShare = (username) => {
    const { shares } = this.state

    // Return a promise
    return Promise.resolve(this.setState({
      shares: shares.filter(share => share.username !== username),
    }))
  }

  onReturnToHome = () => {
    const { onPushRoute } = this.props

    onPushRoute('/')
  }

  onSubmit = () => {
    const { onCreate, onPushRoute } = this.props
    const { description, name, shares } = this.state

    const group = {
      description,
      name,
      shares,
    }

    Promise.resolve(onCreate(group))
      .then(({ data }) => {
        // Redirect to group page if group is successfully created.
        if (data && data.name) {
          const { name: groupName } = data

          onPushRoute(`/groups/${groupName}`)
        }
      })
  }

  /**
   * @renders
   */
  render () {
    const { accessOptions } = this.props
    const { description, name, shares } = this.state

    // Create props
    const canSubmit = name.length > 0
    const submitLabel = canSubmit ? 'Create Group' : 'No Group Name'

    return (
      <div className={cn.root}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <h4 className={cn.section__title}>
                Create new group
              </h4>

              <div className={cn.section__top_gutter}>
                <h5 className={cn.section__title}>
                  General info
                </h5>

                <div className={cn.section__top_gutter_sm}>
                  <span className={cn.section__text}>
                    Group name is required. The description is optional.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <label className={cn.label} htmlFor="projectName">
                  Group name

                  <TextInput
                    className={cn.input}
                    id="projectName"
                    name="name"
                    onChange={this.onFieldChange}
                    placeholder="Group-01"
                    value={name}
                  />
                </label>

                <div className={cn.section__top_gutter_lg}>
                  <label className={cn.label} htmlFor="projectDescription">
                    Group description (optional)

                    <TextInput
                      className={cn.input}
                      onChange={this.onFieldChange}
                      name="description"
                      placeholder="My group is about..."
                      value={description}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className="col-md-3">
              <h5 className={cn.section__title}>
                Group Members
              </h5>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text}>
                  Add users to the group by their username.
                </span>
              </div>

              <div className={cn.section__top_gutter}>
                <h5 className={cn.section__title}>
                  Access Types
                </h5>
              </div>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text_dk}>
                  Admin
                </span>
                <span className={cn.section__text}>
                  Admins can add/remove users to the group. They can also delete any
                  group projects.
                </span>
              </div>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text_dk}>
                  Member
                </span>
                <span className={cn.section__text}>
                  Members can edit and create group projects. They can also delete any
                  projects that they created.
                </span>
              </div>

              <div className={cn.section__top_gutter_sm}>
                <span className={cn.section__text_dk}>
                  View Only
                </span>
                <span className={cn.section__text}>
                  View only users cannot edit or create any projects.
                </span>
              </div>
            </div>

            <div className="col-md-8 col-md-offset-1">
              <div className={cn.paper}>
                <ShareUserCreator
                  accessOptions={accessOptions}
                  onShareUserCreate={this.onCreateShare}
                  shares={shares}
                />

                <div className={cn.share_list}>
                  <ShareUserList
                    accessOptions={accessOptions}
                    onDelete={this.onDeleteShare}
                    onEdit={this.onCreateShare}
                    users={shares}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={cx(cn.section__top_gutter_lg, 'row')}>
            <div className={cx(cn.actions, 'col-md-8 col-md-offset-4')}>
              <BaseButton
                disabled={!canSubmit}
                onClick={this.onSubmit}
                primary
                raised
              >
                {submitLabel}
              </BaseButton>

              <BaseButton onClick={this.onReturnToHome}>
                Cancel
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * @redux
 */
const mapDispatch = {
  onCreate: reqCreateGroup,
  onFetchUserInfo: reqUserInfo,
  onPushRoute: pushRoute,
}

/**
 * @export
 */
export default connect(null, mapDispatch)(NewGroupView)
