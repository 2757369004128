/**
 * This module provides methods to check access levels by making sure an access
 * string contains the desired access char.
 *
 * Access chars:
 * Read-only:  'r'
 * Read-write: 'w'
 * Admin:      'a'
 * Own:        'o'
 */
export const hasOwnerAccess = (access = '') => access.indexOf('o') > -1
export const hasAdminAccess = (access = '') => access.indexOf('a') > -1
export const hasWriteAccess = (access = '') => access.indexOf('w') > -1
export const hasReadAccess = (access = '') => access.indexOf('r') > -1
