import React from 'react'
import {bool, func, string} from 'prop-types'

import BaseButton from 'components/buttons/BaseButton'
import Presets from './StackActionsPresets'
import cn from './StackActions.css'

export default function StackActions ({
  disabled,
  onPresetChange,
  onStackAdd,
  onStackReset,
  preset,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.actions}>
        <BaseButton
          className={cn.btn}
          disabled={disabled}
          onClick={onStackAdd}
        >
          New Stack
        </BaseButton>
        <BaseButton
          className={cn.btn}
          disabled={disabled}
          onClick={onStackReset}
        >
          Reset Stack
        </BaseButton>
      </div>
      <Presets
        disabled={disabled}
        onChange={onPresetChange}
        preset={preset}
      />
    </div>
  )
}

StackActions.propTypes = {
  disabled: bool,
  onPresetChange: func.isRequired,
  onStackAdd: func.isRequired,
  onStackReset: func.isRequired,
  preset: string,
}

StackActions.defaultProps = {
  disabled: false,
  preset: '',
}
