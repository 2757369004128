import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ElementContext } from 'common'
import { MOUSE_BUTTONS } from 'core/Constants'
import noop from 'utils/noop'
import safelyGetBoundingRect from 'utils/safelyGetBoundingRect'

import ContextMenuTypes from '../ContextMenuTypes'
import {
  getRowAminos,
  getRowBounds,
  getRowCounter,
  getRowCursorSelection,
  getRowElements,
  getRowNonElements,
  getRowSequence,
  getRowUnmappedRegions,
} from '../selectors'
import {
  CounterType,
  CursorType,
  RowStyleType,
  TagType,
} from '../types'
import SequenceRow from './SequenceRow'

export class SequenceRowContainer extends Component {
  static propTypes = {
    aminos: arrayOf(TagType).isRequired,
    charWidth: number.isRequired,
    counter: CounterType.isRequired,
    cursor: CursorType.isRequired,
    disabled: bool,
    elements: arrayOf(TagType).isRequired,
    invalidRegions: arrayOf(TagType),
    isAminoContext: bool,
    onContextMenu: func,
    onCursorChange: func,
    onRowSizeUpdate: func,
    rowEnd: number.isRequired,
    rowIndex: number.isRequired,
    rowOffset: number.isRequired,
    sequence: string.isRequired,
    style: RowStyleType.isRequired,
    tagRegions: arrayOf(arrayOf(TagType)),
  }

  static defaultProps = {
    disabled: false,
    invalidRegions: [],
    isAminoContext: false,
    onContextMenu: noop,
    onCursorChange: noop,
    onRowSizeUpdate: noop,
    tagRegions: [],
  }

  /**
   * Due to the row height being dependent on the rect values being grabbed in
   * the previous render, we have to force update on mount to pass the correct
   * height to the svg.
   */
  componentDidMount () {
    this.forceUpdate()
  }

  /**
   * Due to the row height being dependent on the rect values being grabbed in
   * the previous render, there are a few cases where we need to manually force
   * an update in order to get the row to render with the correct height.
   *
   * 1. When the counter length changes.
   * 2. When the number of non-element tag rows changes.
   */
  componentDidUpdate ({ counter: prevCounter, tagRegions: prevTagRegions }) {
    const { counter, tagRegions } = this.props

    if (prevCounter.width !== counter.width || prevTagRegions.length !== tagRegions.length) {
      this.forceUpdate()
    }
  }

  /**
   * Sets a ref to the body group in order to grab the height of the row since
   * its height is dynamic.
   */
  handleBodyRefSet = (r) => {
    this.body = r
  }

  /**
   * Sets a ref to the group wrapper in order to grab the real height of the row
   * in order to set the correct height to the svg element.
   */
  handleRowRefSet = (r) => {
    this.row = r
  }

  /**
   * Passes the row index to onRowSizeUpdater handler.
   */
  handleRowSizeUpdate = () => {
    const { onRowSizeUpdate, rowIndex } = this.props

    onRowSizeUpdate(rowIndex)
  }

  /**
   * Opens the context menu for a cursor selection.
   */
  handleSelectionContextMenu = (e) => {
    this.openContextMenu(e, ContextMenuTypes.SELECTION)
  }

  /**
   * Passes along the mouse coordinates to the onCursorChange handler.
   */
  handleSelectionMove = ({ clientX }) => {
    const {
      charWidth,
      onCursorChange,
      rowEnd,
      rowOffset,
      sequence,
    } = this.props

    onCursorChange(
      null,
      null,
      {
        charWidth,
        clientX,
        reset: false,
        // Ignore indices greater than the actual sequence length in row.
        rowEnd: Math.min(rowOffset + (rowOffset + sequence.length), rowEnd),
        rowOffset,
      },
    )
  }

  /**
   * Passes along the mouse coordinates to the onCursorChange handler. But only
   * does so for left mouse button clicks.
   */
  handleSelectionStart = ({ button, clientX }) => {
    if (button !== MOUSE_BUTTONS.left) {
      return
    }

    const {
      charWidth,
      onCursorChange,
      rowEnd,
      rowOffset,
      sequence,
    } = this.props

    onCursorChange(
      null,
      null,
      {
        charWidth,
        clientX,
        // Reset for new selection.
        reset: true,
        // Ignore indices greater than the actual sequence length in row.
        rowEnd: Math.min(rowOffset + (rowOffset + sequence.length), rowEnd),
        rowOffset,
      },
    )
  }

  /**
   * Passes the tag's indices to onCursorChange and opens the context menu for
   * that tag.
   */
  handleTagClick = (e, tag) => {
    e.stopPropagation()

    const {
      id,
      label,
      offset,
      length,
    } = tag
    const { onCursorChange } = this.props

    onCursorChange(offset, offset + length)

    this.openContextMenu(e, ContextMenuTypes.TAG, { tagId: id, tagName: label })
  }

  /**
   * Opens context menu based on the menuType and passes along the mouse
   * coordinates for menu placement. Additional menuProps can be passed along as
   * well for use with the onContextMenu handler's parent component.
   */
  openContextMenu (e, menuType, menuProps) {
    e.preventDefault()
    e.stopPropagation()

    const { clientX: x, clientY: y } = e
    const { onContextMenu } = this.props

    onContextMenu({ x, y }, menuType, menuProps)
  }

  render () {
    const {
      aminos,
      counter,
      cursor,
      disabled,
      elements,
      invalidRegions,
      isAminoContext,
      sequence,
      style,
      tagRegions,
    } = this.props

    const { height: bodyHeight } = safelyGetBoundingRect(this.body)
    // Default value to zero to prevent NaN.
    const { height: rowHeight = 0 } = safelyGetBoundingRect(this.row)

    // Adjust row height to not cut off numbers and maintain some padding.
    const adjustedRowHeight = rowHeight + 10

    return (
      <SequenceRow
        aminos={aminos}
        bodyHeight={bodyHeight}
        counter={counter}
        cursor={cursor}
        disabled={disabled}
        elements={elements}
        invalidRegions={invalidRegions}
        isAminoContext={isAminoContext}
        onBodyRefSet={this.handleBodyRefSet}
        onRowRefSet={this.handleRowRefSet}
        onRowSizeUpdate={this.handleRowSizeUpdate}
        onSelectionContextMenu={this.handleSelectionContextMenu}
        onSelectionMove={this.handleSelectionMove}
        onSelectionStart={this.handleSelectionStart}
        onTagClick={this.handleTagClick}
        sequence={sequence}
        style={{
          ...style,
          height: adjustedRowHeight,
        }}
        tagRegions={tagRegions}
      />
    )
  }
}

const mapState = (
  state,
  {
    charWidth,
    cursor,
    elementContext,
    rowIndex,
    rowLength,
    sequence,
    tags,
    transformCounter,
  }
) => {
  const isAminoContext = elementContext === ElementContext.AA

  const selectorProps = {
    charWidth,
    context: elementContext,
    cursor,
    isAminoContext,
    rowIndex,
    rowLength,
    sequence,
    tags,
    transformCounter,
  }

  const [rowOffset, rowEnd] = getRowBounds(state, selectorProps)
  const elements = getRowElements(state, selectorProps)
  const aminos = getRowAminos(state, selectorProps)
  const rowSeq = getRowSequence(state, selectorProps)
  const counter = getRowCounter(state, selectorProps)
  const cursorSelection = getRowCursorSelection(state, selectorProps)
  const invalidRegions = getRowUnmappedRegions(state, selectorProps)
  const tagRegions = getRowNonElements(state, selectorProps)

  return {
    aminos,
    counter,
    cursor: cursorSelection,
    elements,
    invalidRegions,
    isAminoContext,
    sequence: rowSeq,
    rowEnd,
    rowOffset,
    tagRegions,
  }
}

export default connect(mapState)(SequenceRowContainer)
