import React from 'react'
import {bool, func, string} from 'prop-types'

import BaseButton from 'components/buttons/BaseButton'
import cn from './ElementExplorerSuggestion.css'

export default function ElementExplorerSuggestion ({disabled, suggestion, onFetch}) {
  return (
    <div className={cn.base}>
      <span className={cn.text}>
        Not sure what to search? Try
      </span>
      <BaseButton
        className={cn.btn}
        disabled={disabled}
        onClick={() => onFetch(suggestion)}
      >
        {suggestion}
      </BaseButton>
    </div>
  )
}

ElementExplorerSuggestion.propTypes = {
  disabled: bool,
  onFetch: func.isRequired,
  suggestion: string.isRequired,
}

ElementExplorerSuggestion.defaultProps = {
  disabled: false,
}
