import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'

import ProjectActionButtons from 'components/ProjectActionButtons'
import DesignSelectInput from 'components/DesignSelectInput'
import { VariationsInput } from 'components/variations-input'
import cn from './DesignActions.css'

export default function DesignActions({ className }) {
  return (
    <div className={cx(cn.base, className)}>
      <div className={cn.left}>
        <DesignSelectInput />
        <VariationsInput />
      </div>
      <div className={cn.right}>
        <ProjectActionButtons />
      </div>
    </div>
  )
}

DesignActions.propTypes = {
  className: string,
}

DesignActions.defaultProps = {
  className: '',
}
