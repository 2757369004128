import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import {createSelector} from 'reselect'

const getRoot = get('toast')

export const getToastDict = flow(getRoot, get('items'))

export const getToasts = createSelector(
  getToastDict,
  toasts => Object.keys(toasts).map(key => ({
    ...toasts[key],
    id: key,
  })),
)

export default {
  getToastDict,
  getToasts,
}
