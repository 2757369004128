import { func, node, string } from 'prop-types'
import React, { Component } from 'react'

import noop from 'utils/noop'
import { InputValueType } from 'types'

import Switch from './Switch'

export default class SwitchContainer extends Component {
  static propTypes = {
    className: string,
    initialValue: InputValueType.isRequired,
    labelLeft: node,
    labelRight: node,
    onToggleChange: func,
    trackClass: string,
    valueLeft: InputValueType.isRequired,
    valueRight: InputValueType.isRequired,
  }

  static defaultProps = {
    className: '',
    labelLeft: 'Off',
    labelRight: 'On',
    onToggleChange: noop,
    trackClass: '',
  }

  constructor (props) {
    super(props)

    const { initialValue, valueLeft, valueRight } = props
    const on = initialValue === valueRight

    this.state = {
      on,
      value: on ? valueRight : valueLeft,
    }
  }

  handleToggleClick = () => {
    const { onToggleChange, valueLeft, valueRight } = this.props
    const { on } = this.state

    // Flip value
    const nextValue = on ? valueLeft : valueRight

    this.setState({
      on: !on,
      value: nextValue,
    })

    onToggleChange(nextValue)
  }

  render () {
    const {
      className,
      labelLeft,
      labelRight,
      trackClass,
    } = this.props
    const { on, value } = this.state

    return (
      <Switch
        className={className}
        labelLeft={labelLeft}
        labelRight={labelRight}
        onToggleClick={this.handleToggleClick}
        toggleOn={on}
        trackClass={trackClass}
        value={value}
      />
    )
  }
}
