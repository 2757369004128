import React from 'react'
import {arrayOf, number, oneOfType, shape, string} from 'prop-types'

import Rect from './RectStackRect'

export default function RectStack ({
  className,
  height,
  rectClass,
  stack,
  width,
}) {
  return (
    <svg className={className} height={height} width={width}>
      <g transform={`scale(1, -1) translate(0, -${height})`}>
        {stack.map((part, i) => (
          <Rect
            className={rectClass}
            key={part.key}
            width={width}
            {...part}
          />
        ))}
      </g>
    </svg>
  )
}

RectStack.propTypes = {
  className: string,
  height: number,
  rectClass: string,
  stack: arrayOf(shape({
    fill: string,
    height: number.isRequired,
    key: oneOfType([number, string]),
    y: number.isRequired,
  })),
  width: number,
}

RectStack.defaultProps = {
  className: '',
  height: 100,
  rectClass: '',
  stack: [],
  width: 30,
}
