import cx from 'classnames'
import React from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  shape,
  string,
} from 'prop-types'

import CollapsibleButton from 'components/buttons/CollapsibleFaIconAndLabelButton'
import ActiveMarker from 'components/toggables/ActiveMarker'
import SlideoutView from 'components/toggables/SlideoutView'

import cn from './DesignerSidebarMenu.css'

function DesignerSidebarMenu ({
  className,
  onOptionSelect,
  onViewClose,
  options,
  showView,
  view,
  viewLabel,
}) {
  return (
    <div className={cx(cn.base, className)}>
      <div className={cn.top}>
        <SlideoutView
          onClose={onViewClose}
          open={showView}
          title={viewLabel}
        >
          {view}
        </SlideoutView>
        <div className={cn.actions}>
          {options.map(({active, icon, label}, i) => (
            <CollapsibleButton
              icon={icon}
              key={label}
              label={label}
              onClick={onOptionSelect(i)}
            >
              <ActiveMarker active={active} />
            </CollapsibleButton>
          ))}
        </div>
      </div>
    </div>
  )
}

DesignerSidebarMenu.propTypes = {
  className: string,
  onOptionSelect: func.isRequired,
  onViewClose: func.isRequired,
  options: arrayOf(shape({
    active: bool,
    icon: node.isRequired,
    label: node.isRequired,
  })).isRequired,
  showView: bool,
  view: node,
  viewLabel: node,
}

DesignerSidebarMenu.defaultProps = {
  className: '',
  showView: false,
  view: null,
  viewLabel: 'Slideout View Label',
}

export default DesignerSidebarMenu
