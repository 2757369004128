import { Component } from 'react'
import { arrayOf, func, number, object, shape, string } from 'prop-types'

const MAX_COMMIT_LENGTH = 80

export default class ProjectCommitModalContainer extends Component {
  static propTypes = {
    children: func.isRequired,
    onError: func,
    onPreProjectCommit: func,
    onProjectCommit: func.isRequired,
    onProjectCommitSuccess: func,
    // onSequenceJobStarted: func.isRequired, Only for library projects which are not available yet.
    projectId: number.isRequired,
    templates: arrayOf(
      shape({
        elementOrder: arrayOf(number).isRequired,
        expressionSystem: string.isRequired,
        id: number.isRequired,
        sequence: string.isRequired,
        tags: object.isRequired,
        variations: number.isRequired,
      }),
    ).isRequired,
  }

  static defaultProps = {
    onError: () => console.error('Error occurred'),
    onPreProjectCommit: () => console.error('Pre project commit callback'),
    onProjectCommitSuccess: () =>
      console.debug('Project committed successfully'),
  }

  state = { message: '' }

  get isValid() {
    return this.state.message.length > 0
  }

  handleCommitMessageChange = ({ target }) => {
    if (target.value.length > MAX_COMMIT_LENGTH) {
      return
    }

    this.setState({ message: target.value })
  }

  handleProjectCommit = () => {
    const {
      onError,
      onPreProjectCommit,
      onProjectCommit,
      onProjectCommitSuccess,
      // onSequenceJobStarted,
      projectId,
      templates,
    } = this.props

    onPreProjectCommit()

    return Promise.resolve(
      onProjectCommit(projectId, {
        commitMessage: this.state.message,
        sequenceTemplates: templates,
      }),
    )
      .then(({ data }) => {
        onProjectCommitSuccess()
        // return onSequenceJobStarted(data.jobId)
      })
      .catch(onError)
  }

  render() {
    return this.props.children({
      commitMessage: this.state.message,
      disableAccept: !this.isValid,
      onChangeCommitMessage: this.handleCommitMessageChange,
      onProjectCommit: this.handleProjectCommit,
    })
  }
}
