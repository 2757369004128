import _drop from 'lodash/drop'

import {actions} from 'actions/error'

const initialState = {
  errors: [],
}

/**
 * @export
 */
export default function errorReducer (state = initialState, action = {}) {
  const {error, errorText, type} = action

  if (type === actions.CLEAR_ERROR) {
    return {
      errors: _drop(state.errors),
    }
  }
  else if (type === actions.DISPLAY_ERROR) {
    return {
      errors: state.errors.concat(errorText),
    }
  }
  else if (error) {
    return {
      errors: state.errors.concat(error),
    }
  }

  return state
}
