import React from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import cx from 'classnames'

import FormControl from 'components/FormControl'
import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import RoundOutlineButton from 'components/RoundOutlineButton'
import { TextArea, TextInput } from 'components/text-input'
import cn from './CustomElement.css'

export default function CustomElement ({
  description,
  disableAccept,
  disableDescription,
  disableName,
  disableSequence,
  disableType,
  errors,
  name,
  modalAcceptText,
  modalSubtitle,
  modalTitle,
  onFieldChange,
  onModalAccept,
  onModalCancel,
  onSequenceFieldChange,
  onTypeFieldChange,
  sequence,
  sequenceError,
  sequenceErrorText,
  types,
}) {
  const { description: descError, name: nameError } = errors

  return (
    <ModalLayout
      acceptButtonLabel={modalAcceptText}
      className={cn.base}
      disableAccept={disableAccept}
      onAccept={onModalAccept}
      onClose={onModalCancel}
    >
      <ModalIconHeader
        icon="square"
        subtitle={modalSubtitle}
        title={modalTitle}
      />

      <FormControl
        className={cn.control}
        icon="label"
        idFor="name"
        label="Name"
      >
        <TextInput
          disabled={disableName}
          id="name"
          name="name"
          onChange={onFieldChange}
          placeholder="Name is required"
          value={name}
        />
        {nameError && (
          <span className={cn.errorText}>
            {nameError}
          </span>
        )}
      </FormControl>

      <FormControl
        icon="label"
        idFor="desc"
        label="Description"
      >
        <TextInput
          disabled={disableDescription}
          id="desc"
          name="description"
          onChange={onFieldChange}
          value={description}
        />
        {descError && (
          <span className={cn.errorText}>
            {descError}
          </span>
        )}
      </FormControl>

      <FormControl
        bodyClass={cn.controlInline}
        icon="label"
        idFor="type"
        label="Type"
      >
        {types.map(type => (
          <RoundOutlineButton
            className={cx(cn.btn, { [cn.btnActive]: type.active })}
            disabled={disableType}
            key={type.key}
            name="type"
            onClick={onTypeFieldChange}
            value={type.value}
          >
            {type.label}
          </RoundOutlineButton>
        ))}
      </FormControl>

      <FormControl
        icon="label"
        idFor="sequence"
        label="Sequence"
      >
        <TextArea
          className={cx(cn.sequenceInput, { [cn.sequenceInputEmpty]: !sequence })}
          disabled={disableSequence}
          id="sequence"
          onChange={onSequenceFieldChange}
          placeholder="Insert or paste sequence here"
          rows={10}
          value={sequence}
        />
        <div className={cx(cn.error, { [cn.errorShow]: sequenceError })}>
          {sequenceErrorText}
        </div>
      </FormControl>
    </ModalLayout>
  )
}

CustomElement.propTypes = {
  description: string,
  disableAccept: bool,
  disableName: bool,
  disableDescription: bool,
  disableSequence: bool,
  disableType: bool,
  errors: shape({
    description: string,
    name: string,
  }),
  name: string,
  modalAcceptText: string,
  modalSubtitle: string,
  modalTitle: string,
  onFieldChange: func.isRequired,
  onModalAccept: func.isRequired,
  onModalCancel: func.isRequired,
  onSequenceFieldChange: func.isRequired,
  onTypeFieldChange: func.isRequired,
  sequence: string,
  sequenceError: bool,
  sequenceErrorText: string,
  types: arrayOf(shape({
    active: bool,
    key: string.isRequired,
    label: string.isRequired,
    value: string.isRequired,
  })),
}

CustomElement.defaultProps = {
  description: '',
  disableName: false,
  disableDescription: false,
  disableSequence: false,
  disableType: false,
  name: '',
  sequence: '',
  sequenceError: false,
  sequenceErrorText: '',
  types: [],
}
