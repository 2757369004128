import React, { Component } from 'react'
import {
  arrayOf,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types'

import { ElementContext } from 'common'
import {
  getConstructRegionAbsoluteIndex,
  updateConstructAARegionLength,
  updateConstructRegionLength,
} from 'core/construct'
import { ElementContextType } from 'types'

import TagModal from './TagModal'
import configEditorByState from './configEditorByState'
import { getTagsToDisplay } from './selectors'

const ConservedDomainIcon = require('styles/img/icon_conserved_domain_tag.svg')

export default class TagModalContainer extends Component {
  static propTypes = {
    absoluteIndex: number.isRequired,
    editingTag: shape({
      name: string,
      type: string,
    }).isRequired,
    editingTagId: number.isRequired,
    editorState: oneOf(['create', 'edit', 'editMany']).isRequired,
    elementContext: ElementContextType.isRequired,
    end: number.isRequired,
    initialCursorIndex: number,
    onClose: func.isRequired,
    onSubmit: func.isRequired,
    sequence: string.isRequired,
    start: number.isRequired,
    relatedTagIds: arrayOf(number),
    // eslint-disable-next-line
    tags: object.isRequired,
  }

  static defaultProps = {
    initialCursorIndex: 0,
    relatedTagIds: [],
  }

  constructor (props) {
    super(props)

    const {
      editingTag,
      elementContext,
      sequence,
      tags,
    } = props
    const { description = '', name, type } = editingTag

    this.state = {
      description,
      elementContext,
      name,
      sequence,
      tags,
      type,
    }
  }

  get isValid () {
    const { name } = this.state

    return name.length > 0
  }

  get tagsToUpdate () {
    const { description, name, type, tags } = this.state
    const { editingTagId } = this.props

    return {
      ...tags,
      [editingTagId]: { ...tags[editingTagId], description, name, type }
    }
  }

  get editingTagIcon () {
    const { type } = this.state

    return type.includes('feature') ? ConservedDomainIcon : ''
  }

  get tagTypeIcon () {
    const { type } = this.state

    if (type.includes('motif')) {
      return 'repeat'
    }

    if (type.includes('user')) {
      return 'local_offer'
    }

    return 'panorama'
  }

  get tagTypeLabel () {
    const { type } = this.state

    if (type.includes('feature')) {
      return 'conserved domain'
    }

    if (type.includes('user')) {
      return 'custom'
    }

    return 'element'
  }

  get tagTypeDescription () {
    const { type } = this.state

    if (type.includes('motif')) {
      return `Conserved domain tags are features from the Conserved Domain Database
        (CDD). These domains are evolutionarily conserved and typically have a well
        understood sequence, structure, and/or function relationship.`
    }

    if (type.includes('user')) {
      return `Custom tags are customizable and can be used to annotate portions
        of a sequence that are of particular interest.`
    }

    return `Element tags are the basic building blocks of your project.`
  }

  handleEditingTagFieldChange = ({ target }) => {
    const { name, value } = target

    this.setState({ [name]: value })
  }

  handleSubmit = () => {
    const {
      end,
      start,
      onClose,
      onSubmit,
      relatedTagIds,
      editingTagId,
    } = this.props
    const { sequence } = this.state

    onSubmit({
      end,
      start,
      sequence,
      tags: this.tagsToUpdate,
      relatedTagIds,
      tagId: editingTagId,
    })
    onClose()
  }

  handleSequenceInsert = (cursor, input) => {
    const { start } = cursor
    const { editingTag } = this.props
    const { amino, id } = editingTag

    const updateConstruct = amino
      ? updateConstructAARegionLength
      : updateConstructRegionLength

    const newState = updateConstruct(
      this.state,
      id,
      getConstructRegionAbsoluteIndex(this.state, id, start),
      input.length,
      input,
    )

    this.setState(newState)
  }

  handleSequenceDelete = (end, moveIndexTo, deleteLength) => {
    const { editingTag } = this.props
    const { amino, id } = editingTag

    const updateConstruct = amino
      ? updateConstructAARegionLength
      : updateConstructRegionLength

    const newState = updateConstruct(
      this.state,
      id,
      getConstructRegionAbsoluteIndex(this.state, id, moveIndexTo),
      deleteLength * -1, // Flip sign
    )

    this.setState(newState)
  }

  render () {
    const {
      absoluteIndex,
      initialCursorIndex,
      editorState,
      editingTagId,
      onClose,
      start
    } = this.props
    const {
      description,
      name,
      sequence,
      tags,
      type,
    } = this.state

    const {
      disableNameChange,
      disableTypeSelect,
      modalTitle,
      modalAcceptText,
      ...editorProps
    } = configEditorByState(editorState)

    const {
      amino,
      aminos,
      length,
      metadata,
      spannedElements,
    } = tags[editingTagId]

    const editorConfigProps = {
      ...editorProps,
      elementContext: amino ? ElementContext.AA : ElementContext.DNA,
      initialCursorIndex: initialCursorIndex - start,
      // Disable sequence editing for tags that span multiple elements.
      disableSequenceEditor: spannedElements.length > 1,
    }

    const { proteinFamily } = metadata

    const tagsToRender = getTagsToDisplay(null, {
      id: editingTagId,
      isAA: amino,
      start,
      tags,
    })

    // Get sequence slice to display in editor.
    const subsequence = amino ? aminos : sequence.slice(absoluteIndex, absoluteIndex + length)

    return (
      <TagModal
        amino={amino}
        disableNameChange={disableNameChange}
        disableSubmit={!this.isValid}
        disableTypeSelect={disableTypeSelect}
        editingTagDescription={description}
        editingTagIcon={this.editingTagIcon}
        editingTagName={name}
        editingTagProteinFamily={proteinFamily}
        editingTagType={type}
        editingTagTypeIcon={this.tagTypeIcon}
        editingTagTypeLabel={this.tagTypeLabel}
        editingTagTypeDescription={this.tagTypeDescription}
        editorConfigProps={editorConfigProps}
        modalAcceptText={modalAcceptText}
        modalTitle={modalTitle}
        onClose={onClose}
        onTagFieldChange={this.handleEditingTagFieldChange}
        onSequenceDelete={this.handleSequenceDelete}
        onSequenceInsert={this.handleSequenceInsert}
        onSubmit={this.handleSubmit}
        sequence={subsequence}
        tags={tagsToRender}
      />
    )
  }
}
