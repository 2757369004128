import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _values from 'lodash/values'
import {createSelector} from 'reselect'

/**
 * @root
 */
const getRoot = _get('entities')

/**
 * @base
 */
const getEntities = _flow(getRoot, _get('groups'))

/**
 * @helpers
 */
const getNameFromProps = (_, props) => props.name

/**
 * Returns an array of all user's groups.
 *
 * @return {Array}
 */
export const getGroups = createSelector(
  getEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns a group that matches the name provided in props.
 *
 * @return {Object}
 */
export const getGroupByName = createSelector(
  getEntities,
  getNameFromProps,
  (entities, name) => {
    return entities[name]
  },
)

/**
 * Returns an array of all groups user has write access to.
 *
 * @return {Array}
 */
export const getGroupsWithWriteAccess = createSelector(
  getGroups,
  (groups) => {
    return groups.filter((group) => {
      return group.access === 'o' || group.access.indexOf('w') > -1
    })
  },
)
