import React from 'react'
import {func, shape, string} from 'prop-types'

import { TextInput } from 'components/text-input'
import cn from './index.css'

export default function ControlFilter ({
  filterProps,
  hint,
  onInputChange,
}) {
  return (
    <div className={cn.base}>
      <TextInput
        className={cn.input}
        name='name'
        onChange={onInputChange}
        placeholder={hint}
        value={filterProps.name}
      />
    </div>
  )
}

ControlFilter.propTypes = {
  filterProps: shape({
    name: string.isRequired,
  }).isRequired,
  hint: string,
  onInputChange: func.isRequired,
}

ControlFilter.defaultProps = {
  hint: 'Filter by name',
}
