import cx from 'classnames'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React from 'react'

import BaseButton from 'components/buttons/BaseButton'
import classes from './RadioButtonSelect.css'

export default function RadioButtonSelect ({
  activeIndex,
  className,
  disabled,
  onSelect,
  optionClass,
  options,
}) {
  return (
    <div className={cx(classes.base, className)}>
      {options.map((option, i) => (
        <BaseButton
          className={cx(classes.button, optionClass, { [classes.active]: activeIndex === i })}
          disabled={disabled}
          key={option.label}
          onClick={() => onSelect(i, option.value)}
        >
          {option.label}
        </BaseButton>
      ))}
    </div>
  )
}

RadioButtonSelect.propTypes = {
  activeIndex: number.isRequired,
  className: string,
  disabled: bool,
  onSelect: func.isRequired,
  optionClass: string,
  options: arrayOf(shape({
    label: string.isRequired,
    value: oneOfType([number, string]),
  })),
}

RadioButtonSelect.defaultProps = {
  className: '',
  disabled: false,
  optionClass: '',
  options: [],
}
