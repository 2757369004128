import React from 'react'
import {
  bool,
  func,
  number,
  string,
} from 'prop-types'
import {connect} from 'react-redux'

import {reqUpdateProject} from 'actions/project'
import InlineEditText from 'components/InlineEditText'
import {getProjectId} from 'selectors/designer'
import {getProjectById} from 'selectors/project'
import {hasWriteAccess} from 'utils/checkAccess'

import cn from './ProjectNotes.css'

function ProjectNotes ({
  disabled,
  id,
  notes,
  onUpdate,
}) {
  return (
    <div className={cn.base}>
      <p className={cn.hint}>
        Click notes to edit
      </p>
      <div className={cn.body}>
        <InlineEditText
          isDisabled={disabled}
          isTextArea
          onUpdate={(update) => onUpdate(id, update)}
          propName='description'
          rows={25}
          value={notes}
        />
      </div>
    </div>
  )
}

ProjectNotes.propTypes = {
  disabled: bool,
  id: number.isRequired,
  notes: string,
  onUpdate: func.isRequired,
}

ProjectNotes.defaultProps = {
  disabled: false,
  notes: '',
}

const mapState = (state) => {
  const id = getProjectId(state)
  const {access, description} = getProjectById(state, {id})

  return {
    disabled: !hasWriteAccess(access),
    id,
    notes: description,
  }
}

const mapDispatch = {
  onUpdate: reqUpdateProject,
}

export {ProjectNotes}

export default connect(mapState, mapDispatch)(ProjectNotes)
