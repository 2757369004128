import isArray from 'lodash/isArray'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'

// Updates an entity cache in response to any action with response.entities.
const initialState = {
  customElements: {},
  enzymeElements: {},
  groups: {},
  orthologs: {},
  igemElements: {},
  ncbiElements: {},
  projects: {},
  publicElements: {},
  publicProjects: {},
  variableElements: {},
}

// Don't merge for these
const replaceEntitiesFor = [
  'enzymeElements',
  'igemElements',
  'ncbiElements',
  'publicElements',
]

export default function entities (state = initialState, {type, response = {}, ...action}) {
  if (response.entities) {
    // normalizr should only be returning 1 type!!
    const entityType = Object.keys(response.entities)[0]

    if (response.isDestructive) {
      // we need to filter all the objects with keys that were returned of the entity types
      try {
        const keysToRemove = response.result

        return {
          ...state,
          [entityType]: omit(state[entityType], keysToRemove)
        }
      }
      catch (err) {
        console.error('Failed to remove items from entities!', response)
        return state
      }
    }
    else if (replaceEntitiesFor.includes(entityType)) {
      return {
        ...state,
        [entityType]: response.entities[entityType],
      }
    }
    else {
      // we just need to add the entities
      return mergeWith({}, state, response.entities, (objValue, srcValue) => {
        if (isArray(objValue)) {
          return srcValue
        }
      })
    }
  }

  return state
}
