import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'
import flow from 'lodash/flow'
import get from 'lodash/fp/get'

import { isTemplateVariable } from 'core/template/Template'
import { isTagAmino, isTagElement, isTagVariable, getTagEnd, areTagsRelated } from 'core/template/Tag'
import { styleTag } from 'core/template/TagUI'
import dnaToAminos from 'core/sequence/dnaToAminos'

export const getRoot = get('templates')
export const getTemplateOrder = flow(getRoot, get('order'))
export const getTemplateExpression = flow(getRoot, get('expressionSystem'))
export const getEditingTemplate = flow(getRoot, get('editing'))
export const getEditingTemplateId = flow(getEditingTemplate, get('id'))
export const getEditingTemplateVariations = flow(getEditingTemplate, get('variations'))
export const getEditingTemplateElementOrder = flow(getEditingTemplate, get('elementOrder'))
export const getEditingTemplateTags = flow(getEditingTemplate, get('tags'))
export const getEditingTemplateSequence = flow(getEditingTemplate, get('sequence'))
export const getSelection = flow(getRoot, get('selection'))

export const getEditingTemplateElementTagOrder = createSelector(
  getEditingTemplateElementOrder,
  getEditingTemplateTags,
  (elementOrder, tags) => elementOrder.map(id => tags[id]),
)

export const isEditingTemplateVariable = createSelector(
  getEditingTemplateElementTagOrder,
  elementTagOrder => isTemplateVariable(elementTagOrder),
)

export const getTagById = createSelector(
  getEditingTemplateTags,
  (_, props) => isNil(props.id) ? props.tagId : props.id,
  (tags, id) => tags[id],
)

export const getEditingTagsArray = createSelector(
  getEditingTemplateTags,
  tags => Object.keys(tags).map(key => tags[key]),
)

export const getEditingTemplateTagsArray = createSelector(
  getEditingTagsArray,
  tags => tags.map(styleTag),
)

export const getTemplatesForCommit = createSelector(
  getTemplateOrder,
  getEditingTemplate,
  getRoot,
  getTemplateExpression,
  (order, editingTemplate, templates, expressionSystem) => (
    order.map(id => editingTemplate.id === id
      ? { ...editingTemplate, expressionSystem }
      : { ...templates[id], expressionSystem }
    )
  ),
)

export const getEditingTemplateElementsWithSequence = createSelector(
  getEditingTemplateElementTagOrder,
  getEditingTemplateSequence,
  (elements, sequence) => elements.map(element => ({
    ...element,
    sequence: sequence.slice(element.offset, getTagEnd(element)),
  })),
)

const isMammalianExpression = expression => (
  expression === 'human' || expression === 'mouse'
)

export const getNormalizedTemplateExpression = createSelector(
  getTemplateExpression,
  expression => isMammalianExpression(expression) ? 'mammal' : expression,
)

export const getAllRelatedTags = createSelector(
  getEditingTagsArray,
  getTagById,
  (tags, tag) => tags.filter(checkTag => areTagsRelated(tag, checkTag)),
)

export const getIsTagElement = createSelector(
  getTagById,
  isTagElement,
)

export const getIsTagVariable = createSelector(
  getTagById,
  isTagVariable,
)

export const getIsTagAmino = createSelector(
  getTagById,
  isTagAmino,
)

export const getTagSequenceSlice = createSelector(
  getTagById,
  getEditingTemplateSequence,
  (tag, sequence) => sequence.substring(tag.offset, getTagEnd(tag)),
)

export const getTagSequenceSliceAsAminos = createSelector(
  getTagSequenceSlice,
  dnaToAminos,
)

export const getSelectionSequenceSlice = createSelector(
  (_, { selection }) => ({
    start: Math.min(selection.start, selection.end),
    end: Math.max(selection.start, selection.end),
  }),
  getEditingTemplateSequence,
  ({ start, end }, sequence) => sequence.substring(start, end),
)

export const getTagAsElement = createSelector(
  getEditingTemplateSequence,
  getTagById,
  (sequence, tag) => ({
    ...tag,
    owner: '',
    sequence: sequence.slice(tag.offset, tag.offset + tag.length),
    sequenceLength: isTagAmino(tag) ? `${tag.length / 3} AA` : `${tag.length} BP`,
    variable: isTagVariable(tag),
  }),
)

export default {
  getAllRelatedTags,
  getEditingTagsArray,
  getEditingTemplate,
  getEditingTemplateElementOrder,
  getEditingTemplateElementsWithSequence,
  getEditingTemplateElementTagOrder,
  getEditingTemplateId,
  getEditingTemplateSequence,
  getEditingTemplateTags,
  getEditingTemplateTagsArray,
  getEditingTemplateVariations,
  getIsTagAmino,
  getIsTagElement,
  getIsTagVariable,
  getNormalizedTemplateExpression,
  getRoot,
  getSelectionSequenceSlice,
  getTagAsElement,
  getTagById,
  getTagSequenceSlice,
  getTagSequenceSliceAsAminos,
  getTemplateExpression,
  getTemplateOrder,
  getTemplatesForCommit,
  isEditingTemplateVariable,
}
