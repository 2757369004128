import clamp from 'lodash/clamp'
import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import React, { Component } from 'react'

import { ElementContext } from 'common'
import { isAminoValid, isDNAValid } from 'core/sequence/sequence_factory'
import { copyTextToClipboard } from 'core/utils/Clipboard'
import noop from 'utils/noop'
import { ElementContextType, TagType } from 'types'

import SequenceEditor from './SequenceEditor'
import { CursorDataType } from './types'

const DELETE_KEY = 8
const VALID_DNA_KEYS = [65, 71, 84, 67]
const VALID_AA_KEYS = VALID_DNA_KEYS.concat([
  70,
  76,
  83,
  89,
  87,
  80,
  72,
  81,
  82,
  73,
  77,
  78,
  75,
  86,
  68,
  69,
])

export default class SequenceEditorContainer extends Component {
  static propTypes = {
    className: string,
    disabled: bool,
    elementContext: ElementContextType.isRequired,
    initialCursorIndex: number,
    onCursorUpdate: func,
    onSequenceDelete: func,
    onSequenceEditStart: func,
    onSequenceInsert: func,
    /**
     * Weird bug happens with rows rendering a cursor where the row height is
     * miscalculated leading to overlapping rows on initial render. This
     * disrupts instances where we want to start the editor at a different index
     * other than zero such as in the GeneContigSequenceViewer. In order to
     * bypass the bug, this can be defined to scroll the view without the
     * cursor.
     */
    scrollToIndex: number,
    selection: CursorDataType,
    sequence: string.isRequired,
    tags: arrayOf(TagType).isRequired,
    transformCounter: func,
  }

  static defaultProps = {
    className: '',
    disabled: false,
    initialCursorIndex: 0,
    onCursorUpdate: noop,
    onSequenceDelete: noop,
    onSequenceEditStart: noop,
    onSequenceInsert: noop,
    scrollToIndex: 0,
    selection: {
      end: 0,
      start: 0,
    },
    transformCounter: null,
  }

  constructor (props) {
    super(props)

    const { initialCursorIndex, selection } = props
    const { end: jumpCursorEnd, start: jumpCursorStart } = selection

    this.state = {
      cursorEnd: initialCursorIndex,
      cursorStart: initialCursorIndex,
      jumpCursorEnd,
      jumpCursorStart,
      selecting: false,
    }
  }

  /**
   * Updates cursor from store.
   */
  componentWillReceiveProps ({ selection }) {
    const { jumpCursorEnd, jumpCursorStart } = this.state
    const { end, start } = selection

    if (jumpCursorStart !== start || jumpCursorEnd !== end) {
      this.setState({
        cursorEnd: end,
        cursorStart: start,
        jumpCursorStart: start,
      })
    }
  }

  /**
   * Bind listener to handle the cursor selection end event.
   */
  componentDidUpdate (_, { selecting: prevSelecting }) {
    const { selecting } = this.state

    if (!prevSelecting && selecting) {
      document.addEventListener('mouseup', this.handleCursorMoveEnd)
    }
    else if (prevSelecting && !selecting) {
      document.removeEventListener('mouseup', this.handleCursorMoveEnd)
    }
  }

  /**
   * Cleanup event listeners.
   */
  componentWillUnmount () {
    document.removeEventListener('mouseup', this.handleCursorMoveEnd)
  }

  /**
   * Returns the normalized indices for the cursor since reverse dragging can
   * cause the endIndex to be lesser than the startIndex. Since many of our
   * functions require the start to be the min, this getter ensures the indices
   * are always in the correct order.
   */
  get normalizedCursor () {
    const { cursorEnd, cursorStart } = this.state

    return {
      end: Math.max(cursorStart, cursorEnd),
      start: Math.min(cursorStart, cursorEnd),
    }
  }

  /**
   * If a position object is provided, the cursor data will be derived from that.
   * Otherwise it will use the cursorStart and cursorEnd values.
   */
  handleCursorChange = (cursorStart, cursorEnd, position) => {
    if (position) {
      this.calculateCursorPosition(position)

      return
    }

    this.setState({ cursorStart, cursorEnd })
  }

  /**
   * Passes the current selection state to the onCursorUpdate handler.
   */
  handleCursorMoveEnd = () => {
    const { onCursorUpdate } = this.props
    const { cursorEnd, cursorStart } = this.state

    this.setState({ selecting: false })

    onCursorUpdate(cursorStart, cursorEnd)
  }

  /**
   * Handles the ref for the editor node in order to use its position to
   * calculate cursor position from a mouse position.
   */
  handleEditorRefSet = (r) => {
    this.editor = r
  }

  /**
   * Copies the sequence slice bound by the cursor indices in state to the
   * browser's clipboard.
   */
  handleSequenceCopy = (callback) => {
    const { end, start } = this.normalizedCursor

    // Ignore if the cursor isn't a range larger than 0.
    if (start === end) {
      return
    }

    const { sequence } = this.props

    copyTextToClipboard(sequence.substring(start, end))

    if (typeof callback === 'function') {
      callback(sequence)
    }
  }

  /**
   * Invokes handleSequenceCopy but removes the cursor selection on callback.
   */
  handleSequenceCut = () => {
    this.handleSequenceCopy(this.deleteSequence)
  }

  /**
   * Calls the appropriate handler for a valid edit key.
   */
  handleSequenceEdit = ({ key, keyCode: kc }) => {
    const { elementContext } = this.props

    if (kc === DELETE_KEY) {
      this.deleteSequence()
    }
    else if ((elementContext === ElementContext.DNA && VALID_DNA_KEYS.includes(kc))
      || (elementContext === ElementContext.AA && VALID_AA_KEYS.includes(kc))) {
      this.insertSequence(key)
    }
  }

  /**
   * Only inserts the text from clipboard if it's a valid sequence string based
   * on the context in props.
   */
  handleSequencePaste = ({ clipboardData }) => {
    const text = clipboardData.getData('Text')
    const { elementContext } = this.props

    if ((elementContext === ElementContext.DNA && !isDNAValid(text))
      || (elementContext === ElementContext.AA && !isAminoValid(text))) {
      return
    }

    this.insertSequence(text)
  }

  /**
   * Deletes the sequence part from the index of the cursor in state.
   */
  deleteSequence = () => {
    const cursor = this.normalizedCursor
    const { end, start } = cursor

    // Ignore if cursor is at 0.
    if (end === 0) {
      return
    }

    const { disabled, onSequenceDelete, onSequenceEditStart } = this.props

    const length = Math.max(1, end - start)
    const moveIndexTo = end - length

    if (!disabled) {
      onSequenceDelete(end, moveIndexTo, length)

      this.setState({
        cursorEnd: moveIndexTo,
        cursorStart: moveIndexTo,
      })
    }

    onSequenceEditStart(start)
  }

  /**
   * Inserts a string into it's sequence sequence.
   */
  insertSequence = (sequence) => {
    const { disabled, onSequenceEditStart, onSequenceInsert } = this.props

    // Move index to length of the inserted sequence from the current insert index.
    const cursor = this.normalizedCursor
    const { start } = cursor
    const moveIndexTo = start + sequence.length

    if (!disabled) {
      onSequenceInsert(cursor, sequence)

      this.setState({
        cursorEnd: moveIndexTo,
        cursorStart: moveIndexTo,
      })
    }

    onSequenceEditStart(start, sequence)
  }

  /**
   * Calculates the cursor index based on the distance between a mouse position
   * and the left bound of the editor node.
   */
  calculateCursorPosition ({
    charWidth,
    clientX,
    reset,
    rowEnd,
    rowOffset,
  }) {
    const { cursorStart, selecting } = this.state
    const { left } = this.editor.getBoundingClientRect()

    // Ignore if not selecting and this isn't a reset.
    if (!selecting && !reset) {
      return
    }

    const mouseIndex = clamp(
      Math.round((clientX - left) / charWidth) + rowOffset,
      rowOffset,
      rowEnd,
    )

    this.setState({
      cursorEnd: mouseIndex,
      cursorStart: reset ? mouseIndex : cursorStart,
      selecting: true,
    })
  }

  render () {
    const {
      className,
      disabled,
      elementContext,
      scrollToIndex,
      sequence,
      tags,
      transformCounter,
    } = this.props
    const { cursorEnd, cursorStart, jumpCursorStart } = this.state

    return (
      <SequenceEditor
        className={className}
        cursor={{
          end: Math.max(cursorEnd, cursorStart),
          start: Math.min(cursorEnd, cursorStart),
        }}
        disabled={disabled}
        elementContext={elementContext}
        onCursorChange={this.handleCursorChange}
        onEditorRefSet={this.handleEditorRefSet}
        onSequenceCopy={this.handleSequenceCopy}
        onSequenceCut={this.handleSequenceCut}
        onSequenceEdit={this.handleSequenceEdit}
        onSequencePaste={this.handleSequencePaste}
        scrollToIndex={scrollToIndex || jumpCursorStart}
        sequence={sequence}
        tags={tags}
        transformCounter={transformCounter}
      />
    )
  }
}
