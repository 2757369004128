import React, {Component} from 'react'
import {bool, func, object, string} from 'prop-types'

import AminoStack from './AminoStack'

/**
 * Renders a visualization of an amino map distribution.
 */
export default class AminoStackContainer extends Component {
  static propTypes = {
    active: bool,
    aminos: object,
    disabled: bool,
    id: string.isRequired,
    incomplete: bool,
    onDelete: func.isRequired,
    onSelect: func.isRequired,
  }

  static defaultProps = {
    active: false,
    aminos: {},
    disabled: false,
    incomplete: false,
  }

  // Only update the stack when its active state or amino map changes.
  shouldComponentUpdate ({active, disabled, aminos}) {
    return active !== this.props.active || disabled !== this.props.disabled ||
      aminos !== this.props.aminos
  }

  get aminos () {
    const {aminos} = this.props
    // Tracks current total height of the stack.
    let height = 0

    return Object.keys(aminos).map(key => {
      const amino = aminos[key]
      const y = height

      // Adjust height
      height += amino.value

      return {
        fill: amino.color,
        height: amino.value,
        key: amino.key,
        y,
      }
    })
  }

  handleDelete = () => {
    const {id, onDelete} = this.props
    onDelete(id)
  }

  handleSelect = () => {
    const {active, id, onSelect} = this.props

    // Only call select if stack is not already active.
    if (!active) {
      onSelect(id)
    }
  }

  render () {
    const {active, disabled, incomplete} = this.props

    return (
      <AminoStack
        active={active}
        aminoStack={this.aminos}
        disabled={disabled}
        incomplete={incomplete}
        onDelete={this.handleDelete}
        onSelect={this.handleSelect}
      />
    )
  }
}
