import createActionTypes from 'utils/createActionTypes'

/**
 * @constants
 */
export const actions = createActionTypes([
  'CLEAR_ERROR',
  'DISPLAY_ERROR',
], 'error')

/**
 * Creates action to clear last displayed error.
 *
 * @return {Action}
 */
export function clearError () {
  return {
    type: actions.CLEAR_ERROR,
  }
}

/**
 * Creates action to display an error to display.
 *
 * @param {String}          errorText
 * @return {Action}
 */
export function displayError (errorText) {
  return {
    type: actions.DISPLAY_ERROR,
    errorText,
  }
}
