import {createApiAction} from 'store/apiMiddleware'
import applyTransformsToArray from 'utils/applyTransformsToArray'
import createActionTypes from 'utils/createActionTypes'
import addAuthUsername from './utils/addAuthUsername'

/**
 * Api endpoint
 */
const api = '/elements/variable'

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_CREATE_VAR_ELEMENT',
  'REQ_CREATE_VAR_ELEMENT_ERROR',
  'REQ_CREATE_VAR_ELEMENT_SUCCESS',
  'REQ_CREATE_VAR_ELEMENT_SHARE',
  'REQ_CREATE_VAR_ELEMENT_SHARE_ERROR',
  'REQ_CREATE_VAR_ELEMENT_SHARE_SUCCESS',
  'REQ_DELETE_VAR_ELEMENT',
  'REQ_DELETE_VAR_ELEMENT_ERROR',
  'REQ_DELETE_VAR_ELEMENT_SUCCESS',
  'REQ_DELETE_VAR_ELEMENT_SHARE',
  'REQ_DELETE_VAR_ELEMENT_SHARE_ERROR',
  'REQ_DELETE_VAR_ELEMENT_SHARE_SUCCESS',
  'REQ_UPDATE_VAR_ELEMENT',
  'REQ_UPDATE_VAR_ELEMENT_ERROR',
  'REQ_UPDATE_VAR_ELEMENT_SUCCESS',
  'REQ_VAR_ELEMENTS',
  'REQ_VAR_ELEMENTS_ERROR',
  'REQ_VAR_ELEMENTS_SUCCESS',
], 'variableElement')

/**
 * Dispatches request to create new variable element.
 *
 * @param {Object}    elementData
 *    @param {Array}  aminoProbabilities (required)
 *    @param {String} name (required)
 * @return {Promise}  resolves to new element
 */
export function reqCreateVariableElement ({elementData}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_VAR_ELEMENT,
      actions.REQ_CREATE_VAR_ELEMENT_SUCCESS,
      actions.REQ_CREATE_VAR_ELEMENT_ERROR,
    ],
    endpoint: api,
    requestOpts: {
      data: {
        aminoProbabilities: elementData.aminoProbabilities,
        name: elementData.name,
      },
      method: 'POST',
    },
    schemaType: 'variableElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to create access share for variable element.
 *
 * @param {Number}    elementId
 * @param {Object}    shareData
 *    @param {String}   access
 *    @param {String}   username
 * @return {Promise}  resolves to updated element
 */
export function reqCreateVariableElementShare (elementId, shareData) {
  return createApiAction({
    actionTypes: [
      actions.REQ_CREATE_VAR_ELEMENT_SHARE,
      actions.REQ_CREATE_VAR_ELEMENT_SHARE_SUCCESS,
      actions.REQ_CREATE_VAR_ELEMENT_SHARE_ERROR,
    ],
    endpoint: `${api}/${elementId}/share`,
    requestOpts: {
      data: shareData,
      method: 'POST',
    },
    schemaType: 'variableElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to delete a variable element.
 *
 * @param {Number}    elementId
 * @return {Promise}  resolves to id of deleted element
 */
export function reqDeleteVariableElement (elementId) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_VAR_ELEMENT,
      actions.REQ_DELETE_VAR_ELEMENT_SUCCESS,
      actions.REQ_DELETE_VAR_ELEMENT_ERROR,
    ],
    endpoint: `${api}/${elementId}`,
    isDestructive: true,
    requestOpts: {
      method: 'DELETE',
    },
    schemaType: 'variableElement',
  })
}

/**
 * Dispatches request to delete an access share to a variable element.
 *
 * @param {Number}    elementId
 * @param {String}    shareUsername
 * @return {Promise}  resolves to updated element
 */
export function reqDeleteVariableElementShare (elementId, shareUsername) {
  return createApiAction({
    actionTypes: [
      actions.REQ_DELETE_VAR_ELEMENT_SHARE,
      actions.REQ_DELETE_VAR_ELEMENT_SHARE_SUCCESS,
      actions.REQ_DELETE_VAR_ELEMENT_SHARE_ERROR,
    ],
    endpoint: `${api}/${elementId}/share`,
    requestOpts: {
      data: {username: shareUsername},
      method: 'DELETE',
    },
    schemaType: 'variableElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request to update a variable element.
 *
 * @param {Number}    elementId
 * @param {Object}    elementData
 *    @param {Array}    aminoProbabilities
 *    @param {String}   name
 * @return {Promise}  resolves to updated element
 */
export function reqUpdateVariableElement ({elementId, elementData}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_UPDATE_VAR_ELEMENT,
      actions.REQ_UPDATE_VAR_ELEMENT_SUCCESS,
      actions.REQ_UPDATE_VAR_ELEMENT_ERROR,
    ],
    endpoint: `${api}/${elementId}`,
    requestOpts: {
      data: {
        aminoProbabilities: elementData.aminoProbabilities,
        name: elementData.name,
      },
      method: 'PUT',
    },
    schemaType: 'variableElement',
    transformResponse: addAuthUsername,
  })
}

/**
 * Dispatches request for user's variable elements.
 *
 * @return {Promise}  resolves to array of elements
 */
export function reqVariableElements () {
  return createApiAction({
    actionTypes: [
      actions.REQ_VAR_ELEMENTS,
      actions.REQ_VAR_ELEMENTS_SUCCESS,
      actions.REQ_VAR_ELEMENTS_ERROR,
    ],
    endpoint: api,
    schemaType: 'variableElementArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}
