/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-find-dom-node */

import { bool, func } from 'prop-types'
import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { findDOMNode } from 'react-dom'

import { DragTypes } from 'typeConstants'

/**
 * Returns a HOC that connects React DND drag capabilities to the
 * TargetComponent.
 *
 * @param {Object}  dragContract
 * @return {Func}   hoc
 */
export function withDrag (dragContract) {
  return (TargetComponent) => {
    const connectDrag = DragSource(
      DragTypes.ELEMENT,
      dragContract,
      (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        dragging: monitor.isDragging(),
      }),
    )

    class WithDrag extends Component {
      static propTypes = {
        connectDragPreview: func.isRequired,
        connectDragSource: func.isRequired,
        dragging: bool.isRequired,
      }

      static displayName = `withDrag(${TargetComponent})`

      componentDidMount () {
        const { connectDragPreview } = this.props

        // Use empty image as a drag preview so browsers don't draw it
        // and we can draw whatever we want on the custom drag layer instead.
        if (connectDragPreview) {
          connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
          })
        }
      }

      render () {
        const { connectDragSource, ...props } = this.props

        return (
          <TargetComponent
            {...props}
            ref={ref => connectDragSource(findDOMNode(ref))}
          />
        )
      }
    }

    return connectDrag(WithDrag)
  }
}

/**
 * Returns a HOC that connects React DND drop capabilities to the
 * TargetComponent.
 *
 * @param {Object}  dropContract
 * @return {Func}   hoc
 */
export function withDrop (dropContract) {
  return (TargetComponent) => {
    const connectDrop = DropTarget(
      DragTypes.ELEMENT,
      dropContract,
      (connect, monitor) => ({
        clearDragItemIndex: () => {
          const dragItem = monitor.getItem()

          if (dragItem && dragItem.index > -1) {
            dragItem.index = undefined
          }
        },
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver({ shallow: false }),
      }),
    )

    class WithDrop extends Component {
      static propTypes = {
        clearDragItemIndex: func.isRequired,
        connectDropTarget: func.isRequired,
        isOver: bool.isRequired,
      }

      static displayName = `withDrop(${TargetComponent})`

      render () {
        const { connectDropTarget, ...props } = this.props

        return (
          <TargetComponent
            {...props}
            ref={ref => connectDropTarget(findDOMNode(ref))}
          />
        )
      }
    }

    return connectDrop(WithDrop)
  }
}
