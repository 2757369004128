import React from 'react'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import cx from 'classnames'

import FontAwesomeIcon from 'components/FontAwesomeIcon'
import FontAwesomeButton from 'components/FontAwesomeButton'
import RoundOutlineButton from 'components/RoundOutlineButton'
import cn from './User.css'

export default function User ({
  accessTypes,
  onAccessChange,
  onAccessDelete,
  processing,
  username,
}) {
  return (
    <div className={cn.base}>
      <FontAwesomeIcon
        className={cx(cn.icon, {[cn.iconShow]: processing})}
        icon={processing ? 'spinner' : 'user-circle'}
        spin={processing}
      />

      <span className={cn.name}>
        {username}
      </span>

      <div className={cn.access}>
        {accessTypes.map(type => (
          <RoundOutlineButton
            className={cx(cn.accessBtn, {[cn.accessBtnActive]: type.active})}
            disabled={processing}
            key={type.key}
            name='access'
            onClick={onAccessChange}
            value={type.value}
          >
            {type.label}
          </RoundOutlineButton>
        ))}
      </div>

      <FontAwesomeButton
        className={cn.deleteBtn}
        disabled={processing}
        icon='trash'
        labelClass={cn.deleteBtnLabel}
        onClick={onAccessDelete}
      />
    </div>
  )
}

User.propTypes = {
  accessTypes: arrayOf(shape({
    active: bool,
    key: string.isRequired,
    label: string.isRequired,
    value: string.isRequired,
  })),
  onAccessChange: func.isRequired,
  onAccessDelete: func.isRequired,
  processing: bool,
  username: string.isRequired,
}

User.defaultProps = {
  processing: false,
}
