import React from 'react'
import {bool, func, number, object, string} from 'prop-types'
import cx from 'classnames'

import Slider from './AminoSlidersSlider'
import cn from './AminoSliders.css'

AminoSliders.propTypes = {
  aminos: object,
  className: string,
  disabled: bool,
  limit: number,
  onUpdateSlider: func.isRequired,
}

AminoSliders.defaultProps = {
  aminos: {},
  className: '',
  disabled: false,
  limit: 100,
}

export default function AminoSliders ({
  aminos,
  className,
  disabled,
  limit,
  onUpdateSlider,
}) {
  return (
    <div className={cx(cn.base, className)}>
      {Object.keys(aminos).map(key => (
        <Slider
          color={aminos[key].color}
          disabled={disabled}
          id={key}
          key={key}
          label={aminos[key].label}
          limit={limit + aminos[key].value}
          onUpdate={onUpdateSlider}
          value={aminos[key].value}
        />
      ))}
    </div>
  )
}
