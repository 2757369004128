export const MAX_VARIATIONS = 100
export const MAX_TEMPLATES = 10

export const ElementTypes = {
  CUSTOM: 'custom',
  CUSTOM_DNA: 'custom.dna',
  CUSTOM_AMINO: 'custom.amino',
  VARIABLE: 'variable',
  BARCODE: 'barcode',
  BARCODE_50: 'barcode.50',
  BARCODE_100: 'barcode.100',
  ENZYME: 'enzyme',
  IGEM: 'igem',
  NCBI: 'ncbi',
}

export const VariableTypes = [
  ElementTypes.VARIABLE,
  ElementTypes.BARCODE,
  ElementTypes.BARCODE_50,
  ElementTypes.BARCODE_100,
]

export const PanelTypes = {
  IGEM: ElementTypes.IGEM,
  NCBI: ElementTypes.NCBI,
  ENZYME: ElementTypes.ENZYME,
  CUSTOM_DNA: ElementTypes.CUSTOM_DNA,
  VARIABLE: ElementTypes.VARIABLE,
  BARCODE: ElementTypes.BARCODE,
  FORM: 'form',
  SEQUENCES_PREVIEW: 'seqpreview',
  DETAIL: 'detail',
}

export const ModalTypes = {
  ELEMENT_FORM: 'elementForm',
}

export const DefaultSearches = {
  NCBI: 'Pten CDS',
  IGEM: 'T7 promoter',
  ENZYME: 'EcoR',
  CUSTOMDNA: 'Pten C-terminal truncated',
}

export const ToastTypes = {
  success: '@@toast/SUCCESS_TOAST',
  error: '@@toast/ERROR_TOAST',
  info: '@@toast/INFO_TOAST',
  warning: '@@toast/WARNING_TOAST',
}

export const DragTypes = {
  ELEMENT: '@@drag/ELEMENT',
}

/**
 *  Used in Select field where the value is the displayed text and the key is the value of the item.
 *  CRITICAL: the keys need to match the ones in ExpressionSystem Enum in bio.expression.py
 */
export const ExpressionSystem = {
  none: 'None',
  human: 'Human',
  yeast: 'Yeast',
  ecoli: 'Ecoli',
  mouse: 'Mouse',
}

export const accessTypes = {
  view: 'r',
  edit: 'rw',
  admin: 'rwa',
  own: 'rwao',
}

export const GroupAccessTypes = {
  r: 'View Only',
  rw: 'Member',
  rwa: 'Admin',
}

// These should reflect the different breakpoints found in styles/variables.css.
export const screenTypes = {
  desktopLg: 1199,
  desktop: 991,
  tablet: 768,
  mobile: 575,
}

// Access types.
export const accessOptions = {
  default: [
    {label: 'View Only', primaryText: 'View Only', value: accessTypes.view},
    {label: 'View/Edit', primaryText: 'View/Edit', value: accessTypes.edit},
  ],
  group: [
    {label: 'View Only', primaryText: 'View Only', value: accessTypes.view},
    {label: 'Member', primaryText: 'Member', value: accessTypes.edit},
    {label: 'Admin', primaryText: 'Admin', value: accessTypes.admin},
  ],
}
