import React from 'react'
import {func, string} from 'prop-types'

import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'

export default function ConfirmDeleteModal ({
  modalSubtitle,
  modalTitle,
  onAccept,
  onCancel,
}) {
  return (
    <ModalLayout
      acceptButtonLabel='Delete'
      onAccept={onAccept}
      onClose={onCancel}
      size='small'
    >
      <ModalIconHeader
        icon='trash'
        subtitle={modalSubtitle}
        title={modalTitle}
      />
    </ModalLayout>
  )
}

ConfirmDeleteModal.propTypes = {
  modalSubtitle: string,
  modalTitle: string,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
}

ConfirmDeleteModal.defaultProps = {
  modalSubtitle: 'Confirm the deletion of this item.',
  modalTitle: 'Delete Item',
}
