import cx from 'classnames'
import {
  bool,
  func,
  node,
  number,
  string,
} from 'prop-types'
import React, { Component } from 'react'

import { Color, ElementContext } from 'common'
import FAIcon from 'components/FontAwesomeIcon'
import { noop } from 'utils'
import { ElementContextType } from 'types'

import DNAIcon from './DNAIcon'
import ElementSize from './ElementSize'
import cn from './Element.css'

class DelayedSpinner extends Component {
  static propTypes = {
    delay: number,
  }

  static defaultProps = {
    delay: 350,
  }

  state = {
    show: false,
  }

  componentDidMount () {
    const { delay } = this.props

    this.timer = setTimeout(() => {
      this.setState({ show: true })
    }, delay)
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
  }

  render () {
    const { show } = this.state

    return show && (
      <div className={cn.loading_overlay}>
        <FAIcon icon="circle-o-notch" spin />
      </div>
    )
  }
}

const AAStyle = {
  background: Color.AMINO_BG,
  color: Color.AMINO_FG,
}

const DNAStyle = {
  background: Color.DNA_BG,
  color: Color.DNA_FG,
}

export default function Element ({
  elementContext,
  elementFunction,
  hasMappedDNA,
  lengthText,
  loading,
  name,
  onDNAMap,
  selected,
}) {
  const isAA = elementContext === ElementContext.AA

  return (
    <div
      className={cx(cn.body, {
        [cn.body__highlight]: selected,
        [cn.body_symbol]: isAA,
      })}
      style={ElementSize}
    >
      <div className={cn.context} style={isAA ? AAStyle : DNAStyle}>
        <div className={cn.context_body}>
          <div className={cn.context__text}>
            {elementContext}
          </div>
        </div>
      </div>

      <div className={cn.body_top}>
        <div className={cn.name}>
          {name}
        </div>
      </div>

      <div
        className={cx(cn.body_bottom, {
          [cn.body_bottom__mapped]: isAA && hasMappedDNA,
        })}
      >
        <div className={cn.length}>
          {lengthText}
        </div>

        {elementFunction && (
          <div className={cn.func}>
            {elementFunction}
          </div>
        )}
      </div>

      {isAA && (
        <div className={cn.symbol}>
          <button
            className={cn.symbol_wrapper}
            onClick={onDNAMap}
            type="button"
          >
            <DNAIcon mapped={hasMappedDNA} />

            {!hasMappedDNA && (
              <div className={cn.warning}>
                <FAIcon className={cn.warning_icon} icon="exclamation-triangle" />
              </div>
            )}
          </button>
        </div>
      )}

      {loading && <DelayedSpinner />}
    </div>
  )
}

Element.propTypes = {
  elementContext: ElementContextType.isRequired,
  elementFunction: node,
  hasMappedDNA: bool,
  lengthText: string.isRequired,
  loading: bool,
  name: string.isRequired,
  onDNAMap: func,
  selected: bool,
}

Element.defaultProps = {
  elementFunction: null,
  hasMappedDNA: false,
  loading: false,
  onDNAMap: noop,
  selected: false,
}
