import React from 'react'
import {number, shape} from 'prop-types'
import isNil from 'lodash/isNil'

import cn from './SequenceEditorDetailsBar.css'

export default function SequenceEditorDetailsBar ({details}) {
  return (
    <div className={cn.base}>
      {Object.keys(details).map(key => (
        isNil(details[key])
          ? null
          : (
            <div className={cn.detail} key={key}>
              <span className={cn.statusKey}>
                {`${key}:`}
              </span>
              <span className={cn.statusValue}>
                {details[key]}
              </span>
            </div>
          )
      ))}
    </div>
  )
}

SequenceEditorDetailsBar.propTypes = {
  /**
   * Below are the typical statuses that we want to display to the user,
   * although any other key/value pairs can be added.
   */
  details: shape({
    cursor: number,
    end: number,
    length: number,
    start: number,
  }),
}

SequenceEditorDetailsBar.defaultProps = {
  details: {},
}
