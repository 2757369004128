/* eslint-disable */

/**
 * Codon map to their corresponding aminos.
 */
export default {
  ttt: 'f', ttc: 'f',
  tta: 'l', ttg: 'l', ctt: 'l', ctc: 'l', cta: 'l', ctg: 'l',
  att: 'i', atc: 'i', ata: 'i',
  atg: 'm',
  gtt: 'v', gtc: 'v', gta: 'v', gtg: 'v',
  tct: 's', tcc: 's', tca: 's', tcg: 's', agt: 's', agc: 's',
  cct: 'p', ccc: 'p', cca: 'p', ccg: 'p',
  act: 't', acc: 't', aca: 't', acg: 't',
  gct: 'a', gcc: 'a', gca: 'a', gcg: 'a',
  tat: 'y', tac: 'y',
  cat: 'h', cac: 'h',
  caa: 'q', cag: 'q',
  aat: 'n', aac: 'n',
  aaa: 'k', aag: 'k',
  gat: 'd', gac: 'd',
  gaa: 'e', gag: 'e',
  tgt: 'c', tgc: 'c',
  tgg: 'w',
  cgt: 'r', cgc: 'r', cga: 'r', cgg: 'r', aga: 'r', agg: 'r',
  ggt: 'g', ggc: 'g', gga: 'g', ggg: 'g',
}
