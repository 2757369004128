import {connect} from 'react-redux'

import {reqCreateVariableElement, reqUpdateVariableElement} from 'actions/variableElement'

export default connect(null, {
  onElementCreate: reqCreateVariableElement,
  onElementUpdate: reqUpdateVariableElement,
}, (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  disabled: ownProps.modalType === 'clone',
  onModalAccept: ownProps.modalType === 'new' || ownProps.modalType === 'clone'
    ? dispatchProps.onElementCreate
    : dispatchProps.onElementUpdate
}))
