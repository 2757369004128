import React, {Component} from 'react'
import {func} from 'prop-types'

import FontAwesomeIcon from 'components/FontAwesomeIcon'
import cn from './ElementExplorerLoader.css'

export default class ElementExplorerLoader extends Component {
  static propTypes = {
    onFetch: func,
  }

  static defaultProps = {
    onFetch: () => console.debug('Callback on mount.'),
  }

  componentDidMount () {
    this.props.onFetch()
  }

  render () {
    return (
      <div className={cn.base}>
        <FontAwesomeIcon icon='spinner' size='3x' spin />
      </div>
    )
  }
}
