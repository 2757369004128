import React, {Component} from 'react'
import {arrayOf, bool, func, number, object, oneOf, string} from 'prop-types'

import {getAminoMap, getMapLimit, matchPreset} from 'utils/aminoMap'
import generateId from 'utils/generateId'
import VariableElement from './VariableElement'

export default class VariableElementContainer extends Component {
  static propTypes = {
    aminoProbabilities: arrayOf(object),
    disabled: bool,
    id: number,
    modalType: oneOf(['new', 'clone', 'edit']).isRequired,
    name: string,
    onModalCancel: func.isRequired,
    onModalAccept: func.isRequired,
  }

  static defaultProps = {
    aminoProbabilities: [{}],
    disabled: false,
    disableStacks: false,
    id: null,
    name: '',
    submitText: 'Create',
  }

  constructor (props) {
    super(props)

    const {aminoProbabilities, name} = props
    // Shape stacks and build ids to track them. This allows us to more easily
    // apply changes to stacks independent of each other as well as hold meta
    // data regarding each stack.
    const {ids, stacks} = aminoProbabilities.reduce((acc, map) => {
      const id = generateId()

      return {
        ids: acc.ids.concat(id),
        stacks: {
          ...acc.stacks,
          [id]: this.shapeStack(id, getAminoMap(map)),
        },
      }
    }, {ids: [], stacks: {}})

    // Use first stack as initial active.
    const activeId = ids[0]
    const activeMap = stacks[activeId].aminos

    this.state = {
      activeId,
      activeMap,
      name,
      preset: matchPreset(activeMap),
      stackIds: ids,
      stacks,
      submitting: false,
    }
  }

  get modalAcceptText () {
    return this.props.modalType === 'edit' ? 'Save' : 'Create'
  }

  get modalSubtitle () {
    return this.props.modalType === 'edit'
      ? 'Make changes to the details of this element.'
      : this.props.modalType === 'clone'
        ? 'Make a copy of this element to edit.'
        : 'Create a new element with customized details.'
  }

  get modalTitle () {
    return this.props.modalType === 'edit'
      ? 'Edit Element'
      : this.props.modalType === 'clone'
        ? 'Clone Element'
        : 'Create Element'
  }

  /**
   * Returns the error text for any conditions that aren't met.
   * @param  {String}   name   Element name
   * @param  {[Object]} stacks Amino stacks
   * @return {String}          Error text
   */
  errorText (name, stacks) {
    if (!name) {
      return 'Name required'
    }
    else if (this.incomplete(stacks)) {
      return 'Incomplete stacks'
    }
    else {
      return ''
    }
  }

  /**
   * Returns if the element has any unfilled stacks.
   * @param  {[Object]} stacks        Amino stacks
   * @param  {Object}   stacks.aminos Amino map
   * @return {Boolean}                If element has unfilled stacks
   */
  incomplete (stacks) {
    return stacks.filter(({aminos}) => getMapLimit(aminos) > 0).length > 0
  }

  /**
   * Returns a stack object in the shape required by our components to render
   * correctly.
   * @param  {String} id  Stack id
   * @param  {Object} map Amino map
   * @return {Object}     Stack object
   */
  shapeStack (id, map) {
    return {
      aminos: map,
      id,
      incomplete: getMapLimit(map) > 0,
    }
  }

  handleFieldChange = ({target}) => {
    this.setState({[target.name]: target.value})
  }

  handleStackAdd = () => {
    const id = generateId()
    const map = getAminoMap()

    this.setState({
      activeId: id,
      activeMap: map,
      preset: '',
      stackIds: this.state.stackIds.concat(id),
      stacks: {
        ...this.state.stacks,
        [id]: this.shapeStack(id, map),
      },
    })
  }

  /**
   * Changes the name of the element.
   * @param  {Object} e onChange event
   */
  changeName = (e) => {
    this.setState({name: e.target.value})
  }

  /**
   * Deletes the stack that matches to the passed in stackId. The edge case we
   * have to take care of here is enforcing that the user always has at least 1\
   * stack. Also since only the active stack can be deleted currently, we have
   * to handle adjusting the active stack id and map to the stack that precedes
   * the one being deleted.
   * @param  {String} stackId Amino stack id
   */
  handleStackDelete = (stackId) => {
    const {stackIds, stacks} = this.state

    // At least 1 stack must exist.
    if (stackIds.length < 2) {
      return
    }

    // Filter out the deleted stack's id.
    const ids = stackIds.filter(id => id !== stackId)
    // Get the index of the stack id in order to get the preceding index number.
    const idIndex = stackIds.indexOf(stackId)
    // Move to the id and corresponding map previous to the stack being deleted.
    const prevId = stackIds[idIndex - 1]
    const map = stacks[prevId].aminos

    this.setState({
      activeId: prevId,
      activeMap: map,
      // Check for matching preset.
      preset: matchPreset(map),
      stackIds: ids,
      // Use the filtered ids to create a new stacks object without the deleted
      // stack data.
      stacks: ids.reduce((acc, id) => ({
        ...acc,
        [id]: stacks[id],
      }), {}),
    })
  }

  handleStackReset = () => {
    const {activeId, stacks} = this.state
    const map = getAminoMap()

    this.setState({
      activeMap: map,
      // See: aminoMap.js for preset values. Empty string should be value for
      // 'None'.
      preset: '',
      stacks: {
        ...stacks,
        [activeId]: this.shapeStack(activeId, map),
      },
    })
  }

  handlePresetChange = (preset, map) => {
    const {activeId, stacks} = this.state

    this.setState({
      activeMap: map,
      preset,
      stacks: {
        ...stacks,
        [activeId]: this.shapeStack(activeId, map),
      },
    })
  }

  handleStackSelect = (id) => {
    const map = this.state.stacks[id].aminos

    this.setState({
      activeId: id,
      activeMap: map,
      // Check for matching preset.
      preset: matchPreset(map),
    })
  }

  handleAminoMapUpdate = (id, value) => {
    const {activeId, activeMap, stacks} = this.state

    // Update map
    const map = {
      ...activeMap,
      [id]: {
        ...activeMap[id],
        value,
      },
    }

    this.setState({
      activeMap: map,
      // Check for matching preset.
      preset: matchPreset(map),
      stacks: {
        ...stacks,
        [activeId]: this.shapeStack(activeId, map),
      },
    })
  }

  /**
   * Handles the submission of the variable element in state. Before sending the
   * element data, we need to destructure the shape of the amino probabilities.
   * We only need to store their probability values since all other meta data
   * related to the aminos (ex. color, codons) are stored externally. So long as
   * we have the correct keys and values, we can reconstruct the amino shape
   * here on the client upon request.
   *
   * NOTE: Make sure to flag pending in order to properly disable the submit
   * button to prevent accidental repeat submissions.
   */
  handleModalAccept = () => {
    const {disabled, id: elementId, onModalCancel, onModalAccept} = this.props
    const {name, stackIds, stacks, submitting} = this.state

    // Double check for any errors.
    const aminoStacks = stackIds.map(id => stacks[id])

    if (disabled || submitting || this.errorText(name, aminoStacks)) {
      return
    }

    const elementData = {
      name,
      // Destructure aminos.
      aminoProbabilities: stackIds.map(id => (
        Object.keys(stacks[id].aminos).reduce((acc, key) => ({
          ...acc,
          [key]: {value: stacks[id].aminos[key].value},
        }), {})
      )),
    }

    this.setState({submitting: true})

    Promise.resolve(onModalAccept({elementId, elementData}))
      // Automatically close the editor upon successful submissions.
      .then(() => {
        onModalCancel()
      })
      // Unflag submitting on errors.
      .catch(() => {
        this.setState({submitting: false})
      })
  }

  render () {
    const {disabled, onModalCancel} = this.props
    const {
      activeId,
      activeMap,
      name,
      preset,
      stackIds,
      stacks,
      submitting,
    } = this.state
    const aminoStacks = stackIds.map(id => ({
      ...stacks[id],
      active: id === activeId,
    }))

    return (
      <VariableElement
        activeAminoMap={activeMap}
        activeAminoMapLimit={getMapLimit(activeMap)}
        activePreset={preset}
        aminoStacks={aminoStacks}
        disableAccept={submitting || !!this.errorText(name, aminoStacks)}
        disabled={disabled}
        modalAcceptText={this.modalAcceptText}
        modalSubtitle={this.modalSubtitle}
        modalTitle={this.modalTitle}
        name={name}
        onAminoMapUpdate={this.handleAminoMapUpdate}
        onFieldChange={this.handleFieldChange}
        onModalAccept={this.handleModalAccept}
        onModalCancel={onModalCancel}
        onPresetChange={this.handlePresetChange}
        onStackAdd={this.handleStackAdd}
        onStackDelete={this.handleStackDelete}
        onStackReset={this.handleStackReset}
        onStackSelect={this.handleStackSelect}
      />
    )
  }
}
