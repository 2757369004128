import React from 'react'
import {func} from 'prop-types'

import ProjectCommitContainer from 'components/ProjectCommitContainer'
import ProjectCommitModal from './ProjectCommitModal'

export default function ProjectCommitModalContainer ({onClose}) {
  return (
    <ProjectCommitContainer onProjectCommitSuccess={onClose}>
      {({
        commitMessage,
        disableAccept,
        onChangeCommitMessage,
        onProjectCommit,
      }) => (
        <ProjectCommitModal
          acceptButtonLabel={!disableAccept ? 'Commit' : 'Commit message required'}
          commitMessage={commitMessage}
          disableAccept={disableAccept}
          onAccept={onProjectCommit}
          onCancel={onClose}
          onChangeCommitMessage={onChangeCommitMessage}
        />
      )}
    </ProjectCommitContainer>
  )
}

ProjectCommitModalContainer.propTypes = {
  onClose: func.isRequired,
}
