import React, { Component } from 'react'
import { bool, func, string } from 'prop-types'
import { connect } from 'react-redux'

import { reqAWSFileUrl } from 'actions/authUser'
import { setElementContext } from 'actions/designer'
import { openModal } from 'actions/modal'
import { getIfFetchingAWSFile } from 'selectors/authUser'
import {
  getHasUnsavedChanges,
  getIsGeneratingSequences,
  getProjectId,
  getSequenceKeypath,
} from 'selectors/designer'
import { getIfUserCanEditProject } from 'selectors/project'

import ProjectActionButtons from './ProjectActionButtons'

class ProjectActionButtonsContainer extends Component {
  static propTypes = {
    disabled: bool,
    downloadingSequences: bool,
    generatingSequences: bool,
    hasUnsavedChanges: bool,
    onContextChange: func.isRequired,
    onModalOpen: func.isRequired,
    onSequenceDownload: func.isRequired,
    sequenceFilepath: string.isRequired,
  }

  static defaultProps = {
    disabled: false,
    downloadingSequences: false,
    generatingSequences: false,
    hasUnsavedChanges: false,
  }

  handleCommitClick = () => {
    this.openModal('commit')
  }

  handleForkClick = () => {
    this.openModal('fork')
  }

  handleSequenceDownloadClick = () => {
    const { onSequenceDownload, sequenceFilepath } = this.props

    onSequenceDownload(sequenceFilepath)
  }

  openModal (modalType) {
    const { onModalOpen } = this.props

    onModalOpen(modalType)
  }

  render () {
    const {
      disabled,
      downloadingSequences,
      generatingSequences,
      hasUnsavedChanges,
      onContextChange,
      sequenceFilepath,
    } = this.props

    const disableCommit = disabled || !hasUnsavedChanges || generatingSequences

    const disableSequenceDownload = disabled || downloadingSequences || !sequenceFilepath

    return (
      <ProjectActionButtons
        disableCommit={disableCommit}
        disableFork={disabled}
        disableSequenceDownload={disableSequenceDownload}
        generatingSequences={generatingSequences}
        onCommitClick={this.handleCommitClick}
        onContextChange={onContextChange}
        onForkClick={this.handleForkClick}
        onSequenceDownloadClick={this.handleSequenceDownloadClick}
      />
    )
  }
}

const mapState = state => ({
  disabled: !getIfUserCanEditProject(state, { id: getProjectId(state) }),
  downloadingSequences: getIfFetchingAWSFile(state),
  generatingSequences: getIsGeneratingSequences(state),
  hasUnsavedChanges: getHasUnsavedChanges(state),
  sequenceFilepath: getSequenceKeypath(state) || '',
})

const mapDispatch = {
  onContextChange: setElementContext,
  onModalOpen: openModal,
  onSequenceDownload: reqAWSFileUrl,
}

export { ProjectActionButtonsContainer }

export default connect(mapState, mapDispatch)(ProjectActionButtonsContainer)
