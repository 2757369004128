/* eslint-disable react/no-find-dom-node */

import cx from 'classnames'
import {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string
} from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'

import { noop } from 'utils'

import ComposedTab from './Tab'
import cn from './TabHeader.css'

const getUnderlineStyle = (node, rootNode) => {
  const { left, width } = node.getBoundingClientRect()
  const { left: rootLeft } = rootNode.getBoundingClientRect()

  return {
    transform: `translate(${left - rootLeft}px, 0)`,
    width,
  }
}

/**
 * @export
 * @component
 */
export default class TabHeader extends Component {
  static propTypes = {
    /**
     * Active tab's index.
     */
    activeIndex: number,
    /**
     * Class for root element.
     */
    className: string,
    disabled: bool,
    /**
     * Changes tab.
     */
    onChangeTab: func,
    /**
     * List of tab nav options.
     */
    tabs: arrayOf(shape({
      isDisabled: bool,
      label: string,
    })),
  }

  static defaultProps = {
    activeIndex: 0,
    className: '',
    disabled: false,
    onChangeTab: noop,
    tabs: [],
  }

  state = {
    styleUnderline: {},
  }

  componentDidMount () {
    this.setUnderlineStyle()

    window.addEventListener('resize', this.setUnderlineStyle)
  }

  componentDidUpdate ({ activeIndex: prevActiveIndex }) {
    const { activeIndex } = this.props

    if (activeIndex !== prevActiveIndex) {
      this.setUnderlineStyle()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setUnderlineStyle)
  }

  cacheNode = key => (ref) => {
    this[key] = ref
  }

  setUnderlineStyle = () => {
    if (this.active && this.node) {
      const style = getUnderlineStyle(
        findDOMNode(this.active),
        findDOMNode(this.node),
      )

      this.setState({ styleUnderline: style })
    }
  }

  render () {
    const {
      activeIndex,
      className,
      disabled,
      onChangeTab,
      tabs,
    } = this.props
    const { styleUnderline } = this.state

    return (
      <div className={cx(cn.root, className)} ref={this.cacheNode('node')}>
        <div className={cn.tabs}>
          {
            tabs.map((tab, index) => (
              <ComposedTab
                index={index}
                isActive={index === activeIndex}
                isDisabled={disabled || tab.isDisabled}
                key={tab.label}
                onSelect={onChangeTab}
                ref={index === activeIndex ? this.cacheNode('active') : null}
              >
                {tab.label}
              </ComposedTab>
            ))
          }
        </div>
        <div
          className={cx(cn.underline, { [cn.underline_disabled]: disabled })}
          style={styleUnderline}
        />
      </div>
    )
  }
}
