import cx from 'classnames'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'

import BaseButton from 'components/buttons/BaseButton'
import FaIcon from 'components/FontAwesomeIcon'
import Dropdown from 'components/toggables/Dropdown'
import cn from './Select.css'

const getActiveLabel = (value, options) => (
  options.reduce((label, option) => {
    if (label) {
      return label
    }

    if (option.value !== value) {
      return ''
    }

    return option.activeText || option.label
  }, '')
)

export default class Select extends Component {
  static propTypes = {
    className: string,
    disabled: bool,
    menuClass: string,
    name: string,
    onChange: func.isRequired,
    optionClass: string,
    options: arrayOf(shape({
      activeText: oneOfType([number, string]),
      key: oneOfType([number, string]),
      label: string.isRequired,
      value: oneOfType([number, string]).isRequired,
    })),
    tipClass: string,
    toggleClass: string,
    value: oneOfType([number, string]).isRequired,
  }

  static defaultProps = {
    className: '',
    disabled: false,
    menuClass: '',
    name: '',
    optionClass: '',
    options: [],
    tipClass: '',
    toggleClass: '',
  }

  state = {
    open: false,
  }

  /**
   * Closes the menu. A small delay has to be added to allow the link to fire
   * before we update the menu state.
   */
  hideOptions = () => {
    setTimeout(() => {
      this.setState({ open: false })
    }, 200)
  }

  toggleOptions = () => {
    const { disabled } = this.props
    const { open } = this.state

    if (disabled) {
      return
    }

    this.setState({ open: !open })
  }

  render () {
    const {
      className,
      disabled,
      menuClass,
      name,
      onChange,
      optionClass,
      options,
      tipClass,
      toggleClass,
      value,
    } = this.props

    const { open } = this.state

    return (
      <div className={cx(cn.base, className)}>
        <input className={cn.input} type="hidden" value={value} />
        <BaseButton
          bodyClass={cn.toggleBody}
          className={cx(cn.toggle, toggleClass)}
          disabled={disabled}
          onBlur={this.hideOptions}
          onClick={this.toggleOptions}
        >
          <span className={cn.toggleLabel}>
            {getActiveLabel(value, options)}
          </span>
          <FaIcon
            className={cn.toggleCaret}
            icon="caret-down"
            title="Toggle options menu"
          />
        </BaseButton>
        <Dropdown
          className={cx(cn.menu, menuClass)}
          tipClass={tipClass}
          open={open}
        >
          {options.map((option, i) => (
            <BaseButton
              bodyClass={cn.optionBody}
              className={cx(cn.option, optionClass, { [cn.active]: option.value === value })}
              disabled={!open}
              key={option.label}
              name={name || option.name}
              onClick={e => onChange(e, option.key, option.value, i)}
              value={option.value}
            >
              {option.label}
            </BaseButton>
          ))}
        </Dropdown>
      </div>
    )
  }
}
