export const actions = {
  closeModal: '@modal/CLOSE_MODAL',
  openModal: '@modal/OPEN_MODAL',
}

export function closeModal () {
  return {type: actions.closeModal}
}

export function openModal (type = '', modalProps = {}) {
  return {
    type: actions.openModal,
    modalType: type,
    modalProps,
  }
}
