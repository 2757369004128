import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import React from 'react'

import noop from 'utils/noop'

import HashmarkCounter from '../HashmarkCounter'
import Tag from '../Tag'
import {
  CounterType,
  CursorType,
  RowStyleType,
  TagType,
} from '../types'

import Cursor from './Cursor'
import DiagonalStripPattern from './DiagonalStripePattern'
import RowGroupCounter from './RowGroupCounter'
import RowSizeUpdater from './RowSizeUpdater'
import cn from './classes.css'

export default function SequenceRow ({
  aminos,
  bodyHeight,
  counter,
  cursor,
  disabled,
  elements,
  invalidRegions,
  onBodyRefSet,
  onRowRefSet,
  onRowSizeUpdate,
  onSelectionContextMenu,
  onSelectionMove,
  onSelectionStart,
  onTagClick,
  sequence,
  style,
  tagRegions,
}) {
  const { height, width } = style

  const groupCounter = new RowGroupCounter()

  return (
    <RowSizeUpdater bodyHeight={height} onRowSizeUpdate={onRowSizeUpdate}>
      {() => (
        <div
          onMouseDown={onSelectionStart}
          onMouseMove={onSelectionMove}
          role="button"
          style={style}
          tabIndex={0}
        >
          <svg className={cn.svg} height={height} width={width}>
            <DiagonalStripPattern />

            <g ref={onRowRefSet} transform="translate(0, 10)">
              <g ref={onBodyRefSet}>
                <g transform="translate(0, 2)">
                  {invalidRegions.map(({ tag, style: tagStyle }) => (
                    <Tag
                      key={tag.id}
                      style={tagStyle}
                      tag={tag}
                    />
                  ))}
                </g>

                {aminos.length > 0 && (
                  <g transform={`translate(0, ${groupCounter.getNewHeight()})`}>
                    {aminos.map(({ tag, style: aaStyle }) => (
                      <Tag key={tag.id} style={aaStyle} tag={tag} />
                    ))}
                  </g>
                )}

                <g transform={`translate(0, ${groupCounter.getNewHeight()})`}>
                  {elements.map(({ tag, style: tagStyle }) => (
                    <Tag
                      key={tag.id}
                      onClick={onTagClick}
                      style={tagStyle}
                      tag={tag}
                    />
                  ))}
                </g>

                {tagRegions.length > 0 && tagRegions.map(groups => (
                  <g transform={`translate(0, ${groupCounter.getNewHeight()})`}>
                    {groups.map(({ tag, style: tagStyle }) => (
                      <Tag
                        key={tag.id}
                        onClick={onTagClick}
                        style={tagStyle}
                        tag={tag}
                      />
                    ))}
                  </g>
                ))}

                <g>
                  <foreignObject height={16} width={width}>
                    <span className={cn.sequence}>
                      {sequence}
                    </span>
                  </foreignObject>
                </g>
              </g>

              <g transform={`translate(0, ${groupCounter.getNewHeight()})`}>
                <HashmarkCounter counter={counter} />
              </g>

              <g>
                {cursor.exists && (
                  <Cursor
                    isActive={!disabled}
                    isLine={cursor.isLine}
                    onSelectionContextMenu={onSelectionContextMenu}
                    showLeftBound={cursor.showLeftBound}
                    showRightBound={cursor.showRightBound}
                    style={{
                      ...cursor.style,
                      height: bodyHeight + 15,
                    }}
                  />
                )}
              </g>
            </g>
          </svg>
        </div>
      )}
    </RowSizeUpdater>
  )
}

SequenceRow.propTypes = {
  aminos: arrayOf(TagType).isRequired,
  bodyHeight: number,
  counter: CounterType.isRequired,
  cursor: CursorType.isRequired,
  disabled: bool,
  elements: arrayOf(TagType).isRequired,
  invalidRegions: arrayOf(TagType).isRequired,
  onBodyRefSet: func,
  onRowRefSet: func,
  onRowSizeUpdate: func,
  onSelectionContextMenu: func,
  onSelectionMove: func,
  onSelectionStart: func,
  onTagClick: func,
  sequence: string.isRequired,
  style: RowStyleType.isRequired,
  tagRegions: arrayOf(arrayOf(TagType)).isRequired,
}

SequenceRow.defaultProps = {
  bodyHeight: 0,
  disabled: false,
  onBodyRefSet: noop,
  onRowRefSet: noop,
  onRowSizeUpdate: noop,
  onSelectionContextMenu: noop,
  onSelectionMove: noop,
  onSelectionStart: noop,
  onTagClick: noop,
}
