import cx from 'classnames'
import { node, string } from 'prop-types'
import React from 'react'

import classes from './TooltipBlock.css'

// Map of valid tooltip positions to their corresponding CSS classes.
const classMap = {
  'bottom left': cx(classes.bottom, classes.left),
  'bottom right': cx(classes.bottom, classes.right),
  'top left': cx(classes.top, classes.left),
  'top right': cx(classes.top, classes.right),
}

/**
 * Returns the corresponding CSS class to a tooltip position.
 * @param  {String} pos Tooltip position
 * @return {String}     CSS class
 */
const getTooltipClassFromPosition = (pos) => {
  // Throw error for improper tooltip position.
  if (!classMap[pos]) {
    throw Error('Not a valid tooltip position. See TooltipBlock for more info')
  }

  return classMap[pos]
}

/**
 * A component that renders a block with a tooltip arrow.
 * @prop       {Node}   children        HTML node
 * @prop       {String} className       Custom class for root element
 * @prop       {String} tipClass        Custom class for tip element
 * @prop       {String} tooltipPosition Position of tooltip arrow
 */
export default function TooltipBlock ({
  children,
  className,
  tipClass,
  tooltipPosition,
}) {
  const positionClass = getTooltipClassFromPosition(tooltipPosition)

  return (
    <div className={cx(classes.base, className)}>
      <div className={cx(classes.tip, positionClass, tipClass)} />
      {children}
    </div>
  )
}

TooltipBlock.propTypes = {
  children: node.isRequired,
  className: string,
  tipClass: string,
  tooltipPosition: string.isRequired,
}

TooltipBlock.defaultProps = {
  className: '',
  tipClass: '',
}
