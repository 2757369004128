export const makeElementType = type => ['element'].concat(type).join('.')

export const addType = (type = '') => (types = []) => (
  Array.isArray(types) ? types.concat(type) : [types].concat(type)
)

export const addAminoType = addType('amino')

export const addVariableType = addType('variable')

export const isType = (type = '') => element => element.type.includes(type)

export const isVariable = isType('variable')

export const isAmino = isType('amino')

export const isCustomElement = isType('custom')

export const isVariableElement = isType('element.variable')

export const isBarcodeElement = isType('barcode')

export const isEnzymeElement = isType('enzyme')

export const isIgemElement = isType('igem')

export const isNcbiElement = isType('ncbi')

export default {
  addAminoType,
  addType,
  addVariableType,
  isAmino,
  isBarcodeElement,
  isCustomElement,
  isEnzymeElement,
  isIgemElement,
  isNcbiElement,
  isVariable,
  isVariableElement,
  makeElementType,
}
