/* global Raven */
import 'font-awesome/css/font-awesome.css'
import React from 'react'
import ReactDOM from 'react-dom'

import 'normalize.css'
import 'styles/vendor/bootstrap.css'
import 'styles/global.css'

const isProduction = process.env.NODE_ENV === 'production'

// Handle any configuraton for production builds here.
if (isProduction) {
  Raven.config('https://42cf96c401124d3e8b886e83660998db@sentry.io/111894').install()
}

const renderApp = AppComponent => {
  ReactDOM.render(<AppComponent />, document.getElementById('app'))
}

// App component depends on environment. Make sure to include the default
// property for the require statements since we're using ES6 modules.
const App = isProduction ? require('./App').default : require('./App.dev').default

renderApp(App)

// Hot module reload configuration to allow the browser to automatically reload
// with changes to any component file that is a dependency for the app.
// See https://github.com/gaearon/react-hot-loader
if (module.hot) {
  // Make sure we're accepting the reload for the dev app since this only
  // pertains to development.
  module.hot.accept('./App.dev', () => {
    // If you use Webpack 2 in ES modules mode, you can
    // use <App /> here rather than require() a <NextApp />.
    const NextApp = require('./App.dev').default
    renderApp(NextApp)
  })
}
