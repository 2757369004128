import React, {Component} from 'react'
import {arrayOf, func, node, number, oneOfType, shape, string} from 'prop-types'

import SelectInput from './SelectInput'

export default class SelectInputContainer extends Component {
  static propTypes = {
    onMenuHide: func,
    onMenuShow: func,
    options: arrayOf(shape({
      label: node.isRequired,
      value: oneOfType([number, string]).isRequired,
    })),
    value: oneOfType([number, string]).isRequired,
  }

  static defaultProps = {
    onMenuHide: () => console.debug('SelectInput hide menu'),
    onMenuShow: () => console.debug('SelectInput show menu'),
    options: [],
  }

  state = {
    open: false,
  }

  componentWillReceiveProps ({value}) {
    return value !== this.props.value && this.setState({open: false})
  }

  /**
   * Handles edge case where user accidentally drags mouse over the dropdown
   * menu before releasing outside of the menu. In this case the menu should
   * hide, but the initial blur event captures the menu node rather than the
   * node on the mouseup event.
   */
  componentDidUpdate (_, {open}) {
    if (!open && this.state.open) {
      document.addEventListener('mouseup', this.handleMenuHide)
    }
    else if (open && !this.state.open) {
      document.removeEventListener('mouseup', this.handleMenuHide)
    }
  }

  get activeOptionLabel () {
    return this.props.options.reduce((activeLabel, {label, value}) => (
      activeLabel || (value === this.props.value && label)
    ), '')
  }

  setRef = (r) => {
    this.node = r
  }

  toggleMenu () {
    this.setState({open: !this.state.open})
  }

  handleMenuHide = ({relatedTarget}) => {
    this.props.onMenuHide()

    return this.node && !this.node.contains(relatedTarget) && this.toggleMenu()
  }

  handleMenuShow = () => {
    this.props.onMenuShow()
    this.toggleMenu()
  }

  render () {
    return (
      <SelectInput
        {...this.props}
        {...this.state}
        activeOptionLabel={this.activeOptionLabel}
        innerRef={this.setRef}
        onMenuHide={this.handleMenuHide}
        onMenuShow={this.handleMenuShow}
      />
    )
  }
}
