import { func, number } from 'prop-types'
import { PureComponent } from 'react'

export default class RowSizeUpdater extends PureComponent {
  static propTypes = {
    bodyHeight: number.isRequired,
    children: func.isRequired,
    onRowSizeUpdate: func.isRequired,
  }

  /**
   * Make sure the row height is recalculated whenever the height changes due
   * to changes in how many tags may render in the row. We have to run this
   * calculation after the new dimensions or tags render to ensure we get the
   * correct height.
   */
  componentWillReceiveProps ({ bodyHeight }) {
    const { bodyHeight: prevBodyHeight, onRowSizeUpdate } = this.props

    if (bodyHeight !== prevBodyHeight) {
      onRowSizeUpdate()
    }
  }

  render () {
    const { children } = this.props

    return children()
  }
}
