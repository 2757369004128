import { connect } from 'react-redux'

import { updateTags } from 'actions/templates'
import {
  addConstructRegion,
  getConstructRegionAbsoluteIndex,
  isTagElement,
} from 'core/construct'
import { getElementContext } from 'selectors/designer'
import { getEditingTemplateSequence, getEditingTemplateTags, getEditingTemplateElementOrder } from 'selectors/templates'
import { TAG_TYPES } from 'core/Constants'
import { isNil } from 'utils'

const isCreatingNewTag = ({ editorState }) => editorState === 'create'

export default connect((state, ownProps) => {
  const { id, end, start } = ownProps

  let construct = {
    tags: getEditingTemplateTags(state),
    elementOrder: getEditingTemplateElementOrder(state),
  }

  if (isCreatingNewTag(ownProps)) {
    const index = Math.min(start, end)
    const length = Math.max(start, end) - index

    construct = addConstructRegion(
      construct,
      { type: TAG_TYPES.user },
      index,
      length,
    )
  }

  const { tags } = construct

  const tagKeys = Object.keys(tags)
  const editId = isNil(id) ? tagKeys[tagKeys.length - 1] : id
  const editingTag = tags[editId]

  return {
    absoluteIndex: isTagElement(editingTag)
      ? editingTag.offset
      : getConstructRegionAbsoluteIndex(construct, editId),
    editingTag,
    editingTagId: editingTag.id,
    elementContext: getElementContext(state),
    elementOrder: getEditingTemplateElementOrder(state),
    sequence: getEditingTemplateSequence(state),
    tags,
  }
}, { onSubmit: updateTags })
