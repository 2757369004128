import React from 'react'

import { CounterType } from './types'

import cn from './HashmarkCounter.css'

export default function HashmarkCounter ({ counter }) {
  const {
    labelXOffset,
    labelYOffset,
    markHeight,
    marks,
    width,
  } = counter

  return (
    <g>
      <line
        stroke="var(--clr-base-lt)"
        strokeWidth={1}
        x1={0}
        x2={width}
      />

      {marks.map(({ key, label, x }) => (
        <g key={key} transform={`translate(${x})`}>
          <line
            stroke="var(--clr-base-lt)"
            strokeWidth={1}
            x1={0}
            x2={0}
            y1={0}
            y2={markHeight}
          />

          <text className={cn.text} x={labelXOffset} y={labelYOffset}>
            {label}
          </text>
        </g>
      ))}
    </g>
  )
}

HashmarkCounter.propTypes = {
  counter: CounterType.isRequired,
}
