import React from 'react'
import {node, string} from 'prop-types'
import cx from 'classnames'

import cn from './FormControl.css'

export default function FormControl ({
  bodyClass,
  children,
  className,
  icon,
  idFor,
  label,
  labelChildren,
}) {
  return (
    <div className={cx(cn.base, className)}>
      <div className={cn.header}>
        <label className={cn.label} htmlFor={idFor}>
          {icon && <i className='material-icons'>{icon}</i>}
          <span className={cx(cn.labelText, {[cn.labelTextWithIcon]: icon})}>
            {label}
          </span>
        </label>
        {labelChildren}
      </div>
      <div className={cx(cn.body, bodyClass)}>
        {children}
      </div>
    </div>
  )
}

FormControl.propTypes = {
  bodyClass: string,
  children: node,
  className: string,
  icon: string,
  idFor: string,
  label: node,
  labelChildren: node,
}

FormControl.defaultProps = {
  bodyClass: '',
  children: null,
  className: '',
  icon: '',
  idFor: '',
  label: null,
  labelChildren: null,
}
