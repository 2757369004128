import {connect} from 'react-redux'

import {pollForSequenceJobCompletion} from 'actions/designer'
import {reqCreateProjectCommit} from 'actions/project'
import {getProjectId} from 'selectors/designer'
import {getTemplatesForCommit} from 'selectors/templates'

export default connect(
  state => ({
    projectId: getProjectId(state),
    templates: getTemplatesForCommit(state),
  }), {
    onProjectCommit: reqCreateProjectCommit,
    onSequenceJobStarted: pollForSequenceJobCompletion,
  }
)
