import { randomColor } from 'common/color'
import {
  isNil,
  overlap,
  replaceString,
  spliceArray,
} from 'utils'

import { idMaker } from './utils/IdGenerator'

/**
 * ===============================================
 * HELPER FUNCTIONS
 * ===============================================
 */

/**
 * Returns a DNA length converted to amino acids. (3 BP = 1 AA).
 */
const toAminoLength = len => len * 3

/**
 * Should be used to parse object keys into a numeric id,
 */
const parseIdString = idString => parseInt(idString, 10)

/**
 * Standardizes sequences as lowercase characters.
 */
const cleanSequence = sequence => sequence.toLowerCase()

/**
 * ===============================================
 * TAG FUNCTIONS
 * ===============================================
 */

/**
 * Creates a tag object.
 */
const createTag = ({
  amino = false,
  aminos = '',
  color = randomColor(),
  description = '',
  id,
  hasMappedDNA = false,
  length,
  name = '',
  metadata = {},
  offset,
  spannedElements = [],
  type = '',
}) => ({
  amino,
  aminos,
  color,
  description,
  hasMappedDNA,
  length,
  id,
  name,
  metadata,
  offset,
  spannedElements,
  type,
})

/**
 * Returns the end index of a tag object.
 */
export const getTagEnd = (tag = {}) => tag.offset + tag.length

/**
 * Returns if a tag is an element. All element tags contain their own id as the
 * first spannedElement.
 */
export const isTagElement = ({ id, spannedElements }) => spannedElements[0] === id

/**
 * Returns if a tag overlaps a given index. 2 index bounds may be provided. If
 * no second index is provided, the first index arg is used as the second bound.
 */
const doesTagOverlapIndex = (tag, index1, index2) => (
  overlap(
    [tag.offset, getTagEnd(tag)],
    [index1, (isNil(index2) ? index1 : index2)],
  )
)

/**
 * Returns if 2 tags are related based on their names. All related tags have
 * names that are denoted by ending in a hyphen and a series of numbers. No
 * other tags should have this convention in their name.
 */
const isTagRelated = (tag1, tag2) => {
  const nameRegex = /-*[0-9](?!.*-[0-9])/

  return tag1.id !== tag2.id
    && tag1.name.replace(nameRegex, '') === tag2.name.replace(nameRegex, '')
}

/**
 * Returns the index of a related tag based on the length between the 2 tags.
 * Since related tags are equal length, the index of order for a related tag
 * should be able to be determined by how far it is from another tag. Zero
 * should be the min value returned in the case that a tag that occurs in the
 * sequence before the first tag supplied is passed as an argument.
 */
const getTagRelatedIndex = (tag1, tag2) => (
  Math.max(0, Math.floor((tag2.offset - getTagEnd(tag1)) / tag1.length))
)

/**
 * ===============================================
 * TAG STORE FUNCTIONS
 * ===============================================
 */

/**
 * Adds an element tag to a tag store and updates the offsets of all element
 * tags that follow after it.
 */
function addTagsElement (tags, elementOrder, index, elementTag) {
  const { id, length } = elementTag

  return elementOrder.reduce((tagsCopy, elementId, i) => {
    if (i <= index) {
      return tagsCopy
    }

    const tagCopy = tags[elementId]

    tagsCopy[elementId] = {
      ...tagCopy,
      offset: tagCopy.offset + length,
    }

    return tagsCopy
  }, { ...tags, [id]: elementTag })
}

/**
 * Deletes an element tag from a tag store, updates the offsets of all element
 * tags that follow after it, and removes any non-element tags that span it.
 */
function deleteTagsElement (tags, elementOrder, index, elementTag) {
  const { id, length } = elementTag
  const tagsCopy = {}

  Object.keys(tags).forEach((keyString) => {
    const key = parseIdString(keyString)
    const tagCopy = tags[key]
    const iterIndex = elementOrder.indexOf(key)

    // Copy over all element tags except for the one with the matching id.
    if (elementOrder.includes(key) && key !== id) {
      // Adjust the offset for element tags that come after the deleted tag.
      const offsetAdjust = iterIndex >= index ? length : 0

      tagsCopy[key] = {
        ...tagCopy,
        offset: tagCopy.offset - offsetAdjust,
      }
    }
    // Copy over tag if it does not span the deleted element tag.
    else if (!tagCopy.spannedElements.includes(id)) {
      tagsCopy[key] = tagCopy
    }

    /**
     * If the 2 above conditions aren't met, that means the tag spans the
     * deleted element and should be removed from the tag store.
     */
  })

  return tagsCopy
}

/**
 * Adjust the offset positions of element tags by looking at the end index of
 * the element that precedes it except for the first element which should have
 * an index of zero.
 */
function adjustElementOffsetFromIndex (tags, elementOrder, index = 0) {
  const tagsCopy = {}

  elementOrder.forEach((id, i) => {
    // Copy over all elements that occur before the specified index.
    if (i < index) {
      tagsCopy[id] = tags[id]
    }
    // If the specified index is zero, make sure the first offset is zero as well.
    else if (index === 0 && i === 0) {
      tagsCopy[id] = {
        ...tags[id],
        offset: 0,
      }
    }
    else {
      tagsCopy[id] = {
        ...tags[id],
        offset: getTagEnd(tagsCopy[elementOrder[i - 1]]),
      }
    }
  })

  return tagsCopy
}

/**
 * Updates the offset position of the moved element tag, updates the offsets
 * of all element tags that follow after it, and removes any non-element tags
 * that span more than two elements including the one moved.
 */
function moveTagsElement (tags, elementOrder, index) {
  // Start copy object with elements adjusted.
  const tagsCopy = adjustElementOffsetFromIndex(tags, elementOrder, index)

  // Id of element that was moved.
  const id = elementOrder[index]

  return Object.keys(tags).reduce((newTags, keyString) => {
    // Coerce to integer value to ensure equality checks work properly.
    const key = parseIdString(keyString)

    const tagCopy = tags[key]

    // Return early since all element tags have already been adjusted.
    if (elementOrder.includes(key)) {
      return newTags
    }

    // Copy over tag if it does not span more than 2 elements including the moved element.
    if (tagCopy.spannedElements.length < 2 || !tagCopy.spannedElements.includes(id)) {
      tagsCopy[key] = tagCopy
    }

    /**
     * If the above conditions isn't met, that means the tag spans more than 2
     * elements including the moved one and should be removed from the tag store.
     */

    return newTags
  }, tagsCopy)
}

/**
 * Updates the lengths of an element tag and propagates that change to the
 * offsets and/lengths of all tags that come after or overlap the index at which
 * the edit occurs.
 */
function updateTagsPosition (tags, id, index, lengthAdjust) {
  const tagsCopy = { ...tags }

  const updateTag = tagsCopy[id]

  /**
   * Get top-most parent element tag id to check which non-element tags need to
   * be updated.
   */
  const parentElementId = tagsCopy[id].spannedElements[0]

  Object.keys(tags).forEach((keyString) => {
    // Coerce to integer value to ensure equality checks work properly.
    const key = parseIdString(keyString)

    const tagCopy = tags[key]

    /**
     * If tag is an element, 3 cases need to be handled.
     *
     * 1. If edited element, adjust it's length.
     * 2. If element occurs before edited element, copy over with no changes.
     * 3. If element occurs after edited element, adjust offset.
     */
    if (isTagElement(tagCopy)) {
      if (key === id) {
        tagsCopy[key] = {
          ...tagCopy,
          length: tagCopy.length + lengthAdjust,
        }
      }
      else if (tagCopy.offset < index) {
        tagsCopy[key] = tagCopy
      }
      else {
        tagsCopy[key] = {
          ...tagCopy,
          offset: tagCopy.offset + lengthAdjust,
        }
      }
    }
    /**
     * If non-element tag spans edited element, 4 cases need to be handled.
     *
     * 1. If tag overalps edit index, adjust its length.
     * 2. If tag is related (conserve domain tags), adjust its length and offset.
     * 3. If tag occurs before edit index, copy over with no change.
     * 4. If tag occurs after edit index, adjust its offset.
     */
    else if (tagCopy.spannedElements.includes(parentElementId)) {
      if (doesTagOverlapIndex(tagCopy, index)) {
        tagsCopy[key] = {
          ...tagCopy,
          length: tagCopy.length + lengthAdjust,
        }
      }
      else if (isTagRelated(tagCopy, updateTag)) {
        tagsCopy[key] = {
          ...tagCopy,
          length: tagCopy.length + lengthAdjust,
          offset: tagCopy.offset < index
            ? tagCopy.offset
            /**
             * The offset is adjusted by a multiplier due to every related tag
             * increasing its length.
             */
            : tagCopy.offset + (lengthAdjust * getTagRelatedIndex(updateTag, tagCopy)),
        }
      }
      else if (tagCopy.offset < index) {
        tagsCopy[key] = tagCopy
      }
      else {
        tagsCopy[key] = {
          ...tagCopy,
          offset: tagCopy.offset + lengthAdjust,
        }
      }
    }
    /**
     * All non-element tags that don't span the edited element should be copied
     * over with no changes.
     */
    else {
      tagsCopy[key] = tagCopy
    }
  })

  return tagsCopy
}

/**
 * ===============================================
 * AA TAG STORE FUNCTIONS
 * ===============================================
 */

/**
 * DNA elements have a constant length in AA context.
 */
const DNA_LENGTH = 3

/**
 * Creates a tag store of amino acid elements. In AA context, all DNA elements
 * are truncated to a constant length in order to reduce the amount of
 * unimportant regions that need to be visualized. This means that all element
 * tag offsets need to be adjusted to accompany this change as well as removing
 * any non-element tags that either overlap DNA elements or span more than 2
 * element tags.
 */
function createAATags (tags, elementOrder) {
  // Get adjusted element tags.
  const elementTagsCopy = elementOrder.reduce((tagsCopy, id, i) => {
    const tagCopy = tags[id]
    const { amino, aminos } = tagCopy

    tagsCopy[id] = {
      ...tagCopy,
      // Don't render amino acids since they're rendered as the sequence string.
      aminos: '',
      length: amino ? aminos.length : DNA_LENGTH,
      offset: i === 0 ? 0 : getTagEnd(tagsCopy[elementOrder[i - 1]]),
    }

    return tagsCopy
  }, {})

  return Object.keys(tags).reduce((tagsCopy, keyString) => {
    const key = parseIdString(keyString)
    const tagCopy = tags[key]
    const { length, offset, spannedElements } = tagCopy
    const parentElement = tagsCopy[spannedElements[0]]

    // Ignore element tags, tags with DNA element parent, or tags spanning more than 2 elements.
    if (elementOrder.includes(key) || !parentElement.amino || spannedElements.length > 1) {
      return tagsCopy
    }

    // Divide length/offset by 3 to convert it from DNA -> AA space.
    tagsCopy[key] = {
      ...tagCopy,
      length: length / 3,
      offset: offset / 3,
    }

    return tagsCopy
  }, elementTagsCopy)
}

/**
 * Returns the amino acid sequence for a tag store by concatenating the amino
 * sequences for only amino acid elements.
 */
function getAATagsSequence (tags, elementOrder) {
  return elementOrder.reduce((sequence, id) => {
    const { amino, aminos } = tags[id]

    return `${sequence}${amino ? aminos : ' '.repeat(DNA_LENGTH)}`
  }, '')
}

/**
 * ===============================================
 * CONSTRUCT FUNCTIONS
 * ===============================================
 */

/**
 * Creates a construct object.
 */
export function createConstruct (id, constructProps = {}) {
  if (isNil(id)) {
    throw new Error('A numeric id must be provided to create a construct')
  }

  const {
    elementOrder = [],
    expressionSystem = '',
    sequence = '',
    tags = {},
    variations = 1,
  } = constructProps

  return {
    elementOrder,
    expressionSystem,
    id,
    sequence,
    tags,
    variations,
  }
}

/**
 * Updates a construct object by merging its update into the construct object.
 */
export function updateConstruct (construct, update) {
  return {
    ...construct,
    ...update,
  }
}

/**
 * Adds an element to a construct.
 */
export function addConstructElement (construct, element, index) {
  const {
    elementOrder,
    sequence,
    tags,
    ...constructProps
  } = construct

  const id = idMaker.newId(tags)

  /**
   * If insert index is zero, offset will be zero, otherwise it's the end
   * index of the tag that precedes the insert index.
   */
  const prevIndex = Math.max(0, index - 1)
  const offset = (index > 0 && getTagEnd(tags[elementOrder[prevIndex]])) || 0

  const elementTag = createTag({
    ...element,
    id,
    offset,
    sequence: cleanSequence(element.sequence),
    spannedElements: [id],
  })

  const newOrder = spliceArray(elementOrder, index, 0, id)

  return {
    ...constructProps,
    elementOrder: newOrder,
    sequence: replaceString(
      sequence,
      element.sequence,
      offset,
      offset,
    ),
    tags: addTagsElement(tags, newOrder, index, elementTag),
  }
}

/**
 * Deletes an element from a construct.
 */
export function deleteConstructElement (construct, index) {
  const {
    elementOrder,
    sequence,
    tags,
    ...constructProps
  } = construct

  const id = elementOrder[index]
  const tag = tags[id]
  const { offset } = tag

  return {
    ...constructProps,
    elementOrder: spliceArray(elementOrder, index, 1),
    sequence: replaceString(sequence, '', offset, getTagEnd(tag)),
    tags: deleteTagsElement(tags, elementOrder, index, tag),
  }
}

/**
 * Moves an element to another position within the construct.
 */
export function moveConstructElement (construct, newIndex, elementId) {
  const {
    elementOrder,
    sequence,
    tags,
    ...constructProps
  } = construct

  const oldIndex = elementOrder.indexOf(elementId)

  // Get indices and element sub-sequence to replace in the sequence.
  const { offset: deleteStart, length } = tags[elementId]
  const deleteEnd = deleteStart + length
  const seqSubstring = sequence.slice(deleteStart, deleteEnd)

  const newElementOrder = spliceArray(
    spliceArray(elementOrder, oldIndex, 1),
    newIndex,
    0,
    elementId,
  )

  // Changes need to start from the smallest index.
  const smallestIndex = Math.min(oldIndex, newIndex)

  const newTags = moveTagsElement(tags, newElementOrder, smallestIndex)

  /**
   * Must come after updating the offsets for the element tags. If the moved
   * element was moved to a smaller index, then the sub-sequence should be
   * inserted at the offset of the element previously occupying that index. If
   * the moved element was moved to a greater index, then the sub-sequence
   * should be inserted at its new offset position.
   */
  const insertIdx = newIndex < oldIndex
    ? tags[elementOrder[newIndex]].offset
    : newTags[newElementOrder[newIndex]].offset

  return {
    ...constructProps,
    elementOrder: newElementOrder,
    sequence: replaceString(
      replaceString(sequence, '', deleteStart, deleteEnd),
      seqSubstring,
      insertIdx,
      insertIdx,
    ),
    tags: newTags,
  }
}

/**
 * Adds a non-element region tag to the construct.
 */
export function addConstructRegion (construct, tag, index, length) {
  const { elementOrder, tags } = construct
  const tagEnd = index + length

  // Find which element tags this non-element tag overlaps.
  const spannedElements = elementOrder.filter((id) => {
    const elementTag = tags[id]

    return overlap([index, tagEnd], [elementTag.offset, getTagEnd(elementTag)])
  })

  const newTag = createTag({
    ...tag,
    id: idMaker.newId(tags),
    length,
    offset: index - tags[spannedElements[0]].offset,
    spannedElements,
  })

  return {
    ...construct,
    tags: {
      ...tags,
      [newTag.id]: newTag,
    }
  }
}

/**
 * Deletes any number of non-element region tag from the construct.
 */
export function deleteConstructRegions (construct, tagIds) {
  const deleteIds = Array.isArray(tagIds) ? tagIds : [tagIds]

  const { tags } = construct

  return {
    ...construct,
    // Reduce/filter tags by not including any tags matching the deleted ids upon copy.
    tags: Object.keys(tags).reduce((newTags, keyString) => {
      const key = parseIdString(keyString)

      if (!deleteIds.includes(key)) {
        newTags[key] = tags[key]
      }

      return newTags
    }, {}),
  }
}

/**
 * Updates the length of a DNA region in the construct. This update should
 * propagate down to the offsets of any tags overlapping the region with offsets
 * greater than the index at which the update occurs as well as any element tags
 * that come after the updated element.
 */
export function updateConstructRegionLength (
  construct,
  id,
  /**
   * Index should be absolute offset from 0 for the construct, meaning this
   * may need to be adjusted to account for the offset of the editing tag if
   * used in an editor component.
   */
  index,
  lengthAdjust,
  input,
) {
  const { sequence, tags } = construct

  const isDelete = lengthAdjust < 0
  const insertSequence = isDelete ? '' : cleanSequence(input)
  const endIdx = isDelete ? index - lengthAdjust : index

  return {
    ...construct,
    sequence: replaceString(sequence, insertSequence, index, endIdx),
    tags: updateTagsPosition(tags, id, index, lengthAdjust),
  }
}

/**
 * Updates the length of an amino acid region in the construct. This should
 * update the aminos sequence of the element tag that overlaps the region and
 * remove any DNA previously mapped to the element. Changes should propagate to
 * all other related tags just like they do for updateConstructRegionLength.
 */
export function updateConstructAARegionLength (
  construct,
  id,
  startIndex,
  lengthAdjust,
  input,
) {
  const { sequence, tags } = construct
  const tagCopy = tags[id]
  const { aminos, offset } = tagCopy

  /**
   * The index passed in arguments is the absolute index position meaning we
   * can use the tag's offset to find the correlated edit index for the amino
   * sequence.
   */
  const editIndex = startIndex - offset

  const isDelete = lengthAdjust < 0
  const insertAA = isDelete ? '' : cleanSequence(input)
  const endIdx = isDelete ? editIndex - lengthAdjust : editIndex

  // Create new tag store with updated amino acid sequence.
  const tagsCopy = {
    ...tags,
    [id]: {
      ...tagCopy,
      aminos: replaceString(aminos, insertAA, editIndex, endIdx),
      hasMappedDNA: false,
    },
  }

  /**
   * Use length of new aminos sequence to create a string of white spaces equal
   * to the number of DNA basepairs that equals to (3BP = 1AA).
   */
  const insertSequence = ' '.repeat(toAminoLength(tagsCopy[id].aminos.length))

  return {
    ...construct,
    sequence: replaceString(sequence, insertSequence, offset, getTagEnd(tagCopy)),
    tags: updateTagsPosition(tagsCopy, id, startIndex, toAminoLength(lengthAdjust)),
  }
}

/**
 * Updates the construct sequence by inserting the DNA sequence in arguments
 * into the position of the amino acid region. This should also flag the amino
 * acid element as having mapped DNA.
 */
export function updateConstructAARegionSequence (construct, id, dnaSequence) {
  const { sequence, tags } = construct

  const tag = tags[id]

  return {
    ...construct,
    sequence: replaceString(
      sequence,
      cleanSequence(dnaSequence),
      tag.offset,
      getTagEnd(tag),
    ),
    tags: {
      ...tags,
      [id]: {
        ...tag,
        hasMappedDNA: true,
      },
    },
  }
}

/**
 * Returns the absolute index position from zero given a construct, the tag id
 * of the relative tag, and an index. If the relative tag is an element, the
 * absolute index should be the sum of its offset and the relative index. If
 * the tag is non-element, it should find its parent element tag and return the
 * sum of its parent's offset, its own offset, and the relative index.
 */
export function getConstructRegionAbsoluteIndex (construct, tagId, relativeIndex = 0) {
  const { tags } = construct
  const relativeTag = tags[tagId]
  const { offset, spannedElements } = relativeTag

  if (isTagElement(relativeTag)) {
    return offset + relativeIndex
  }

  const elementParentTag = tags[spannedElements[0]]

  return elementParentTag.offset + offset + relativeIndex
}

/**
 * Creates a construct for AA context space.
 */
export function createAAConstruct (construct) {
  const { elementOrder, tags } = construct

  return {
    ...construct,
    sequence: getAATagsSequence(tags, elementOrder),
    tags: createAATags(tags, elementOrder)
  }
}

/**
 * Adds motif feature tags to an amino acid element.
 */
export function addConstructAARegionMotifFeature (construct, elementId, features) {
  const { tags } = construct
  const { offset } = tags[elementId]

  const MotifColor = '#9938b5'

  /**
   * Indices returned by the api are in amino space, so we have to normalize
   * them to dna space by converting each index value to the 3 dna bases each
   * amino represents.
   */
  const x3 = x => x * 3

  return {
    ...construct,
    tags: Object.keys(features).reduce((tagsCopy, key) => {
      const {
        indices,
        length,
        name,
        type,
        metadata,
      } = features[key]

      indices.forEach((index, i) => {
        const id = idMaker.newId(tagsCopy)

        tagsCopy[id] = createTag({
          color: MotifColor,
          id,
          length: x3(length),
          metadata,
          name: `${name}-${index}-${i}`,
          offset: offset + x3(index),
          spannedElements: [elementId],
          type,
        })
      })

      return tagsCopy
    }, { ...tags })
  }
}

/**
 * Converts a sequence selection indices to AA context. Due to AA constructs
 * truncating DNA elements, the conversion of indices needs to use the cycle
 * through the construct's elements in order to correctly calculate what the
 * new indices are.
 */
export function convertConstructSelectionToAA (construct, selection) {
  const { elementOrder, tags } = construct
  const { end, start } = selection

  let startIndex = 0
  let endIndex = null

  // Converts the starting index.
  const aaStart = elementOrder.reduce((index, id, i) => {
    const tag = tags[id]
    const { amino, aminos, offset } = tag
    const tagEnd = getTagEnd(tag) - 1

    /**
     * If the DNA selection start index is greater than the element's end index,
     * then add either the length of an AA element's amino sequence or the
     * truncated fixed length for DNA elements.
     */
    if (start > tagEnd) {
      if (!amino) {
        return index + DNA_LENGTH
      }

      return index + aminos.length
    }

    /**
     * If the selection index falls between a tag's index position, then
     * add the length of aminos it overlaps for AA elements or half the fixed
     * length for DNA elements.
     */
    if (overlap([offset, tagEnd], [start, start])) {
      startIndex = i

      const adjust = amino ? ((start - offset) / 3) : (DNA_LENGTH / 2)

      return index + Math.floor(adjust)
    }

    return index
  }, 0)

  // Converts the starting index.
  const aaEnd = elementOrder.reduce((index, id, i) => {
    /**
     * If the iteree's index is less than the starting element index or if an
     * end index exists, ignore.
     */
    if (i < startIndex || !isNil(endIndex)) {
      return index
    }

    const tag = tags[id]
    const { amino, aminos, offset } = tag

    // Prevent's overlapping of sequential element start/end indices.
    const tagEnd = getTagEnd(tag) - 1

    const isSameTag = startIndex === i

    if (overlap([offset, tagEnd], [end, end])) {
      endIndex = i
    }

    if (isSameTag) {
      const endAdjust = isNil(endIndex) ? tagEnd : end
      const adjust = amino ? ((endAdjust - start - offset) / 3) : (DNA_LENGTH / 2)

      return index + Math.ceil(adjust)
    }

    if (!isNil(endIndex)) {
      const adjust = amino ? ((end - offset) / 3) : (DNA_LENGTH / 2)

      return index + Math.ceil(adjust)
    }

    /**
     * If the DNA selection end index is greater than the element's end index,
     * then add either the length of an AA element's amino sequence or the
     * truncated fixed length for DNA elements.
     */
    if (end > tagEnd) {
      if (!amino) {
        return index + DNA_LENGTH
      }

      return index + aminos.length
    }

    return index
  }, aaStart)

  return {
    end: aaEnd,
    start: aaStart,
  }
}

/**
 * Converts a sequence selection indices to DNA context.
 */
export function convertConstructSelectionToDNA (construct, selection) {
  const { elementOrder, tags } = construct
  const { end, start } = selection

  // Convert elementTags to AA space.
  const aaTags = createAATags(tags, elementOrder)

  let startIndex = 0
  let endIndex = null

  const dnaStart = elementOrder.reduce((index, id, i) => {
    const tag = aaTags[id]
    const { amino, length, offset } = tag
    const tagEnd = getTagEnd(tag) - 1

    if (start > tagEnd) {
      return index + (amino ? length : tags[id].length)
    }

    if (overlap([offset, tagEnd], [start, start])) {
      startIndex = i

      const lengthAdjust = start % 5 === 0 ? tags[id].length : Math.round(tags[id].length / 2)

      const adjust = amino ? ((start - offset) * 3) : lengthAdjust

      return index + Math.floor(adjust)
    }

    return index
  }, 0)

  const dnaEnd = elementOrder.reduce((index, id, i) => {
    if (i < startIndex || !isNil(endIndex)) {
      return index
    }

    const tag = aaTags[id]
    const { amino, offset } = tag

    // Prevent's overlapping of sequential element start/end indices.
    const tagEnd = getTagEnd(tag) - 1

    const isSameTag = startIndex === i

    const dnaLength = tags[id].length

    if (overlap([offset, tagEnd], [end, end])) {
      endIndex = i
    }

    if (isSameTag) {
      const endAdjust = isNil(endIndex) ? tagEnd : end
      const adjust = amino ? ((endAdjust - start - offset) * 3) : (dnaLength / 2)

      return index + Math.ceil(adjust)
    }

    if (!isNil(endIndex)) {
      const adjust = amino ? ((end - offset) * 3) : (dnaLength / 2)

      return index + Math.ceil(adjust)
    }

    if (end > tagEnd) {
      return index + dnaLength
    }

    return index
  }, dnaStart)

  return {
    end: dnaEnd,
    start: dnaStart,
  }
}
