import React from 'react'
import {bool, string} from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/BaseButton'
import cn from './RoundOutlineButton.css'

export default function RoundOutlineButton ({className, primary, ...props}) {
  return <BaseButton {...props} className={cx(cn.base, className, {[cn.primary]: primary})} />
}

RoundOutlineButton.propTypes = {
  className: string,
  primary: bool,
}

RoundOutlineButton.defaultProps = {
  className: '',
  primary: false,
}
