import React from 'react'
import { bool, func } from 'prop-types'

import { ElementContext } from 'common'
import FaIconButton from 'components/FaIconButton'
import Switch from 'components/Switch'

import cn from './ProjectActionButtons.css'

function ProjectActionButtons ({
  disableCommit,
  disableFork,
  disableSequenceDownload,
  generatingSequences,
  onContextChange,
  onCommitClick,
  onForkClick,
  onSequenceDownloadClick,
}) {
  return (
    <div className={cn.base}>
      <Switch
        initialValue={ElementContext.DNA}
        labelLeft="DNA"
        labelRight="AA"
        onToggleChange={onContextChange}
        valueLeft={ElementContext.DNA}
        valueRight={ElementContext.AA}
      />
      <FaIconButton
        className={cn.btn}
        disabled={disableCommit}
        icon={generatingSequences ? 'spinner fa-spin' : 'floppy-o'}
        iconSize='lg'
        onClick={onCommitClick}
        size='small'
        title='Save project'
      />
      <FaIconButton
        className={cn.btn}
        disabled={disableFork}
        icon='code-fork'
        iconSize='lg'
        onClick={onForkClick}
        size='small'
        title='Fork project'
      />
      <FaIconButton
        className={cn.btn}
        disabled={disableSequenceDownload}
        icon='download'
        iconSize='lg'
        onClick={onSequenceDownloadClick}
        size='small'
        title='Download sequences'
      />
    </div>
  )
}

ProjectActionButtons.propTypes = {
  disableCommit: bool,
  disableFork: bool,
  disableSequenceDownload: bool,
  generatingSequences: bool,
  onCommitClick: func.isRequired,
  onContextChange: func.isRequired,
  onForkClick: func.isRequired,
  onSequenceDownloadClick: func.isRequired,
}

ProjectActionButtons.defaultProps = {
  disableCommit: false,
  disableFork: false,
  disableSequenceDownload: false,
  generatingSequences: false,
}

export default ProjectActionButtons
