export default class IdGenerator {
  constructor (prefix = '') {
    this.prefix = prefix
  }

  inc (counter) {
    return Number(counter) + 1
  }

  findGreatestIndex (idMap = {}) {
    return Object.keys(idMap)
      .map(Number)
      .reduce((lastId, id) => (
        Number.isNaN(id) || lastId > id ? lastId : id
      ), 0)
  }

  makeId (id) {
    return this.prefix ? `${this.prefix}-${id}` : id
  }

  newId (idMap = {}, id = 0) {
    return this.makeId(this.inc(this.findGreatestIndex(idMap)))
  }
}

export const idMaker = new IdGenerator()
