import {connect} from 'react-redux'

import ConfirmDeleteModal from 'components/ConfirmDeleteModal'
import CustomCodonModal from 'components/CustomCodonModal'
import FeatureSearchModal from 'components/FeatureSearchModal'
import ElementModal from 'components/ElementModal'
import { ConnectedFileUploadModal } from 'components/FileUploadModal'
import OptimizeDNAModal from 'components/OptimizeDNAModal'
import OptimizeDNASettingsModal from 'components/OptimizeDNASettingsModal'
import ProjectCommitModal from 'components/ProjectCommitModal'
import ProjectForkModal from 'components/ProjectForkModal'
import TagModal from 'components/TagModal'
import {closeModal} from 'actions/modal'
import {getModalType, getModalProps} from 'selectors/modal'

const modals = {
  codon: CustomCodonModal,
  commit: ProjectCommitModal,
  featureSearch: FeatureSearchModal,
  delete: ConfirmDeleteModal,
  element: ElementModal,
  fasta: ConnectedFileUploadModal,
  fork: ProjectForkModal,
  optimizeDNA: OptimizeDNAModal,
  optimizeDNASettings: OptimizeDNASettingsModal,
  tag: TagModal,
}

const mapState = (state) => {
  const Modal = modals[getModalType(state)]

  return {
    isOpen: !!Modal,
    modalProps: getModalProps(state),
    Modal,
  }
}

const mapDispatch = {
  onClose: closeModal,
}

export default connect(mapState, mapDispatch)
