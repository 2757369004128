import React from 'react'
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/buttons/BaseButton'
import cn from './ElementExplorerDirectory.css'

export default function ElementExplorerDirectory ({
  activeOption,
  onDirectoryChange,
  options,
}) {
  return (
    <div className={cn.base}>
      {options.map(option => (
        <BaseButton
          className={cx(cn.option, { [cn.optionActive]: activeOption === option.value })}
          key={option.key}
          name="directory"
          onClick={onDirectoryChange}
          value={option.value}
        >
          <div className={cn.optionBody}>
            <span>{option.label}</span>
          </div>
        </BaseButton>
      ))}
    </div>
  )
}

ElementExplorerDirectory.propTypes = {
  activeOption: oneOfType([number, string]),
  onDirectoryChange: func.isRequired,
  options: arrayOf(shape({
    iconProps: shape({
      icon: string.isRequired,
      spin: bool,
    }),
    key: oneOfType([number, string]).isRequired,
    label: node.isRequired,
    value: oneOfType([number, string]).isRequired,
  })).isRequired,
}

ElementExplorerDirectory.defaultProps = {
  activeOption: '',
}
