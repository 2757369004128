import React, {Component} from 'react'
import {
  arrayOf,
  func,
  shape,
  string,
} from 'prop-types'
import {connect} from 'react-redux'

import ProjectCommitHistory from 'components/ProjectCommitHistory'
import ProjectNotes from 'components/ProjectNotes'
import ProjectSettings from 'components/ProjectSettings'
import ProjectShare from 'components/ProjectShare'
import TemplateList from 'components/TemplateList'

import DesignerSidebarMenu from './DesignerSidebarMenu'

// Action views.
const SIDEBAR_VIEWS = [
  {View: TemplateList, icon: 'th-large', label: 'Designs'},
  {View: ProjectNotes, icon: 'file-text', label: 'Notes'},
  {View: ProjectCommitHistory, icon: 'history', label: 'Commit History'},
  {View: ProjectShare, icon: 'users', label: 'Share Project'},
  {View: ProjectSettings, icon: 'cog', label: 'Settings'},
]

class DesignerSidebarMenuContainer extends Component {
  static propTypes = {
    className: string,
    options: arrayOf(shape({
      icon: string.isRequired,
      label: string.isRequired,
      View: shape({}).isRequired,
    })).isRequired,
  }

  static defaultProps = {
    className: '',
  }

  constructor (props) {
    super(props)

    this.state = {
      activeIndex: 0,
      showView: false,
    }
  }

  handleOptionSelect = index => () => {
    this.setState({
      activeIndex: index,
      showView: true,
    })
  }

  handleViewClose = () => {
    this.setState({showView: false})
  }

  render () {
    const {className, options} = this.props
    const {activeIndex, showView} = this.state
    const {label: viewLabel, View} = options[activeIndex]

    const viewOptions = options.map(({icon, label}, i) => ({
      active: i === activeIndex && showView,
      icon,
      label,
    }))

    return (
      <DesignerSidebarMenu
        className={className}
        onOptionSelect={this.handleOptionSelect}
        onViewClose={this.handleViewClose}
        options={viewOptions}
        showView={showView}
        view={View && <View />}
        viewLabel={viewLabel}
      />
    )
  }
}

const mapState = () => ({
  options: SIDEBAR_VIEWS,
})

export {DesignerSidebarMenuContainer}

export default connect(mapState)(DesignerSidebarMenuContainer)
