import classNames from 'classnames'
import {
  bool,
  func,
  node,
  shape,
  string,
} from 'prop-types'
import React from 'react'

import FaIconButton from 'components/FaIconButton'
import classes from './SlideoutView.css'

export default function SlideoutView ({
  children,
  onClose,
  open,
  style,
  title,
}) {
  return (
    <div className={classNames(classes.base, { [classes.open]: open })} style={style}>
      <div className={classes.header}>
        <h4 className={classes.title}>
          {title}
        </h4>
      </div>
      <div className={classes.body}>
        {children}
      </div>
      <FaIconButton
        className={classes.close}
        icon="times"
        onClick={onClose}
        title="Hide"
      />
    </div>
  )
}

SlideoutView.propTypes = {
  children: node.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  style: shape({}),
  title: string.isRequired,
}

SlideoutView.defaultProps = {
  style: {},
}
