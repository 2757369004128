import React from 'react'
import {
  arrayOf,
  bool,
  func,
  object,
  string,
} from 'prop-types'

import { ShareUserType } from 'types'

import User from './User'
import cn from './ShareUserList.css'

function ShareUserList ({
  accessOptions,
  authUsername,
  disabled,
  onDelete,
  onEdit,
  users,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.header}>
        <span className={cn.header__title}>
          Members with access
        </span>
        <span className={cn.header__total}>
          {users.length}
        </span>
      </div>
      <ul className={cn.list}>
        {users.map(({ access, username }) => (
          <li className={cn.list__user} key={username}>
            <User
              access={access}
              accessOptions={accessOptions}
              disabled={disabled}
              isAuthUser={authUsername === username}
              onEdit={onEdit}
              onDelete={onDelete}
              username={username}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

ShareUserList.propTypes = {
  accessOptions: arrayOf(object).isRequired,
  authUsername: string,
  disabled: bool,
  onDelete: func.isRequired,
  onEdit: func.isRequired,
  users: arrayOf(ShareUserType).isRequired,
}

ShareUserList.defaultProps = {
  authUsername: '',
  disabled: false,
}

export default ShareUserList
