import {connect} from 'react-redux'

import {closeSnackbar} from 'actions/snackbar'
import {getSnackbarIsOpen, getSnackbarText, getSnackbarAction} from 'selectors/snackbar'

export default connect(state => ({
  open: getSnackbarIsOpen(state),
  text: getSnackbarText(state),
  onAction: getSnackbarAction(state),
}), {onClose: closeSnackbar})
