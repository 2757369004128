import React, { Component } from 'react'
import {
  bool,
  func,
  node,
  number,
  oneOf,
  string,
} from 'prop-types'
import cx from 'classnames'

import { noop, safelyGetBoundingRect } from 'utils'
import cn from './ResizablePane.css'

export default class ResizablePane extends Component {
  static propTypes = {
    boundClass: string,
    boundPosition: oneOf(['bottom', 'left', 'right', 'top']),
    className: string,
    children: node,
    initialSize: number,
    onResizeStart: func,
    paneClass: string,
    resized: bool,
  }

  static defaultProps = {
    boundClass: '',
    boundPosition: 'right',
    children: null,
    className: '',
    initialSize: 0,
    onResizeStart: noop,
    paneClass: '',
    resized: false,
  }

  state = {
    resizing: false,
    size: 0,
  }

  componentDidMount () {
    const { initialSize } = this.props
    const { height, width } = this.pane.getBoundingClientRect()
    const size = initialSize || (this.isHorizontal ? width : height)

    this.setSize(size)
  }

  componentDidUpdate (_, { resizing: prevResizing }) {
    const { resizing } = this.state

    if (!prevResizing && resizing) {
      window.addEventListener('mouseup', this.endResize)
      window.addEventListener('mousemove', this.resize)
    }
    else if (prevResizing && !resizing) {
      window.removeEventListener('mouseup', this.endResize)
      window.removeEventListener('mousemove', this.resize)
    }
  }

  get boundClass () {
    const { boundClass, boundPosition } = this.props
    const { resizing } = this.state

    return cx(cn.bound, boundClass, {
      [cn.boundRow]: boundPosition === 'top' || boundPosition === 'bottom',
      [cn.resizing]: resizing,
      [cn.boundFirst]: boundPosition === 'top' || boundPosition === 'left',
    })
  }

  get isHorizontal () {
    const { boundPosition } = this.props
    return boundPosition === 'left' || boundPosition === 'right'
  }

  get style () {
    const { size } = this.state

    return {
      width: size,
    }
  }

  setPaneRef = (r) => {
    this.pane = r
  }

  setSize (size) {
    this.setState({ size })
  }

  startResize = (e) => {
    e.preventDefault()

    const { width: size } = safelyGetBoundingRect(this.pane)

    this.setState({ resizing: true, size })

    const { onResizeStart } = this.props

    onResizeStart(e)
  }

  resize = ({ clientX }) => {
    const { boundPosition } = this.props
    const { left, right } = this.pane.getBoundingClientRect()
    const boundFirst = boundPosition === 'left'

    const delta = boundFirst
      ? right - clientX
      : clientX - left

    this.setState({ size: delta })
  }

  endResize = (e) => {
    e.preventDefault()

    this.setState({ resizing: false })
  }

  render () {
    const {
      children,
      className,
      paneClass,
      resized,
    } = this.props

    return (
      <div className={cx(cn.base, className, { [cn.resized]: resized })}>
        <div
          className={cx(cn.pane, paneClass)}
          ref={this.setPaneRef}
          style={this.style}
        >
          {children}
        </div>
        <div
          className={this.boundClass}
          onMouseDown={this.startResize}
          role="button"
          tabIndex={0}
        >
          <div className={cn.boundDot} />
          <div className={cn.boundDot} />
          <div className={cn.boundDot} />
        </div>
      </div>
    )
  }
}
