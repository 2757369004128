import cx from 'classnames'
import { bool, func } from 'prop-types'
import React from 'react'

import noop from 'utils/noop'

import { CursorStyleType } from '../types'

import cn from './classes.css'

export default function Cursor ({
  isActive,
  isLine,
  onSelectionContextMenu,
  showLeftBound,
  showRightBound,
  style,
}) {
  const { height, x, width } = style
  const handleColor = isActive ? 'var(--clr-primary)' : 'var(--clr-base)'
  const selectionColor = '#0099ff'
  const handleDiameter = 10
  const handleRadius = handleDiameter / 2

  const handleOffset = handleRadius * -1

  return (
    <g opacity={0.7} transform={`translate(${x})`}>
      {!isLine && (
        <rect
          fill={selectionColor}
          height={height}
          onContextMenu={onSelectionContextMenu}
          opacity={0.3}
          x={0}
          width={width}
        />
      )}

      {showRightBound && (
        <g transform={`translate(${width})`}>
          <circle
            cx={0}
            cy={handleOffset}
            fill={handleColor}
            r={handleRadius}
            stroke={handleColor}
          />
          <line
            className={cx(cn.cursor, { [cn.cursor_active]: isLine && isActive })}
            stroke={handleColor}
            strokeWidth={1}
            x1={0}
            x2={0}
            y1={0}
            y2={height}
          />
          <circle
            cx={0}
            cy={height}
            fill={handleColor}
            r={handleRadius}
            stroke={handleColor}
          />
        </g>
      )}

      {showLeftBound && (
        <g>
          <circle
            cx={0}
            cy={handleOffset}
            fill={handleColor}
            r={handleRadius}
            stroke={handleColor}
          />
          <line
            className={cx(cn.cursor, { [cn.cursor_active]: isLine && isActive })}
            stroke={handleColor}
            strokeWidth={1}
            x1={0}
            x2={0}
            y1={0}
            y2={height}
          />
          <circle
            cx={0}
            cy={height}
            fill={handleColor}
            r={handleRadius}
            stroke={handleColor}
          />
        </g>
      )}
    </g>
  )
}

Cursor.propTypes = {
  isActive: bool,
  isLine: bool,
  onSelectionContextMenu: func,
  showLeftBound: bool,
  showRightBound: bool,
  style: CursorStyleType.isRequired,
}

Cursor.defaultProps = {
  isActive: false,
  isLine: true,
  onSelectionContextMenu: noop,
  showLeftBound: true,
  showRightBound: false,
}
