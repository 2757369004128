import cx from 'classnames'
import React from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'

import RaisedButton from 'components/buttons/RaisedButton'
import RadioButtonSelect from 'components/inputs/RadioButtonSelect'
import { TextInput } from 'components/text-input'
import {InputOptionType} from 'types'

import cn from './ShareUserCreator.css'

function ShareUserCreator ({
  accessOptions,
  activeAccessIndex,
  className,
  disabled,
  disableSubmit,
  onAccessChange,
  onShareUserCreate,
  onUsernameChange,
  username,
}) {
  return (
    <div className={cx(cn.base, className)}>
      <div className={cn.name}>
        <TextInput
          disabled={disabled}
          name='username'
          onChange={onUsernameChange}
          placeholder='Enter a username to start'
          value={username}
        />
      </div>
      <div className={cn.access}>
        <p className={cn.text}>
          {`Choose the user's access`}
        </p>
        <RadioButtonSelect
          activeIndex={activeAccessIndex}
          className={cn.accessOptions}
          onSelect={onAccessChange}
          options={accessOptions}
        />
      </div>
      <div className={cn.submit}>
        <RaisedButton disabled={disableSubmit} onClick={onShareUserCreate} primary>
          Add User
        </RaisedButton>
      </div>
    </div>
  )
}

ShareUserCreator.propTypes = {
  accessOptions: arrayOf(InputOptionType).isRequired,
  activeAccessIndex: number.isRequired,
  className: string,
  disabled: bool,
  disableSubmit: bool,
  onAccessChange: func.isRequired,
  onShareUserCreate: func.isRequired,
  onUsernameChange: func.isRequired,
  username: string.isRequired,
}

ShareUserCreator.defaultProps = {
  className: '',
  disabled: false,
  disableSubmit: false,
}

export default ShareUserCreator
