/* global __AUTH0_CLIENT_ID__, __AUTH0_DOMAIN__ */

const config = {
  callbackURL: `${window.location.protocol}//${window.location.host}`,
  clientID: __AUTH0_CLIENT_ID__,
  credentialOpts: {
    api: 'aws',
    role: 'arn:aws:iam::511726753330:role/tupacbio_user_s3_access',
    principal: 'arn:aws:iam::511726753330:saml-provider/auth0',
    grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
  },
  domain: __AUTH0_DOMAIN__,
  // Lock options.
  lockOpts: {
    autoclose: true,
  },
  params: {
    // Learn about scopes: https://auth0.com/docs/scopes
    scope: 'openid nickname email',
  },
  responseType: 'token id_token',
}

export default config
