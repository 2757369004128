import React from 'react'
import {string} from 'prop-types'
import cx from 'classnames'

import FaIconButton from 'components/FaIconButton'
import cn from './FaIconAndLabelButton.css'

export default function FaIconAndLabelButton ({
  bodyClass,
  className,
  icon,
  iconClass,
  label,
  ...props
}) {
  return (
    <FaIconButton
      {...props}
      bodyClass={cx(cn.body, bodyClass)}
      className={cx(cn.base, className)}
      iconClass={cx(cn.icon, iconClass)}
      icon={icon}
    >
      <span>{label}</span>
    </FaIconButton>
  )
}

FaIconAndLabelButton.propTypes = {
  bodyClass: string,
  className: string,
  icon: string.isRequired,
  iconClass: string,
  label: string,
}

FaIconAndLabelButton.defaultProps = {
  bodyClass: '',
  className: '',
  iconClass: '',
  label: '',
}
