import React, {Component} from 'react'
import {bool, func, shape, string} from 'prop-types'

import Spinner from 'components/Spinner'
import cn from './ProjectLoader.css'

export default class ProjectLoader extends Component {
  static propTypes = {
    children: func.isRequired,
    onError: func.isRequired,
    onFetchProject: func.isRequired,
    onProjectLoad: func.isRequired,
    onTemplatesLoad: func.isRequired,
    project: shape({
      isPublic: bool,
    }),
    projectId: string.isRequired,
  }

  state = {
    loading: true,
    project: {},
  }

  async componentDidMount () {
    const {project, projectId} = this.props

    this.fetchProject(projectId, project.isPublic)
  }

  componentWillReceiveProps ({project, projectId}) {
    if (projectId !== this.props.projectId) {
      this.setState({loading: true})
      this.fetchProject(projectId, project.isPublic)
    }
  }

  loadProject (project) {
    const {onProjectLoad, onTemplatesLoad} = this.props
    onProjectLoad(project) // TODO: Remove/replace for new reducers
    onTemplatesLoad(project.latestCommit.sequenceTemplates)
  }

  fetchProject (id, isPublic) {
    const {onError, onFetchProject} = this.props

    Promise.resolve(onFetchProject(id, isPublic))
      .then(({data}) => {
        this.loadProject(data)
        this.setState({loading: false})
      })
      .catch(({message}) => {
        onError(message)
        window.location.href = '/'
      })
  }

  render () {
    const {children, project} = this.props

    return this.state.loading
      ? <div className={cn.base}><Spinner /></div>
      : children({project})
  }
}
