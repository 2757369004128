import React from 'react'
import {bool, func, number, string} from 'prop-types'
import cx from 'classnames'

import FaIconAndLabelButton from 'components/FaIconAndLabelButton'
import FaIconButton from 'components/FaIconButton'
import cn from './TemplateListItem.css'

export default function TemplateListItem ({
  active,
  disabled,
  id,
  label,
  onDelete,
  onDuplicate,
  onSelect,
}) {
  return (
    <li className={cn.base}>
      <div className={cn.body}>
        <FaIconAndLabelButton
          className={cx(cn.bodyLabel, {[cn.bodyLabelActive]: active})}
          icon={active ? 'circle' : 'circle-thin'}
          label={label}
          onClick={onSelect}
          value={id}
        />
      </div>
      <div className={cn.actions}>
        <FaIconButton
          className={cn.action}
          disabled={disabled}
          icon='clone'
          onClick={onDuplicate}
          title='Duplicate'
          value={id}
        />
        <FaIconButton
          className={cn.action}
          disabled={disabled}
          icon='trash'
          onClick={onDelete}
          title='Delete'
          value={id}
        />
      </div>
    </li>
  )
}

TemplateListItem.propTypes = {
  active: bool,
  disabled: bool,
  id: number.isRequired,
  label: string.isRequired,
  onDelete: func.isRequired,
  onDuplicate: func.isRequired,
  onSelect: func.isRequired,
}

TemplateListItem.defaultProps = {
  active: false,
  disabled: false,
}
