import React from 'react'
import {bool, func, node, string} from 'prop-types'

import CollapsibleParent from 'components/CollapsibleParent'
import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import cn from './BasicModal.css'

export default function BasicModal ({
  acceptButtonLabel,
  cancelButtonLabel,
  children,
  disableAccept,
  modalIcon,
  modalSubtitle,
  modalTitle,
  onAccept,
  onCancel,
}) {
  return (
    <ModalLayout
      acceptButtonLabel={acceptButtonLabel}
      bodyClass={cn.base}
      cancelButtonLabel={cancelButtonLabel}
      disableAccept={disableAccept}
      onAccept={onAccept}
      onClose={onCancel}
      size='small'
    >
      <ModalIconHeader
        icon={modalIcon}
        subtitle={modalSubtitle}
        title={modalTitle}
      />
      <CollapsibleParent className={cn.body} disableHeight>
        {children}
      </CollapsibleParent>
    </ModalLayout>
  )
}

BasicModal.propTypes = {
  acceptButtonLabel: node,
  cancelButtonLabel: node,
  children: node,
  disableAccept: bool,
  modalIcon: string,
  modalSubtitle: string,
  modalTitle: string,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
}

BasicModal.defaultProps = {
  acceptButtonLabel: 'Done',
  cancelButtonLabel: 'Cancel',
  children: null,
  disableAccept: false,
  modalIcon: 'search',
  modalSubtitle: 'Modal subtitle',
  modalTitle: 'Modal Title',
}
