import {createApiAction} from 'store/apiMiddleware'
import applyTransformsToArray from 'utils/applyTransformsToArray'
import createActionTypes from 'utils/createActionTypes'
import addAuthUsername from './utils/addAuthUsername'

/**
 * Api endpoints
 */
const api = {
  enzyme: '/enzymes',
  igem: '/igem',
  ncbi: '/ncbi',
}

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_ENZYME_SEARCH',
  'REQ_ENZYME_SEARCH_ERROR',
  'REQ_ENZYME_SEARCH_SUCCESS',
  'REQ_IGEM_SEARCH_ERROR',
  'REQ_IGEM_SEARCH_SUCCESS',
  'REQ_IGEM_SEARCH',
  'REQ_NCBI_SEARCH',
  'REQ_NCBI_SEARCH_ERROR',
  'REQ_NCBI_SEARCH_SUCCESS',
], 'externalElements')

/**
 * Dispatches request to search enzyme database for elements.
 *
 * @param {String}    search
 * @param {String}    elementType
 * @return {Promise}  resolves to elements
 */
export function reqEnzymeSearch ({search = ''}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_ENZYME_SEARCH,
      actions.REQ_ENZYME_SEARCH_SUCCESS,
      actions.REQ_ENZYME_SEARCH_ERROR,
    ],
    endpoint: api.enzyme,
    requestOpts: {
      query: {
        search,
      },
    },
    schemaType: 'enzymeArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request to search IGem database for elements.
 *
 * @param {String}    search
 * @param {String}    elementType
 * @return {Promise}  resolves to elements
 */
export function reqIGemSearch ({search = ''}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_IGEM_SEARCH,
      actions.REQ_IGEM_SEARCH_SUCCESS,
      actions.REQ_IGEM_SEARCH_ERROR,
    ],
    endpoint: api.igem,
    requestOpts: {
      query: {
        search,
      },
    },
    schemaType: 'igemArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}

/**
 * Dispatches request to search NCBI database for elements.
 *
 * @param {String}    searchTerm
 * @param {String}    elementType
 * @return {Promise}  resolves to elements
 */
export function reqNCBISearch ({search = '', type = ''}) {
  return createApiAction({
    actionTypes: [
      actions.REQ_NCBI_SEARCH,
      actions.REQ_NCBI_SEARCH_SUCCESS,
      actions.REQ_NCBI_SEARCH_ERROR,
    ],
    endpoint: api.ncbi,
    requestOpts: {
      query: {
        search,
        type,
      },
    },
    schemaType: 'ncbiArray',
    transformResponse: applyTransformsToArray(addAuthUsername),
  })
}
