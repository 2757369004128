import curry from 'curry'
import isNil from 'lodash/isNil'

/**
 * Used to ensure certain function parameters are met.
 * @param {string} param
 */
export function requiredParam (param) {
  const requiredParamError = new Error(`Required parameter, "${param}" is missing.`)

  // preserve original stack trace
  if (typeof Error.captureStackTrace === 'function') {
    Error.captureStackTrace(requiredParamError, requiredParam)
  }

  throw requiredParamError
}

/**
 * Returns if an array or string has a length greater than the supplied length.
 * @param {number} length
 * @returns {(toCheck: array|string) => boolean}
 */
export const isLongerThan = length => toCheck => toCheck.length > length

/**
 * Returns if an array or string is not empty.
 */
export const isNotEmpty = isLongerThan(0)

/**
 * Truncates a string representation of a float number to the supplied length.
 * @param {number} length
 * @returns {(str: string|number) => string}
 */
export const truncate = length => str => parseFloat(str).toFixed(length)

/**
 * Replaces a value with an alternative value if it matches to the supplied
 * match.
 * @param {any} match
 * @returns {(altValue: any) => any}
 */
export const altValueWhen = match => altValue => value => (
  value === match ? altValue : value
)

/**
 * Ensures an object exists for instances when we're pulling out a property from
 * an object.
 * @param {object}   obj
 * @returns {object}
 */
export const alwaysObject = obj => obj || {}

/**
 * Returns a new array with element removed from the specified index.
 * @param {array}   array
 * @param {number}  index Index to remove value at
 * @returns {array} Updated array
 */
export const removeAtIndex = (array, index) => {
  const newArray = array.slice()

  newArray.splice(index, 1)

  return newArray
}

/**
 * Returns a new array with elements swapped at the specified indices.
 * @param {Array}  array
 * @param {Number} fromIndex Index to remove value from
 * @param {Number} toIndex   Index to move value to
 * @return {Array} Updated array
 */
export const moveToIndex = ({array, fromIndex, toIndex}) => {
  const newArray = array.slice()
  const val = newArray[fromIndex]

  newArray.splice(fromIndex, 1)
  newArray.splice(toIndex, 0, val)

  return newArray
}

/**
 * Returns a new array with element added at the specified index.
 *
 * @param {Array}       arr
 * @param {Number}      index
 * @param {Any}         item
 * @param {Number}      itemsRemove
 * @return {Array}      array with added element
 */
export const insertAtIndex = ({array, index, item, itemsRemove = 0}) => {
  const newArr = array.slice()

  newArr.splice(index, itemsRemove, item)

  return newArr
}

export const insertInString = ({insert, index, string}) => (
  `${string.slice(0, index)}${insert}${string.slice(index)}`
)

export const deleteFromString = ({startIndex, endIndex, string}) => (
  `${string.slice(0, startIndex)}${string.slice(endIndex)}`
)

export function spliceString ({startIndex = 0, endIndex = 0, string = '', insert = ''}) {
  return `${string.slice(0, startIndex)}${insert}${string.slice(endIndex)}`
}

export const identity = x => x

/**
 * Returns a new array with elements swapped at the specified indices.
 * @param {array} array
 * @param {any}   item Index to remove value from
 * @return {array} Updated array
 */
export const push = (array, item) => {
  array.push(item)
  return array
}

/**
 * Returns an array of strings with each containing the number of chars supplied
 * or less.
 * @param {number} n
 * @return {(str: string) => [string]}
 */
export const splitEvery = (n) => (str) => {
  const parts = []

  for (let i = 0; i < str.length;) {
    const start = i
    i += n
    parts.push(str.slice(start, i))
  }

  return parts
}

/**
 * Returns if two number ranges overlap one another.
 * @param {number}  x1
 * @param {number}  x2
 * @param {number}  y1
 * @param {number}  y2
 * @param {boolean} [inclusive=true]
 * @returns {boolean}
 */
export const overlap = (x1, x2, y1, y2, inclusive = true) => (
  inclusive ? x1 <= y2 && x2 >= y1 : x1 < y2 && x2 > y1
)

/**
 * Returns if two number ranges overlap one another.
 * @param {string} string
 * @param {number} minX
 * @param {number} maxX
 * @param {number} minY
 * @param {number} maxY
 * @returns {string}
 */
export const sliceOverlap = ({string, minX, maxX, minY, maxY}) => (
  string.slice(Math.max(minX, minY), Math.min(maxX, maxY))
)

export const concatIf = curry((predicate, array, item) => (
  predicate ? array.concat(item) : array
))

export const concatIfNotNull = curry((array, item) => (
  !isNil(item) ? array.concat(item) : array
))

export function objectToArray (object = {}) {
  return Object.keys(object).map(key => object[key])
}

export function spliceArray (array, insertAt, deleteCount, item) {
  const copy = array.slice()
  copy.splice(insertAt, deleteCount, item)

  return copy
}

export function makeCharString (char = '', length = 0) {
  let str = ''

  for (let i = 0; i < length; i++) {
    str += char
  }

  return str
}

export function roundTo (places) {
  return x => parseFloat(x.toFixed(places))
}

export const roundToHundredth = roundTo(2)

export function toPercent (numerator, denominator, decimalPlaces = 0) {
  return `${roundTo(0)(numerator / denominator * 100)}%`
}

export default {
  altValueWhen,
  alwaysObject,
  concatIf,
  concatIfNotNull,
  deleteFromString,
  identity,
  insertAtIndex,
  insertInString,
  isLongerThan,
  isNotEmpty,
  makeCharString,
  moveToIndex,
  objectToArray,
  push,
  removeAtIndex,
  requiredParam,
  roundTo,
  sliceOverlap,
  spliceArray,
  spliceString,
  splitEvery,
  toPercent,
  truncate,
}
