export const aminoAcids = {
  f: {
    abbreviation: 'Phe',
    codons: ['ttt', 'ttc'],
    color: '#9191c1',
    name: 'Phenylalanine',
  },
  l: {
    abbreviation: 'Leu',
    codons: ['tta', 'ttg', 'ctt', 'ctc', 'cta', 'ctg'],
    color: '#71ba71',
    name: 'Leucine',
  },
  i: {
    abbreviation: 'Ile',
    codons: ['att', 'atc', 'ata'],
    color: '#99e599',
    name: 'Isoleucine',
  },
  m: {
    abbreviation: 'Met',
    codons: ['atg'],
    color: '#e8e886',
    name: 'Methionine',
  },
  v: {
    abbreviation: 'Val',
    codons: ['gtt', 'gtc', 'gta', 'gtg'],
    color: '#0F820F',
    name: '#99e599',
  },

  s: {
    abbreviation: 'Ser',
    codons: ['tct', 'tcc', 'tca', 'tcg', 'agt', 'agc'],
    color: '#f7cc8c',
    name: 'Serine',
  },
  p: {
    abbreviation: 'Pro',
    codons: ['cct', 'ccc', 'cca', 'ccg'],
    color: '#dc9682',
    name: 'Proline',
  },
  t: {
    abbreviation: 'Thr',
    codons: ['act', 'acc', 'aca', 'acg'],
    color: '#f7cc8c',
    name: 'Threonine',
  },
  a: {
    abbreviation: 'Ala',
    codons: ['gct', 'gcc', 'gca', 'gcg'],
    color: '#c8c8c8',
    name: 'Alanine',
  },

  y: {
    abbreviation: 'Tyr',
    codons: ['tat', 'tac'],
    color: '#adaddb',
    name: 'Tyrosine',
  },
  h: {
    abbreviation: 'His',
    codons: ['cat', 'cac'],
    color: '#bebef7',
    name: 'Histidine',
  },
  q: {
    abbreviation: 'Gln',
    codons: ['caa', 'cag'],
    color: '#9aeaea',
    name: 'Glutamine',
  },
  n: {
    abbreviation: 'Asn',
    codons: ['aat', 'aac'],
    color: '#9aeaea',
    name: 'Asparagine',
  },
  k: {
    abbreviation: 'Lys',
    codons: ['aaa', 'aag'],
    color: '#baceff',
    name: 'Lysine',
  },
  d: {
    abbreviation: 'Asp',
    codons: ['gat', 'gac'],
    color: '#e28585',
    name: 'Aspartic acid',
  },
  e: {
    abbreviation: 'Glu',
    codons: ['gaa', 'gag'],
    color: '#e28585',
    name: 'Glutamic acid',
  },
  c: {
    abbreviation: 'Cys',
    codons: ['tgt', 'tgc'],
    color: '#e8e886',
    name: 'Cysteine',
  },
  w: {
    abbreviation: 'Trp',
    codons: ['tgg'],
    color: '#e09fe0',
    name: 'Tryptophan',
  },
  r: {
    abbreviation: 'Arg',
    codons: ['cgt', 'cgc', 'cga', 'cgg', 'aga', 'agg'],
    color: '#baceff',
    name: 'Arginine',
  },
  g: {
    abbreviation: 'Gly',
    codons: ['ggt', 'ggc', 'gga', 'ggg'],
    color: '#ebebeb',
    name: 'Glycine',
  },
  // S: {abbreviation: 'Ser', x: 220, y: 30, color: {tupac: '#FA9600', amino: '#FA9600', shapely: '#FF7042'}, name: 'Serine'},
}

export default aminoAcids
