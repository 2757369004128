import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _values from 'lodash/values'
import {createSelector} from 'reselect'

/**
 * @root
 */
const getRoot = _get('entities')

/**
 * @base
 */
export const getEntities = _flow(getRoot, _get('customElements'))
const getPublicEntities = _flow(getRoot, _get('publicElements'))

/**
 * @helpers
 */
const getIdFromProps = (_, props) => props.id

/**
 * Returns an array of all user's custom elements.
 *
 * @return {[Object]}
 */
export const getCustomElements = createSelector(
  getEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns an array of all public custom elements.
 *
 * @return {[Object]}
 */
export const getPublicCustomElements = createSelector(
  getPublicEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns a custom element with the matching id provided in props.
 *
 * @return {Object}
 */
export const getCustomElementById = createSelector(
  getEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)
