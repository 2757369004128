import React from 'react'
import {arrayOf, bool, func, node, shape, string} from 'prop-types'

import RoundOutlineButton from 'components/RoundOutlineButton'
import cn from './AminoRadioSelect.css'

export default function AminoRadioSelect ({
  label,
  name,
  onCodonSelect,
  options,
}) {
  return (
    <div className={cn.base}>
      <span className={cn.label}>
        {label}
      </span>

      <div className={cn.options}>
        {options.map(option => (
          <RoundOutlineButton
            className={cn.btn}
            key={option.key}
            name={name}
            onClick={onCodonSelect}
            primary={option.active}
            value={option.value}
          >
            {option.label}
          </RoundOutlineButton>
        ))}
      </div>
    </div>
  )
}

AminoRadioSelect.propTypes = {
  label: node.isRequired,
  name: string.isRequired,
  onCodonSelect: func.isRequired,
  options: arrayOf(shape({
    active: bool,
    key: string.isRequired,
    label: string.isRequired,
    value: string.isRequired,
  })),
}

AminoRadioSelect.defaultProps = {
  options: [],
}
