/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import cx from 'classnames'
import { push as pushRoute } from 'connected-react-router'
import { func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { reqDeleteGroupMember, reqUpdateGroup } from 'actions/group'
import BaseButton from 'components/BaseButton'
import { TextInput } from 'components/text-input'
import { getMyUsername } from 'selectors/authUser'
import { noop } from 'utils'

import cn from './classes.css'

/**
 * @component
 */
export class SettingsView extends Component {
  static propTypes = {
    /**
     * Group in view.
     */
    group: shape({
      access: string,
      name: string,
    }),
    /**
     * Deletes user's access to group.
     */
    onDeleteAccess: func,
    /**
     * Dispatches a route change.
     */
    onPushRoute: func,
    /**
     * Updates group details.
     */
    onUpdateGroup: func,
    /**
     * User's username.
     */
    username: string,
  }

  static defaultProps = {
    group: {
      access: '',
      name: '',
    },
    onDeleteAccess: noop,
    onPushRoute: noop,
    onUpdateGroup: noop,
    username: '',
  }

  /**
   * @lifecycle
   */
  constructor (props) {
    super(props)

    const { group } = props
    const { description, name } = group

    this.state = {
      description: description || '',
      name: name || '',
      showLeaveDialog: false,
    }
  }

  /**
   * @handlers
   */
  onFieldChange = ({ target }) => {
    const { name, value } = target

    this.setState({ [name]: value })
  }

  onLeaveGroup = () => {
    const {
      group,
      onDeleteAccess,
      onPushRoute,
      username,
    } = this.props
    const { name } = group

    Promise.resolve(onDeleteAccess(name, username)).then(() => {
      onPushRoute('/')
    })
  }

  onSubmitChanges = () => {
    const { group, onUpdateGroup } = this.props
    const { name: groupName } = group

    const { description, name } = this.state

    onUpdateGroup(groupName, { description, name })
  }

  onToggleLeaveDialog = () => {
    const { showLeaveDialog } = this.state

    this.setState({ showLeaveDialog: !showLeaveDialog })
  }

  /**
   * @renders
   */
  render () {
    const { group } = this.props

    const { description, name, showLeaveDialog } = this.state

    const {
      access,
      description: preDescription,
      name: preName,
    } = group

    const isOwner = access === 'o'
    const hasAccess = isOwner || (access && access.indexOf('a') > -1)

    const subtitle = hasAccess
      ? 'You can edit the group settings.'
      : 'Only the owner and admins can edit group settings.'

    const accessSectionText = isOwner
      ? 'Owners cannot leave the group.'
      : 'This will revoke your access to all group materials.'

    const nameError = name.length < 1 ? 'Required' : ''

    const hasChanges = name !== preName || description !== preDescription

    return (
      <div className={cx('container-fluid', cn.settings)}>
        <div className={cx(cn.section__top_gutter, 'row')}>
          <div className="col-md-3">
            <h4 className={cn.section__title}>
              Group Settings
            </h4>

            <div className={cn.section__top_gutter_sm}>
              <span className={cn.section__text}>
                {subtitle}
              </span>
            </div>
          </div>

          <div className="col-md-8 col-md-offset-1">
            <div className={cn.paper}>
              <label className={cn.label} htmlFor="group-name">
                Group name

                <TextInput
                  className={cn.input}
                  disabled={!hasAccess}
                  id="group-name"
                  name="name"
                  onChange={this.onFieldChange}
                  placeholder="Group-01"
                  value={name}
                />
              </label>

              <div className={cn.section__top_gutter}>
                <label className={cn.label} htmlFor="group-description">
                  Group description (optional)

                  <TextInput
                    className={cn.input}
                    disabled={!hasAccess}
                    id="group-description"
                    onChange={this.onFieldChange}
                    name="description"
                    placeholder="My group is about..."
                    value={description}
                  />
                </label>
              </div>

              <div className={cn.section__top_gutter}>
                <BaseButton
                  disabled={!hasAccess || !!nameError || !hasChanges}
                  onClick={this.onSubmitChanges}
                  primary
                  raised
                >
                  Save Changes
                </BaseButton>
              </div>
            </div>
          </div>
        </div>

        <div className={cx(cn.section__top_gutter_lg, 'row')}>
          <div className="col-md-3">
            <h4 className={cn.section__title}>
              Your Access Settings
            </h4>

            <div className={cn.section__top_gutter_sm}>
              <span className={cn.section__text}>
                Use with caution.
              </span>
            </div>
          </div>

          <div className="col-md-8 col-md-offset-1">
            <div className={cn.paper}>
              <span
                className={cx(cn.section__text, {
                  [cn.section__text_error]: !isOwner,
                })}
              >
                {accessSectionText}
              </span>

              <div className={cn.section__top_gutter}>
                <BaseButton
                  className={cn.warn_btn}
                  disabled={isOwner}
                  onClick={this.onToggleLeaveDialog}
                >
                  Leave Group
                </BaseButton>
              </div>
            </div>
          </div>
        </div>

        {showLeaveDialog && (
          <div className={cn.dialog_wrapper}>
            <button className={cn.dialog_exit} onClick={this.onToggleLeaveDialog} type="button" />
            <div className={cn.dialog}>
              <h1 className={cn.dialog_title}>
                Are you sure you want to leave this group?
              </h1>

              <div className={cn.dialog_actions}>
                <BaseButton onClick={this.onToggleLeaveDialog}>
                  Cancel
                </BaseButton>
                <BaseButton className={cn.warn_btn} onClick={this.onLeaveGroup}>
                  Leave
                </BaseButton>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

/**
 * @redux
 */
function mapState (state) {
  return {
    username: getMyUsername(state),
  }
}

const mapDispatch = {
  onDeleteAccess: reqDeleteGroupMember,
  onPushRoute: pushRoute,
  onUpdateGroup: reqUpdateGroup,
}

/**
 * @export
 */
export default connect(mapState, mapDispatch)(SettingsView)
