import React from 'react'
import {arrayOf, func, object} from 'prop-types'

import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import AccessAdd from './AccessAdd'
import AccessList from './AccessList'
import cn from './ShareElement.css'

export default function ShareElement ({
  onAccessAdd,
  onAccessDelete,
  onModalClose,
  users,
}) {
  return (
    <ModalLayout
      acceptButtonLabel='Done'
      className={cn.base}
      onAccept={onModalClose}
      onClose={onModalClose}
    >
      <ModalIconHeader
        icon='user-plus'
        subtitle='Share this element with other users to allow them to either view or edit it.'
        title='Share Element'
      />

      <div className={cn.body}>
        <AccessAdd onAccessAdd={onAccessAdd} />
        <AccessList
          onAccessChange={onAccessAdd}
          onAccessDelete={onAccessDelete}
          users={users}
        />
      </div>
    </ModalLayout>
  )
}

ShareElement.propTypes = {
  onAccessAdd: func.isRequired,
  onAccessDelete: func.isRequired,
  onModalClose: func.isRequired,
  users: arrayOf(object).isRequired,
}
