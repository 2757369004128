/**
 * Returns if a sequence is a valid DNA string.
 * @param {String} input Sequence
 */
export const isDNAValid = input => /^[ATGC]*$/i.test(input)

export const isAminoValid = input => /^[FLSYCWPHQRIMTNKVADEG]*$/i.test(input)

/**
 * Returns if the sequence length is valid by determining if it is divisible by
 * 3 (3 DNA bases to form 1 codon).
 * @param {Number} length Sequence length
 */
export const isLengthValid = length => length % 3 === 0

/**
 * Returns an array of elements with their offset position in the sequence.
 * @param {[Object]} elements Array of elements
 */
export const mapTemplateOffsets = (elements) => {
  let offset = 0

  return elements.map((element) => {
    const mapped = {
      ...element,
      offset,
      length: element.sequence.length,
    }

    offset += element.sequence.length

    return mapped
  })
}

/**
 * Returns a string representation of the sequence formed by a collection of
 * elements. This is a higher-order function that first takes in a generating
 * function to extract the sequence from an element object. This is useful for
 * cases where we want to do something with the sequence data like transform it
 * or in cases like barcode elements, generate a psuedo sequence. The generator
 * function should expect an element object and should return a string.
 * @param {Function} generateSequence Function to extract the sequence from an element
 * @param {[Object]} elements         Array of elements
 */
export const elementsToSequence = generateSequence => elements => (
  elements.reduce((sequence, element) => sequence + generateSequence(element), '')
)

/**
 * Returns a string representation of a sequence with the provided character and
 * length.
 * @param {String} [char='x'] Character to print
 * @param {Number} [length=0] Sequence length
 */
export const psuedoSequence = (char = 'x') => (length = 0) => {
  let sequence = ''

  for (let i = 0; i < length; i++) {
    sequence += char
  }

  return sequence
}

export default {
  elementsToSequence,
  mapTemplateOffsets,
  psuedoSequence,
}
