import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _values from 'lodash/values'
import {createSelector} from 'reselect'

/**
 * @root
 */
const getRoot = _get('entities')

/**
 * @base
 */
export const getEntities = _flow(getRoot, _get('variableElements'))

/**
 * @helpers
 */
const getIdFromProps = (_, props) => props.id

/**
 * Returns an array of all variable elements.
 *
 * @return {Array}
 */
export const getVariableElements = createSelector(
  getEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns a variable element with the matching id provided in props.
 *
 * @return {Array}
 */
export const getVariableElementById = createSelector(
  getEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)
