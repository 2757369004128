import cx from 'classnames'
import React from 'react'
import {node, string} from 'prop-types'

import cn from './ProjectSettingsSection.css'

function ProjectSettingsSection ({
  children,
  className,
  subtitle,
  subtitleClass,
  title,
}) {
  return (
    <div className={cx(cn.base, className)}>
      <div className={cn.header}>
        <h2 className={cn.header__title}>
          {title}
        </h2>
        <h3 className={cx(cn.header__subtitle, subtitleClass)}>
          {subtitle}
        </h3>
      </div>
      <div className={cn.body}>
        {children}
      </div>
    </div>
  )
}

ProjectSettingsSection.propTypes = {
  children: node.isRequired,
  className: string,
  subtitle: node,
  subtitleClass: string,
  title: node,
}

ProjectSettingsSection.defaultProps = {
  className: '',
  subtitle: '',
  subtitleClass: '',
  title: '',
}

export default ProjectSettingsSection
