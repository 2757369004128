import { func, number } from 'prop-types'
import React from 'react'

import ConstructViewer from 'components/ConstructViewer'
import SequenceEditor from 'components/SequenceEditor'

import { ConnectedDesignerTabs } from './DesignerTabs'
import ResizablePane from './ResizablePanes/ResizablePane'
import ConnectedSequenceEditorProvider from './SequenceEditorProvider'
import cn from './DesignerCore.css'

export default function DesignerCore ({
  onResizeHandlerCreate,
  resizedIndex,
}) {
  return (
    <div className={cn.root}>
      <ResizablePane
        initialSize={600}
        min={500}
        onResizeStart={onResizeHandlerCreate(0)}
        resized={resizedIndex === 0}
      >
        <ConnectedDesignerTabs />
      </ResizablePane>

      <div className={cn.pane__no_resize}>
        <ConstructViewer />
      </div>

      <ResizablePane
        boundPosition="left"
        initialSize={300}
        min={200}
        onResizeStart={onResizeHandlerCreate(1)}
        resized={resizedIndex === 1}
      >
        <ConnectedSequenceEditorProvider>
          {editorProps => <SequenceEditor {...editorProps} />}
        </ConnectedSequenceEditorProvider>
      </ResizablePane>
    </div>
  )
}

DesignerCore.propTypes = {
  onResizeHandlerCreate: func.isRequired,
  resizedIndex: number.isRequired,
}
