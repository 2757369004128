import {addElementTag} from 'core/template/Tag'
import {makeCharString} from 'core/utils'
import { idMaker } from 'core/utils/IdGenerator'
import { getEditingTemplate, getEditingTemplateTags } from 'selectors/templates'
import randomSequence from 'core/sequence/randomSequence'
import {getJobResult, getDNAOptimizeJob} from 'services/ApiService'
import {JOB_POLL_INTERVAL} from 'config'

export const actions = {
  addElementToTemplate: 'ADD_ELEMENT_TO_TEMPLATE',
  addFeatureTags: 'ADD_FEATURE_TAGS',
  addPendingAminoElementToTemplate: 'ADD_PENDING_AMINO_ELEMENT_TO_TEMPLATE',
  addTagToTemplate: 'ADD_TAG_TO_TEMPLATE',
  addTemplate: 'ADD_TEMPLATE',
  deleteElementFromTemplate: 'DELETE_ELEMENT_FROM_TEMPLATE',
  deleteTagsFromTemplate: 'DELETE_TAGS_FROM_TEMPLATE',
  deleteTemplate: 'DELETE_TEMPLATE',
  duplicateTemplate: 'DUPLICATE_TEMPLATE',
  fulfillPendingAminoElement: 'FULFILL_PENDING_AMINO_ELEMENT',
  setTemplates: 'SET_TEMPLATES',
  startTemplateEdit: 'START_TEMPLATE_EDIT',
  updateExpressionSystem: 'UPDATE_EXPRESSION_SYSTEM',
  updateSequence: 'UPDATE_SEQUENCE',
  updateTags: 'UPDATE_TAGS',
  updateTemplate: 'UPDATE_TEMPLATE',
  updateTemplateElementOrder: 'UPDATE_TEMPLATE_ELEMENT_ORDER',
  updateTemplateVariations: 'UPDATE_TEMPLATE_VARIATIONS',
}

export const startTemplateEdit = (templateId) => ({
  type: actions.startTemplateEdit,
  templateId,
})

export const setTemplates = (templates = []) => ({
  type: actions.setTemplates,
  templates,
})

function updateTemplate (construct) {
  return {
    payload: construct,
    type: actions.updateTemplate,
  }
}

export const addElementToTemplate = ({ element, index, id }) => ({
  type: actions.addElementToTemplate,
  element,
  index,
  id,
})

export function addElement (element, index) {
  return async (dispatch, getState, container) => {
    const constructState = getEditingTemplate(getState())
    const construct = container.constructRepo.createConstructEntity(constructState)
    const id = construct.getNewTagId()

    construct.addElement(element, index, id)

    dispatch(updateTemplate(construct.serialize()))

    if (element.sequence) {
      return
    }

    const { data } = await container.api.element.getCustomElementWithSequence(element.id)

    construct.insertElementSequence(data.sequence, id)

    dispatch(updateTemplate(construct.serialize()))
  }
}

export const addTemplate = () => ({
  type: actions.addTemplate,
})

export const duplicateTemplate = (templateId) => ({
  type: actions.duplicateTemplate,
  templateId,
})

export const deleteTemplate = (templateId) => ({
  type: actions.deleteTemplate,
  templateId,
})

export const updateTemplateVariations = variations => ({
  type: actions.updateTemplateVariations,
  variations,
})

export const deleteElementFromTemplate = index => ({
  type: actions.deleteElementFromTemplate,
  index,
})

export const updateTemplateElementOrder = ({elementOrder, moveId, oldIndex, newIndex}) => ({
  type: actions.updateTemplateElementOrder,
  elementOrder,
  moveId,
  oldIndex,
  newIndex,
})

export const updateExpressionSystem = expressionSystem => ({
  type: actions.updateExpressionSystem,
  expressionSystem,
})

export const addTagToTemplate = tag => ({
  type: actions.addTagToTemplate,
  tag,
})

export const deleteTagsFromTemplate = tagIds => ({
  type: actions.deleteTagsFromTemplate,
  tagIds,
})

export const updateTags = ({tags, sequence, start, end, relatedTagIds, tagId}) => ({
  type: actions.updateTags,
  tags,
  sequence,
  start,
  end,
  relatedTagIds,
  tagId,
})

export const addFeatureTags = (tagId, features) => ({
  type: actions.addFeatureTags,
  features,
  tagId,
})

export const updateSequence = ({ sequence, offset, elementId }) => ({
  type: actions.updateSequence,
  elementId,
  sequence,
  offset,
})

export default {
  actions,
  addElementToTemplate,
  addFeatureTags,
  addTagToTemplate,
  addTemplate,
  deleteElementFromTemplate,
  deleteTagsFromTemplate,
  deleteTemplate,
  duplicateTemplate,
  setTemplates,
  startTemplateEdit,
  updateExpressionSystem,
  updateSequence,
  updateTags,
  updateTemplateElementOrder,
  updateTemplateVariations,
}
