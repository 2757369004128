import React from 'react'

import FontAwesomeIcon from 'components/FontAwesomeIcon'
import cn from './PollingModalSpinner.css'

export default function PollingModalSpinner (props) {
  return (
    <div className={cn.base}>
      <FontAwesomeIcon icon='spinner' size='3x' spin />
    </div>
  )
}
