import get from 'lodash/fp/get'
import identity from 'lodash/identity'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import values from 'lodash/values'
import {createSelector} from 'reselect'

import {idMaker} from 'core/utils/IdGenerator'
import invariant from 'core/utils/invariant'
import createReducer from 'utils/createReducer'

const REQUEST_STATUS = {
  pending: 'REQUEST_PENDING',
  failed: 'REQUEST_FAILED',
  succeeded: 'REQUEST_SUCCEEDED',
}

/**
 * Request object.
 */

export class Request {
  constructor ({id, requestType, request, requestProps}) {
    invariant(!isNil(id), 'An id is required')
    invariant(isString(requestType), 'A request type is required')

    this.id = id
    this.requestType = requestType
    this.request = request
    this.requestProps = requestProps
    this.status = REQUEST_STATUS.pending
    this.error = ''
    this.data = {}
  }

  fail = (error) => {
    this.status = REQUEST_STATUS.failed
    this.error = error

    return this
  }

  succeed = (data) => {
    this.status = REQUEST_STATUS.succeeded
    this.data = data

    return this
  }
}

/**
 * @selectors
 */

const getRequests = get('requests')

export const isRequestTypePending = createSelector(
  getRequests,
  (_, {requestType}) => requestType,
  (requests, type) => values(requests).reduce((isPending, request) => (
    isPending || (request.requestType === type && request.status !== REQUEST_STATUS.pending)
  ), false),
)

export const getRequestIfFulfilled = createSelector(
  getRequests,
  (_, {requestType, requestProps}) => ({requestType, requestProps}),
  (requests, {requestType, requestProps}) => values(requests).reduce((fulfilled, request) => (
    fulfilled || (request.requestType === requestType &&
      request.status === REQUEST_STATUS.fulfilled &&
      isEqual(request.requestProps, requestProps) ? request : null
    )
  ), null)
)

export const getRequestById = createSelector(
  getRequests,
  (_, {id}) => id,
  (requests, id) => requests[id],
)

/**
 * @actions
 */

export const ACTION_TYPES = {
  addRequest: '@REQUESTS/ADD_REQUEST',
  failRequest: '@REQUESTS/FAIL_REQUEST',
  fulfillRequest: '@REQUESTS/FULFILL_REQUEST',
}

export const makeRequestAction = ({
  requestType,
  request,
  onRequest = identity,
  onSuccess,
  onError,
}) =>
  (...requestProps) => (dispatch, getState) => {
    const state = getState()

    if (isRequestTypePending(state, {requestType})) {
      return
    }

    const maybePreviousRequest = getRequestIfFulfilled(state, {requestType, requestProps})

    if (maybePreviousRequest) {
      return onSuccess({
        data: maybePreviousRequest.data,
        dispatch,
        getState,
        requestProps,
      })
    }

    const requestId = idMaker.newId(getRequests(state))

    const newRequest = new Request({
      requestType,
      request,
      requestProps,
      id: requestId,
    })

    // Callback with request id for caller to track the request in Redux.
    onRequest({dispatch, getState, requestId})

    dispatch({
      type: ACTION_TYPES.addRequest,
      id: requestId,
      request: newRequest,
    })

    return Promise.resolve(request(...requestProps))
      .then(({data}) => {
        dispatch({
          type: ACTION_TYPES.fulfillRequest,
          id: requestId,
        })

        return onSuccess({data, dispatch, getState, requestProps})
      })
      .catch(({error}) => {
        dispatch({
          type: ACTION_TYPES.failRequest,
          error,
          id: requestId,
        })

        return onError({error, dispatch, getState, requestProps})
      })
  }

/**
 * @reducer
 */

const initialState = {}

export const requestReducer = createReducer(initialState, {
  [ACTION_TYPES.addRequest]: (state, {id, request}) => (
    state[id] ? state : {...state, [id]: request}
  ),

  [ACTION_TYPES.failRequest]: (state, {id, error}) => ({
    ...state,
    [id]: state[id].fail(error),
  }),

  [ACTION_TYPES.fulfillRequest]: (state, {id}) => ({
    ...state,
    [id]: state[id].succeed(),
  })
})
