import cx from 'classnames'
import { bool, node, string } from 'prop-types'
import React from 'react'

import TooltipBlock from 'components/blocks/TooltipBlock'
import cn from './Dropdown.css'

export default function Dropdown ({
  children,
  className,
  open,
  optionsClass,
  position,
  tipClass,
}) {
  return (
    <div className={cx(cn.base, className, { [cn.open]: open })}>
      <TooltipBlock
        className={cx(cn.menu, optionsClass)}
        tipClass={tipClass}
        tooltipPosition={position}
      >
        {children}
      </TooltipBlock>
    </div>
  )
}

Dropdown.propTypes = {
  children: node.isRequired,
  className: string,
  open: bool,
  optionsClass: string,
  position: string, // See valid positions in TooltipBlock.
  tipClass: string,
}

Dropdown.defaultProps = {
  className: '',
  open: false,
  optionsClass: '',
  position: 'top left',
  tipClass: '',
}
