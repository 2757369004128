import { push } from 'connected-react-router'
import { func, shape, string } from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { addToast } from 'actions/toast'
import { SITE_ROOT } from 'config'
import {
  login,
  loginFromStorage,
  logout,
} from 'providers/store/auth-user/actions'
import { getMyAuthToken } from 'selectors/authUser'
import auth from 'services/auth'
import { noop } from 'utils'

export class AuthProvider extends Component {
  static propTypes = {
    authToken: string.isRequired,
    children: func.isRequired,
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
    onError: func,
    onLogin: func,
    onLoginFromStorage: func.isRequired,
    onLogout: func.isRequired,
    onRedirect: func.isRequired,
  }

  static defaultProps = {
    onError: noop,
    onLogin: noop,
  }

  constructor(props) {
    super(props)

    this.state = { checkingAuth: false }

    this.auth = auth
    this.auth.setupLockEvents(this.handleError, this.handleAuthSuccess)
  }

  componentDidMount() {
    const { onLoginFromStorage } = this.props

    onLoginFromStorage().catch(e => {
      // There should be only be an error message when there was a previously
      // active session.
      if (e.message) {
        this.handleError(e.message)
      }

      this.redirectUnauthorizedToHome()
    })
  }

  handleAuthSuccess = ({ credentials, profile, token }) => {
    const { onLogin } = this.props

    this.setState({ checkingAuth: true })

    onLogin(token, credentials, profile)
      .catch(e => {
        this.handleError(e.message || e)
      })
      .finally(() => {
        this.setState({ checkingAuth: false })
      })
  }

  handleError = message => {
    const { onError } = this.props

    onError({
      message,
      type: 'error',
    })
  }

  handleLoginShow = () => {
    this.auth.handleLockShow()
  }

  handleLogout = () => {
    const { onLogout } = this.props

    onLogout()
    this.auth.handleLogout()

    /**
     * Redirect user back to home. This also resets all redux state so we don't
     * have to worry about having to manually flush any private data from the store.
     */
    window.location.href = SITE_ROOT
  }

  handleSignUpShow = () => {
    this.auth.handleLockShow({ initialScreen: 'signUp' })
  }

  redirectUnauthorizedToHome() {
    const { location, onRedirect } = this.props
    const { pathname } = location

    if (pathname !== SITE_ROOT) {
      onRedirect(SITE_ROOT)
    }
  }

  render() {
    const { checkingAuth } = this.state
    const { authToken, children } = this.props

    return children({
      checkingAuth,
      isAuthorized: !!authToken,
      onLoginShow: this.handleLoginShow,
      onLogout: this.handleLogout,
      onSignUpShow: this.handleSignUpShow,
    })
  }
}

const mapState = state => ({ authToken: getMyAuthToken(state) })

const mapDispatch = {
  onError: addToast,
  onLogin: login,
  onLoginFromStorage: loginFromStorage,
  onLogout: logout,
  onRedirect: push,
}

export default connect(
  mapState,
  mapDispatch,
)(AuthProvider)
