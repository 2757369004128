import React from 'react'
import {arrayOf, bool, func, object} from 'prop-types'
import cx from 'classnames'

import FontAwesomeButton from 'components/FontAwesomeButton'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import RectStack from 'components/RectStack'
import cn from './AminoStack.css'

export default function AminoStack ({
  active,
  aminoStack,
  disabled,
  incomplete,
  onDelete,
  onSelect,
}) {
  console.log({disabled})
  return (
    <div className={cx(cn.base, {[cn.active]: active})}>
      <RectStack
        className={cn.stack}
        height={100}
        stack={aminoStack}
      />
      <button className={cn.selectBtn} onClick={onSelect} />
      {!disabled && active && (
        <FontAwesomeButton
          className={cn.deleteBtn}
          icon='times'
          onClick={onDelete}
          title='Delete stack'
        />
      )}
      {incomplete && (
        <FontAwesomeIcon
          className={cn.warning}
          icon='exclamation'
          title='Incomplete stack'
        />
      )}
    </div>
  )
}

AminoStack.propTypes = {
  active: bool,
  aminoStack: arrayOf(object).isRequired,
  disabled: bool,
  incomplete: bool,
  onDelete: func.isRequired,
  onSelect: func.isRequired,
}

AminoStack.defaultProps = {
  active: false,
  disabled: false,
  incomplete: false,
}
