import React, {Component} from 'react'
import {arrayOf, bool, func, number} from 'prop-types'

import Header from './TemplateListHeader'
import Item from './TemplateListItem'
import cn from './TemplateList.css'

export class TemplateList extends Component {
  static propTypes = {
    disabled: bool,
    editingId: number,
    onTemplateAdd: func,
    onTemplateDelete: func,
    onTemplateDuplicate: func,
    onTemplateSelect: func,
    templateIds: arrayOf(number),
  }

  static defaultProps = {
    disabled: false,
    editingId: -1,
    onTemplateAdd: () => console.log('Added template'),
    onTemplateDelete: () => console.log('Deleted template'),
    onTemplateDuplicate: () => console.log('Duplicated template'),
    onTemplateSelect: () => console.log('Selected template'),
    templateIds: [],
  }

  handleTemplateAdd = () => {
    return this.props.disabled ? false : this.props.onTemplateAdd()
  }

  handleTemplateDelete = ({target}) => {
    return this.props.disabled || this.props.templateIds.length < 1
      ? false
      : this.props.onTemplateDelete(Number(target.value))
  }

  handleTemplateDuplicate = ({target}) => {
    return this.props.disabled ? false : this.props.onTemplateDuplicate(Number(target.value))
  }

  handleTemplateSelect = ({target}) => {
    this.props.onTemplateSelect(Number(target.value))
  }

  render () {
    const {disabled, editingId, templateIds} = this.props

    return (
      <div className={cn.base}>
        <Header
          disabled={disabled}
          onTemplateAdd={this.handleTemplateAdd}
          total={templateIds.length}
        />
        <div className={cn.body}>
          <ul className={cn.list}>
            {templateIds.map((id, i) => (
              <Item
                active={editingId === id}
                disabled={disabled}
                id={id}
                key={id}
                label={`Design #${i + 1}`}
                onDelete={this.handleTemplateDelete}
                onDuplicate={this.handleTemplateDuplicate}
                onSelect={this.handleTemplateSelect}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default TemplateList
