import classNames from 'classnames'
import isString from 'lodash/isString'
import React, { Component } from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  string,
} from 'prop-types'
import { connect } from 'react-redux'
import { Motion, spring } from 'react-motion'

import { selectElement } from 'actions/designer'
import { deleteElementFromTemplate, updateTemplateElementOrder } from 'actions/templates'
import { addConstructElement } from 'providers/store/construct/actions'
import { getActiveElement, getProjectId } from 'selectors/designer'
import { getIfUserCanEditProject } from 'selectors/project'
import { getEditingTemplateElementOrder } from 'selectors/templates'
import { insertAtIndex, swapAtIndex, removeAtIndex } from 'utils/array'

import ConnectedConstructElement from './ConstructElement'
import ConnectedDragBoundary from './DragBoundary'
import DragProvider from './DragProvider'
import ConnectedDropTarget from './DropTarget'
import ElementSize from './ElementSize'

import cn from './ConstructViewer.css'

// Id to identify drop targets from construct elements.
export const DROP_ID = 'drop'

const HEIGHT_PADDING = 10
const DEFAULT_ELEMENT_HEIGHT = ElementSize.height + HEIGHT_PADDING

const motionConfig = { stiffness: 300, damping: 30 }

/**
 * Returns the y position in pixels for a construct elements based on its index
 * position in the construct.
 */
const getYPos = i => spring((i * DEFAULT_ELEMENT_HEIGHT), motionConfig)

export class ConstructViewer extends Component {
  static propTypes = {
    activeElementId: number,
    className: string,
    disabled: bool,
    elementIds: arrayOf(number),
    onTemplateAddElement: func.isRequired,
    onTemplateDeleteElement: func.isRequired,
    onTemplateElementOrderUpdate: func.isRequired,
    onSelectElement: func.isRequired,
  }

  static defaultProps = {
    activeElementId: -1,
    className: '',
    disabled: false,
    elementIds: [],
  }

  constructor (props) {
    super(props)

    this.state = {
      dropIndex: -1,
      elementIds: props.elementIds,
    }
  }

  componentWillReceiveProps ({ elementIds }) {
    this.setState({ elementIds })
  }

  handleDropTargetAdd = (index) => {
    const { elementIds } = this.state

    this.setState({
      dropIndex: index,
      elementIds: insertAtIndex(
        elementIds,
        index,
        `${DROP_ID}-${index}`, // Needs to differentiate from tail drop id.
      ),
    })
  }

  /**
   * Adds an element to the sequence. We have to reset the dropIndex before
   * adding the element to flag that no drop target exists.
   * @param {Object} element Element to add
   * @param {Number} index   Index to add element
   */
  handleElementAdd = (element, index) => {
    const { onTemplateAddElement } = this.props

    this.setState({ dropIndex: -1 })
    onTemplateAddElement(element, index)
  }

  removeDropTarget = () => {
    const { dropIndex, elementIds } = this.state

    if (dropIndex < 0 || (elementIds[dropIndex] && elementIds[dropIndex].element)) {
      return
    }

    this.setState({
      dropIndex: -1,
      elementIds: removeAtIndex(elementIds, dropIndex),
    })
  }

  handleElementDelete = (index) => {
    const { onTemplateDeleteElement } = this.props
    const { elementIds } = this.state

    const sequence = removeAtIndex(elementIds, index)

    this.setState({ elementIds: sequence })

    // Small delay to allow deletion animation to complete.
    setTimeout(() => {
      onTemplateDeleteElement(index)
    }, 300)
  }

  handleDropTargetMove = (oldIndex, newIndex, maybeDropIndex) => {
    const { dropIndex, elementIds } = this.state

    this.setState({
      dropIndex: typeof maybeDropIndex === 'number' ? maybeDropIndex : dropIndex,
      elementIds: swapAtIndex(elementIds, oldIndex, newIndex),
    })
  }

  handleElementMove = ({ finalIndex }) => {
    const { onTemplateElementOrderUpdate } = this.props
    const { elementIds } = this.state
    const id = elementIds[finalIndex]

    onTemplateElementOrderUpdate({
      moveId: id,
      newIndex: finalIndex,
    })
  }

  render () {
    const {
      activeElementId,
      className,
      disabled,
      onSelectElement,
    } = this.props
    const { elementIds } = this.state

    return (
      <DragProvider
        elementIds={elementIds}
        onClick={onSelectElement}
        onDragEnd={this.handleElementMove}
        onDragIndexChange={this.handleDropTargetMove}
      >
        {({
          lastPressedIndex,
          mouseY,
          pressed,
          onDragStart,
        }) => (
          <div className={classNames(cn.base, className)}>
            <ConnectedDragBoundary className={cn.sequence} onDragLeave={this.removeDropTarget}>
              {elementIds.concat(DROP_ID).map((id, i) => {
                const dragging = i === lastPressedIndex && pressed
                const style = { y: dragging ? mouseY : getYPos(i) }

                return (
                  <Motion key={id} style={style}>
                    {({ y }) => {
                      const motionStyle = {
                        position: 'absolute',
                        transform: `translate3d(0, ${y}px, 0)`,
                        zIndex: dragging ? 2 : 1,
                      }

                      return isString(id)
                        ? (
                          <ConnectedDropTarget
                            index={i}
                            onDrop={this.handleElementAdd}
                            style={motionStyle}
                          />
                        )
                        : (
                          <ConnectedConstructElement
                            disabled={disabled}
                            id={id}
                            index={i}
                            onDelete={this.handleElementDelete}
                            onDragStart={onDragStart}
                            onHover={this.handleDropTargetAdd}
                            onHoverMove={this.handleDropTargetMove}
                            pressY={y}
                            selected={activeElementId === id}
                            style={motionStyle}
                          />
                        )
                    }}
                  </Motion>
                )
              })}
            </ConnectedDragBoundary>
          </div>
        )}
      </DragProvider>
    )
  }
}

const mapState = state => ({
  activeElementId: getActiveElement(state).id,
  disabled: !getIfUserCanEditProject(state, { id: getProjectId(state) }),
  elementIds: getEditingTemplateElementOrder(state),
})

const mapDispatch = {
  onTemplateAddElement: addConstructElement,
  onTemplateDeleteElement: deleteElementFromTemplate,
  onTemplateElementOrderUpdate: updateTemplateElementOrder,
  onSelectElement: selectElement,
}

export default connect(mapState, mapDispatch)(ConstructViewer)
