import React from 'react'
import { arrayOf, bool, func, object, shape, string } from 'prop-types'

import BaseButton from 'components/buttons/BaseButton'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import SelectInput from 'components/SelectInput'
import { TextInput } from 'components/text-input'
import cn from './index.css'

export default function ControlSearchType({
  disableSearch,
  hint,
  onInputChange,
  onSearch,
  searchProps,
  typeOptions,
}) {
  const handleSearch = () => onSearch(searchProps.search)

  return (
    <div className={cn.base}>
      <TextInput
        className={cn.input}
        name="search"
        onChange={onInputChange}
        onReturn={handleSearch}
        placeholder={hint}
        value={searchProps.search}
      />
      <SelectInput
        className={cn.select}
        name="type"
        onChange={onInputChange}
        options={typeOptions}
        toggleBodyClass={cn.selectToggleBody}
        value={searchProps.type}
      />
      <BaseButton
        className={cn.btn}
        disabled={disableSearch}
        onClick={handleSearch}
        primary={!disableSearch}
      >
        <div className={cn.btnBody}>
          <FontAwesomeIcon icon="search" />
          <span className={cn.btnLabel}>Search</span>
        </div>
      </BaseButton>
    </div>
  )
}

ControlSearchType.propTypes = {
  disableSearch: bool,
  hint: string,
  onInputChange: func.isRequired,
  onSearch: func.isRequired,
  searchProps: shape({
    search: string.isRequired,
    type: string.isRequired,
  }).isRequired,
  typeOptions: arrayOf(object).isRequired,
}

ControlSearchType.defaultProps = {
  disableSearch: false,
  hint: 'Search directory',
}
