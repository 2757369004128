import React from 'react'
import {bool, func, string} from 'prop-types'

import { ModalIconHeader } from 'components/modal-icon-header'
import ModalLayout from 'components/ModalLayout'
import { TextInput } from 'components/text-input'
import cn from './ProjectCommitModal.css'

export default function ProjectCommitModal ({
  acceptButtonLabel,
  commitMessage,
  disableAccept,
  onAccept,
  onCancel,
  onChangeCommitMessage,
}) {
  return (
    <ModalLayout
      acceptButtonLabel={acceptButtonLabel}
      disableAccept={disableAccept}
      onAccept={onAccept}
      onClose={onCancel}
      size='small'
    >
      <ModalIconHeader
        icon='floppy-o'
        subtitle='Saves the current state of your project.'
        title='Commit project'
      />
      <div className={cn.body}>
        <label className={cn.inputLabel} htmlFor='commit-message'>
          Add a brief message describing the changes
        </label>
        <TextInput
          className={cn.input}
          id='commit-message'
          name='message'
          onChange={onChangeCommitMessage}
          placeholder='Summarize your changes'
          value={commitMessage}
        />
      </div>
    </ModalLayout>
  )
}

ProjectCommitModal.propTypes = {
  acceptButtonLabel: string,
  commitMessage: string,
  disableAccept: bool,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
  onChangeCommitMessage: func.isRequired,
}

ProjectCommitModal.defaultProps = {
  commitMessage: '',
  disableAccept: false,
}
