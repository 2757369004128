import cx from 'classnames'
import {
  arrayOf,
  func,
  shape,
  string,
} from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { reqCreateGroupMember, reqDeleteGroupMember } from 'actions/group'
import ShareUserCreator from 'components/ShareUserCreator'
import ShareUserList from 'components/ShareUserList'
import { noop } from 'utils'

import cn from './classes.css'

/**
 * @component
 */
export const ShareView = ({
  group,
  onCreateShare,
  onDeleteShare,
}) => {
  const { access } = group

  const hasAdminAccess = access === 'o' || (access && access.indexOf('a') > -1)

  const accessOptions = [
    { key: 'View Only', label: 'View Only', value: 'r' },
    { key: 'Member', label: 'Member', value: 'rw' },
    { key: 'Admin', label: 'Admin', value: 'rwa' },
  ]
  const isDisabled = !hasAdminAccess
  const subtitle = hasAdminAccess
    ? 'You can add, change, or revoke member access to this group.'
    : 'Only admins and owners can change member access.'

  return (
    <div className="container-fluid">
      <div className={cx(cn.section__top_gutter, 'row')}>
        <div className="col-md-3">
          <h4 className={cn.section__title}>
            Group Members
          </h4>

          <div className={cn.section__top_gutter_sm}>
            <span className={cn.section__text}>
              {subtitle}
            </span>
          </div>

          <div className={cn.section__top_gutter}>
            <h4 className={cn.section__title}>
              Access Types
            </h4>
          </div>

          <div className={cn.section__top_gutter_sm}>
            <span className={cn.section__text_dk}>
              Admin
            </span>
            <span className={cn.section__text}>
              Admins can add/remove users to the group. They can also delete any
              group projects.
            </span>
          </div>

          <div className={cn.section__top_gutter_sm}>
            <span className={cn.section__text_dk}>
              Member
            </span>
            <span className={cn.section__text}>
              Members can edit and create group projects. They can also delete any
              projects that they created.
            </span>
          </div>

          <div className={cn.section__top_gutter_sm}>
            <span className={cn.section__text_dk}>
              View Only
            </span>
            <span className={cn.section__text}>
              View only users cannot edit or create any projects.
            </span>
          </div>
        </div>

        <div className="col-md-8 col-md-offset-1">
          <div className={cn.paper}>
            <ShareUserCreator
              accessOptions={accessOptions}
              disabled={isDisabled}
              onShareUserCreate={(shareData) => {
                const { name } = group

                // Return the promise
                return Promise.resolve(onCreateShare(name, shareData))
              }}
              shares={group.shares}
            />

            <div className={cn.share_list}>
              <ShareUserList
                accessOptions={accessOptions}
                disabled={isDisabled}
                onDelete={(username) => {
                  const { name } = group

                  // Return the promise
                  return Promise.resolve(onDeleteShare(name, username))
                }}
                onEdit={(shareData) => {
                  const { name } = group

                  // Return the promise
                  return Promise.resolve(onCreateShare(name, shareData))
                }}
                users={group.shares}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShareView.propTypes = {
  /**
   * Group in view.
   */
  group: shape({
    /**
     * List of members.
     */
    shares: arrayOf(shape({
      access: string,
      username: string,
    })),
  }),
  /**
   * Creates a group member or edits an existing member's access.
   */
  onCreateShare: func,
  /**
   * Deletes a group member.
   */
  onDeleteShare: func,
}

ShareView.defaultProps = {
  group: {
    shares: [],
  },
  onCreateShare: noop,
  onDeleteShare: noop,
}

ShareView.displayName = 'ShareView'

/**
 * @redux
 */
const mapDispatch = {
  onCreateShare: reqCreateGroupMember,
  onDeleteShare: reqDeleteGroupMember,
}

/**
 * @export
 */
export default connect(null, mapDispatch)(ShareView)
