import omit from 'lodash/omit'

import {actions} from 'actions/toast'

const initialState = {
  items: {},
}

export default function toastReducer (state = initialState, action) {
  const {type, toast, id} = action

  if (toast) {
    return {
      items: {
        ...state.items,
        [Object.keys(state.items).length]: toast,
      }
    }
  }
  else if (type === actions.deleteToast) {
    return {
      items: omit(state.items, id),
    }
  }

  return state
}
