import {API} from './createApiAction'
import normalizeData from './normalizeData'
import request from './request'

/**
 * Returns a configured Redux middleware function to handle api requests.
 *
 * @param {Object}    config
 *    @param {String}   baseUrl
 *    @param {Object}   headers
 *    @param {[Func]}   requestTransforms
 * @return {Func}     api middleware
 */
export default function createApiMiddleware (config = {}) {
  const {
    baseUrl = '',
    headers = {},
    requestTransforms = [],
  } = config

  const createRequest = (state, opts) => {
    return requestTransforms.reduce((req, transform) => {
      return transform(state, req)
    }, {...opts, headers})
  }

  return function apiMiddleware (store) {
    return (next) => (action) => {
      if (!action[API]) {
        return next(action)
      }

      const {
        actionTypes,
        endpoint,
        isDestructive,
        onError,
        onResolve,
        paginate,
        requestOpts,
        schemaType,
        transformResponse,
      } = action[API]

      const [requestType, successType, failureType] = actionTypes

      next({
        type: requestType,
        paginate,
      })

      const state = store.getState()

      const req = createRequest(state, requestOpts)

      return request(`${baseUrl}${endpoint}`, req)
        .then((response) => {
          const {data, lastPage} = response

          const preNormalize = typeof transformResponse === 'function'
            ? transformResponse(data, state)
            : data

          const normalizedData = schemaType
            ? normalizeData(preNormalize, schemaType)
            : preNormalize

          next({
            type: successType,
            paginate: {
              ...paginate,
              totalPages: lastPage + 1, // Index starts at 1
            },
            response: {
              ...normalizedData,
              isDestructive,
            },
          })

          if (typeof onResolve === 'function') {
            onResolve(response)
          }

          return response
        })
        .catch((error) => {
          next({
            type: failureType,
            error: error.message,
            paginate,
          })

          if (typeof onError === 'function') {
            onError(error.message)
          }
        })
    }
  }
}
