import {createApiAction} from 'store/apiMiddleware'
import createActionTypes from 'utils/createActionTypes'

/**
 * Api endpoint
 */
const api = '/users'

/**
 * @constants
 */
export const actions = createActionTypes([
  'REQ_USER_INFO',
  'REQ_USER_INFO_ERROR',
  'REQ_USER_INFO_SUCCESS',
], 'user')

/**
 * Returns a user's basic information.
 *
 * @param {String}    username
 * @return {Promise}  resolves to user's info
 */
export function reqUserInfo (username = '') {
  return createApiAction({
    actionTypes: [
      actions.REQ_USER_INFO,
      actions.REQ_USER_INFO_SUCCESS,
      actions.REQ_USER_INFO_ERROR,
    ],
    endpoint: `${api}/${username}`,
  })
}
