import values from 'lodash/values'

import {aminoAcids} from 'utils/aminoAcids'

// Repackages amino acids with a zeroed value property.
const aminos = Object.keys(aminoAcids).reduce((final, key) => {
  return {
    ...final,
    [key]: {...aminoAcids[key], value: 0},
  }
}, {})

// Cache amino acid keys in array. This will be used to contruct amino maps and
// keeps the order the same.
const keys = Object.keys(aminoAcids)

/**
 * Returns the structure needed by our components to correctly render an amino.
 * @param  {String} key   Corresponding key to the amino
 * @param  {Number} value Percentage for map
 * @return {Object}       Amino acid object
 */
const getAmino = (key, value) => ({
  color: aminoAcids[key].color,
  key,
  label: aminoAcids[key].abbreviation,
  value: value || 0,
})

/**
 * Returns an amino map. If a map is passed in, we build a map with the values
 * provided.
 * @param  {Object} [map={}] Amino map
 * @return {Object}          Amino map with correct shape
 */
export const getAminoMap = (map = {}) => {
  return keys.reduce((final, key) => {
    const amino = map[key] || {}

    return {
      ...final,
      [key]: getAmino(key, amino.value),
    }
  }, {})
}

// Amino acid presets with their values pre-calculated and names.
export const presets = [
  // None.
  {
    key: 'no',
    label: 'None',
    map: {},
    value: '',
  },
  // Even distribution across all aminos.
  {
    key: 'eq',
    label: 'Equalize',
    map: Object.keys(aminos).reduce((acc, curr) => {
      return {...acc, [curr]: 5}
    }, {}),
    value: 'eq',
  },
  // Alanine, Isoleucine, Leucine, Methionine, Phenylalanine, Tryptophan,
  // Tyrosine, Valine.
  {
    key: 'hy',
    label: 'Hydrophobic Group',
    map: {a: 13, i: 13, l: 13, m: 13, f: 12, w: 12, y: 12, v: 12},
    value: 'hy',
  },
  // Aspartic Acid, Glutamic Acid.
  {
    key: 'ng',
    label: 'Negatively Charged Group',
    map: {d: 50, e: 50},
    value: 'ng',
  },
  // Serine, Threonine, Asparagine, Glutamine.
  {
    key: 'pl',
    label: 'Polar Group',
    map: {s: 25, t: 25, n: 25, q: 25},
    value: 'pl',
  },
  // Arginine, Histidine, Lysine.
  {
    key: 'ps',
    label: 'Positively Charged Group',
    map: {r: 34, h: 33, k: 33},
    value: 'ps',
  },
  // Cysteine, Glycine, Proline.
  {
    key: 'sp',
    label: 'Special Group',
    map: {c: 34, g: 33, p: 33},
    value: 'sp',
  },
]

/**
 * Returns an amino map with the preset values for valid preset.
 * @param  {String} value Value of preset to apply
 * @return {Object}       Preset amino map
 */
export function applyPreset (value) {
  // Get the map from the matching preset.
  const {map} = presets.filter(preset => preset.value === value)[0]

  // We have to reshape the preset map for 'getAminoMap' to properly assign its
  // values.
  const presetMap = Object.keys(map).reduce((final, key) => ({
    ...final,
    [key]: {value: map[key]}
  }), {})

  return getAminoMap(presetMap)
}

/**
 * Returns the preset name if amino map is a match.
 * @param  {Object} aminoMap Amino map
 * @return {String}          Value of preset
 */
export function matchPreset (aminoMap) {
  // The default preset is none which should be the first option.
  const defaultValue = presets[0].value
  // Filter down to aminos with values to cut down the amount of comparisons we
  // need to make.
  const aminos = Object.keys(aminoMap).filter(key => aminoMap[key].value > 0)

  // Run a reduction that begins with the default value and iterates against
  // each preset to find a matching amino map. If no preset map matches, we
  // return the default value which should be 'None'.
  return presets.reduce((final, preset) => {
    // Return early if a preset matched outside of the default preset.
    if (final !== defaultValue) {
      return final
    }

    const {map, value} = preset
    const presetAminos = Object.keys(map)

    // Compare length first before values to cut out unneccessary comparisons.
    if (presetAminos.length !== aminos.length) {
      return final
    }

    // Run a reduction that begins with a default of the presets name. When an
    // amino value for the preset doesn't match to the amino of the provided map,
    // we replace it with the default value and continue to return early for
    // each iteration that comes afterwards to bypass unneccessary comparisons.
    return presetAminos.reduce((presetValue, amino) => {
      if (presetValue === defaultValue) {
        return presetValue
      }

      return aminoMap[amino].value === map[amino] ? value : defaultValue
    }, value)
  }, defaultValue)
}

/**
 * Returns the remaining limit for an amino map.
 * @param  {Object} aminoMap Amino map
 * @return {Number}          Remaining percentage
 */
export function getMapLimit (aminoMap) {
  return values(aminoMap).reduce((limit, amino) => {
    return limit - amino.value
  }, 100)
}
