import isNil from 'lodash/isNil'

import {isTagVariable} from './Tag'
import {DEFAULT_EXPRESSION_SYSTEM} from 'core/Constants'

export const createTemplate = template => Object.freeze({
  expressionSystem: DEFAULT_EXPRESSION_SYSTEM,
  sequence: '',
  variations: 1,
  elementOrder: [],
  tags: {},
  ...template,
})

export const makeTemplateCreator = idGenerator => ({template, idMap}) => (
  createTemplate({
    ...template,
    id: template.id || idGenerator.newId(idMap),
  })
)

export const updateTemplate = ({
  template,
  expression,
  sequence,
  variations,
  elementOrder,
  tags,
}) => Object.freeze({
  ...template,
  expressionSystem: isNil(expression) ? template.expressionSystem : expression,
  sequence: isNil(sequence) ? template.sequence : sequence,
  variations: isNil(variations) ? template.variations : variations,
  elementOrder: isNil(elementOrder) ? template.elementOrder : elementOrder,
  tags: isNil(tags) ? template.tags : tags,
})

export const isTemplateVariable = elementTags => (
  elementTags.reduce((variable, tag) => variable || isTagVariable(tag), false)
)

export default {
  create: createTemplate,
  isVariable: isTemplateVariable,
  makeCreator: makeTemplateCreator,
  update: updateTemplate,
}
