import React from 'react'
import {arrayOf, bool, func, object, string} from 'prop-types'

import FormControl from 'components/FormControl'
import MaterialIcon from 'components/MaterialIcon'
import ModalLayout from 'components/ModalLayout'
import SequenceEditor from 'components/SequenceEditor'
import { TextArea, TextInput } from 'components/text-input'
import cn from './TagModal.css'

export default function TagModal ({
  amino,
  disableNameChange,
  disableSubmit,
  editingTagDescription,
  editingTagIcon,
  editingTagProteinFamily,
  editingTagName,
  editingTagTypeIcon,
  editingTagTypeLabel,
  editingTagTypeDescription,
  editorConfigProps,
  modalAcceptText,
  modalTitle,
  onClose,
  onTagFieldChange,
  onSequenceDelete,
  onSequenceInsert,
  onSubmit,
  sequence,
  tags,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.col}>
        <div className={cn.editorHeader}>
          {`Edit ${amino ? 'AA' : 'DNA'} Sequence Below`}
        </div>
        <SequenceEditor
          {...editorConfigProps}
          className={cn.sequence}
          contextMenuProps={editorConfigProps.contextMenuProps}
          disabled={editorConfigProps.disableSequenceEditor}
          onSequenceDelete={onSequenceDelete}
          onSequenceInsert={onSequenceInsert}
          sequence={sequence}
          tags={tags}
        />
      </div>
      <ModalLayout
        acceptButtonLabel={modalAcceptText}
        disableAccept={disableSubmit}
        onAccept={onSubmit}
        onClose={onClose}
      >
        <div className={cn.body}>
          <div className={cn.header}>
            <h1 className={cn.title}>
              {modalTitle}
            </h1>
          </div>

          <FormControl
            className={cn.formControl}
            icon='label'
            idFor='tag-name'
            label='Name'
          >
            <TextInput
              disabled={disableNameChange}
              id='tag-name'
              name='name'
              onChange={onTagFieldChange}
              placeholder='Name is required'
              value={editingTagName}
            />
          </FormControl>

          <FormControl
            className={cn.formControl}
            icon='local_offer'
            label='Type'
          >
            <div className={cn.type}>
              {editingTagIcon
                ? (
                  <div className={cn.typeIconBox}>
                    <img className={cn.typeIconSet} src={editingTagIcon} />
                    <span className={cn.typeIconLabel}>
                      {editingTagTypeLabel}
                    </span>
                  </div>
                )
                : (
                  <MaterialIcon
                    className={cn.typeIconBox}
                    icon={editingTagTypeIcon}
                    iconClass={cn.typeIcon}
                    label={(
                      <span className={cn.typeIconLabel}>
                        {editingTagTypeLabel}
                      </span>
                    )}
                  />
                )}
              <p className={cn.typeDesc}>
                {editingTagTypeDescription}
              </p>
            </div>
          </FormControl>

          <FormControl
            className={cn.formControl}
            icon='description'
            idFor='tag-description'
            label='Description'
          >
            <TextArea
              id='tag-description'
              name='description'
              onChange={onTagFieldChange}
              placeholder=''
              rows={6}
              value={editingTagDescription}
            />
          </FormControl>

          {editingTagProteinFamily && (
            <FormControl
              className={cn.formControl}
              icon='group_work'
              idFor='tag-protein-family'
              label='Protein Family'
            >
              <TextInput
                disabled
                id='tag-protein-family'
                name='description'
                onChange={onTagFieldChange}
                placeholder=''
                value={editingTagProteinFamily}
              />
            </FormControl>
          )}
        </div>
      </ModalLayout>
    </div>
  )
}

TagModal.propTypes = {
  amino: bool,
  disableNameChange: bool,
  disableSubmit: bool,
  disableTypeSelect: bool,
  editingTagDescription: string,
  editingTagIcon: string,
  editingTagProteinFamily: string,
  editingTagName: string,
  editingTagTypeIcon: string,
  editingTagTypeLabel: string,
  editingTagTypeDescription: string,
  editorConfigProps: object,
  modalAcceptText: string,
  modalTitle: string,
  onClose: func.isRequired,
  onTagFieldChange: func.isRequired,
  onSequenceInsert: func.isRequired,
  onSequenceDelete: func.isRequired,
  onSubmit: func.isRequired,
  sequence: string.isRequired,
  tags: arrayOf(object).isRequired,
}

TagModal.defaultProps = {
  amino: false,
  disableNameChange: false,
  disableSubmit: false,
  disableTypeSelect: false,
  editingTagDescription: '',
  editingTagIcon: '',
  editingTagProteinFamily: '',
  editingTagName: '',
  editingTagTypeIcon: 'panorama',
  editingTagTypeLabel: 'element',
  editingTagTypeDescription: 'Tag type description',
  editorConfigProps: {},
  modalAcceptText: 'Save',
  modalTitle: 'Tag editor',
}
