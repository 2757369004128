import dnaToAminos from './dnaToAminos'
import sequenceFactory from './sequence_factory'

export {
  dnaToAminos,
  sequenceFactory,
}

export default {
  dnaToAminos,
  sequenceFactory,
}
