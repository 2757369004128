import { createSelector } from 'reselect'

import { ElementContext } from 'common'
import { getTagAsElement } from 'selectors/templates'

const isAminoElement = ({ type }) => type.indexOf('amino') > -1

export const getConstructElement = createSelector(
  getTagAsElement,
  element => ({
    ...element,
    elementContext: isAminoElement(element) ? ElementContext.AA : ElementContext.DNA,
    lengthText: element.sequenceLength,
  }),
)

export default {
  getConstructElement,
}
