import _flow from 'lodash/fp/flow'
import _get from 'lodash/fp/get'
import _values from 'lodash/values'
import {createSelector} from 'reselect'

/**
 * @root
 */
const getRoot = _get('entities')

/**
 * @helpers
 */
const getIdFromProps = (_, props) => props.id

/**
 * @base
 */
const getEnzymeEntities = _flow(getRoot, _get('enzymeElements'))
const getIGemEntities = _flow(getRoot, _get('igemElements'))
const getNCBIEntities = _flow(getRoot, _get('ncbiElements'))

/**
 * Returns an array of all enzyme elements.
 *
 * @return {Array}
 */
export const getEnzymeElements = createSelector(
  getEnzymeEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns an enzyme element with the matching id provided in props.
 *
 * @return {Array}
 */
export const getEnzymeElementById = createSelector(
  getEnzymeEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)

/**
 * Returns an array of all igem elements.
 *
 * @return {Array}
 */
export const getIGemElements = createSelector(
  getIGemEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns an iGem element with the matching id provided in props.
 *
 * @return {Array}
 */
export const getIGemElementById = createSelector(
  getIGemEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)

/**
 * Returns an array of all ncbi elements.
 *
 * @return {Array}
 */
export const getNCBIElements = createSelector(
  getNCBIEntities,
  (entities) => {
    return _values(entities)
  },
)

/**
 * Returns an ncbi element with the matching id provided in props.
 *
 * @return {Array}
 */
export const getNCBIElementById = createSelector(
  getNCBIEntities,
  getIdFromProps,
  (entities, id) => {
    return entities[id]
  },
)
