import { arrayOf, func, string } from 'prop-types'
import React, { Component } from 'react'

import { OrthologExplorerProvider } from 'components/OrthologExplorer'
import { noop } from 'utils'
import { TagType } from 'types'

import DesignerCore from './DesignerCore'

export default class DesignerCoreContainer extends Component {
  static propTypes = {
    onModalOpen: func,
    sequence: string,
    tags: arrayOf(TagType),
  }

  static defaultProps = {
    onModalOpen: noop,
    sequence: '',
    tags: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      resizedIndex: 0,
    }
  }

  handleResizeHandlerCreate = index => () => {
    this.setState({ resizedIndex: index })
  }

  render() {
    const { resizedIndex } = this.state

    return (
      <OrthologExplorerProvider>
        <DesignerCore
          {...this.props}
          onResizeHandlerCreate={this.handleResizeHandlerCreate}
          resizedIndex={resizedIndex}
        />
      </OrthologExplorerProvider>
    )
  }
}
