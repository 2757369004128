import { func } from 'prop-types'
import { Component } from 'react'

import noop from 'utils/noop'

export default class ContextMenuProvider extends Component {
  static propTypes = {
    children: func.isRequired,
  }

  constructor (props) {
    super(props)

    this.state = {
      menuPosition: { x: 0, y: 0 },
      menuProps: {},
      menuType: '',
    }
  }

  handleMenuClose = () => {
    this.handleMenuToggle()
  }

  handleMenuToggle = (
    menuPosition = { x: 0, y: 0 },
    menuType = '',
    menuProps = {},
  ) => {
    this.setState({
      menuPosition,
      menuProps,
      menuType,
    })
  }

  render () {
    const { children } = this.props
    const { menuPosition, menuProps, menuType } = this.state

    return children({
      menuPosition,
      menuProps,
      menuType,
      onContextMenuClose: !menuType ? noop : this.handleMenuClose,
      onContextMenuOpen: this.handleMenuToggle,
    })
  }
}
