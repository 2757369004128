import React, {Component} from 'react'
import {bool, func, node} from 'prop-types'

import Snackbar from './SnackbarAnimated'

export default class SnackbarContainer extends Component {
  static propTypes = {
    onAction: func,
    onClose: func.isRequired,
    open: bool,
    text: node.isRequired,
  }

  static defaultProps = {
    open: false,
  }

  constructor (props) {
    super(props)

    this.state = {
      message: props.text,
    }
  }

  componentWillReceiveProps ({text}) {
    return text && text !== this.props.text && this.setState({
      message: text,
    })
  }

  handleClose = () => {
    this.props.onClose()
  }

  handleMessageClear = () => {
    return !this.props.open && this.setState({message: ''})
  }

  render () {
    return (
      <Snackbar
        {...this.props}
        message={this.state.message}
        onClose={this.handleClose}
        onMotionEnd={this.handleMessageClear}
      />
    )
  }
}
