import React from 'react'
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types'
import cx from 'classnames'

import BaseButton from 'components/BaseButton'
import FontAwesomeButton from 'components/FontAwesomeButton'
import cn from './SelectInput.css'

export default function SelectInput ({
  activeOptionLabel,
  className,
  disabled,
  id,
  innerRef,
  label,
  labelClass,
  name,
  onChange,
  onMenuHide,
  onMenuShow,
  open,
  optionBodyClass,
  optionClass,
  options,
  toggleBodyClass,
  toggleClass,
  toggleLabelClass,
  toggleWrapperClass,
  value,
}) {
  return (
    <div className={cx(cn.base, className)} ref={innerRef}>
      <input
        className={cn.input}
        id={id}
        name={name}
        type='hidden'
        value={value}
      />

      {label && <span className={cx(cn.label, labelClass)}>{label}</span>}

      <div className={cx(cn.toggleWrapper, toggleWrapperClass)}>
        <FontAwesomeButton
          bodyClass={cx(cn.toggleBody, toggleBodyClass)}
          className={cx(cn.toggle, toggleClass)}
          disabled={disabled}
          icon='caret-down'
          label={activeOptionLabel}
          labelClass={cx(cn.toggleLabel, toggleLabelClass)}
          onBlur={onMenuHide}
          onClick={onMenuShow}
          reverseOrder
          title='Toggle options menu'
        />

        <div className={cx(cn.menu, { [cn.menuOpen]: open })}>
          {options.map(option => (
            <BaseButton
              bodyClass={cx(cn.optionBody, optionBodyClass)}
              className={cx(cn.option, optionClass)}
              key={option.key}
              name={name}
              onClick={onChange}
              value={option.value}
            >
              {option.label}
            </BaseButton>
          ))}
        </div>
      </div>
    </div>
  )
}

SelectInput.propTypes = {
  activeOptionLabel: string,
  className: string,
  disabled: bool,
  innerRef: func.isRequired,
  label: string,
  labelClass: string,
  name: string.isRequired,
  onChange: func.isRequired,
  onMenuHide: func.isRequired,
  onMenuShow: func.isRequired,
  open: bool,
  optionBodyClass: string,
  optionClass: string,
  options: arrayOf(shape({
    key: string.isRequired,
    label: node.isRequired,
    value: oneOfType([number, string]).isRequired,
  })),
  toggleBodyClass: string,
  toggleClass: string,
  toggleLabelClass: string,
  toggleWrapperClass: string,
  value: oneOfType([number, string]).isRequired,
}

SelectInput.defaultProps = {
  activeOptionLabel: '',
  className: '',
  disabled: false,
  label: '',
  labelClass: '',
  open: false,
  optionBodyClass: '',
  optionClass: '',
  options: [],
  toggleBodyClass: '',
  toggleClass: '',
  toggleLabelClass: '',
  toggleWrapperClass: '',
}
