import {
  bool,
  number,
  shape,
  string,
} from 'prop-types'

/**
 * Element object passed through the construct viewer components.
 */
export const ConstructElementType = shape({
  aminos: string,
  hasMappedDNA: bool,
  name: string.isRequired,
  sequenceLength: string,
  type: string.isRequired,
})

/**
 * Style object used to position construct elements in the construct viewer.
 */
export const ConstructElementStyleType = shape({
  position: string,
  transform: string,
  zIndex: number,
})
