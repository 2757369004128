import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import React, { Component } from 'react'
import {
  bool,
  func,
  number,
  string,
} from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { openModal } from 'actions/modal'
import { updateSequence } from 'actions/templates'
import FAIcon from 'components/FontAwesomeIcon'
import { withDrop } from 'hoc/withDragAndDrop'
import { getTemplateExpression } from 'selectors/templates'

import Element from './Element'
import { getConstructElement } from './selectors'
import { ConstructElementType, ConstructElementStyleType } from './types'

import cn from './ConstructElement.css'

export class ConstructElement extends Component {
  static propTypes = {
    disabled: bool,
    element: ConstructElementType.isRequired,
    expressionSystem: string.isRequired,
    index: number.isRequired,
    loading: bool,
    onDelete: func.isRequired,
    onDragStart: func.isRequired,
    onModalOpen: func.isRequired,
    onSequenceUpdate: func.isRequired,
    pressY: number.isRequired,
    selected: bool,
    style: ConstructElementStyleType.isRequired,
  }

  static defaultProps = {
    disabled: false,
    loading: false,
    selected: false,
  }

  shouldComponentUpdate ({
    element,
    loading,
    selected,
    style,
  }) {
    const {
      element: prevElement,
      loading: prevLoading,
      selected: prevSelected,
      style: prevStyle,
    } = this.props

    return !isEqual(style, prevStyle) || !isEqual(element, prevElement)
      || selected !== prevSelected || loading !== prevLoading
  }

  handleDNAMap = () => {
    const {
      element,
      expressionSystem,
      onModalOpen,
      onSequenceUpdate,
    } = this.props
    const { aminos, id, offset } = element

    onModalOpen('optimizeDNASettings', {
      aminoSequence: aminos,
      expressionSystem,
      onSequenceSave: ({ dnaSequence: sequence }) => {
        onSequenceUpdate({
          elementId: id,
          sequence,
          offset,
        })
      }
    })
  }

  handleDelete = (e) => {
    const { index, onDelete } = this.props

    e.stopPropagation()
    onDelete(index)
  }

  handleDragStart = (e) => {
    const {
      disabled,
      element,
      index,
      onDragStart,
      pressY,
    } = this.props

    if (disabled) {
      return
    }

    onDragStart(index, pressY, e.pageY, element)
  }

  handleEdit = (e) => {
    const { element, onModalOpen } = this.props
    const {
      id,
      length,
      offset,
      sequence,
    } = element

    e.stopPropagation()
    onModalOpen('tag', {
      editorState: 'edit',
      end: offset + length,
      id,
      sequence,
      start: offset,
    })
  }

  render () {
    const {
      disabled,
      element,
      loading,
      selected,
      style,
    } = this.props

    return (
      <div
        className={cx(cn.root, { [cn.disabled]: disabled })}
        onMouseDown={this.handleDragStart}
        role="button"
        style={style}
        tabIndex={0}
      >
        <div className={cn.menu}>
          {/* <button className={cn.menu_item}>
            <FAIcon className={cn.menu_icon} icon="info" />
          </button> */}

          <div className={cn.menu_item}>
            <button
              className={cn.menu_item_btn}
              onMouseDown={this.handleEdit}
              type="button"
            >
              <FAIcon className={cn.menu_icon} icon="pencil" />
            </button>
          </div>

          <div className={cn.menu_item}>
            <button
              className={cn.menu_item_btn}
              onMouseDown={this.handleDelete}
              type="button"
            >
              <FAIcon className={cn.menu_icon} icon="trash" />
            </button>
          </div>
        </div>

        <Element
          {...element}
          loading={loading}
          onDNAMap={this.handleDNAMap}
          selected={selected}
        />
      </div>
    )
  }
}

// An id must be present in props in order to grab the actual element object.
const mapState = (state, props) => ({
  element: getConstructElement(state, props),
  expressionSystem: getTemplateExpression(state),
  loading: state.templates.loadingTags[props.id],
})

const mapDispatch = {
  onModalOpen: openModal,
  onSequenceUpdate: updateSequence,
}

const dropSpec = {
  hover: (props, monitor) => {
    const { index, onHover, onHoverMove } = props

    const dragItem = monitor.getItem()

    dragItem.isAnimating = dragItem.isAnimating || false

    // If already animating or if indices match, do nothing
    if (dragItem.isAnimating || dragItem.index === index) {
      return
    }

    const handleSequenceChangeAnimation = (changeHandler) => {
      changeHandler()

      dragItem.isAnimating = true
      dragItem.index = index

      // Needs timer to prevent animation thrashing from elements trying to swap
      // again as their nodes cross each other.
      setTimeout(() => {
        dragItem.isAnimating = false
      }, 300)
    }

    // Undefined index means dragged item hasn't been added to sequence yet
    if (isNil(dragItem.index)) {
      handleSequenceChangeAnimation(() => {
        onHover(index)
      })
    }
    // Indices don't match so swap their elements
    else {
      handleSequenceChangeAnimation(() => {
        onHoverMove(dragItem.index, index, index)
      })
    }
  },
}

export default compose(
  connect(mapState, mapDispatch),
  withDrop(dropSpec),
)(ConstructElement)
