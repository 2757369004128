import React, {Component} from 'react'
import {arrayOf, func, number, object} from 'prop-types'

import ShareElement from './ShareElement'

export default class ShareElementContainer extends Component {
  static propTypes = {
    elementId: number.isRequired,
    onAccessAdd: func.isRequired,
    onAccessDelete: func.isRequired,
    onModalCancel: func.isRequired,
    onToastAdd: func.isRequired,
    users: arrayOf(object),
  }

  static defaultProps = {
    users: [],
  }

  isExistingUser (username = '', users = []) {
    return users.reduce((exists, user) => (
      exists || user.username === username
    ), false)
  }

  handleAccessAdd = ({access, username}) => {
    const {elementId, onAccessAdd, onToastAdd, users} = this.props

    return Promise.resolve(onAccessAdd(elementId, {access, username}))
      .then(() => {
        const context = this.isExistingUser(username, users) ? 'access has been changed' : 'added to this element'

        return onToastAdd({
          type: 'success',
          message: `${username} ${context}`,
        })
      })
      .catch(err => onToastAdd({
        type: 'error',
        message: err,
      }))
  }

  handleAccessDelete = ({username}) => {
    const {elementId, onAccessDelete, onToastAdd} = this.props

    return Promise.resolve(onAccessDelete(elementId, username))
      .then(() => onToastAdd({
        type: 'success',
        message: `${username} removed from this element`,
      }))
      .catch(err => onToastAdd({
        type: 'error',
        message: err,
      }))
  }

  render () {
    const {onModalCancel, users} = this.props

    return (
      <ShareElement
        onAccessAdd={this.handleAccessAdd}
        onAccessDelete={this.handleAccessDelete}
        onModalClose={onModalCancel}
        users={users}
      />
    )
  }
}
