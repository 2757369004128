import {connect} from 'react-redux'

import {
  addTemplate,
  deleteTemplate,
  duplicateTemplate,
  startTemplateEdit,
} from 'actions/templates'

import {getProjectId} from 'selectors/designer'
import {getTemplateOrder, getEditingTemplateId} from 'selectors/templates'
import {getIfUserCanEditProject} from 'selectors/project'

export default connect((state) => ({
  disabled: !getIfUserCanEditProject(state, {id: getProjectId(state)}),
  editingId: getEditingTemplateId(state),
  templateIds: getTemplateOrder(state),
}), {
  onTemplateAdd: addTemplate,
  onTemplateDelete: deleteTemplate,
  onTemplateDuplicate: duplicateTemplate,
  onTemplateSelect: startTemplateEdit,
})
