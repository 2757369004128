import {normalize, schema} from 'normalizr'

import {
  shapeGroup,
  shapeProject,
} from './dataShapes'

import {
  makeCustomElement,
  makeEnzymeElement,
  makeIgemElement,
  makeNcbiElement,
  makeVariableElement,
} from 'core/element/Element'

/**
 * We use this Normalizr schemas to transform API responses from a nested form
 * to a flat form where repos and users are placed in `entities`, and nested
 * JSON objects are replaced with their IDs. This is very convenient for
 * consumption by reducers, because we can easily build a normalized tree and
 * keep it updated as we fetch more data.
 *
 * Read more about Normalizr: https://github.com/paularmstrong/normalizr
 *
 * Nested example:
 * repoSchema.define({
 *   owner: userSchema
 * })
 */

/**
 * Schemas
 */
const customElementSchema = new schema.Entity('customElements', {}, {
  processStrategy: makeCustomElement,
})

const enzymeElementSchema = new schema.Entity('enzymeElements', {}, {
  processStrategy: makeEnzymeElement,
})

const groupSchema = new schema.Entity('groups', {}, {
  idAttribute: 'name',
  processStrategy: shapeGroup,
})

const igemElementSchema = new schema.Entity('igemElements', {}, {
  processStrategy: makeIgemElement,
})

const ncbiElementSchema = new schema.Entity('ncbiElements', {}, {
  idAttribute: 'accession',
  processStrategy: makeNcbiElement,
})

const projectSchema = new schema.Entity('projects', {group: groupSchema}, {
  processStrategy: shapeProject,
})

const publicElementSchema = new schema.Entity('publicElements', {}, {
  processStrategy: makeCustomElement,
})

const publicProjectSchema = new schema.Entity('publicProjects', {group: groupSchema}, {
  processStrategy: shapeProject,
})

const variableElementSchema = new schema.Entity('variableElements', {}, {
  processStrategy: makeVariableElement,
})

const schemas = {
  customElement: customElementSchema,
  customElementArray: new schema.Array(customElementSchema),
  enzymeArray: new schema.Array(enzymeElementSchema),
  group: groupSchema,
  groupArray: new schema.Array(groupSchema),
  igemArray: new schema.Array(igemElementSchema),
  ncbiArray: new schema.Array(ncbiElementSchema),
  project: projectSchema,
  projectArray: new schema.Array(projectSchema),
  publicElementArray: new schema.Array(publicElementSchema),
  publicProject: publicProjectSchema,
  publicProjectArray: new schema.Array(publicProjectSchema),
  variableElement: variableElementSchema,
  variableElementArray: new schema.Array(variableElementSchema),
}

/**
 * Applies specified schema to data structure.
 *
 * @param {Object}  data
 * @param {String}  schemaType
 * @return {Object} normalized data
 */
export default function normalizeData (data, schemaType) {
  const schemaToApply = schemas[schemaType]

  if (!schemaToApply) {
    throw new Error(`No schema type of ${schemaType} exists`)
  }

  return normalize(data, schemaToApply)
}
