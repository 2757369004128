import React, { Component } from 'react'
import { bool, func, number } from 'prop-types'

import ProjectCommitContainer from 'components/ProjectCommitContainer'
import { storageService } from 'services/storage-service'
import { ProjectForkModal } from './project-fork-modal'
import SaveSection from './ProjectForkModalSaveSection'

export default class ProjectForkModalContainer extends Component {
  static propTypes = {
    hasUnsavedChanges: bool,
    onClose: func.isRequired,
    onProjectFork: func.isRequired,
    onToastAdd: func.isRequired,
    projectId: number.isRequired,
  }

  static defaultProps = {
    hasUnsavedChanges: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      committing: false,
      showSaveSection: props.hasUnsavedChanges,
    }
  }

  handlePreCommit = () => {
    this.setState({ committing: true })
  }

  handleCommitSuccess = () => {
    this.setState({ committing: false, showSaveSection: false })
  }

  handleToggleSkip = () => {
    this.setState({ showSaveSection: !this.state.showSaveSection })
  }

  handleProjectFork = () => {
    const { onClose, onProjectFork, onToastAdd, projectId } = this.props

    return Promise.resolve(onProjectFork(projectId))
      .then(({ data }) => {
        const { id, ownerName } = data

        // Set toast in storage to be displayed in the new tab window.
        storageService.setItem('toasts', {
          1: {
            message: 'Project forked',
            type: 'success',
          },
        })
        window.open(`/${ownerName}/projects/${id}`, '_blank')
        onClose()
      })
      .catch(err => {
        onToastAdd({
          type: 'error',
          message: err,
        })
      })
  }

  render() {
    const { committing, showSaveSection } = this.state
    const { hasUnsavedChanges, onClose } = this.props
    const hasSaveSection = !showSaveSection && hasUnsavedChanges

    return (
      <ProjectForkModal
        acceptButtonLabel={showSaveSection ? 'Skip' : 'Fork'}
        cancelButtonLabel={hasSaveSection ? 'Back' : 'Cancel'}
        disableAccept={showSaveSection && committing}
        onAccept={
          showSaveSection ? this.handleToggleSkip : this.handleProjectFork
        }
        onCancel={hasSaveSection ? this.handleToggleSkip : onClose}
      >
        {showSaveSection && (
          <ProjectCommitContainer
            onPreProjectCommit={this.handlePreCommit}
            onProjectCommitSuccess={this.handleCommitSuccess}
          >
            {({
              commitMessage,
              disableAccept,
              onChangeCommitMessage,
              onProjectCommit,
            }) => (
              <SaveSection
                acceptButtonLabel={disableAccept ? 'Summary required' : 'Save'}
                commitMessage={commitMessage}
                disableAccept={disableAccept}
                onChangeCommitMessage={onChangeCommitMessage}
                onProjectCommit={onProjectCommit}
              />
            )}
          </ProjectCommitContainer>
        )}
      </ProjectForkModal>
    )
  }
}
