import React from 'react'
import {
  arrayOf,
  bool,
  func,
  string,
} from 'prop-types'

import BaseButton from 'components/BaseButton'
import SelectInput from 'components/SelectInput'
import {InputOptionType} from 'types'

import cn from './ProjectSettingsExpressionInput.css'

function ProjectSettingsExpressionInput ({
  activeExpression,
  disabled,
  expressions,
  onExpressionCreate,
  onExpressionChange,
}) {
  return (
    <div className={cn.base}>
      <div className={cn.body}>
        <SelectInput
          className={cn.body__input}
          isDisabled={disabled}
          label='Optimize Codons For'
          name="expression-system-select-input"
          onChange={onExpressionChange}
          options={expressions}
          toggleClass={cn.body__input__toggle}
          value={activeExpression}
        />
        <BaseButton
          className={cn.body__create_btn}
          onClick={onExpressionCreate}
          primary
          raised
        >
          New Expression
        </BaseButton>
      </div>
    </div>
  )
}

ProjectSettingsExpressionInput.propTypes = {
  activeExpression: string.isRequired,
  disabled: bool,
  expressions: arrayOf(InputOptionType).isRequired,
  onExpressionCreate: func.isRequired,
  onExpressionChange: func.isRequired,
}

ProjectSettingsExpressionInput.defaultProps = {
  disabled: false,
}

export default ProjectSettingsExpressionInput
